<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>
    <!-- 以下是业务表单内容 -->
    <div class="mod-__personchange">
      <vxe-table
          border
          resizable
          auto-resize
          show-overflow
          :loading="dataLoading"
          align="center"
          :row-config="{isCurrent: true, isHover: true}"
          :data="tableData">
        <vxe-colgroup :title="groupTitle">
          <vxe-column field="item" title="项目名称" width="150"></vxe-column>
          <vxe-column field="before" title="变更前"></vxe-column>
          <vxe-column field="after" title="变更后">
            <template #default="{ row }">
              <span class="after-red-color">{{ row.after }}</span>
            </template>
          </vxe-column>
        </vxe-colgroup>
      </vxe-table>
      <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
    </div>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        typeId: '',
        name1: '',
        idcard1: '',
        mobile1: '',
        post1: '',
        rank1: '',
        years1: '',
        certificate1: '',
        age1: '',
        name2: '',
        idcard2: '',
        mobile2: '',
        post2: '',
        rank2: '',
        years2: '',
        certificate2: '',
        age2: ''
      },
      tableData: [],
      dataLoading: false,

      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/opm/personChange'
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    groupTitle: function () {
      return this.dataForm.typeName ? this.dataForm.typeName + ' 变更信息' : '人员变更信息'
    }
  },
  methods: {
    init() {
      //  通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.dataLoading = true
      this.$http.get('/opm/personChange/' + this.dataForm.id).then(({data: res}) => {
        this.dataLoading = false
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.tableData.push({item: "姓名", before: this.dataForm.name1, after: this.dataForm.name2})
        this.tableData.push({item: "身份证号", before: this.dataForm.idcard1, after: this.dataForm.idcard2})
        this.tableData.push({item: "手机号", before: this.dataForm.mobile1, after: this.dataForm.mobile2})
        this.tableData.push({item: "职位", before: this.dataForm.post1, after: this.dataForm.post2})
        this.tableData.push({item: "职称", before: this.dataForm.rank1, after: this.dataForm.rank2})
        this.tableData.push({item: "从业年限", before: this.dataForm.years1 ? this.dataForm.years1 + ' 年' : '', after: this.dataForm.years2 ? this.dataForm.years2 + ' 年' : ''})
        this.tableData.push({item: "证书", before: this.dataForm.certificate1, after: this.dataForm.certificate2})
        this.tableData.push({item: "年龄", before: this.dataForm.age1 ? this.dataForm.age1 + ' 岁' : '', after: this.dataForm.age2 ? this.dataForm.age2 + ' 岁' : ''})
      }).catch(() => {
        this.dataLoading = false
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.after-red-color {
  color: red;
}
</style>