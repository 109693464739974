<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__period">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="期次名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="dataForm.contractId && $hasPermission('mps:period:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:period:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="期次名称" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="期次序号" width="100" header-align="center" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="startDate" label="开始日期" width="130" header-align="center" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="endDate" label="截止日期" width="130" header-align="center" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="isActive" label="是否为当前期次" width="130" header-align="center" align="center" sortable="custom">
          <template v-slot="{row}">
            <span v-if="row.isActive == 1" style="color: #2196F3">是</span>
            <span v-else style="color: #9E9E9E">否</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
          <template v-slot="{row}">
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:period:update')"></table-button>
            <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:period:delete')"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:period:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './period-add-or-update'
import ViewPage from './period-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/period/page',
        getDataListIsPage: true,
        exportURL: '/mps/period/export',
        deleteURL: '/mps/period',
        createdIsNeed: false
      },
      dataForm: {
        prjId: '',
        contractId: '',
        contractName: '',
        name: '',
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        if (!id) {
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        }
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>