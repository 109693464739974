<template>
  <el-dialog width="95%" fullscreen v-dialog-drag :visible.sync="visible" title="查看" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-table
      ref="table"
      v-loading="dataListLoading"
      :data="dataList"
      row-key="id"
      lazy
      :load="loadTableData"
      highlight-current-row
      border
      :max-height="tableHeight"
      style="width: 100%;"
      :row-style="{height: '0px'}"
      :cell-style="{padding: '2px 0'}">
      <el-table-column prop="code" label="编号" header-align="center" width="240" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="名称" header-align="center" min-width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="boqCodes" label="清单编码" header-align="center" align="left" width="300" show-overflow-tooltip></el-table-column>
    </el-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        code: '',
        name: '',
        sortNo: ''
      },
      dataListLoading: false,
      dataList: [],
      tableHeight: 600,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getDataList()
        }
      })
    },
    // el-table懒加载节点
    loadTableData (data,node,resolve) {
      let pid = data.id
      this.$http.get(
        '/mps/wbsTemplate/findChildren',
        {
          params: {
            versionId: this.dataForm.id,
            pid: pid,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    getDataList () {
      this.dataListLoading = true
      this.$http.get(
        '/mps/wbsTemplate/findChildren',
        {
          params: {
            versionId: this.dataForm.id,
            pid: 0,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false

        this.expandDef()

      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },

    // 展开节点
    expandDef () {
      const els = document.getElementsByClassName('el-table__expand-icon')
      this.$nextTick(() => {
        // 展开第一级的第一个节点
        if (els && els.length > 0) {
          els[0].click()
        }
      })
    },
  }
}
</script>