<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__unit">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="单位名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" @click="addOrUpdateHandle()" v-if="$hasPermission('mps:unit:save')"></toolbar-button>
            <toolbar-button role="import" @click="importHandle" v-if="$hasPermission('mps:unit:import')"></toolbar-button>
            <toolbar-button role="export" @click="exportHandle" v-if="$hasPermission('mps:unit:export')"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()" v-if="$hasPermission('mps:unit:delete')"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="可以为各计量单位设置不同的小数位精度" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="计量单位" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="accuracy" label="小数位精度" show-overflow-tooltip sortable="custom"></el-table-column>
        <!-- <el-table-column prop="sortNo" label="排序序号" width="150" header-align="center" align="center" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
          <template v-slot="{row}">
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:unit:update')"></table-button>
            <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:unit:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/unit/import" thumbnail="import_unit.jpg"></excel-import>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './unit-add-or-update'
import ViewPage from './unit-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/unit/page',
        getDataListIsPage: true,
        deleteURL: '/mps/unit',
        exportURL: `/mps/unit/export`,
        createdIsNeed: false,
      },
      dataForm: {
        prjId: '',
        name: '',
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    prjChangeHandle(data) {
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      if (this.dataForm.prjId) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.init()
        })
      } else {
        this.$message({
          message: '请先选择一个项目！',
          type: 'warning',
          duration: 3000,
          onClose: () => {
            // do nothing
          }
        })
      }
    },
  }
}
</script>