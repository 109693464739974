<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="auto">
      <el-form-item label="上级清单" prop="pid">
        <boq-parent-select v-model="dataForm.pid" :contractId="dataForm.contractId"></boq-parent-select>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="清单编号">
            <el-input v-model="dataForm.code" placeholder="清单编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="清单名称">
            <el-input v-model="dataForm.name" placeholder="清单名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitId" label="单位">
            <unit-select v-model="dataForm.unitId" :contract-id="dataForm.contractId"></unit-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单价" prop="price">
            <el-input placeholder="单价" v-model="dataForm.price">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同数量" prop="initNum">
            <el-input-number v-model="dataForm.initNum" :controls="false" style="width: 100%"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="计量规则">
            <el-select v-model="dataForm.ruleId" clearable placeholder="请选择" class="w-percent-100">
              <el-option
                  v-for="item in ruleOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计量前置条件" prop="precondition">
            <el-select v-model="dataForm.precondition" style="width: 100%;">
              <el-option label="无" :value="0"></el-option>
              <el-option label="安全生产费" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="remark" label="备注">
        <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="备注" v-model="dataForm.remark"></el-input>
      </el-form-item>
      <el-form-item prop="separate" v-if="dataForm.isLeaf">
        <el-checkbox v-model="dataForm.separate" :true-label="1" :false-label="0">计量报表单列显示</el-checkbox>
      </el-form-item>
      <!-- <el-form-item v-if="dataForm.pnames" label="名称路径" prop="pnames">
        {{ dataForm.pnames }}
      </el-form-item> -->
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import {checkNumber} from '@/utils/form-validate'
import BoqParentSelect from './boq-parent-select'
export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          pid: '',
          name: '',
          code: '',
          unitName: '',
          contractId: '', // 合同标段ID
          statusId: 0, // 清单状态 枚举，0：未锁定，1：已锁定
          typeId: 1,// 清单类型：默认原始清单
          price: null,
          initNum: null, // 原始数量
          initAmount: null,// 原始金额
          checkedNum: null,
          remark: '',
          sortNo: 0,
          separate: 0, // 在计量报表中单列
          ruleId:'', // 计量规则Id
          precondition: 0,  // 计量前置条件
        },

        oldPid: '', // 记录数据原始的上级节点ID

        ruleOptions:[]
      }
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          code: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          price: [
            {validator: checkNumber, trigger: 'change'}
          ],
          pid: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ]
        }
      }
    },
    components: {
      BoqParentSelect
    },
  methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          this.getMeasRuleList()
          if (this.dataForm.id) {
            this.getInfo()
          } else {
            // 获取排序序号
            this.getNewSortNo()
          }
        })
      },
      // 获取信息
      getInfo () {
        this.$http.get(`/mps/boq/${this.dataForm.id}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.oldPid = this.dataForm.pid
        }).catch()
      },
      // 获取排序序号
      getNewSortNo () {
        this.$http.get(`/mps/boq/${this.dataForm.contractId}/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.sortNo = res.data
        }).catch()
      },
      // 获取计量规则
      getMeasRuleList(){
        this.$http.get(`/mps/boq/openapi/measRule/${this.dataForm.contractId}/list`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.ruleOptions = res.data
        }).catch()
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          // 计算原始金额
          if (this.dataForm.initNum && this.dataForm.price) {
            this.dataForm.initAmount = this.dataForm.initNum * this.dataForm.price
          }

          this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/boq', this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (this.dataForm.id) {
              this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
            } else {
              this.$emit('addNode', this.dataForm.pid)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
              }
            })
          }).catch()
        })
      },1000,{'leading': true,'trailing': false})
    }
  }
</script>

<style lang="scss">
  .mod-mps__boq {

  .parentNode-list {

  .el-input__inner,
  .el-input__suffix {
    cursor: pointer;
  }

  }
  }
</style>
