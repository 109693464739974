<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__safetycost">
      <el-form key="first" :model="dataForm" :rules="dataRule" :validate-on-rule-change="false" ref="dataForm" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="期次" prop="periodName">
              <el-input v-model="dataForm.periodName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标题" prop="name">
              <el-input v-model="dataForm.name" placeholder="名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" placeholder="备注说明" :autosize="{ minRows: 3 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <my-upload :refId="dataForm.id"></my-upload>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" size="mini" @click="addData()">添加</el-button>
      <vxe-table id="mxTable"
                 keep-source
                 ref="mxTableRef"
                 :loading="mxDataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataForm.details"
                 :max-height="tableHeight"
                 :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
        <vxe-column field="sortNo" title="序号" width="60" align="center" fixed="left" :edit-render="{ name: '$input', props: {type: 'number',min: 1} }"></vxe-column>
        <vxe-column field="name" title="费用条目" fixed="left" :edit-render="{ name: '$input'}"></vxe-column>
        <vxe-column field="money" title="本期发生金额（元）" width="160" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
        <vxe-column field="remark" title="备注说明" :edit-render="{ name: '$input'}"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneMxHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<script>
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        contractId: '',
        name: '',
        remark: '',
        details: [],
        periodName: ''
      },
      mxDataListLoading: false,
      tableHeight: 400,

      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/opm/safetyCost'
      }
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  components: {},
  methods: {
    init() {
      //  通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },

    // 回调函数：用于给起草人修改保存被驳回的流程
    async submitCallback(processCallback) {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '正在提交...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // 获取明细的修改、新增、删除行
        this.setMxData()

        if (this.process.updateURL) {
          // 提交业务表单信息
          this.$http.put(this.process.updateURL, this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              loading.close()
              return this.$message.error(res.msg)
            }
            // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
            processCallback(() => {
              // 最后一步执行完毕后的回调函数
              loading.close()
            })
          }).catch(() => {
            loading.close()
          })
        } else {
          // 如果没有在xxx-process.vue中配置updateURL属性，则不需要执行该接口，直接将该被驳回的流程提交到流程下一步阶段。
          // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
          processCallback(() => {
            // 最后一步执行完毕后的回调函数
            loading.close()
          })
        }
      })
    },

    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/opm/safetyCost/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },

    setMxData() {
      const {removeRecords} = this.$refs.mxTableRef.getRecordset()
      this.dataForm.addMxList = []
      this.dataForm.updateMxList = []
      let fullDataList = this.$refs.mxTableRef.getTableData().fullData
      if (fullDataList && fullDataList.length > 0) {
        for (let i = 0; i < fullDataList.length; i++) {
          let mxData = fullDataList[i]
          let isOk = mxData.name // 费用名称为空，默认不保存该条明细
          if (isOk) {
            // 新增
            if (!mxData.id) {
              this.dataForm.addMxList.push(mxData)
            } else {
              // 修改
              this.dataForm.updateMxList.push(mxData)
            }
          }
        }
      }

      let deleteMxIdList = []
      if (removeRecords && removeRecords.length > 0) {
        removeRecords.forEach(function (element) {
          if (element && element['id']) {
            deleteMxIdList.push(element['id'])
          }
        })
      }
      this.dataForm.deleteMxIdList = deleteMxIdList
    },
    // 添加费用记录
    addData() {
      const $table = this.$refs.mxTableRef
      // 列表末尾新增费用记录
      $table.insertAt({
        id: '',
        name: '',
        remark: '',
        money: null,
        sortNo: this.$refs.mxTableRef.getTableData().fullData.length+1
      }, -1)
    },
    // 删除一行明细数据
    deleteOneMxHandle(row) {
      let $table = this.$refs.mxTableRef
      if ($table != null) {
        $table.remove(row)
      }
    },
  }
}
</script>
