<template>
  <div :style="{width: '100%',height: height}">
    <div :key="id" :id="id"></div>
  </div>
</template>

<script>

let script
// 脚本标识
const scriptId = 'onlyoffice-editor'
// 异步加载 api.js
const loadScript = () => new Promise((resolve, reject) => {
  const src = `${window.SITE_CONFIG['onlyofficeDocumentServerUrl']}/web-apps/apps/api/documents/api.js`
  script = document.querySelector(`#${scriptId}`)
  // 加载成功
  const onLoad = () => {
    resolve()
    script.removeEventListener('load', onLoad)
  }
  // 加载失败
  const onError = () => {
    reject(new Error(`脚本 ${src} 加载失败`))
    script.removeEventListener('error', onError)
  }
  if (!script) {
    script = document.createElement('script')
    script.id = scriptId
    script.src = src
    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)
    document.head.appendChild(script)
  } else if (window.DocsAPI) {
    resolve()
  } else {
    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)
  }
})

export default {
  name: 'OnlyofficeEditor',
  props: {
    config: {
      type: Object,
      default: null
    },
    height: {  // 文档高度
      type: [Number, String],
      default: '100vh'
    }
  },
  data() {
    return {
      id: `editor-${new Date().getTime().toString('32')}`,
      consolePrint: true, // 控制台是否打印各种事件的触发情况
    }
  },
  watch: {
    config: {
      handler() {
        this.initEditor()
      },
      deep: true
    }
  },
  beforeDestroy() {
    // 组件销毁前销毁编辑器
    if (this.editor) {
      this.editor.destroyEditor()
      this.editor = null
    }
  },
  methods: {
    // 初始化编辑器
    initEditor() {
      loadScript(this.src).then(this.createEditor)
    },
    // 创建编辑器
    createEditor() {
      if (this.consolePrint) console.log("createEditor")
      if (this.editor) {
        this.editor.destroyEditor()
        this.editor = null
      }
      this.config.events = {
        ...this.config.events,
        "onAppReady": this.onAppReady,
        "onCollaborativeChanges": this.onCollaborativeChanges,
        "onDocumentReady": this.onDocumentReady,
        "onDocumentStateChange": this.onDocumentStateChange,
        "onDownloadAs": this.onDownloadAs,
        "onError": this.onError,
        "onInfo": this.onInfo,
        // "onMetaChange": this.onMetaChange,
        // "onMakeActionLink": this.onMakeActionLink,
        "onOutdatedVersion": this.onOutdatedVersion,
        // "onPluginsReady": this.onPluginsReady,
        // "onRequestClose": this.onRequestClose,
        // "onRequestCreateNew": this.onRequestCreateNew,
        // "onRequestEditRights": this.onRequestEditRights,
        // "onRequestHistory": this.onRequestHistory,
        // "onRequestHistoryClose": this.onRequestHistoryClose,
        // "onRequestHistoryData": this.onRequestHistoryData,
        // "onRequestInsertImage": this.onRequestInsertImage,
        // "onRequestOpen": this.onRequestOpen,
        "onRequestReferenceData": this.onRequestReferenceData,
        // "onRequestReferenceSource": this.onRequestReferenceSource,
        // "onRequestRename": this.onRequestRename,
        // "onRequestRestore": this.onRequestRestore,
        // "onRequestSaveAs": this.onRequestSaveAs,
        // "onRequestSelectDocument": this.onRequestSelectDocument,
        // "onRequestSelectSpreadsheet": this.onRequestSelectSpreadsheet,
        // "onRequestSendNotify": this.onRequestSendNotify,
        // "onRequestSharingSettings": this.onRequestSharingSettings,
        // "onRequestUsers": this.onRequestUsers,
        "onWarning": this.onWarning,
      }
      if (window.DocsAPI) this.editor = new window.DocsAPI.DocEditor(this.id, this.config)
    },
    // 显示message信息
    // 只能在onAppReady后调用
    showMessage(message) {
      this.editor.showMessage(message)
    },
    // 文档编辑器就绪时触发
    onAppReady() {
      if (this.consolePrint) console.log("onAppReady触发")
    },
    // the function called when the document is co-edited by the other user in the strict co-editing mode.
    onCollaborativeChanges() {
      if (this.consolePrint) console.log("onCollaborativeChanges触发")
    },
    // 文档加载好后触发
    onDocumentReady() {
      if (this.consolePrint) console.log("onDocumentReady触发")
    },
    // 文档被修改后触发，如果仅是修改，参数为{"data": true} ,如果该修改被发送至document editing service（也就是后台）,参数为{"data" : false}
    onDocumentStateChange(event) {
      if (event.data) {
        if (this.consolePrint) console.log("onDocumentStateChange触发：文档被修改");
      } else {
        if (this.consolePrint) console.log("onDocumentStateChange触发：修改已被document editing service接收");
      }
    },
    // 当用户点击“下载为”时触发
    onDownloadAs(event) {
      let fileType = event.data.fileType;
      let url = event.data.url;
      if (this.consolePrint) console.log("onDownloadAs触发：url: " + url);
    },
    // error codes参考：https://github.com/ONLYOFFICE/sdkjs/blob/master/common/errorCodes.js
    onError(event) {
      if (this.consolePrint) console.log("onError触发: code " + event.data.errorCode + ", description " + event.data.errorDescription)
    },
    // 文件被打开时触发，mode为“view”或“edit”
    onInfo(event) {
      if (this.consolePrint) console.log("onInfo触发，文件被打开，模式为： <" + event.data.mode + ">");
    },
    // the function called when the meta information of the document is changed via the meta command.
    // The name of the document is sent in the data.title parameter. The Favorite icon highlighting state is sent in the data.favorite parameter.
    // When the user clicks the Favorite icon, the setFavorite method is called to update the information about the Favorite icon highlighting state. If the method is not declared, the Favorite icon will not be changed.
    // 暂时用不到
    onMetaChange(event) {
      if (this.consolePrint) console.log("onMetaChange触发:")
      if (this.consolePrint) console.log(event)
    },
    // 当用户尝试去获取一个带有书签的文档链接时触发
    // 暂时用不到
    onMakeActionLink(event) {},
    // 当用户打开了一个旧版本的文档时（多发生于此文档刚好在用户打开的时刻被保存为一个新版本）触发
    // 当此事件触发时，文档编辑器应该被重新初始化并在config中传入一个新的document.key
    onOutdatedVersion() {
      if (this.consolePrint) console.log("onOutdatedVersion触发")
      location.reload()
      // 发布outdated事件触发父组件中的刷新逻辑
      // this.$emit('outdated')
    },
    // 当所有插件加载完成并可以使用时触发
    // 暂时用不到
    onPluginsReady() {
      if (this.consolePrint) console.log("onPluginsReady触发")
    },
    // 调用requestClose()方法，编辑器会检查用户是否有未保存的修改，如果有的话会弹窗询问用户，若用户选择关闭编辑器，则此事件触发
    onRequestClose() {
      if (this.consolePrint) console.log("onRequestClose触发")
      // 以下为官网示例代码，强制关闭编辑器窗口并销毁editor对象
      if (window.opener) {
        window.close();
        return;
      }
      this.editor.destroyEditor();
    },
    // 当用户点击<新建>按钮时触发，此事件可以替代config中createUrl的作用（即在这里实现新建文档的逻辑）
    // 若没有配置此事件，且createUrl未声明，则<新建>按钮不会显示
    onRequestCreateNew() {},
    // 当用户尝试将view模式切换至edit模式时触发（点击<编辑文档>按钮--位于文件名右侧的笔图标）
    // 当此事件触发后，必须用edit模式重新初始化编辑器，如果此事件没有声明则<编辑文档>按钮不会显示
    // 注：若模式为<view>且permissions 中edit为true,则必须声明此事件
    onRequestEditRights() {},
    // 当用户点击<历史版本>按钮时触发，当此事件和onRequestHistoryData事件没有声明时，<历史版本>按钮不会显示
    // 暂时用不到
    onRequestHistory() {},
    // 当用户从查看历史版本中点击<关闭历史>按钮时触发，当触发此事件时，必须手动重新初始化文档编辑器
    // 暂时用不到
    onRequestHistoryClose() {},
    // 与onRequestHistory一起使用
    // 暂时用不到
    onRequestHistoryData(event) {},
    // 当用户点击 <插入>→<图片>→<存储设备中的图片>按钮时触发, 当此事件未声明时，此<插入>→<图片>→<存储设备中的图片>按钮不会显示
    // 此处的存储设备应该是指文件存储服务器
    // 暂时用不到
    onRequestInsertImage(event) {},
    // 当用户点击<External links>→<Open source>（中文为外部链接→开源）时触发，此事件如果未声明，则<Open source>按钮不会显示
    // **当用户点击<开源>按钮时，onlyoffice会自动打开一个空白窗口，目前没有找到原因，所以此事件暂时不用
    onRequestOpen(event) {
      if (this.consolePrint) console.log("onRequestOpen触发")
      // 根据fileKey查询对应文件的signedUrl并在新窗口打开
      // this.$http.get(`oss/file/${event.data.referenceData.fileKey}`).then(({ data: res }) => {
      //   if (res.code !== 0) {
      //     return this.$message.error(res.msg)
      //   }
      //   console.log(window.location.protocol)
      //   if (window.location.protocol == 'http:' && res.data.signedUrl.startsWith('https:')) {
      //     res.data.signedUrl = res.data.signedUrl.replace('https:', 'http:')
      //   }
      //   window.open(res.data.signedUrl)
      // }).catch(() => {
      // })
    },
    // 当用户点击<External links>→<Update values>（中文为外部链接→更新数据）时触发，此事件如果未声明，则<Update values>和<Paste link>按钮不会显示
    onRequestReferenceData(event) {
      if (this.consolePrint) console.log('onRequestReferenceData触发')
      this.$http.post(
          '/onlyoffice/onlyoffice/reference',
          {
            referenceData: event.data.referenceData,
          },
      ).then(({data: res}) => {
        if (res.error == 1) {
          this.$message.error(res.message)
        } else {
          // 此处调用setReferenceData后，才会更新数据
          this.editor.setReferenceData(res)
        }
      }).finally(() => {
        // do nothing
      })
    },

    // 当用户点击<External links>→<Change source>时触发，此事件如果未声明，则<Change source>按钮不会显示
    // 暂时用不到
    onRequestReferenceSource(event) {},
    // 当用户点击<重新命名>时触发，此事件如果未声明，则<重新命名>按钮不会显示
    // 暂时不用
    onRequestRename(event) {
      if (this.consolePrint) console.log('onRequestRename触发')
      let newTitle = event.data
    },
    // 当用户点击历史版本中的<Restore>按钮时触发，此事件如果未声明，则<Change source>按钮不会显示
    // 用于回退至某历史版本
    // 暂时用不到
    onRequestRestore(event) {},
    // 当用户点击<另存副本为>按钮时触发，此事件如果未声明，则<另存副本为>按钮不会显示
    // 暂时用不到
    onRequestSaveAs(event) {
      if (this.consolePrint) console.log('onRequestSaveAs触发')
      let fileType = event.data.fileType;
      let title = event.data.title;
      let url = event.data.url;
      // 需要实现另存为的逻辑
    },
    // 用于document比较时的事件
    // 暂时用不到
    onRequestSelectDocument() {
    },
    // the function called when the user is trying to select recipients data by clicking the Mail merge button.
    // 暂时用不到
    onRequestSelectSpreadsheet() {
    },
    // 当用户在某个评论中被提及（mentioned）时触发
    // 暂时用不到
    onRequestSendNotify() {
    },
    // 当用户点击<更改访问权限>按钮时触发，此事件如果未声明，则<更改访问权限>按钮不会显示
    // 暂时用不到
    onRequestSharingSettings() {
    },
    // 当用户在评论中选择被提及（mention）的用户时、选择能修改特定单元格的用户时、设置用户头像时触发
    // 暂时用不到
    onRequestUsers() {
    },
    // 当一个warning发生时触发
    // error codes参考：https://github.com/ONLYOFFICE/sdkjs/blob/master/common/errorCodes.js
    onWarning(event) {
      if (this.consolePrint) console.log(`onWarning触发, code: ${event.data.warningCode}, description: ${event.data.warningDescription}`)
    }
  }
}
</script>
