<template>
  <el-dialog v-dialog-drag width="350px" :visible.sync="visible" :title="$t('add')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="计划年月" prop="planDate">
            <el-date-picker style="width: 100%" v-model="dataForm.planDate" format="yyyy年MM月" value-format="yyyy-MM-dd" type="month" placeholder="请选择"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from "xe-utils";

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        planDate: '',
        y: '',
        m: ''
      }
    }
  },
  computed: {
    dataRule() {
      return {
        planDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        // 计划年月 默认值为当前日期所在年月
        this.dataForm.planDate = XEUtils.toDateString(new Date(),'yyyy-MM-dd')
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        // 根据计划年月，重新计算月计划的年份、月份
        if (this.dataForm.planDate) {
          let ymdList = this.dataForm.planDate.split('-')
          this.dataForm.y = ymdList[0]
          this.dataForm.m = ymdList[1]
        }

        this.$http['post']('/mps/materialMonthPlan/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>