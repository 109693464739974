<template>
  <el-dialog width="80%" title="查看" :visible.sync="visible" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="wbsTab">
        <el-form :model="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="编号" prop="code">
                <el-input v-model="dataForm.code" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="层级" prop="levelId">
                <el-select v-model="dataForm.levelId" disabled :style="{ width: '100%' }" clearable>
                  <el-option label="单位工程" :value="1"/>
                  <el-option label="子单位工程" :value="2"/>
                  <el-option label="分部工程" :value="3"/>
                  <el-option label="子分部工程" :value="4"/>
                  <el-option label="分项工程" :value="5"/>
                  <el-option label="子分项工程" :value="6"/>
                  <el-option label="未知层级" :value="-1"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级" prop="parentName">
                <el-input v-model="dataForm.parentName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="桩号" prop="stakeNo">
                <el-input v-model="dataForm.stakeNo" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图号" prop="drawingNo">
                <el-input v-model="dataForm.drawingNo" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="unitId" label="单位">
                <unit-select v-model="dataForm.unitId" :contract-id="dataForm.contractId" :disabled="true"></unit-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="num">
                <el-input-number v-model="dataForm.num" :controls="false" placeholder="数量" style="width: 100%;" readonly></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="形象单元" prop="scheduleUnitId">
                <scheduleunit-single-select v-model="dataForm.scheduleUnitId" :prjId="dataForm.prjId" readonly></scheduleunit-single-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="wbsTypeId">
                <el-select v-model="dataForm.wbsTypeId" placeholder="请选择节点工程类型" :style="{ width: '100%' }" disabled>
                  <el-option label="未知类型" value="UNKNOWN"/>
                  <el-option label="路基工程" value="SUBGRADE"/>
                  <el-option label="路面工程" value="PAVEMENT"/>
                  <el-option label="桥梁工程" value="BRIDGE"/>
                  <el-option label="涵洞工程" value="CULVERT"/>
                  <el-option label="机电工程" value="ELECTRICAL"/>
                  <el-option label="环保工程" value="ENV"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input v-model="dataForm.remark" type="textarea" :autosize="{ minRows: 2,maxRows:10 }" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="dataForm.pnames" label="名称路径" prop="pnames">
                {{ dataForm.pnames }}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <el-link type="primary" @click="openIfcModel" :underline="false">查看模型</el-link>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="构件属性" name="wbsPropValTab">
        <vxe-table id="wbsPropValTable"
                   keep-source
                   ref="mxAddWbsProValTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="dataForm.wbsPropValDetails"
                   :max-height="tableHeight"
                   :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
          <vxe-column field="propName" title="构件属性名称" width="300" fixed="left"></vxe-column>
          <vxe-column field="propCode" title="构件属性编号" width="200" fixed="left"></vxe-column>
          <vxe-column field="val" title="构件属性值" min-width="110" fixed="left"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
    <!-- ifc模型浏览组件 -->
    <ifc-viewer v-if="ifcViewerVisible" ref="ifcViewer"></ifc-viewer>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '0', // 根节点默认为0
        prjId: '',
        name: '',
        code: '',
        sortNo: 0,
        num: '',
        remark: '',
        parentName: ''
      },
      activeTabName: 'wbsTab',
      mxDataListLoading: false,
      tableHeight: 400,
      ifcViewerVisible: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {},
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/wbs/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = '无'
        }
      }).catch(() => {
      })

      this.mxDataListLoading = true
      this.$http.get('/mps/wbs/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = '无'
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },
    // 查看ifc模型
    openIfcModel() {
      this.ifcViewerVisible = true
      this.$nextTick(() => {
        this.$refs.ifcViewer.init(this.dataForm.id)
      })
    },
  }
}
</script>
