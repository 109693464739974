<template>
  <el-card shadow="never" class="aui-card--fill">
    <el-form inline size="mini">
      <el-form-item>
        <contract-cascader include-sub @change="contractChange"></contract-cascader>
      </el-form-item>
      <el-form-item>
        <period-select v-model="dataForm.periodId" :contract-id="dataForm.contractId" defaultSelect @change="periodChangeHandle"></period-select>
      </el-form-item>
      <el-form-item v-if="dataForm.periodId">
        <process-status v-if="dataForm.measId" btnSize="small" :biz="meas" :procTitle="meas.name"
            @beforeProcessSend="beforeProcessSendHandle" @refreshDataList="afterProcessStartHandle">
        </process-status>
        <el-button icon="el-icon-document-add" type="primary" v-else @click="createNewMeas">{{ startNewMeasBtn }}</el-button>
      </el-form-item>
      <div v-if="totalAmount !== null" style="float: right;display: inline;margin-right: 5px">
        本期计量金额&nbsp;<span style="color: blue; font-size: 25px">{{ totalAmount }}</span>&nbsp;元
      </div>
    </el-form>
    <el-tabs type="border-card">
      <el-tab-pane label="计量明细">
        <my-container :left-width="300">
          <template v-slot:left>
            <el-form :inline="true" :model="dataForm">
              <!-- 若没选择期次，则不显示按钮组 -->
              <el-form-item v-if="dataForm.measId">
                <el-button-group>
                  <el-button v-if="canEdit()" icon="el-icon-plus" @click="batchAdd()" size="mini" key="1">新增</el-button>
                  <!-- <toolbar-button  role="add" v-if="canEdit()" @click="batchAdd()"></toolbar-button> -->
                  <!-- <toolbar-button key="2" name="批量新增" v-if="canEdit()" @click="batchAdd()"></toolbar-button>-->
                  <!-- <toolbar-button key="3" name="明细台账" v-if="dataForm.measId" @click="mxReport()"></toolbar-button>-->
                </el-button-group>
              </el-form-item>
              <div v-else style="height: 40px"></div>
            </el-form>
            <div class="tree-container">
              <vxe-table
                  ref="tree"
                  resizable
                  :loading="treeLoading"
                  show-overflow
                  height="auto"
                  border="none"
                  :show-header="false"
                  :tooltip-config="{theme: 'light'}"
                  :row-config="{isCurrent: true, isHover: true, useKey: true, height: 27, keyField: 'id'}"
                  :tree-config="{transform: true, showLine: true, iconOpen: 'vxe-icon-caret-right rotate45', iconClose: 'vxe-icon-caret-right', parentField: 'pid'}"
                  :data="treeData"
                  cell-class-name="pointer-cursor"
                  @cell-mouseenter="mouseEnter"
                  @cell-mouseleave="mouseLeave"
                  @cell-click="treeCellClick"
              >
                <vxe-column field="name" width="700" tree-node>
                  <template v-slot="{ row }">
                    <span>
                      <span v-if="row.isLeaf" style="color: blue">{{ row.name }}</span>
                      <span v-else>{{ row.name }}</span>
                      <span v-show="row.show">
                        <el-button type="text" style="color: red; padding-left: 30px;" icon="el-icon-close" size="mini" @click.stop="() => deleteHandle(row.measTicketId)" v-if="canEdit()"></el-button>
                      </span>
                    </span>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </template>
          <template v-slot:right>
            <el-tabs v-model="tabActiveName" type="border-card" :style="{height: rightTabsHeight + 'px'}">
              <el-tab-pane label="基本内容" name="0">
                <meas-ticket-list v-show="measTicketInfoTabType == 1" ref="measTicketList" @measTicketClick="measTicketListClickHandle" :tableHeight="measMxTableHeight"></meas-ticket-list>
                <meas-ticket-info-update v-show="measTicketInfoTabType == 2" ref="measTicketInfoUpdate" @wbsChanged="fetchMeasTicketQualityFile"></meas-ticket-info-update>
                <meas-ticket-info-view v-show="measTicketInfoTabType == 3" ref="measTicketInfoView"></meas-ticket-info-view>
              </el-tab-pane>
              <el-tab-pane label="清单明细" name="1">
                <meas-mx ref="mx" :tableHeight="measMxTableHeight"></meas-mx>
              </el-tab-pane>
              <el-tab-pane label="计量草图" name="2" v-if="ticketDataForm.measTicketId">
                <meas-ticket-draft-pic ref="measTicketDraftPic" :height="measMxTableHeight"></meas-ticket-draft-pic>
              </el-tab-pane>
              <el-tab-pane label="质保资料" name="3" v-if="ticketDataForm.measTicketId">
                <meas-ticket-quality-file ref="measTicketQualityFile" :height="measMxTableHeight"></meas-ticket-quality-file>
              </el-tab-pane>
              <el-tab-pane label="计量附件" name="4" v-if="ticketDataForm.measTicketId">
                <meas-ticket-file-list ref="measTicketFileList" :height="measMxTableHeight"></meas-ticket-file-list>
              </el-tab-pane>
            </el-tabs>
          </template>
        </my-container>
      </el-tab-pane>
      <el-tab-pane label="费用明细">
        <meas-cost :canEdit="canEdit()" :measId="dataForm.measId" :processStatus="meas.processStatus" :period="period" :period-id="dataForm.periodId" :contract-id="dataForm.contractId"></meas-cost>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹窗，批量新增计量单及明细 -->
    <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="batchAddSuccessHandle" @close="closeDialogHandle"></batch-add>
    <!-- 明细台账 -->
    <mx-report v-if="mxReportVisible" ref="mxReport" @close="closeDialogHandle"></mx-report>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import MeasMx from './meas-mx'
import MeasCost from './meascost'
import BatchAdd from './meas-batch-add'
import MxReport from "@/views/modules/mps/meas-mx-report";
import MeasTicketInfoUpdate from "@/views/modules/mps/meas-ticket-info-update";
import MeasTicketInfoView from "@/views/modules/mps/meas-ticket-info-view";
import MeasTicketList from "@/views/modules/mps/meas-ticket-list";
import MeasTicketDraftPic from "@/views/modules/mps/meas-ticket-draft-pic";
import MeasTicketFileList from "@/views/modules/mps/meas-ticket-file-list";
import MeasTicketQualityFile from "@/views/modules/mps/meas-ticket-quality-file";
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        deleteURL: '/mps/measTicket',
        createdIsNeed: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        contractId: '',
        subcontractorId: '',
        periodId: '',
        measId: '',
      },
      ticketDataForm: {
        measTicketId: '',
        subItemId: '',
        subItemName: '',
      },
      treeLoading: false,
      treeData: [],
      batchAddVisible: false,
      mxReportVisible: false,
      period: {},
      meas: {},
      contract: {},
      rightTabsHeight: 550,
      measMxTableHeight: 400,
      // 计量单页签显示的类型：1.列表，2.编辑表单，3.查看表单
      measTicketInfoTabType: 1,
      startNewMeasBtn: '开始本期计量',
      // 本期计量金额
      totalAmount: null,
      tabActiveName: '0',
    }
  },
  components: {
    MeasMx,
    MeasCost,
    BatchAdd,
    MxReport,
    MeasTicketInfoUpdate,
    MeasTicketInfoView,
    MeasTicketList,
    MeasTicketDraftPic,
    MeasTicketFileList,
    MeasTicketQualityFile
  },
  created() {
    let _height = window.innerHeight - 200
    this.rightTabsHeight = _height
    this.measMxTableHeight = _height - 50
    this.$bus.$on('measAmountChange', () => {
      this.getTotalAmount()
    })
  },
  beforeDestroy() {
    this.$bus.$off('measAmountChange')
  },
  methods: {
    contractChange(data) {
      this.dataList = []
      this.meas = {}
      this.dataForm.periodId = ''
      this.dataForm.measId = ''
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      if (data.contract) {
        this.contract = data.contract
      }
      this.query()
    },
    periodChangeHandle(period) {
      if (period == null) {
        return
      }
      this.period = period
      this.startNewMeasBtn = `开始${period.name}计量`
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/meas/getByPeriodId/${this.dataForm.periodId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.id) {
          this.dataForm.measId = res.data.id
          this.meas = res.data
        } else {
          this.dataForm.measId = ''
          this.meas = {}
        }
        this.ticketDataForm = {
          measTicketId: '',
          subItemId: '',
          subItemName: '',
        },
        this.getTreeData()
      }).catch(() => {
        // do nothing
      }).finally(() => {
        this.getTotalAmount()
        loading.close()
      })
    },
    // 获取本期计量总金额
    getTotalAmount() {
      if (this.dataForm.measId) {
        this.$http.get('/mps/meas/totalAmount/' + this.dataForm.measId).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.totalAmount = XEUtils.commafy(res.data) || 0
        }).catch(() => {
          // do nothing
        })
      } else {
        this.totalAmount = null
      }
    },
    createNewMeas() {
      if (!this.$hasPermission('mps:meas:save')) {
        this.$alert(`当前登录用户没有权限，不能开启新的计量！`, '操作出错', {
          confirmButtonText: '关闭',
        })
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '请稍候...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http['post']('/mps/meas/', {
        contractId: this.dataForm.contractId,
        subcontractorId: this.dataForm.subcontractorId,
        periodId: this.dataForm.periodId,
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.measId = res.data.id
        this.meas = res.data
        // 自动打开新增窗口
        this.batchAdd()
      }).catch(() => {}).finally(() => {
        loading.close()
      })
    },
    // 此处重写mixinViewModule中的query方法，因为在树节点被删除后，此方法会被自动调用，从而刷新树
    query() {
      this.getTreeData()
    },
    /**
     * 用于新增计量单后，在树上自动展开其父节点，便于用户操作
     * @param defaultExpandId 需要自动展开并点击的节点id
     */
    getTreeData(defaultExpandId) {
      if (this.dataForm.measId) {
        this.treeLoading = true
        this.$http.get(
            `/mps/measTicket/treeList/${this.dataForm.measId}`).then(({data: res}) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          }
          this.treeData = res.data
          if (this.treeData.length > 0) {
            let rootRow = {
              id: '0',
              pid: '-1',
              name: '全部',
            }
            this.treeData.push(rootRow)
            this.$nextTick(() => {
              if (defaultExpandId) {
                this.treeAutoExpandAndClick(defaultExpandId)
              } else {
                // 动态判断需要默认展开的节点
                this.$refs.tree.setTreeExpand(this.getDefaultExpandNodes(), true)
              }
            })
          }
        }).catch(() => {
          this.$message.error('加载出错')
        }).finally(() => {
          this.treeLoading = false
        })
        // 如果不需要自动点击树的节点，则直接刷新右侧tab页数据（目的：1.清空某些页；2.某些页在不点击左侧树的情况下需要查全量数据）
        if (!defaultExpandId) {
          this.fetchRightTabData()
        }
      } else {
        this.treeData = []
        // 此处是为了清空右侧tab页中的数据
        this.fetchRightTabData()
      }
    },
    // 动态判断出来需要默认展开的节点
    getDefaultExpandNodes(){
      let expandNodes = []
      if (this.treeData) {
        if (this.treeData.length <= 20) {// 如果少于20个，则全部展开
          expandNodes = this.treeData
        } else {
          this.treeData.forEach(element => {// 否则要展开第一级和第二级
            if (element.id == '0' || element.pid == '0') {
              expandNodes.push(element)
            }
          })
        }
      }
      return expandNodes
    },
    // 将左侧树展开至给定的subItemId的节点处，并模拟点击选中
    treeAutoExpandAndClick(subItemId) {
      let expandRows = []
      let parentRow
      let rowId = subItemId
      do {
        parentRow = this.$refs.tree.getParentRow(rowId)
        expandRows.push(parentRow)
        rowId = parentRow.id
      } while (parentRow.pid != -1)
      this.$refs.tree.setTreeExpand(expandRows, true)
      // 找到当前行，将其选中并触发点击事件
      let currentRow = this.$refs.tree.getRowById(subItemId)
      this.$refs.tree.setCurrentRow(currentRow)
      this.treeCellClick({row: currentRow, triggerTreeNode: false})
    },
    // 左侧树节点的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.$refs.tree.setTreeExpand(row, true)
        this.ticketDataForm.measTicketId = row.measTicketId
        this.ticketDataForm.subItemName = row.pnames + '/' + row.name
        this.ticketDataForm.subItemId = row.id
        if (this.tabActiveName > '1') {
          this.tabActiveName = '0'
        }
        this.$nextTick(() => {
          this.fetchRightTabData()
        })
      }
    },
    measTicketListClickHandle(subItemId) {
      this.treeAutoExpandAndClick(subItemId)
    },
    /**
     * 批量新增成功的回调方法
     * @param subItemId 刷新左侧树后默认展开此id对应的节点
     */
    batchAddSuccessHandle(subItemId) {
      this.getTreeData(subItemId)
      this.$bus.$emit('measAmountChange')
    },
    // 重新获取右侧tab各页签中的数据
    fetchRightTabData() {
      this.fetchMeasTicketInfo()
      this.fetchMeasMx()
      if (this.ticketDataForm.measTicketId) {
        this.fetchMeasTicketDraftPic()
        this.fetchMeasTicketQualityFile()
        this.fetchMeasTicketFileList()
      }
    },
    // 获取计量单信息页签中的数据
    fetchMeasTicketInfo() {
      if (!this.ticketDataForm.measTicketId) {
        this.measTicketInfoTabType = 1
        this.$refs.measTicketList.dataForm.measId = this.dataForm.measId
        this.$refs.measTicketList.dataForm.subItemId = this.ticketDataForm.subItemId
        this.$refs.measTicketList.ticketType = this.contract.ticketType
        this.$refs.measTicketList.getDataList()
      } else if (this.canEdit()) {
        this.measTicketInfoTabType = 2
        this.$refs.measTicketInfoUpdate.dataForm.id = this.ticketDataForm.measTicketId
        this.$refs.measTicketInfoUpdate.measForm.contractId = this.dataForm.contractId
        this.$refs.measTicketInfoUpdate.measForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.measTicketInfoUpdate.getInfo()
      } else {
        this.measTicketInfoTabType = 3
        this.$refs.measTicketInfoView.dataForm.id = this.ticketDataForm.measTicketId
        this.$refs.measTicketInfoView.getInfo()
      }
    },
    // 获取计量明细页签中的数据
    fetchMeasMx() {
      this.$refs.mx.canEdit = this.canEdit()
      this.$refs.mx.dataForm.mainId = this.ticketDataForm.measTicketId
      this.$refs.mx.dataForm.subItemId = this.ticketDataForm.subItemId
      this.$refs.mx.dataForm.measId = this.dataForm.measId
      this.$refs.mx.subItemName = this.ticketDataForm.subItemName
      this.$refs.mx.processStatus = this.meas ? this.meas.processStatus : null
      this.$refs.mx.allowExceed = this.contract.allowExceed
      this.$refs.mx.ticketType = this.contract.ticketType
      this.$refs.mx.getDataList()
    },
    // 获取计量草图页签中的数据
    fetchMeasTicketDraftPic() {
      this.$refs.measTicketDraftPic.canEdit = this.canEdit()
      this.$refs.measTicketDraftPic.refId = this.ticketDataForm.measTicketId
      this.$refs.measTicketDraftPic.loadFile()
    },
    // 获取质保资料页签中的数据
    fetchMeasTicketQualityFile() {
      this.$refs.measTicketQualityFile.canEdit = this.canEdit()
      this.$refs.measTicketQualityFile.measTicketId = this.ticketDataForm.measTicketId
      this.$refs.measTicketQualityFile.init()
    },
    // 获取计量附件页签中的数据
    fetchMeasTicketFileList() {
      this.$refs.measTicketFileList.canEdit = this.canEdit()
      this.$refs.measTicketFileList.refId = this.ticketDataForm.measTicketId
    },
    // 删除左侧计量单节点后的回调方法: 若删除的节点刚好是当前选中的节点，则清空计量单选择的各个属性，清空右侧tab页签中的数据
    deleteSuccessCallback(measTicketId) {
      if (this.ticketDataForm.measTicketId == measTicketId) {
        this.ticketDataForm.measTicketId = ''
        this.ticketDataForm.subItemName = ''
        this.ticketDataForm.subItemId = ''
      }
      this.$bus.$emit('measAmountChange')
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.addOrUpdateVisible = false
      this.viewVisible = false
      this.batchAddVisible = false
      this.mxReportVisible = false
    },
    // 根据各参数判断是否可以对计量进行修改
    canEdit() {
      if (this.$hasPermission('mps:meas:update') && !this.isProcessExist(this.meas.processStatus)) {
        return true
      } else {
        return false
      }
    },
    // 批量新增
    batchAdd() {
      this.batchAddVisible = true
      this.$nextTick(() => {
        this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchAdd.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.batchAdd.dataForm.measId = this.dataForm.measId
        this.$refs.batchAdd.dataForm.periodId = this.dataForm.periodId
        this.$refs.batchAdd.allowExceed = this.contract.allowExceed
        this.$refs.batchAdd.title = `${this.period.name}计量申请`
        this.$refs.batchAdd.init()
      })
    },
    mxReport() {
      this.mxReportVisible = true
      this.$nextTick(() => {
        this.$refs.mxReport.dataForm.measId = this.dataForm.measId
        this.$refs.mxReport.processExist = this.isProcessExist(this.meas.processStatus)
        this.$refs.mxReport.init()
      })
    },
    // 支持在流程发起前进行一些业务处理（比如：校验业务数据是否完整）
    beforeProcessSendHandle(callback) {
      // 校验所有明细行（包括费用计量的明细）的【本期申请数量】是否都填写了
      this.$http.get(`/mps/meas/verifyNum/${this.meas.id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.length > 0) {
          return this.$alert(res.data, '提示信息', {
            confirmButtonText: '关闭'
          })
        } else {
          callback() // 注意：必须执行这个回调（该回调函数运行的是查询流程模板，及进行必要的相关流程校验）
        }
      }).catch()
    },
    afterProcessStartHandle(e){
      this.meas = {...this.meas, ...e}
      this.getTreeData()
    },
    mouseEnter({row}) {
      if (row.isLeaf) {
        this.$set(row, 'show', true)
      }
    },
    mouseLeave({row}) {
      this.$set(row, 'show', false)
    },
  }
}
</script>
<style lang="scss" scoped>
  .tree-container {
    overflow: auto;
    height: calc(100vh - 240px);
  }
</style>