<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractName">
            <el-input v-model="dataForm.contractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="工区名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区简称" prop="shortName">
            <el-input v-model="dataForm.shortName" placeholder="工区简称" maxlength="6" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="工区编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" placeholder="起讫桩号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总监单位" prop="chiefSupervisionId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.chiefSupervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.supervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="咨询单位" prop="consultationId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.consultationId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" autosize></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      prjId: '',
      dataForm: {
        id: '',
        contractId: '',
        contractName: '',
        name: '',
        shortName: '',
        code: '',
        sspo: '',
        chiefSupervisionId: '',
        supervisionId: '',
        consultationId: '',
        remark: '',
        authority: '',
        sortNo: '',
        delFlag: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      row: null,
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        shortName: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/subcontractor/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/subcontractor/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList', this.row)
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>