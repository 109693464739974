<template>
  <el-dialog :modal-append-to-body="true" :append-to-body="true" :visible.sync="visible" :title="title" v-on="$listeners" width="95%" top="30px">
    <el-row>
      <el-col :span="21">
        <div :style="[divStyle1]">
          <div id="luckysheet" style="margin:0px;padding:0px;position:absolute;width:100%;height:100%;left: 0px;"></div>
        </div>
      </el-col>
      <el-col :span="3">
        <el-card style="width: 220px; margin-left: 10px;">
          <div slot="header" style="font-size: 18px; color: #FF33CC">
            <span>参考示例参数</span>
          </div>
          <div class="rlit">{项目名称}</div>
          <div class="rlit">{标段名称}</div>
          <div class="rlit">{工区名称}</div>
          <div class="rlit">{建设单位}</div>
          <div class="rlit">{施工单位}</div>
          <div class="rlit">{监理单位}</div>
          <div class="rlit">{起讫桩号}</div>
          <div class="rlit">{里程桩号}</div>
          <div class="rlit">{期次}</div>
          <div class="rlit">{合同编号}</div>
          <div class="rlit">{部位}</div>
          <div class="rlit">{图号}</div>
        </el-card>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="closeMe">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>
 
<script>
export default {
  name: "MyLuckysheetDesign",
  data() {
    return {
      visible: false,
      divStyle1: {
        width: '100%',
        height: '560px',
        border: '1px solid #ddd',
        position: 'relative'
      },
      title: '报表设计',
      refId: '',
      jsonData: ''
    }
  },
  
  methods: {
    initLuckysheet () {
      this.visible = true
      this.divStyle1.height = `${(document.documentElement.clientHeight || document.body.clientHeight) - 200}px`
      var _this = this
      this.$nextTick(() => {
        $(function () {
          luckysheet.create({
            container: "luckysheet", // 设定DOM容器的id
            title: "Luckysheet", // 设定表格名称
            lang: "zh", // 设定表格语言
            allowEdit: true,
            data:  eval("(" + _this.jsonData + ")").data,
            //data: JSON.parse(_this.jsonData),
            // data: [
            //   {
            //     name: "sheet1", //工作表名称
            //     index: 0, //工作表索引(新增一个工作表时该值是一个随机字符串)
            //     status: 1, //激活状态
            //     order: 0, //工作表的下标
            //     hide: 0, //是否隐藏
            //     row: 36, //行数
            //     column: 15, //列数
            //     defaultRowHeight: 28, //自定义行高,单位px
            //     defaultColWidth: 100, //自定义列宽,单位px
            //     celldata: [], //初始化使用的单元格数据,r代表行，c代表列，v代表该单元格的值，最后展示的是value1，value2
            //     isPivotTable: false, //是否数据透视表
            //     zoomRatio: 1, // 缩放比例
            //     showGridLines: 1, //是否显示网格线
            //   },
            // ],
            showtoolbar: true,
            showtoolbarConfig: {
              undoRedo: true,
              paintFormat: true, // 格式刷
              currencyFormat: true, // 货币格式
              percentageFormat: true, // 百分比格式
              numberDecrease: false, // '减少小数位数'
              numberIncrease: false, // '增加小数位数
              moreFormats: true, // '更多格式'
              font: true, // '字体'
              fontSize: true, // '字号大小'
              bold: true, // '粗体 (Ctrl+B)'
              italic: true, // '斜体 (Ctrl+I)'
              strikethrough: true, // '删除线 (Alt+Shift+5)'
              underline: true, // '下划线 (Alt+Shift+6)'
              textColor: true, // '文本颜色'
              fillColor: true, // '单元格颜色'
              border: true, // '边框'
              mergeCell: true, // '合并单元格'
              horizontalAlignMode: true, // '水平对齐方式'
              verticalAlignMode: true, // '垂直对齐方式'
              textWrapMode: true, // '换行方式'
              textRotateMode: false, // '文本旋转方式'
              image: true, // '插入图片'
              link: false, // '插入链接'
              chart: false, // '图表'
              postil: true, //'批注'
              pivotTable: false,  //'数据透视表'
              function: true, // '公式'
              frozenMode: true, // '冻结方式'
              sortAndFilter: false, // '排序和筛选'
              conditionalFormat: false, // '条件格式'
              dataVerification: false, // '数据验证'
              splitColumn: false, // '分列'
              screenshot: false, // '截图'
              findAndReplace: false, // '查找替换'
              protection:false, // '工作表保护'
	            print:false,
            },
            showsheetbar: false, // 是否显示底部sheet页按钮
            showsheetbarConfig: {
              add: false, // 新增sheet
              menu: false, // sheet管理菜单
              sheet: false, // sheet页显示
            },
            showinfobar: false, // 是否显示顶部信息栏
            showstatisticBar: false, // 是否显示底部计数栏
            showstatisticBarConfig: {
              count: false, // 计数栏
              view: false, // 打印视图
              zoom: false, // 缩放
            },
            sheetFormulaBar: false, // 是否显示公式栏
            allowCopy: true, // 是否允许拷贝
            enableAddRow: false, // 允许添加行
            enableAddBackTop: false // 回头顶部
          })
        })
      })
    },
    dataFormSubmitHandle () {
      luckysheet.exitEditMode()
      this.$emit('saveData', this.refId, luckysheet.getAllSheets())
      this.visible = false
    },
    closeMe () {
      this.visible = false
      luckysheet.exitEditMode()
    }
  }
}
</script>
<style>
  .luckysheet-cols-menu {z-index: 9004;}
  .luckysheet-input-box {z-index: 3000}
  .rlit {
    font-size: 14px; padding-bottom: 2px;
  }
</style>