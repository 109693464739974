<template>
  <div class="mod-__meas">
    <el-table
        v-loading="dataListLoading"
        ref="table"
        :data="dataList"
        border
        row-key="id"
        highlight-current-row
        style="width: 100%;"
        :cell-style="{padding: '2px 0'}"
    >
<!--      <el-table-column :selectable="()=>false" type="selection" align="center" width="50"></el-table-column>-->
      <el-table-column prop="itemCode" label="费用编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="itemName" label="费用名称" show-overflow-tooltip>
        <template v-slot="{row}">
          <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.itemName}}</el-button>
        </template>
      </el-table-column>
<!--          <el-table-column prop="itemStatus" label="支付/扣回" width="150" header-align="center" align="center" show-overflow-tooltip :formatter="statusFormatter"></el-table-column>-->
      <el-table-column prop="contractAmount" label="签约合同价（元）" align="right" show-overflow-tooltip>
        <template v-slot="{row}">
          <span>{{ moneyFormatter(row.contractAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sumByLastPeriod" label="上期末完成（元）" align="right" show-overflow-tooltip>
        <template v-slot="{row}">
          <span>{{ moneyFormatter(row.sumByLastPeriod) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="申请金额(元)" align="right" show-overflow-tooltip>
        <template v-slot="{row}">
          <span>{{ row.num }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkedNum" label="审定金额(元)" align="right" show-overflow-tooltip>
        <template v-slot="{row}">
          <el-input v-if="taskHandling" v-model="row.checkedNum" @change="rowEdit(row)"></el-input>
          <span v-else>{{ row.checkedNum }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="opinion" label="审核意见" width="300" align="left" show-overflow-tooltip>
        <template v-slot="{row}">
          <el-input v-if="taskHandling" v-model="row.opinion" @change="rowEdit(row)"></el-input>
          <span v-else>{{row.opinion}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" align="center" width="200">
        <template v-slot="{row}">
          <el-button type="text" @click="showLog(row)">审定日志</el-button>
          <table-button role="files" @click="uploadDrawerHandle(row.id, [], true)"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <!-- 弹窗, 查看 -->
    <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
    <!-- 审批记录 -->
    <log v-if="logVisible" ref="log" @close="closeDialogHandle"></log>
  </div>
</template>
<script>
// 2、引入流程混入代码
import mixinViewModule from "@/mixins/view-module";
import {isNumber} from "@/utils/validate";
import ViewPage from './meascost-view'
import Log from './measlog'
import processModule from "@/mixins/process-module";

export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/measCostMx/page',
        getDataListIsPage: true,
        deleteURL: '/mps/measCostMx',
        createdIsNeed: false,
        fetchDataSuccessCallback: this.tableDoLayout
      },
      dataForm: {
        measId: '',
      },
      logVisible: false,
      // 流程节点id
      taskId: '',
    }
  },
  created () {
    this.init()
  },
  watch: {
  },
  props: {
    taskHandling: Boolean,
  },
  components: {
    ViewPage,
    Log,
  },
  methods: {
    init () {
      // 4、通过流程获取业主主表ID
      this.dataForm.measId = this.$route.params.businessKey
      this.$nextTick(() => {
        this.taskId = this.$route.params.taskId
        this.$http.get('/mps/meas/' + this.dataForm.measId).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.getDataList()
        }).catch(() => {
          // do nothing
        })
      })
    },
    // 在el-table-column上使用v-if，以及在header插槽中使用v-if等有可能会改变单元格大小的情况，都可能会导致表格错乱
    // 所以调用此方法让table重新计算各单元格宽高以避免错乱问题
    tableDoLayout() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.viewVisible = false
      this.logVisible = false
    },
    // 修改审定金额和审核意见
    rowEdit(row) {
      if (!isNumber(row.checkedNum)) {
        this.$message({
          message: '审定金额必须为数字！',
          type: 'warning',
          duration: 3000,
          onClose: () => {
            // do nothing
          }
        })
        row.checkedNum = null
      } else {
        // row.checkedNum = XEUtils.toFixed(row.checkedNum, this.amountAccuracy)
        this.$http['put'](
            '/mps/measLog/measCostProcessUpdate',
            {
              mxId: row.id,
              taskId: this.taskId,
              quantity: row.checkedNum,
              opinion: row.opinion
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).catch(() => {})
      }
    },
    // 查看审批记录
    showLog (row) {
      this.logVisible = true
      this.$nextTick(() => {
        this.$refs.log.dataForm.mxId = row.id
        this.$refs.log.init()
      })
    },
    statusFormatter(row, column) {
      switch (row.itemStatus) {
        case 1:
          return "支付";
        case 2:
          return "扣回";
        default:
          return "";
      }
    },
    moneyFormatter(cellValue) {
      // return XEUtils.commafy(cellValue)
      return cellValue
    },
  }
}
</script>
