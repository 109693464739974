<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="320">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              v-if="treeShow"
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="treeProps"
              ref="tree"
              :load="loadSbsChildrenData"
              @node-click="treeNodeClick"
              highlight-current lazy>
              <span slot-scope="{ node, data }">
                <template>
                  <template v-if="data.isLeaf && data.wbsId != null && data.wbsId != ''"><i class="my-circle"></i></template>
                </template>
                <span>{{ node.label }}</span>
              </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <el-form :inline="true" :model="dataForm">
          <el-form-item>
            <vxe-input style="width: 300px;" v-model="filterName" type="search" placeholder="试试按分项名称搜索吧..." clearable @keyup.enter.native="searchEvent" @search-click="searchEvent" @clear="searchEvent"></vxe-input>
          </el-form-item>
          <el-form-item>
            <el-alert title="关联标段分项（计量单元）与分部分项（质检单元）" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
          </el-form-item>
        </el-form>
        <div v-show="!showFilterTable" class="mod-__wbs">
          <el-table
              v-if="tableShow"
              ref="wbsTable"
              v-loading="tableLoading"
              :data="tableData"
              row-key="id"
              lazy
              :load="loadWbsChildrenData"
              border
              highlight-current-row
              style="width: 100%;"
              :max-height="tableHeight"
              :row-style="{height: '0px'}"
              :row-class-name="rowClassName"
              :cell-style="{padding: '2px 0'}">
            <el-table-column prop="name" label="工程名称" show-overflow-tooltip>
              <template v-slot="{row}">
                <i v-if="row.isLeaf && row.sbsBound" class="my-circle"></i>
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="工程编号" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column label="质保资料状态" show-overflow-tooltip align="center">
              <el-table-column label="开工报告" width="80" align="center" show-overflow-tooltip>
                <template v-slot="{row}">
                  <el-tooltip v-if="row.qaFileState >= 1" class="item oss-tooltip" effect="light" content="开工报告已上传" placement="bottom">
                    <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '1')"></i>
                  </el-tooltip>
                  <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="开工报告未上传" placement="bottom">
                    <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '1')"></i>
                  </el-tooltip>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column label="检验申请批复" width="100" align="center" show-overflow-tooltip>
                <template v-slot="{row}">
                  <el-tooltip v-if="row.qaFileState >= 2" class="item oss-tooltip" effect="light" content="检验申请批复已上传" placement="bottom">
                    <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '2')"></i>
                  </el-tooltip>
                  <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="检验申请批复未上传" placement="bottom">
                    <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '2')"></i>
                  </el-tooltip>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column label="交工证书" width="80" align="center" show-overflow-tooltip>
                <template v-slot="{row}">
                  <el-tooltip v-if="row.qaFileState >= 3" class="item oss-tooltip" effect="light" content="交工证书已上传" placement="bottom">
                    <i class="el-icon-check" style="color: green; cursor: pointer;" @click="openQualityFileDialog(row, '3')"></i>
                  </el-tooltip>
                  <el-tooltip v-else-if="row.isLeaf" class="item oss-tooltip" effect="light" content="交工证书未上传" placement="bottom">
                    <i class="el-icon-close" style="color: red; cursor: pointer;" @click="openQualityFileDialog(row, '3')"></i>
                  </el-tooltip>
                  <span v-else></span>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- <el-table-column label="分部分项工程" header-align="center" align="center" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="currentSelectedWbs && scope.row.wbsId == currentSelectedWbs.id" style="color: #00DD00; font-weight: bold; font-size: 14px;">{{ scope.row.wbsName }}</span>
                <span v-else>{{ scope.row.wbsName }}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template v-slot="{row}">
                <vxe-checkbox v-if="row.isLeaf && currentSelectedSbs != null" v-model="myCheckBoxModel[row.id]" size="medium" @change="myCheckedHandle(myCheckBoxModel[row.id], row)"></vxe-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <vxe-table
            ref="filterTable"
            v-show="showFilterTable"
            resizable
            :loading="filterTableLoading"
            :max-height="tableHeight"
            :tooltip-config="{ theme: 'light' }"
            :row-config="{ isCurrent: true, isHover: true, useKey: true, keyField: 'id' }"
            :data="filterTableData"
            :scroll-y="{ enabled: true }">
          <vxe-column field="name" title="分项工程" width="300"></vxe-column>
          <vxe-column field="code" title="工程编号" width="160"></vxe-column>
          <vxe-column field="pnames" title="工程路径" min-width="170" show-overflow></vxe-column>
          <vxe-column title="操作" width="70" align="center" fixed="right">
            <template v-slot="{row}">
              <vxe-checkbox v-if="row.isLeaf && currentSelectedSbs != null" v-model="myCheckBoxModel[row.id]" size="medium" @change="myCheckedHandle(myCheckBoxModel[row.id], row)"></vxe-checkbox>
            </template>
          </vxe-column>
        </vxe-table>
      </template>
    </my-container>
    <div :class="shakeDiv">
      <div>
        <span style="color: #4169E1;" v-if="currentSelectedSbs != null">[选择的标段分项]：{{ (currentSelectedSbs.pnames != null && currentSelectedSbs.pnames != '') ? currentSelectedSbs.pnames + '/' + currentSelectedSbs.name : currentSelectedSbs.name }}</span>
      </div>
      <div style="padding-bottom: 5px;">
        <span style="color: #FF4500;" v-if="currentSelectedSbs != null">[关联的分部分项]：{{ (currentSelectedSbs.wbsPnames != null && currentSelectedSbs.wbsPnames != '') ? (currentSelectedSbs.wbsPnames + '/' + currentSelectedSbs.wbsName) : currentSelectedSbs.wbsName }}</span>
      </div>
    </div>
    <!-- 弹窗查看质检资料 -->
    <quality-file v-if="qualityFileVisible" ref="qualityFile" @close="closeQualityFile"></quality-file>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import QualityFile from './wbs-quality-file'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        showEmptyDataWarningMessage: true
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
      },
      treeLoading: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: this.isLeaf
      },
      treeShow: true,

      tableLoading: false,
      tableData: [],
      tableShow: true,
      tableHeight: 600,

      currentSelectedSbs: null, // 当前选中的标段分项
      selectMsg: '请选择具体的标段分项',
      currentMessage: null,
      filterName: '',
      filterTableLoading: false,
      filterTableData: [],
      showFilterTable: false,
      qualityFileVisible: false,
      // 当前打开的质检资料弹窗的行，用于关闭弹窗后更新这一行的质检资料状态
      currentQualityFileRow: null,
      myCheckBoxModel: {},

      shakeDiv: '',
      shakeDivTimer: '',
    }
  },
  components: {
    QualityFile
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 180;
    })
  },
  methods: {
    // 项目、标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.tableData = []
      this.treeData = []
      this.currentSelectedSbs = null
      this.getSbsRootData()
      this.getWbsRootData()
    },
    // 加载sbs根节点
    getSbsRootData() {
      this.treeLoading = true
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
        this.$http.get('/mps/sbs/children',
            {
              params: {
                pid: 0,
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },
    // 加载wbs树根节点
    getWbsRootData() {
      this.tableLoading = true
      this.tableShow = false
      
      this.$nextTick(() => {
        this.tableShow = true
        this.$http.get('/mps/sbsWbsBind/children',
          {
            params: {
              pid: 0,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
          this.initMyCheckBoxModel(res.data)
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.tableLoading = false
        })
      })
    },
    // 懒加载sbs子节点
    loadSbsChildrenData(node, resolve) {
      if (node.level != 0) {
        let pid = node.data.id
        this.$http.get('/mps/sbs/children',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // 懒加载wbs子节点
    loadWbsChildrenData(data, node, resolve) {
      let pid = data.id
      this.$http.get('/mps/sbsWbsBind/children',
        {
          params: {
            'pid': pid,
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data)
        this.initMyCheckBoxModel(res.data)
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    initMyCheckBoxModel(data){
      if (data && data.length > 0) {
        data.forEach(element => {
          this.myCheckBoxModel[element.id] = element.id == (this.currentSelectedSbs ? this.currentSelectedSbs.wbsId : null)
        })
      }
    },
    refreshMyCheckBoxModel(wbsId){
      if (this.myCheckBoxModel) {
        for (const key in this.myCheckBoxModel) {
          if (this.myCheckBoxModel.hasOwnProperty(key)) {
            if (key == wbsId) {
              this.myCheckBoxModel[key] = true
            } else {
              this.myCheckBoxModel[key] = false
            }
          }
        }
      }
    },
    isLeaf(data, node) {
      return data.isLeaf;
    },
    // 点击左侧树节点
    treeNodeClick(data, node, ele) {
      this.filterName = data.name
      if (!node.isLeaf) {
        this.$refs.tree.setCurrentKey(null)
        this.currentSelectedSbs = null
        this.$message({
          message: this.selectMsg,
          type: 'warning',
          duration: 2000
        })
      } else {
        if (!this.currentSelectedSbs || this.currentSelectedSbs.id != data.id) {
          this.currentSelectedSbs = data

          this.refreshMyCheckBoxModel(this.currentSelectedSbs.wbsId)

          if (this.currentMessage) {// 避免页面中同时打开多个message
            this.currentMessage.close()
          }
          this.currentMessage = this.$message({
            message: `当前选择的标段分项：${data.name}`,
            type: 'info',
            duration: 10000
          })
          
          // 上下抖动，提示用户当前选择的标段分项
          this.shakeDiv = 'shake-div'
          if (this.shakeDivTimer) {
            clearTimeout(this.shakeDivTimer)
          }
          this.shakeDivTimer = setTimeout(()=> {
            this.shakeDiv = ''
          }, 3000)
        }
      }
    },
    // 通过右侧勾选，完成关联（或取消关联）
    myCheckedHandle(selected, row) {
      if (!this.currentSelectedSbs) {
        return this.$message.error(this.selectMsg)
      }
      if (selected) {
        if (this.currentSelectedSbs.wbsId && this.currentSelectedSbs.wbsId != row.id) {
          this.$confirm(`确定要建立标段分项[${this.currentSelectedSbs.name}]与分部分项[${row.name}]的关联吗？`, '提示', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            row.sbsBound = true // 动态更新页面中分部分项工程前的小圆点状态
            this.updateBindData(this.currentSelectedSbs.id, row)
          }).catch(() => {
            this.refreshMyCheckBoxModel(this.currentSelectedSbs.wbsId)
            row.sbsBound = true // 动态更新页面中分部分项工程前的小圆点状态
          })
        } else {
          row.sbsBound = true // 动态更新页面中分部分项工程前的小圆点状态
          this.updateBindData(this.currentSelectedSbs.id, row)
        }
      } else {
        row.sbsBound = false // 动态更新页面中分部分项工程前的小圆点状态
        this.$confirm(`确定取消[${this.currentSelectedSbs.name}]与[${row.name}]的关联吗？`, '提示', {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.updateBindData(this.currentSelectedSbs.id, null)
        }).catch(() => {
          this.refreshMyCheckBoxModel(this.currentSelectedSbs.wbsId)
          row.sbsBound = true // 动态更新页面中分部分项工程前的小圆点状态
        })
      }
    },
    updateBindData(sbsId, row) {
      const loading = this.$loading({
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 更新sbs的wbsId
      this.$http['put']('/mps/sbsWbsBind/cascadeUpdateWbsId', {id: sbsId, wbsId: row ? row.id : null}).then(({data: res}) => {
        if (res.code != 0) {
          return this.$message.error(res.msg)
        }
        if (row) {
          this.currentSelectedSbs.wbsId = row.id
          this.currentSelectedSbs.wbsName = row.name
          this.currentSelectedSbs.wbsPnames = row.pnames
        } else {
          this.currentSelectedSbs.wbsId = null
          this.currentSelectedSbs.wbsName = ''
          this.currentSelectedSbs.wbsPnames = ''
        }

        this.refreshMyCheckBoxModel(this.currentSelectedSbs.wbsId)
        
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 2000
        })
      }).catch((error) => {
        return this.$message.error('出错了！' + error)
      }).finally(() => {
        loading.close()
      })
    },
    // 动态行样式
    rowClassName({row, rowIndex}) {
      if (this.currentSelectedSbs && row.id == this.currentSelectedSbs.wbsId) {
        return 'row-red'
      } else {
        return ''
      }
    },
    // 搜索sbs
    searchEvent() {
      if (this.filterName !== '') {
        this.showFilterTable = true
        this.filterTableLoading = true
        this.$http.get('/mps/wbs/search',
            {
              params: {
                keyword: this.filterName,
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.filterTableData = res.data
          this.initMyCheckBoxModel(res.data)
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.filterTableLoading = false
        })
      } else {
        this.showFilterTable = false
      }
    },
    // 打开质检资料弹窗
    openQualityFileDialog(row, type) {
      this.currentQualityFileRow = row
      this.qualityFileVisible = true
      this.$nextTick(() => {
        this.$refs.qualityFile.dataForm.wbsId = row.id
        this.$refs.qualityFile.activeTabName = type
        this.$refs.qualityFile.canEdit = true
        this.$refs.qualityFile.init()
      })
    },
    // 质检资料弹窗关闭
    closeQualityFile() {
      this.qualityFileVisible = false
      // 更新这一行的质检资料状态
      let id = this.currentQualityFileRow.id
      this.$http.get(`/mps/wbs/${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.currentQualityFileRow.qaFileState = res.data.qaFileState
      }).catch(() => {
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 200px);
}
::v-deep .row-red {
  background-color: #FF99FF !important;
}
.my-circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  background-color: #00b95e;
  border-radius: 50%;
}
@keyframes shake {
  0% { transform: translate(0, 0); }
  50% { transform: translate(0, 10px); }
  100% { transform: translate(0, 0); }
}
.shake-div {
  animation: shake 0.5s infinite;
}
</style>
