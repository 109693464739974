<template>
  <el-dialog :visible.sync="visible" :title="title" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm"
             label-width="auto" size="small">
      <span>复制此节点及其下级节点, 将复制 </span>
      <el-input-number v-model="dataForm.copyTimes" :min="1" :max="20" controls-position="right" style="width: 85px;"></el-input-number>
      <span> 份, 生成的编号分别为: {{dataForm.codePrefix + dataForm.codeStartAt}} 至 {{dataForm.codePrefix + (dataForm.codeStartAt + dataForm.copyTimes - 1)}}</span>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '',
        code: '',
        codePrefix: '',
        codeStartAt: 0,
        copyTimes: 2,
      },
      parentId: '',
      title: '批量复制'
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getCodeRule()
      })
    },
    // 计算编号规则
    getCodeRule() {
      let matches = this.dataForm.code.match(/[0-9]+$/);
      if (matches) {
        this.dataForm.codeStartAt = parseInt(matches[0], 10) + 1
        this.dataForm.codePrefix = this.dataForm.code.substring(0, matches.index)
      } else {
        this.dataForm.codeStartAt = 2
        this.dataForm.codePrefix = this.dataForm.code
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      const loading = this.$loading({
        lock: true,
        text: '正在处理数据...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post('/mps/wbsBatch/batchCopy', this.dataForm).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$emit('refreshParent', this.parentId)
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
          }
        })
      }).catch(() => {
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>