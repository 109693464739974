<template>
  <div>
    <vxe-table id="vxeTable" keep-source ref="table"
               size="mini"
               border
               :loading="tableLoading"
               show-overflow
               :row-config="{keyField: 'key',isCurrent: true,isHover: true}"
               :data="dataList"
               header-align="center"
               :max-height="tableHeight"
               :edit-rules="editRules"
               :merge-cells="mergeCells"
               :edit-config="{trigger: 'click', mode: 'row', showStatus:true}">
      <vxe-column field="code" title="项次" width="50" align="center" fixed="left"></vxe-column>
      <vxe-column field="name" title="检查项目" min-width="150" align="center" fixed="left" >
        <template v-slot="{ row, rowIndex }">
        <span>
            {{ row.name }}
        </span>
          <el-tooltip placement="top-start" content="增加设计值" effect="dark" :open-delay="100">
            <i class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 14px" @click="addDesign(rowIndex)"></i>
          </el-tooltip>
        </template>
      </vxe-column>
      <vxe-column field="unit" title="单位" align="center" width="60"></vxe-column>
      <vxe-column field="formula" title="规定值或允许偏差" :show-overflow="false" align="center" width="130">
        <template v-slot="{ row }">
          <span v-if="formulaPattern(row.formula) != null">
              {{ row.formula }}<br>
              {{ row.formulaDetail ?? ''}}
            <el-tooltip placement="top-start" content="补充说明" effect="dark" :open-delay="100">
              <i class="el-icon-edit-outline" :style="{color: row.formulaDetail?'#0093ff':'red',cursor:'pointer','font-size': '13px'}" @click="formulaSupplement(row)"></i>
            </el-tooltip>
          </span>
          <span v-else>
              {{ row.formula }}
          </span>
        </template>
      </vxe-column>
      <vxe-column field="design" title="设计值" align="center" class-name="cell-underline" width="120" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
      <vxe-column field="extremeVal" title="极值" align="center" class-name="cell-underline" width="120" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
      <vxe-column title="实测值与偏差值" align="center" width="120">
        <template v-slot="{rowIndex}">
          {{rowIndex % 2 == 0 ? '实测值' : '偏差值'}}
        </template>
      </vxe-column>
      <vxe-column v-for="index in detectColumnCount" :field="'detectionList[' + index + ']'" :key="index" :title="index.toString()" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: autoCalDeviation}}" class-name="cell-underline" width="100">
        <template #header>
          <span>
            {{ index }}
          </span>
          <el-tooltip v-if="index == detectColumnCount" placement="top-end" content="增加5列" effect="dark" :open-delay="100">
            <i class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 14px" @click="addColumn()"></i>
          </el-tooltip>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-modal v-model="formulaDetailModalVisible" title="补充具体设计要求" type="alert" showFooter destroy-on-close @confirm="formulaDetailSubmit">
      <template #default>
        <vxe-input v-model="formulaDetail" placeholder="设计要求" style="width: 100%"></vxe-input>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      tableLoading: false,
      qcInspectionDataId: '',
      detectColumnCount: 10, // 检测值列数（默认为10，如果查询到的数据列数大于10，则覆盖此值）
      dataList: [],
      editRules: {},
      tableHeight: 300,
      mergeCells: [],
      formulaDetail: '',
      formulaDetailModalVisible: false,
      currentRow: null, // 当前正在进行 规定值或允许偏差 补充说明 的行
      FORMULA_PATTERN: ['满足设计要求', '满足规范及设计要求', '符合规范及设计要求', '符合设计要求'], // 规定值或允许偏差的模式：处于这种模式时需要补充说明
    }
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.tableHeight = window.innerHeight * 0.8 - 100
    })
  },
  methods: {
    init() {
      this.getInfo()
    },
    // 获取信息
    getInfo() {
      this.tableLoading = true
      this.$http.get('/mps/qcInspectionData/fillTableList/' + this.qcInspectionDataId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        // 遍历数据行
        for (let i = 0; i < res.data.length; i++) {
          let item = res.data[i]
          // 1.获取detectionList最多的列数，作为detectColumnCount的值
          if (item.detectionList.length > this.detectColumnCount) {
            this.detectColumnCount = item.detectionList.length
          }
          // 2.为每一行设置一个独一无二的key, 因为两行数据完全相同的话，vue会重用渲染，导致行号不正确等问题
          if (i % 2 == 0) {
            item.key = item.id + item.designId + '实测值' + new Date().getTime()
          } else {
            item.key = item.id + item.designId + '偏差值' + new Date().getTime()
            // 3.将奇数行的deviationList赋值给detectionList（奇数行显示偏差值），便于页面上显示
            item.detectionList = item.deviationList
          }
          let a = ''
          // 3.如果此行的<formula>以定义好的<FORMULA_PATTERN>开头，则将其拆分为两部分，用于表格显示
          let pattern = this.formulaPattern(item.formula)
          if (item.formula && pattern != null) {
            item.formulaDetail = item.formula.substring(pattern.length)
            item.formula = pattern
          }
        }
        // 计算需要合并的单元格
        this.mergeCells = this.getMergeCells(res.data)
        this.dataList = res.data
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function (resolve, reject) {
      if (!this.validateFormula()) {
        return reject()
      }
      // 将formula和formulaDetail重新组合用于提交(一开始拆分开就是为了前端方便显示)
      let commitData = this.$deepCopy(this.dataList)
      commitData.forEach((item) => {
        if (item.formulaDetail) {
          item.formula = item.formula + item.formulaDetail
        }
      })
      this.$http.post(
          '/mps/qcInspectionData/fillTableSubmit',
          commitData
      ).then(({data: res}) => {
        if (res.code !== 0) {
          reject()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.$emit('refreshDataList')
            resolve()
          }
        })
      }).catch((e) => {
        reject()
        this.$message.error(e)
      })
    }, 1000, {'leading': true, 'trailing': false}),
    // 校验 规定值或允许偏差 列是否都进行了补充说明
    validateFormula() {
      // 顺序遍历，每一组检查项目仅校验第一行的数据
      let id = ''
      for (let i = 0; i < this.dataList.length; i++) {
        let item = this.dataList[i]
        // 如果id与上一行不同，说明此行为对应检查项目的第一行数据
        if (item.id !== id) {
          id = item.id
          // 进行校验
          if (this.formulaPattern(item.formula) != null && !this.$isNotEmpty(item.formulaDetail)) {
            this.$message.error(`请补充 ${item.code} ${item.name} 的<规定值或允许偏差>列的具体设计要求`)
            return false
          }
        }
      }
      return true
    },
    // 计算需要合并的单元格
    getMergeCells(dataList) {
      let result = []
      let sameLineCount = 1
      let id = ''
      for (let i = 0; i < dataList.length; i++) {
        let item = dataList[i]
        if (item.id === id) {
          // 记录id相同的行数
          sameLineCount++
        } else {
          // id不同了，则将前面id相同的sameLineCount行特定的列合并
          result.push(
              // 项次
              {
                row: i - sameLineCount,
                col: 0,
                rowspan: sameLineCount,
                colspan: 1,
              },
              // 检查项目
              {
                row: i - sameLineCount,
                col: 1,
                rowspan: sameLineCount,
                colspan: 1,
              },
              // 单位
              {
                row: i - sameLineCount,
                col: 2,
                rowspan: sameLineCount,
                colspan: 1,
              },
              // 规定值或允许偏差
              {
                row: i - sameLineCount,
                col: 3,
                rowspan: sameLineCount,
                colspan: 1,
              }
          )
          id = item.id
          sameLineCount = 1
        }
        if (i % 2 == 0) {
          result.push(
              // 设计值
              {
                row: i,
                col: 4,
                rowspan: 2,
                colspan: 1
              },
              // 极值
              {
                row: i,
                col: 5,
                rowspan: 2,
                colspan: 1
              },
          )
        }
      }
      // 循环结束后，最后一行的合并还没处理，特殊处理最后一行
      result.push(
          {
            row: dataList.length - sameLineCount,
            col: 0,
            rowspan: sameLineCount,
            colspan: 1,
          },
          {
            row: dataList.length - sameLineCount,
            col: 1,
            rowspan: sameLineCount,
            colspan: 1,
          },
          {
            row: dataList.length - sameLineCount,
            col: 2,
            rowspan: sameLineCount,
            colspan: 1,
          },
          {
            row: dataList.length - sameLineCount,
            col: 3,
            rowspan: sameLineCount,
            colspan: 1,
          }
      )
      return result
    },
    // 自动计算偏差值
    autoCalDeviation({row, rowIndex, column}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        // 如果是偶数行，说明修改的是实测值，则根据实测值计算偏差值（如果设计值不为空且为数字）
        if (rowIndex % 2 == 0) {
          // 如果修改后的值为null或undefined或''，则同时清空偏差值
          if (event.value == null || event.value == undefined || event.value == '') {
            this.dataList[rowIndex + 1][column.field] = null
            return
          }
          if (this.isNumber(row.design)) {
            this.dataList[rowIndex + 1][column.field] = event.value - row.design
          }
        }
      }
    },
    isNumber (s) {
      //非负浮点数
      const regPos = /^\d+(\.\d+)?$/;
      //负浮点数
      const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
      return (s != null) && (s != '') && (s != undefined) && (regPos.test(s) || regNeg.test(s))
    },
    // 增加5列检测值
    addColumn() {
      this.detectColumnCount = this.detectColumnCount + 5
      setTimeout(() => {
        // 使用setTimeout是因为nextTick后列的变化还没有反馈到dom中（猜测与电脑和浏览器的性能有关）
        // 增加列后自动滚动至最右边
        this.$refs.table.scrollTo(this.$refs.table.getScroll().scrollLeft + 1000, null)
        // 因为表格宽度和列发生改变，冻结列会有部分失效，需要重新计算表格
        this.$refs.table.recalculate()
      }, 200)
      this.$nextTick(() => {
        // 增加列后单元格合并会失效（原因不明），重新手动设置一次单元格合并
        this.$refs.table.setMergeCells(this.mergeCells)
      })
    },
    // 增加设计值
    addDesign(rowIndex) {
      // 先循环dataList，找到当前item的最后一行（一个item有可能对应了2行、4行等，根据设计值的多少而定）
      let itemId = this.dataList[rowIndex].id
      let lastRowIndex = rowIndex + 1
      while (lastRowIndex < this.dataList.length && this.dataList[lastRowIndex].id == itemId) {
        lastRowIndex++
      }
      // 将对应行复制两份(一行实测值，一行偏差值)，插入到当前检测项目的最后一行后
      let newRow1 = Object.assign({}, this.dataList[rowIndex])
      newRow1.key = newRow1.id + newRow1.designId + '实测值' + new Date().getTime()
      newRow1.designId = null
      newRow1.design = null
      newRow1.extremeVal = null
      newRow1.detectionList = []

      let newRow2 = Object.assign({}, newRow1)
      newRow2.key = newRow2.id + newRow2.designId + '偏差值' + new Date().getTime()
      newRow2.detectionList = []

      this.dataList.splice(lastRowIndex, 0, newRow1, newRow2)
      // 重新计算单元格合并
      this.$nextTick(() => {
        this.mergeCells = this.getMergeCells(this.dataList)
      })
    },
    /**
     * 判断formula是否属于预设的模式之一
     * @param formula 规定值或允许偏差
     * @returns {string|null} 返回formula在 FORMULA_PATTERN 中预先定义的需要补充说明的模式，若没有符合的，则返回null
     */
    formulaPattern(formula) {
      if (!this.$isNotEmpty(formula)) {
        return null
      }
      for (let pattern of this.FORMULA_PATTERN) {
        if (formula.startsWith(pattern)) {
          return pattern
        }
      }
      return null
    },
    // 规定值或允许偏差 补充说明 弹窗
    formulaSupplement(row) {
      this.currentRow = row
      this.formulaDetail = row.formulaDetail
      this.formulaDetailModalVisible = true
    },
    // 规定值或允许偏差 补充说明 弹窗填写后提交
    formulaDetailSubmit() {
      this.currentRow.formulaDetail = this.formulaDetail.trim()
    }
  }
}
</script>