<template>
  <el-dialog :visible.sync="visible" title="该角色已分配人员" width="80%" v-on="$listeners">
    <div class="mod-sys__user">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.realName" placeholder="姓名" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.username" placeholder="登录账号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <dict-select v-model="dataForm.gender" dict-type="gender" placeholder="性别"></dict-select>
        </el-form-item>
        <el-form-item>
          <dept-tree v-model="dataForm.deptId" placeholder="部门" :query="true"></dept-tree>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList" border style="width: 100%;"
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle">
        <el-table-column prop="realName" label="姓名" sortable="custom" header-align="center" align="center"></el-table-column>
        <el-table-column prop="username" label="登录账号" sortable="custom" header-align="center" align="center"></el-table-column>
        <el-table-column prop="deptName" label="部门" header-align="center" align="center"></el-table-column>
        <el-table-column prop="email" label="电子邮箱" header-align="center" align="center" width="180"></el-table-column>
        <el-table-column prop="mobile" label="手机号码" sortable="custom" header-align="center" align="center" width="150"></el-table-column>
        <el-table-column prop="gender" label="性别" sortable="custom" header-align="center" align="center" width="120">
          <template slot-scope="scope">
            {{ $getDictLabel("gender", scope.row.gender) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="用户状态" sortable="custom" header-align="center" align="center" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">{{ $t('user.status0') }}</el-tag>
            <el-tag v-else size="small" type="success">{{ $t('user.status1') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" sortable="custom" header-align="center" align="center" width="180"></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      visible: false,
      mixinViewModuleOptions: {
        getDataListURL: '/sys/user/openapi/page',
        getDataListIsPage: true,
        createdIsNeed: false
      },
      postList: [],
      dataForm: {
        realName: '',
        username: '',
        deptId: '',
        postId: '',
        gender: '',
        roleId: '' // 角色ID
      }
    }
  },
  components: {},
  methods: {
    init () {
      this.visible = true
      this.getDataList()
    }
  }
}
</script>
