<template>
  <el-dialog v-dialog-drag top="10vh" width="70%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractId">
            <contract-select v-model="dataForm.contractId" :prjId="dataForm.prjId" placeholder="标段选择" @change="contractChangeHandle" :defaultSelect="true"></contract-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="contractCode">
            <el-input v-model="dataForm.contractCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="验收编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工单位" prop="deptId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.deptId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设计单位" prop="designId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.designId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运营单位" prop="operationId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.operationId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="代建单位" prop="proxyId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.proxyId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.supervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开工日期" prop="startDate">
            <el-date-picker type="date" placeholder="开工日期" value-format="yyyy-MM-dd" v-model="dataForm.startDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="竣工日期" prop="completionDate">
            <el-date-picker type="date" placeholder="竣工日期" value-format="yyyy-MM-dd" v-model="dataForm.completionDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收结论" prop="conclusion">
            <el-select v-model="dataForm.conclusion" placeholder="请选择" :clearable="!dataForm.id" class="w-percent-100">
              <el-option :key="1" label="合格" :value="1"></el-option>
              <el-option :key="0" label="不合格" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="填报人" prop="report">
            <el-input v-model="dataForm.report" placeholder="填报人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="reportDate">
            <el-date-picker type="date" placeholder="填报日期" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改问题项" prop="problem">
            <el-input type="textarea" v-model="dataForm.problem" placeholder="整改问题项" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        deptId: '',
        designId: '',
        operationId: '',
        proxyId: '',
        supervisionId: '',
        startDate: '',
        completionDate: '',
        conclusion: '',
        problem: '',
        report: '',
        reportDate: '',
        remark: '',
        prjName: '',
        prjCode: ''
      }
    }
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        contractId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        deptId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        designId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        supervisionId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        startDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        completionDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 填报日期
          this.dataForm.reportDate = XEUtils.toDateString(new Date())
          // 填报人
          this.dataForm.report = this.$store.state.user.realName
        }
      })
    },
    // 标段选择
    contractChangeHandle(data) {
      if (data) {
        this.dataForm.contractCode = data.code
        if (!this.dataForm.id) {
          // 施工单位
          this.dataForm.deptId = data.deptId
          // 监理单位
          this.dataForm.supervisionId = data.supervisionId
        }
      }
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionAcceptance/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/completionAcceptance/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>