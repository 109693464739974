<template>
  <div>
    <el-dialog width="80%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="基本信息" name="tab1">
          <el-form key="first" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="期次" prop="periodId">
                  <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId"
                                 placeholder="期次选择" default-select :hasMeas="false"></period-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="标题" prop="name">
                  <el-input v-model="dataForm.name" placeholder="标题"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="材料明细" name="tab2">
          <el-button-group>
            <el-button v-if="$hasPermission('mps:materialexit:save')" type="primary" size="mini" @click="openMaterialPropSelectHandle()">添加</el-button>
          </el-button-group>
          <vxe-table id="mxTable"
                     keep-source
                     ref="mxTableRef"
                     :loading="mxDataListLoading"
                     size="mini"
                     :column-config="{resizable: true}"
                     border
                     show-overflow
                     :row-config="{isCurrent: true,isHover: true}"
                     :data="dataForm.details"
                     :max-height="tableHeight"
                     :edit-rules="mxTableRules"
                     :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
            <vxe-column field="materialName" title="材料名称" min-width="300" fixed="left"></vxe-column>
            <vxe-column field="spec" title="规格型号" width="200" :edit-render="{ name: '$input'}"></vxe-column>
            <vxe-column field="price" title="单价(元)" width="150" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
            <vxe-column field="num" title="计量数量" width="150" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
            <vxe-column field="unit" title="单位" width="150" align="center" :edit-render="{ name: '$input'}"></vxe-column>
            <vxe-column field="sortNo" title="排序号" width="80" header-align="center" align="center" :edit-render="{ name: '$input', props: {type: 'number',min: 1} }"></vxe-column>
            <vxe-column title="操作" width="80" align="center" fixed="right">
              <template #default="{row}">
                <el-button v-if="$hasPermission('mps:materialexit:delete')" type="text" size="small" @click="deleteOneMxHandle(row)">{{ $t('delete') }}</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <material-more-select v-if="materialPropSelectVisible" ref="materialPropSelect" @submit="materialPropSelectChangeHandle" @close="closeMaterialPropSelectHandle"></material-more-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        periodId: '',
        name: '',
        processInstanceId: '',
        processStatus: '',
        sortNo: '',
        details: []
      },
      activeTabName: 'tab1',
      // 明细 行校验
      mxTableRules: {},
      mxDataListLoading: false,
      materialPropSelectVisible: false,
      tableHeight: 400,
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        periodId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialExit/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },

    //  打开 物资明细选择弹窗
    openMaterialPropSelectHandle() {
      this.materialPropSelectVisible = true
      this.$nextTick(() => {
        this.$refs.materialPropSelect.init()
      })
    },
    // 关闭 物资明细选择弹窗
    closeMaterialPropSelectHandle() {
      this.materialPropSelectVisible = false;
    },
    // 删除一行明细数据
    deleteOneMxHandle(row) {
      let $table = this.$refs.mxTableRef
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        if ($table != null) {
          $table.remove(row)
        }
      }).catch(() => {
        // do Nothing
      })
    },
    // 批量选择物资明细的回调方法
    materialPropSelectChangeHandle(result) {
      if (result && result.length > 0) {
        const $table = this.$refs.mxTableRef
        // 列表末尾新增材料明细
        for (let i = 0; i < result.length; i++) {
          let obj = result[i];
          $table.insertAt({
            materialId: obj.id,
            materialName: obj.name,
            id: '',
            price: null,
            spec: '',
            num: null,
            sortNo: null,
            unit: obj.unit1
          }, -1)
        }
        this.$message({
          message: '请继续填写规格型号、单价、计量数量！',
          type: 'info',
          duration: 5000,
        })
      }
    },

    setMaterialExitMx() {
      const {removeRecords} = this.$refs.mxTableRef.getRecordset()
      this.dataForm.addMxList = []
      this.dataForm.updateMxList = []
      let fullDataList = this.$refs.mxTableRef.getTableData().fullData
      if (fullDataList && fullDataList.length > 0) {
        for (let i = 0; i < fullDataList.length; i++) {
          let mxData = fullDataList[i]
          // 新增
          if (!mxData.id) {
            this.dataForm.addMxList.push(mxData)
          } else {
            // 修改
            this.dataForm.updateMxList.push(mxData)
          }
        }
      }

      let deleteMxIdList = []
      if (removeRecords && removeRecords.length > 0) {
        removeRecords.forEach(function (element) {
          if (element && element['id']) {
            deleteMxIdList.push(element['id'])
          }
        })
      }
      this.dataForm.deleteMxIdList = deleteMxIdList
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          if (this.activeTabName !== 'tab1') {
            this.activeTabName = 'tab1'  // 切换页签到第一页
            this.$message({
              message: "请先补充必填信息",
              type: 'warning',
              duration: 1500
            })
          }
          return false
        }
        this.setMaterialExitMx()

        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/materialExit/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>