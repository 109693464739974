<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     :pdfOssId="dataForm.pdfId"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>
    <!-- 以下是业务表单内容 -->
    <div class="offcie-container" :style="{height:offcieHeight+'px'}">
      <document-editor ref="documentEditor" docHeight="100%"></document-editor>
    </div>
    <div class="upload-container">
      <ul>
        <li v-for="file in uploadDataList" :key="file.id">
          <i class="el-icon-paperclip"></i>
          <el-button type="text" @click="handleDownload(file.url, file)">{{ file.name }}</el-button>
          <el-button style="padding-left: 10px;" type="text" size="mini" @click="openUploadDownloadRecordHandle(file.id)">下载记录</el-button>
        </li>
      </ul>
    </div>
    <my-upload-download-record v-if="uploadDownloadRecordVisible" ref="uploadDownloadRecord" :ossId="currentClickId" @close="closeUploadDownloadRecordDialogHandle"></my-upload-download-record>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'
import documentEditor from '@/views/pages/document-editor/document-editor'

export default {
  mixins: [processModule],
  components: {
    documentEditor
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        wbsId: '',
        workstageId: '',
        qcfileId: '',
        fileId: '',
        name: '',
        code: '',
        ossUpdateDate: '',
        pdfId: ''
      },
      offcieConfig: {
        ossId: '',
        action: 'view',
        type: 'embedded'  // 嵌入式
      },
      // 附件下载记录
      uploadDownloadRecordVisible: false,
      currentClickId: '',
      uploadDataList: [],
      offcieHeight: 500
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.offcieHeight = window.innerHeight - 160
    })
  },
  methods: {
    init() {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
          this.getUploadDataList()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/qcInspectionData/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...res.data
        }
        this.offcieConfig.ossId = this.dataForm.pdfId
        this.$refs.documentEditor.manualInitialEditor(this.offcieConfig)
      }).catch(() => {
        // do nothing
      })
    },
    // 获取附件列表
    getUploadDataList() {
      this.$http.get('/oss/file/list',
          {
            params: {
              refId: this.dataForm.id,
              statusId: 1,
              typeId: ''
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          this.uploadDataList = []
          return this.$message.error(res.msg)
        }
        this.uploadDataList = res.data
        if (this.uploadDataList.length > 0) {
          this.offcieHeight = window.innerHeight - 250
        }
      }).catch(() => {
        //  do nothing
      })
    },
    // 点击附件名称，下载附件
    handleDownload(url, row) {
      // 保存用户下载记录
      if (row) {
        let downloadDataForm = {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }
        this.$http['post']('/oss/download/', downloadDataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        })
      }
      this.$http.get(`/oss/file/signedUrl?url=${url}`)
          .then(({data: res}) => {
            if (res.code === 0 && res.data) {
              window.open(res.data)
            } else {
              this.$message({
                message: '对不起，未查询到相关文件！',
                type: 'error',
                duration: 5000
              })
            }
          }).catch(() => {
        // do nothing
      })
    },

    // 打开附件下载记录弹窗
    openUploadDownloadRecordHandle(ossId) {
      this.uploadDownloadRecordVisible = true
      this.$nextTick(() => {
        this.currentClickId = ossId
        this.$refs.uploadDownloadRecord.init()
      })
    },

    // 关闭附件下载记录弹窗
    closeUploadDownloadRecordDialogHandle() {
      this.uploadDownloadRecordVisible = false
      this.currentClickId = ''
    },
  }
}
</script>
<style lang="scss" scoped>
.offcie-container {
  width: 100%;
  overflow: auto;
}

.upload-container {
  max-height: 100px;
  overflow: auto;
}

ul, li {
  list-style: none;
  padding-left: 2px;
}

</style>