<template>
  <el-dialog :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false"
             v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="金额精度保留" prop="amountAccuracy">
            <el-input v-model="dataForm.amountAccuracy">
              <template slot="append">位小数</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="百分比精度保留" prop="percentAccuracy">
            <el-input v-model="dataForm.percentAccuracy">
              <template slot="append">位小数</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程进度表开始日期" prop="gcjdBeginDate">
            <el-date-picker type="date" placeholder="工程进度表开始日期" value-format="yyyy-MM-dd" v-model="dataForm.gcjdBeginDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程进度表截止日期" prop="gcjdEndDate">
            <el-date-picker type="date" placeholder="工程进度表截止日期" value-format="yyyy-MM-dd" v-model="dataForm.gcjdEndDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否允许超计量" prop="allowExceed">
            <el-radio-group v-model="dataForm.allowExceed">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="中间计量单显示风格" prop="ticketType">
            <el-radio-group v-model="dataForm.ticketType">
              <el-radio :label="1">每页显示一条清单</el-radio>
              <el-radio :label="2">每页显示多条清单</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="质检对接标段编号" prop="referCode">
            <el-input v-model="dataForm.referCode"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import {checkIntIncludeZero, checkNumber} from "@/utils/form-validate";
export default {
  data() {
    return {
      visible: false,
      title: '参数设置',
      dataForm: {
        ticketType: 2, // 默认计量单一对多（一个计量单（一个部位）下面多个清单）
      }
    }
  },
  computed: {
    dataRule() {
      return {
        amountAccuracy: [
          { validator: checkIntIncludeZero, message: '只能填入正整数', trigger: 'change' }
        ],
        percentAccuracy: [
          { validator: checkIntIncludeZero, message: '只能填入正整数', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/contract/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        // 将弹窗标题设置为合同标段的名称
        this.title = this.dataForm.name
      }).catch()
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/contract/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch()
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>