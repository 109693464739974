<template>
  <div style="margin: 10px">
    <h2 v-if="!dataForm.id" style="margin-left: 10px">
      暂无数据
    </h2>
    <el-form class="form_application" v-else :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px">
      <el-row style="margin-bottom: 5px; margin-top: -10px;">
        <el-col :span="24">
          <vxe-button style="width: 120px;" status="primary" icon="my-icon-save" size="mini" :content="submitButtonContent" @click="dataFormSubmitHandle" :loading="submitButtonLoading"></vxe-button>
        </el-col>
      </el-row>
      <div class="propreinfor">
        <el-row>
          <el-col :span="24">
            <el-form-item label="工程/费用名称" prop='subItemId'>
              <div class="content">
                &nbsp;&nbsp;&nbsp;&nbsp;{{dataForm.subItemName}}
              </div>
            </el-form-item>
          </el-col>
<!--          <el-col v-if="dataForm.wbsId != dataForm.subItemId" :span="24">-->
<!--            <el-form-item label="分部分项工程" prop='wbsId'>-->
<!--              <div class="content">-->
<!--                <wbs-select v-model="dataForm.wbsId" :contractId="measForm.contractId" :subcontractor-id="measForm.subcontractorId" placeholder="选择分部分项" @change="wbsSelectChangeHandle" show-p-names></wbs-select>-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col v-if="dataForm.wbsId != dataForm.subItemId" :span="24">
            <el-form-item label="分部分项工程">
              <div class="content">
                &nbsp;&nbsp;&nbsp;&nbsp;{{dataForm.wbsPnames}}
              </div>
            </el-form-item>
          </el-col>
          <!-- 为了页面对齐，暂时独占一行 -->
          <el-col :span="24">
            <el-form-item label="计量部位" prop='name'>
              <div class="content">
                <el-input v-model="dataForm.name" placeholder="请填写部位名称"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="起讫桩号" prop='stakeNo'>
              <div class="content">
                <el-input v-model="dataForm.stakeNo" placeholder="请填写起讫桩号"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交工证书号" prop='ctoa'>
              <div class="content">
                <el-input v-model="dataForm.ctoa" placeholder="交工证书号"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="图号" prop='drawingNo'>
              <div class="content">
                <el-input v-model="dataForm.drawingNo" placeholder="请填写图号"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="计量单号" prop='code'>
              <div class="content">
                &nbsp;&nbsp;&nbsp;&nbsp;{{dataForm.code}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="计算式" prop='formula'>
              <div class="content">
                <el-input type="textarea" v-model="dataForm.formula" placeholder="请填写计算式" :autosize="{ minRows: 2 }"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      
    </el-form>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      dataForm: {
        id: '',
        measId: '',
        code: '',
        formula: '',
        name: '',
        stakeNo: '',
        drawingNo: '',
        subItemId: '',
        subItemName: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      oldWbsId: '',
      measForm: {
        contractId: '',
        subcontractorId: '',
      },
      submitButtonLoading: false,
      submitButtonContent: '保存基本信息'
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    },
  },
  methods: {
    // 获取信息
    getInfo () {
      if (!this.dataForm.id) {
        return
      }
      this.$http.get('/mps/measTicket/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.oldWbsId = res.data.wbsId
      }).catch(() => {})
    },
    wbsSelectChangeHandle({name}) {
      if (!this.dataForm.name) {
        this.dataForm.name = name
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.submitButtonLoading = true
      this.submitButtonContent = '正在保存...'
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/measTicket/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 如果wbsId有修改过，则触发事件
          if (this.dataForm.wbsId != this.oldWbsId) {
            this.oldWbsId = this.dataForm.wbsId
            this.$emit('wbsChanged')
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList', this.dataForm.subItemId)
            }
          })
        }).catch(() => {}).finally(() => {
          this.submitButtonLoading = false
          this.submitButtonContent = '保存基本信息'
        })
      })
    }, 1000, { 'leading': true, 'trailing': false }),
  }
}
</script>
<style lang="scss" scoped>
.form_application {
  //form-class
  .propreinfor {
  //上边框，左边框
  border-left: 1px solid rgb(192, 196, 204);
    border-top: 1px solid rgb(192, 196, 204);
    margin: 0 auto;
  }

.content {
//右边框，下边框
padding: 5px;
  border-right: 1px solid rgb(192, 196, 204);
  border-bottom: 1px solid rgb(192, 196, 204);
  height: 100% !important;
}

/deep/.el-form-item {
//每一项
position: relative;
  height: 100%;
  margin-bottom: 0;
  height: auto !important;
}

/deep/ .el-form-item__label {
//左边的laebel以及颜色，高度，字体等
position: absolute;
  height: 100%;
  border-right: 1px solid rgb(192, 196, 204);
  border-bottom: 1px solid rgb(192, 196, 204);
  padding: 10px;
  background: #f9f9f9;
  font-weight: 400;
  color: #000;
  line-height: normal;
  display: flex;
  align-items: center;//垂直居中
justify-content: left;//左右居中
}

/deep/.el-form-item__content {
//右边输入框或者下拉框等
line-height: 40px;
  height: 100%;
}
/deep/.el-form-item__error {
//校验规则的样式，位于输入框下方
z-index: 999;
  padding-top: 0;
  left: 10px;
  top: calc(100% - 5px);
}

}
</style>