<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-mps__boq">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader ref="contractCascader" @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="add" @click="addChildNode({id: 0})" v-if="(locked != 1) && $hasPermission('mps:boq:save')"></toolbar-button>
            <toolbar-button name="引用范本" icon="el-icon-document-copy" @click="quoteBoqTemplate()" v-if="$hasPermission('mps:boq:quote')"></toolbar-button>
            <toolbar-button role="import" @click="importBoqHandle" v-if="(locked != 1) && $hasPermission('mps:boq:import')"></toolbar-button>
            <!-- 导出功能不需要受锁定状态的限制 -->
            <toolbar-button role="export" @click="exportHandle" v-if="$hasPermission('mps:boq:export')"></toolbar-button>
            <toolbar-button role="delete" @click="deleteNode()" v-if="(locked != 1) && $hasPermission('mps:boq:delete')"></toolbar-button>
            <toolbar-button role="lock" @click="lock()" v-if="locked != 1" :disabled="!$hasPermission('mps:boq:lock')"></toolbar-button>
            <toolbar-button role="unlock" @click="unlock()" v-if="locked == 1" :disabled="!$hasPermission('mps:boq:lock')"></toolbar-button>
          </el-button-group>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <el-tooltip content="刷新名称路径" placement="top" effect="light">
              <el-button icon="el-icon-refresh" v-if="$hasPermission('mps:boq:update')" @click="refreshHandle"></el-button>
            </el-tooltip>
            <el-tooltip content="层级汇总复核数量（金额）、变更后数量（金额）" placement="top" effect="light">
              <el-button icon="el-icon-s-operation" v-if="$hasPermission('mps:boq:calculate')" @click="calculate(dataForm.contractId)"></el-button>
            </el-tooltip>
            <el-tooltip content="全局排序" placement="top" effect="light">
              <el-button icon="el-icon-sort" v-if="$hasPermission('mps:boq:update')" @click="refreshSortNoHandle"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-if="tableShow"
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          row-key="id"
          lazy
          :load="loadTableData"
          @selection-change="dataListSelectionChangeHandle"
          highlight-current-row
          border
          :summary-method="getSummaries"
          show-summary
          style="width: 100%;"
          :row-class-name="rowClassName"
          :max-height="maxTableHeight"
          :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="清单编号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="清单名称" min-width="150" show-overflow-tooltip>
          <template slot-scope="scope">
              <span>
                {{ scope.row.name }}
                <template v-if="scope.row.isLeaf">
                    <el-tooltip effect="light" content="点击查看所有关联的分项" placement="right">
                      <i class="el-icon-view pointer-cursor my-boq-icon" @click="viewSubItem(scope.row)"></i>
                    </el-tooltip>
                </template>
              </span>
          </template>
        </el-table-column>
        <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="90"></el-table-column>
        <el-table-column prop="price" label="单价（元）" header-align="right" align="right" width="150"></el-table-column>
        <el-table-column prop="initNum" label="合同数量" header-align="right" align="right" width="140" :formatter="numberFormatter"></el-table-column>
        <el-table-column prop="initAmount" label="合同金额（元）" header-align="right" align="right" width="140" :formatter="moneyFormatter"></el-table-column>
        <el-table-column prop="checkedNum" label="复核数量" width="150" header-align="right" align="right" show-overflow-tooltip>
          <template #header="scope">
            <span>
              复核数量
              <el-tooltip
                  v-if="$hasPermission('mps:boq:calculate')"
                  :aa="scope"
                  class="item"
                  effect="light"
                  content="使用工具栏中的相应按钮，可自动汇总计算分项清单，形成复核工程量。"
                  placement="top-start"
              >
                <i class="el-icon-s-comment" style="color:#66B1FF; margin-left:5px;"> </i>
              </el-tooltip>
            </span>
          </template>
          <template #default="{row}">
            <span v-if="(row.checkedNum  ? row.checkedNum : 0) != (row.initNum ? row.initNum : 0)" style="color: red">{{ numberFormatter(row, null, row.checkedNum) }}</span>
            <span v-else>{{ numberFormatter(row, null, row.checkedNum) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="realNum" label="变更后数量" header-align="right" align="right" width="140" :formatter="numberFormatter"></el-table-column>
        <el-table-column prop="realAmount" label="变更后金额（元）" header-align="right" align="right" width="160">
          <template v-slot="{row}">
            {{ moneyFormatter(row, null, row.realAmount) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="measSum" label="累计计量" header-align="center" align="right" width="150"></el-table-column> -->
        <el-table-column v-if="locked != 1" :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template slot-scope="{row}">
            <table-button role="addChild" @click="addChildNode(row)" v-if="$hasPermission('mps:boq:save')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:boq:update')"></table-button>
            <table-button role="delete" @click="deleteNode(row.id, row.pid)" v-if="$hasPermission('mps:boq:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @close="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
      <!-- 引用清单范本 -->
      <boqtemplate-quote v-if="boqtemplateQuoteVisible" ref="boqtemplateQuote" @quoteSubmit="quoteSubmitHandle"></boqtemplate-quote>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/boq/import" thumbnail="import_boq.jpg"></excel-import>
      <!-- 查看清单关联的分项列表 -->
      <boq-subItem-view v-if="boqSubItemViewVisible" ref="boqSubItemView" @close="closeBoqSubItemViewDialogHandle"></boq-subItem-view>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './boq-add-or-update'
import XEUtils from 'xe-utils'
import BoqtemplateQuote from './boqtemplate-quote'
import BoqSubItemView from './boq-subItem-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '/mps/boq',
        exportURL: `/mps/boq/export`,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        prjId: '',
        contractId: '',
      },
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableLoading: false,
      tableData: [],
      tableShow: true,
      locked: 0,
      // 合同标段中定义的“金额小数位精度”
      amountAccuracy: 0,
      maxTableHeight: 800,

      // 引用清单范本
      boqtemplateQuoteVisible: false,
      // 关联的分项
      boqSubItemViewVisible: false
    }
  },
  components: {
    AddOrUpdate,
    BoqtemplateQuote,
    BoqSubItemView
  },
  mounted() {
    this.$nextTick(() => {
      this.maxTableHeight = window.innerHeight - 160
    })
  },
  methods: {
    // 项目、合同标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.amountAccuracy = data.contract.amountAccuracy
      this.locked = (data.contract.lockBoq === null ? 0 : data.contract.lockBoq)
      this.getDataList()
    },

    getDataList() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get('/mps/boq/children',
          {
            params: {
              contractId: this.dataForm.contractId,
              pid: 0,
              withMeasSum: '1',
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        // 将查询到的子节点放入treeNodeMap，此操作是为了在新增这些节点的子节点时，可以通过map获取到对应的父节点
        for (let item of res.data) {
          this.treeNodeMap.set(item.id, {item})
        }
        this.tableLoading = false
        this.mergeSummaryCell()
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // el-table懒加载节点
    loadTableData(data, node, resolve, callback) {
      let pid = data.id

      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get('/mps/boq/children',
          {
            params: {
              'contractId': this.dataForm.contractId,
              'pid': pid,
              withMeasSum: '1',
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data)
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, [])
        }
        // 将查询到的子节点放入treeNodeMap，此操作是为了在新增这些节点的子节点时，可以通过map获取到对应的父节点
        for (let item of res.data) {
          this.treeNodeMap.set(item.id, {data: item})
        }
        if (typeof callback === 'function') {
          callback()
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid) {
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid, isPidChanged) {
      if (isPidChanged) {
        // 父节点修改过，直接刷新列表
        this.getDataList()
      } else {
        this.refreshParentNode(pid)
      }
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 若nodeInfo没有node属性，说明此节点未展开过
        if (!nodeInfo.node) {
          // 如果nodeInfo.data.hasChildren为false,说明此节点先前是叶节点，现在有可能新增了子节点，受限于el-table，必须刷新此节点的父节点，以显示展开按钮
          if (!nodeInfo.data.hasChildren) {
            let parentNodeInfo = this.treeNodeMap.get(nodeInfo.data.pid)
            if (parentNodeInfo) {
              this.refreshParentNode(parentNodeInfo.data.id)
            }
          }
          return
        }
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        nodeInfo.data.hasChildren = true // 设置父节点为非叶子节点，出现展开按钮
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve, () => {
          this.updateParentAmount(pid)
        })
      } else {
        this.getDataList()
      }
    },
    // 递归计算上级节点的合价
    updateParentAmount(pid) {
      let tblMap = this.$refs.table.store.states.lazyTreeNodeMap
      let children = tblMap[pid]
      if (children) {
        let realAmount = 0
        let initAmount = 0
        if (children && children.length > 0) {
          children.forEach((item) => {
            realAmount = XEUtils.add(realAmount, item.realAmount)
            initAmount = XEUtils.add(initAmount, item.initAmount)
          })
        }
        let pNode = this.treeNodeMap.get(pid)
        if (pNode && pNode.data) {
          pNode.data.realAmount = realAmount
          pNode.data.initAmount = initAmount
          this.updateParentAmount(pNode.data.pid)
        }
      }
    },
    // 新增子节点
    addChildNode(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
    // 删除
    deleteNode(id, pid) {
      this.deleteHandle(id,
          {
            deleteSuccessCallbackArgs: {id, pid},
            promptMessage: '同时会删除下级节点',
            promptTitle: '确定进行[删除]操作?',
            autoQuery: false
          }
      )
    },
    deleteSuccessCallback({id, pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getDataList()
      }
    },
    // 引用清单范本
    quoteBoqTemplate() {
      if (this.tableData && this.tableData.length > 0) {
        return this.$message.error("工程量清单已存在，无法再次引用！")
      } else {
        this.boqtemplateQuoteVisible = true
        this.$nextTick(() => {
          this.$refs.boqtemplateQuote.init()
        })
      }
    },
    // 确认引用
    quoteSubmitHandle(main, level) {
      this.boqtemplateQuoteVisible = false
      const loading = this.$loading({
        lock: true,
        text: '正在复制清单范本...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get('/mps/boq/quoteBoqTemplate',
          {
            params: {
              prjId: this.dataForm.prjId,
              contractId: this.dataForm.contractId,
              mainId: main.id,
              level: level,
            }
          }
      ).then(({data: res}) => {
        loading.close()
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: `操作成功！`,
          type: 'warning',
          duration: 2000,
        })
        this.getDataList()
      }).catch(() => {
        loading.close()
        return this.$message.error('出错了')
      })
    },
    // 导入合同清单
    importBoqHandle() {
      if (this.locked == 0) {
        // 只允许未锁定时导入合同清单
        this.importHandle()
      } else {
        return this.$message.error('清单已锁定，不允许导入。')
      }
    },
    lock() {
      this.$confirm(`锁定后合同清单将不允许修改，是否继续？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/boq/lockBoq/${this.dataForm.contractId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 1
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockBoq", 1)
          this.getDataList()
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      })
    },
    unlock() {
      this.$confirm(`您确定要解锁合同清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/boq/unlockBoq/${this.dataForm.contractId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockBoq", 0)
          this.locked = 0
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
          })
        }).finally(() => {
          loading.close()
        })
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
    calculate(contractId) {
      this.$confirm(`确定要汇总计算各层级清单的复核金额、变更后金额吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在计算，请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/boq/calculate/${contractId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已完成',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    // 刷新节点的名称路径(pids,pnames)
    refreshHandle() {
      this.$confirm('是否要重新计算所有清单子目的名称路径?').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在计算，请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/boq/${this.dataForm.contractId}/refresh`).then(({data: res}) => {
          if (res.code != 0) {
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            });
          }
          this.$alert('刷新成功', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },
    // 全局排序
    refreshSortNoHandle() {
      this.$confirm('是否要运行全局排序?').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在运行，请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/boq/${this.dataForm.contractId}/refreshSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已完成',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },
    numberFormatter(row, column, cellValue, index) {
      if (XEUtils.isNumber(row.accuracy)) {
        return XEUtils.commafy(cellValue, {digits: row.accuracy})
      } else {
        return cellValue
      }
    },
    moneyFormatter(row, column, cellValue, index) {
      if (XEUtils.isNumber(this.amountAccuracy)) {
        return XEUtils.commafy(cellValue, {digits: this.amountAccuracy})
      } else {
        return cellValue
      }
    },

    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (column.property == 'code') {
          sums[index] = '合计'
          return
        } else if (column.property == 'initAmount' || column.property == 'realAmount') {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            // 通过amountAccuracy动态控制合计金额的小数位精度
            if (XEUtils.isNumber(this.amountAccuracy)) {
              sums[index] = XEUtils.commafy(sums[index], {digits: this.amountAccuracy})
            }
          } else {
            sums[index] = 'N/A'
          }
        }
      })
      return sums
    },
    // 合并合计行的单元格
    mergeSummaryCell() {
      // 实现合计行的单元格合并显示
      // 注意：合计行的单元格合并采用如下方案时，需要严格对上表格的列，如果表格列发生了改变，则此代码也必须一同修改。
      setTimeout(() => {
        if (this.$refs.table.$el) {
          let current = this.$refs.table.$el.querySelector('.el-table__footer-wrapper').querySelector('.el-table__footer')
          let cell = current.rows[0].cells
          cell[1].colSpan = '5'
          cell[1].classList.add("is-center")
          for (let i = 2; i < 6; i++) {
            cell[i].style.display = 'none'
          }
        }
      }, 100)
    },
    // 动态行样式
    rowClassName({row, rowIndex}) {
      // 因为非叶子节点上没有数量，所以只能通过比对金额来判断该清单子目是否合同额发生了变化
      let _initAmount = row.initAmount
      if (!_initAmount) {
        _initAmount = 0
      }
      let _realAmount = row.realAmount
      if (!_realAmount) {
        _realAmount = 0
      }
      if (_initAmount != _realAmount) {
        return 'row-red'
      } else {
        return ''
      }
    },

    // 查看清单关联的分项（sbs或wbs）列表
    viewSubItem(row) {
      this.boqSubItemViewVisible = true
      this.$nextTick(() => {
        this.$refs.boqSubItemView.dataForm.contractId = row.contractId
        this.$refs.boqSubItemView.dataForm.boqId = row.id
        this.$refs.boqSubItemView.init()
      })
    },
    closeBoqSubItemViewDialogHandle() {
      this.boqSubItemViewVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .row-red {
  color: rgb(236, 12, 12) !important;
}

.my-boq-icon {
  float: right;

  &:hover {
    color: #409EFF;
  }
}
</style>