<template>
  <el-dialog v-dialog-drag top="5vh" width="80%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractId">
            <contract-select v-model="dataForm.contractId" :prjId="dataForm.prjId" placeholder="标段选择" @change="contractChangeHandle" :defaultSelect="true"></contract-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="contractCost">
            <el-input placeholder="合同金额" v-model="dataForm.contractCost" disabled>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结算编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="结算编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结算名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="结算名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审单位" prop="deliver">
            <el-input v-model="dataForm.deliver" placeholder="送审单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审日期" prop="deliverDate">
            <el-date-picker type="date" placeholder="送审日期" value-format="yyyy-MM-dd" v-model="dataForm.deliverDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审计单位" prop="approval">
            <el-input v-model="dataForm.approval" placeholder="审计单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审定日期" prop="approvalDate">
            <el-date-picker type="date" placeholder="审定日期" value-format="yyyy-MM-dd" v-model="dataForm.approvalDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="奖罚单位" prop="award">
            <el-input v-model="dataForm.award" placeholder="奖罚单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="奖罚金额" prop="awardAmount">
            <el-input-number v-model="dataForm.awardAmount" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审金额" prop="deliverAmount">
            <el-input-number v-model="dataForm.deliverAmount" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审定金额" prop="approvalAmount">
            <el-input-number v-model="dataForm.approvalAmount" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="初审价" prop="firstReviewPrice">
            <el-input-number v-model="dataForm.firstReviewPrice" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审计费用" prop="approvalCost">
            <el-input-number v-model="dataForm.approvalCost" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="累计增减金额" prop="totalChangeAmount">
            <el-input-number v-model="dataForm.totalChangeAmount" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="增减率" prop="changeRate">
            <el-input-number v-model="dataForm.changeRate" data-unit="%" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="其他应扣款" prop="otherDeduction">
            <el-input-number v-model="dataForm.otherDeduction" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        deliver: '',
        deliverDate: '',
        approvalDate: '',
        award: '',
        approval: '',
        awardAmount: '',
        deliverAmount: '',
        approvalAmount: '',
        firstReviewPrice: '',
        approvalCost: '',
        otherDeduction: '',
        totalChangeAmount: '',
        changeRate: '',
        remark: '',
        contractCost: '',
        prjName:'',
        prjCode:''
      }
    }
  },
  computed: {
    dataRule() {
      return {
        contractId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        deliver: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        deliverDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        approvalDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        award: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        approval: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 标段选择
    contractChangeHandle(data) {
      if (data) {
        this.dataForm.contractCost = data.cost
      }
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionSettlement/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/completionSettlement/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          //  do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>