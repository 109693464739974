/**
 * 表单校验数字值
 */
export function checkNumber (rule, val, callback) {
  //非负浮点数
  const regPos = /^\d+(\.\d+)?$/;
  //负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
  if((val == null) || (val == '') || regPos.test(val) || regNeg.test(val)){
    callback()
  }else{
    callback(new Error('请输入数字'))
  }
}

/**
 * 表单校验正整数值
 */
export function checkInt (rule, value, callback) {
  if (!value || (/^\+?[1-9][0-9]*$/.test(value))) {
    callback()
  } else {
    callback(new Error('请输入正整数'))
  }
}

/**
 * 表单校验正整数值(包含0)
 */
export function checkIntIncludeZero (rule, value, callback) {
  if (!value || (/^([1-9]\d*|[0]{1,1})$/.test(value))) {
    callback()
  } else {
    callback(new Error('请输入正整数'))
  }
}