<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>
    <!-- 以下是业务表单内容 -->
    <div class="mod-__changeorder">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="基本信息" name="tab1">
          <el-form key="first" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px" :validate-on-rule-change="false" size="small">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="变更编号" prop="code">
                  <el-input v-model="dataForm.code" placeholder="请填写变更编号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="变更项目名称" prop="name">
                  <el-input v-model="dataForm.name" placeholder="变更项目名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="变更类型" prop="typeId">
                  <dict-select v-model="dataForm.typeId" dict-type="mps_change_order_type" placeholder="请选择" style="width: 100%;"></dict-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请时间" prop="changeDate">
                  <el-date-picker type="date" placeholder="申请时间" :value-format="dateFormat" v-model="dataForm.changeDate" style="width: 100%;"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="桩号" prop="stakeNo">
                  <el-input v-model="dataForm.stakeNo" placeholder="桩号"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="24">
                <el-form-item label="变更内容" prop="content">
                  <el-input type="textarea" v-model="dataForm.content" placeholder="变更内容" :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <my-upload :refId="dataForm.id"></my-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="新增清单" name="tab2">
          <el-button style="margin-bottom: 5px;" type="primary" size="mini" @click="addHandle(Type_AddBoq)">新增清单子目</el-button>
          <el-button style="margin-bottom: 5px;" type="primary" size="mini" @click="addSubBoqHandle()">新增分项清单</el-button>
          <vxe-table id="vxeTable1" keep-source ref="mxNewAddTableRef"
              :loading="mxDataListLoading"
              size="mini"
              :column-config="{resizable: true}"
              border
              show-overflow
              :row-config="{isCurrent: true,isHover: true}"
              :data="mxDataList"
              :max-height="tableHeight"
              :edit-rules="mxNewAddTableRules"
              @edit-closed="newAddEditClosedEvent"
              :edit-config="mxEditConfig">
            <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
            <!-- <vxe-column field="boqParentCode" title="上级清单编码" width="120" fixed="left"></vxe-column>
            <vxe-column field="boqParentName" title="上级清单名称" width="120" fixed="left"></vxe-column> -->
            <vxe-column field="boqCode" title="清单编号" width="120" fixed="left" :edit-render="{name: '$input'}"></vxe-column>
            <vxe-column field="boqName" title="清单名称" width="180" fixed="left" :edit-render="{name: '$input'}"></vxe-column>
            <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
            <!--<vxe-column field="stakeNo" title="桩号" width="120" fixed="left" :visible="false"></vxe-column>-->
            <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
            <vxe-column field="price" title="单价(元)" width="150" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
            <vxe-column field="changedNum" title="数量" width="150" align="right" :edit-render="{ name: 'MyInputNumber', props: {digitsKey: 'unitAccuracy'} }"></vxe-column>
            <vxe-column field="changedAmount" title="金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
            <vxe-column title="操作" width="80" align="center" fixed="right">
              <template #default="{row}">
                <el-button v-if="$hasPermission('mps:changeorder:delete')" type="text" size="small" @click="deleteOneBoqHandle(row)">{{ $t('delete') }}</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-tab-pane>
        <el-tab-pane label="数量变更" name="tab3">
          <el-button style="margin-bottom: 5px;" type="primary" size="mini" @click="addHandle(Type_ChangeNum)">新增</el-button>
          <vxe-table id="vxeTable2" keep-source ref="mxNumChangeTableRef"
                :loading="mxDataListLoading"
                size="mini"
                :column-config="{resizable: true}"
                border
                show-overflow
                :row-config="{isCurrent: true,isHover: true}"
                :data="mxDataList2"
                :max-height="tableHeight"
                :edit-rules="mxNumChangeTableRules"
                @edit-closed="numChangeEditClosedEvent"
                :edit-config="mxEditConfig">
            <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
            <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
            <vxe-column field="boqName" title="清单名称" width="180" fixed="left"></vxe-column>
            <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
            <!--<vxe-column field="stakeNo" title="桩号" width="120" fixed="left" :visible="false"></vxe-column>-->
            <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
            <vxe-column field="price" title="单价(元)" align="right" width="150"></vxe-column>
            <vxe-column field="oldNum" title="变更前数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="changedNum" title="变更增减" width="150" align="right" :edit-render="{ name: 'MyInputNumber', props: {digitsKey: 'unitAccuracy'} }"></vxe-column>
            <vxe-column field="newNum" title="变更后数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="changedAmount" title="变更增减金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
            <vxe-column title="操作" width="80" align="center" fixed="right">
              <template #default="{row}">
                <el-button v-if="$hasPermission('mps:changeorder:delete')" type="text" size="small" @click="deleteOneBoqHandle(row)">{{ $t('delete') }}</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
      <!--弹窗 清单明细新增、修改-->
      <mx-add-or-update v-if="mxAddOrUpdateVisible" ref="mxBoqAddOrUpdate" :contractId="dataForm.contractId" :requestTypeId="mxTypeId" @close="mxCloseDialogHandle" @newData="getReturnMxBoqData"></mx-add-or-update>
      <!-- 批量新增分项清单 -->
      <subboq-batch-add v-if="subboqBatchAddVisible" ref="subboqBatchAdd" :contractId="dataForm.contractId" @close="closeSubboqBatchAddDialogHandle" @addNewData="subboqBatchAddHandle"></subboq-batch-add>
    </div>
  </el-card>
</template>
<script>
  import processModule from '@/mixins/process-module'
  import MxAddOrUpdate from './changeorder-mx-add-or-update.vue'
  import SubboqBatchAdd from './changeorder-subboq-batch-add.vue'
  import XEUtils from 'xe-utils'
  export default {
    mixins: [processModule],
    data () {
      return {
        dataForm: {
          id: '',
          contractId: '',
          code: '',
          name: '',
          changeDate: '',
          typeId: '',
          stakeNo: '',
          content: '',
          processInstanceId: '',
          processStatus: ''
        },
        activeTabName: 'tab1',
        dateFormat: 'yyyy-MM-dd',
        tableHeight: 400,

        mxDataListLoading: false,
        mxDataList: [],   //  新增清单明细
        mxDataList2: [],   //  清单数量变更明细
        mxAddOrUpdateVisible: false,
        mxTypeId: "",

        Type_ChangeNum: 2, // 变更类型：数量变更
        Type_AddBoq: 3, // 变更类型：新增清单
        Type_AddSubBoq: 4, // 变更类型：新增分项清单

        // 清单数量变更明细 行校验
        mxNumChangeTableRules: {},
        // 新增清单明细 行校验
        mxNewAddTableRules: {},

        subboqBatchAddVisible: false, // 批量新增分项清单的弹窗

        // 明细表 可编辑设置
        mxEditConfig: {
          enabled: false,
          trigger: 'click',
          mode: 'row',
          showStatus: true
        },

        // 配置流程URL
        process: {
          // 流程被驳回后，起草人修改提交时保存业务表单的URL
          updateURL: '/mps/changeOrder'
        }
      }
    },
    components: {
      MxAddOrUpdate,
      SubboqBatchAdd
    },
    created () {
      this.init()
    },
    mounted () {
      this.$nextTick(() => {
        //  表格高度设置
        this.tableHeight = window.innerHeight - 280
      })
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          code: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          content: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          stakeNo: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ]
        }
      },
      contractId () {
        return this.dataForm.contractId
      }
    },
    methods: {
      init () {
        //  通过流程获取业主主表ID
        this.dataForm.id = this.$route.params.businessKey
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          }
        })
      },

      // 获取信息
      getInfo () {
        this.mxDataListLoading = true
        this.$http.get('/mps/changeOrder/' + this.dataForm.id).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            })
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }

          let detailsData = res.data.details || []
          // 遍历清单明细
          for (let i = 0; i < detailsData.length; i++) {
            let item = detailsData[i] || {}
            let typeId = item['typeId'] + '' || '';
            if (typeId == this.Type_ChangeNum) {
              this.mxDataList2.push(item)
            } else if (typeId == this.Type_AddBoq || typeId == this.Type_AddSubBoq) {
              this.mxDataList.push(item)
            }
          }

          this.mxDataListLoading = false
        }).catch(() => {
          this.mxDataListLoading = false
        })
      },

      // 新增清单、新增清单数量变更
      addHandle (typeId) {
        this.mxTypeId = typeId
        this.mxAddOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.mxBoqAddOrUpdate.dataForm.id = ''
          this.$refs.mxBoqAddOrUpdate.init()
        })
      },

      // 新增分项清单（零号清单）
      addSubBoqHandle () {
        this.mxTypeId = 4 // 新增分项清单
        this.subboqBatchAddVisible = true
        this.$nextTick(() => {
          this.$refs.subboqBatchAdd.init()
        })
      },

      // 清单明细新增、修改弹窗关闭
      mxCloseDialogHandle () {
        this.mxAddOrUpdateVisible = false
      },

      // 获取清单明细编辑返回的数据
      getReturnMxBoqData (data) {
        let mxTable = null;
        if (this.mxTypeId == this.Type_AddBoq) {
          mxTable = this.$refs.mxNewAddTableRef
        }
        if (this.mxTypeId == this.Type_ChangeNum) {
          mxTable = this.$refs.mxNumChangeTableRef
        }
        if (!data.id) {
          // 数据直接插入最后一行
          if (mxTable != null) {
            mxTable.insertAt(data,-1)
          }
        }
      },

      closeSubboqBatchAddDialogHandle () {
        this.subboqBatchAddVisible = false
      },

      // 批量新增分项清单
      subboqBatchAddHandle(data) {
        this.$refs.mxNewAddTableRef.insertAt(data, -1)
      },

      // 删除一行清单明细数据
      deleteOneBoqHandle (row) {
        let $table = null
        if (this.activeTabName === 'tab2') {
          $table = this.$refs.mxNewAddTableRef
        } else if (this.activeTabName === 'tab3') {
          $table = this.$refs.mxNumChangeTableRef
        }
        this.$confirm(this.$t('prompt.info',{'handle': this.$t('delete')}),this.$t('prompt.title'),{
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          if ($table != null) {
            $table.remove(row)
          }
        }).catch()
      },
      // 清单明细行 数据校验。验证不通过，返回false
      async mxDataValidate (tableRef,tab) {
        let $tableRef = this.$refs[tableRef]
        let errMap = await $tableRef.validate(true).catch((errMap) => errMap)
        if (errMap) {
          const {row,rule} = Object.values(errMap)[0][0] // 取第一个错误信息
          const rowIndex = $tableRef.getVTRowIndex(row)
          if (this.activeTabName !== tab) {
            this.activeTabName = tab  // 切换页签
          }
          this.$message({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: '第' + (rowIndex + 1) + '行 校验错误：' + rule.$options.message + '',
            duration: 2000
          })
          return false
        }
        return true
      },

      // 清单数量变更 单元格退出编辑事件
      numChangeEditClosedEvent ({row,column}) {
        const $table = this.$refs.mxNumChangeTableRef
        const field = column.property
        // 判断单元格值是否被修改，新增行的单元格触发默认都是已修改状态
        let isOk = $table.isUpdateByRow(row,field) || $table.isInsertByRow(row)
        if (isOk) {
          if (field === 'changedNum') {
            let price = XEUtils.toNumber(row.price)
            let oldNum = XEUtils.toNumber(row.oldNum)
            let changedNum = XEUtils.toNumber(row.changedNum)
            row.newNum = XEUtils.add(oldNum,changedNum)
            row.changedAmount = XEUtils.multiply(price,changedNum)
          }
        }
      },

      // 清单新增 单元格退出编辑事件
      newAddEditClosedEvent ({row,column}) {
        const $table = this.$refs.mxNewAddTableRef
        const field = column.property
        // 判断单元格值是否被修改，新增行的单元格触发默认都是已修改状态
        let isOk = $table.isUpdateByRow(row,field) || $table.isInsertByRow(row)
        if (isOk) {
          if (field === 'changedNum' || field === 'price') {
            let price = XEUtils.toNumber(row.price)
            let changedNum = XEUtils.toNumber(row.changedNum)
            row.changedAmount = XEUtils.multiply(price,changedNum)
          }
        }
      },
      // 数量格式化
      numFormatter ({cellValue,row}) {
        if (XEUtils.isNumber(row.unitAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: row.unitAccuracy})
        } else {
          return cellValue
        }
      },

      // 金额格式化
      amountFormatter ({cellValue}) {
        if (XEUtils.isNumber(this.dataForm.contractAmountAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: this.dataForm.contractAmountAccuracy})
        } else {
          return cellValue
        }
      },

      // 回调函数：用于给起草人修改保存被驳回的流程
      async submitCallback (processCallback) {
        // 明细行 数据校验
        let rs = await this.mxDataValidate('mxNewAddTableRef','tab2')
        if (rs) {
          rs = await this.mxDataValidate('mxNumChangeTableRef','tab3')
          if (!rs) {
            return false
          }
        } else {
          return false
        }

        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            if (this.activeTabName !== 'tab1') {
              this.activeTabName = 'tab1'  // 切换页签到第一页
              this.$message({
                message: "请先补充必填信息",
                type: 'warning',
                duration: 1500
              })
            }
            return false
          }
          const loading = this.$loading({
            lock: true,
            text: '正在提交...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          // 获取清单明细的修改、新增、删除行
          const {insertRecords,removeRecords,updateRecords} = this.$refs.mxNewAddTableRef.getRecordset()
          const {insertRecords: insertRecords2,removeRecords: removeRecords2,updateRecords: updateRecords2} = this.$refs.mxNumChangeTableRef.getRecordset()
          this.dataForm.addMxList = [...insertRecords,...insertRecords2]
          this.dataForm.updateMxList = [...updateRecords,...updateRecords2]
          let deleteMxIdList = []
          if (removeRecords && removeRecords.length > 0) {
            removeRecords.forEach(function (element) {
              if (element && element['id']) {
                deleteMxIdList.push(element['id'])
              }
            })
          }
          if (removeRecords2 && removeRecords2.length > 0) {
            removeRecords2.forEach(function (element) {
              if (element && element['id']) {
                deleteMxIdList.push(element['id'])
              }
            })
          }
          this.dataForm.deleteMxIdList = deleteMxIdList

          if (this.process.updateURL) {
            // 提交业务表单信息
            this.$http.put(this.process.updateURL,this.dataForm).then(({data: res}) => {
              if (res.code !== 0) {
                loading.close()
                return this.$message.error(res.msg)
              }
              // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
              processCallback(() => {
                // 最后一步执行完毕后的回调函数
                loading.close()
              })
            }).catch(() => {
              loading.close()
            })
          } else {
            // 如果没有在xxx-process.vue中配置updateURL属性，则不需要执行该接口，直接将该被驳回的流程提交到流程下一步阶段。
            // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
            processCallback(() => {
              // 最后一步执行完毕后的回调函数
              loading.close()
            })
          }
        })
      },
    }
  }
</script>