<template>
  <el-dialog :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tree
        v-loading="treeLoading"
        :data="dataList"
        node-key="id"
        :props="props"
        ref="tree"
        show-checkbox
        check-on-click-node
        :default-checked-keys="defaultCheckedKeys"
        highlight-current
    ></el-tree>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      dataList: [],
      visible: false,
      dataForm: {
        contractId: '',
        measId: '',
      },
      props: {
        label: 'name',
        // children: 'children',
        disabled: this.checkBoxDisabled
      },
      treeLoading: true,
      // 是否是新的计量主表(根据列表页面选择的期次/计量，如果本期没有计量，或之前的计量流程状态为未通过，则需要新主表)
      // 用于判断是否需要查询已计量的费用条款，并将其标记为不能修改
      newMeas: false,
      defaultCheckedKeys: [],
      title: ''
    }
  },
  computed: {
    dataRule() {
      return {}
    }
  },
  methods: {
    getDataList() {
      this.$http.get(
          '/mps/contractCostItem/list',
          {
            params: {
              'contractId': this.dataForm.contractId,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    init() {
      this.visible = true
      this.getDataList()
      this.getExistList()
    },
    // 查询已计量的合同费用条款id
    // 如果当前选中的计量主表的流程状态为3（未通过），则需要重新生成一条计量主表，并略过此查询
    getExistList() {
      if (this.newMeas) {
        this.treeLoading = false
      } else {
        this.$http.get(
            '/mps/measCostMx/getExistItemIdList',
            {
              params: {
                measId: this.dataForm.measId
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          this.defaultCheckedKeys = res.data
          this.treeLoading = false
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        })
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.tree.getCheckedNodes(true)
      let newData = []
      // 将itemId传至后台
      for (let node of checkedNodes) {
        if (!this.defaultCheckedKeys.includes(node.id)) {
          newData.push({
            itemId: node.id,
          })
        }
      }
      let params = {
        measId: this.dataForm.measId,
        newData: newData
      }
      this.$http.post(
          '/mps/measCostMx/batchAdd',
          params
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
      })
    }, 1000, {'leading': true, 'trailing': false}),
    checkBoxDisabled(data, node) {
      return this.defaultCheckedKeys.includes(data.id)
    },
  }
}
</script>