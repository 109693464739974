<template>
  <el-dialog top="5vh" :width="dataForm.id?'95%':'70%'" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <!--修改-->
    <el-tabs v-if="dataForm.id?true:false" v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="18">
              <el-form-item label="范本名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="请填写范本名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="排序" prop="sortNo">
                <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="清单范本" name="tab2">
        <boq-template ref="boqTemplate"></boq-template>
      </el-tab-pane>
    </el-tabs>
    <!--新增-->
    <el-form v-if="!dataForm.id?true:false" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
      <el-row :gutter="10">
        <el-col :span="18">
          <el-form-item label="范本名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="请填写清单范本名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="排序" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import BoqTemplate from './boqtemplate'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          code: '',
          name: '',
          sortNo: ''
        },
        activeTabName: 'tab1',
      }
    },
    components: {
      BoqTemplate
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          code: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
        }
      }
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
            this.$refs.boqTemplate.mainId = this.dataForm.id
            this.$refs.boqTemplate.getDataList()
          }else{
            this.getNewSortNo()
          }
        })
      },
      // 获取排序序号
      getNewSortNo () {
        this.$http.get(`/mps/boqTemplateMain/newSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.sortNo = res.data
        }).catch(() => {
          // do nothing
        })
      },
      // 获取信息
      getInfo () {
        this.$http.get('/mps/boqTemplateMain/' + this.dataForm.id).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
        }).catch(() => {
          // do nothing
        })
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            if (this.activeTabName !== 'tab1') {
              this.activeTabName = 'tab1'  // 切换页签到第一页
              this.$message({
                message: "请先补充必填信息",
                type: 'warning',
                duration: 1500
              })
            }
            return false
          }
          this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/boqTemplateMain/',this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }).catch(() => {
            // do nothing
          })
        })
      },1000,{'leading': true,'trailing': false})
    }
  }
</script>
