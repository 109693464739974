<template>
  <el-dialog v-dialog-drag top="5vh" width="80%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractName">
            <el-input v-model="dataForm.contractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="contractCode">
            <el-input v-model="dataForm.contractCode" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工单位" prop="deptId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.deptId" style="width: 100%;" disabled></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设计单位" prop="designId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.designId" style="width: 100%;" disabled></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运营单位" prop="operationId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.operationId" style="width: 100%;" disabled></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="代建单位" prop="proxyId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.proxyId" style="width: 100%;" disabled></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.supervisionId" style="width: 100%;" disabled></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开工日期" prop="startDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.startDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="竣工日期" prop="completionDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.completionDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收结论" prop="conclusion">
            <el-select v-model="dataForm.conclusion" disabled class="w-percent-100">
              <el-option :key="1" label="合格" :value="1"></el-option>
              <el-option :key="0" label="不合格" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="填报人" prop="report">
            <el-input v-model="dataForm.report" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="reportDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="整改问题项" prop="problem">
            <el-input type="textarea" v-model="dataForm.problem" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <my-upload-plain :refId="dataForm.id" view></my-upload-plain>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        deptId: '',
        designId: '',
        operationId: '',
        proxyId: '',
        supervisionId: '',
        startDate: '',
        completionDate: '',
        conclusion: '',
        problem: '',
        report: '',
        reportDate: '',
        remark: '',
        prjName: '',
        prjCode: ''
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionAcceptance/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
  }
}
</script>
