<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="模板名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="分类名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        name: '',
        remark: '',
        jsonData: '',
        sortNo: '',
      }
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'},
          {validator: this.isNameUnique, message: '该名称已存在', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/sheetTemplate/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 名称唯一性校验
    isNameUnique(rule, value, callback) {
      this.$http.get('/mps/sheetTemplate/checkNameUnique',
          {
            params: {
              name: this.dataForm.name,
              prjId: this.dataForm.prjId,
              id: !this.dataForm.id ? '' : this.dataForm.id
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data === false) {
          callback(new Error('该名称已存在'))
        } else {
          callback()
        }
      }).catch(() => {
        // do nathing
      }).finally(() => {
        callback()
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/sheetTemplate/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nathing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>