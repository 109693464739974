<template>
  <el-dialog width="70%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form key="first" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="期次" prop="periodId">
                <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId"
                               placeholder="期次选择" default-select :hasMeas="false"></period-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标题" prop="name">
                <el-input v-model="dataForm.name" placeholder="名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="dataForm.remark" placeholder="备注说明" :autosize="{ minRows: 3 }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="费用明细" name="tab2">
        <el-button-group>
          <el-button v-if="$hasPermission('opm:safetycost:save')" type="primary" size="mini" @click="addData()">添加</el-button>
        </el-button-group>
        <vxe-table id="mxTable"
                   keep-source
                   ref="mxTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="dataForm.details"
                   :max-height="tableHeight"
                   :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
          <vxe-column field="sortNo" title="序号" width="60" align="center" fixed="left" :edit-render="{ name: '$input', props: {type: 'number',min: 1} }"></vxe-column>
          <vxe-column field="name" title="费用条目" fixed="left" :edit-render="{ name: '$input'}"></vxe-column>
          <vxe-column field="money" title="本期发生金额（元）" width="160" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}}"></vxe-column>
          <vxe-column field="remark" title="备注说明" :edit-render="{ name: '$input'}"></vxe-column>
          <vxe-column title="操作" width="80" align="center" fixed="right">
            <template #default="{row}">
              <el-button v-if="$hasPermission('opm:safetycost:delete')" type="text" size="small" @click="deleteOneMxHandle(row)">{{ $t('delete') }}</el-button>
            </template>
          </vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        name: '',
        remark: '',
        details: []
      },
      activeTabName: 'tab1',
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        periodId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/opm/safetyCost/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.$message.error("出错了!")
        this.mxDataListLoading = false
      })
    },
    // 添加费用记录
    addData() {
      const $table = this.$refs.mxTableRef
      // 列表末尾新增费用记录
      $table.insertAt({
        id: '',
        name: '',
        remark: '',
        money: null,
        sortNo: this.$refs.mxTableRef.getTableData().fullData.length+1
      }, -1)
    },
    // 删除一行明细数据
    deleteOneMxHandle(row) {
      let $table = this.$refs.mxTableRef
      if ($table != null) {
        $table.remove(row)
      }
    },

    setMxData() {
      const {removeRecords} = this.$refs.mxTableRef.getRecordset()
      this.dataForm.addMxList = []
      this.dataForm.updateMxList = []
      let fullDataList = this.$refs.mxTableRef.getTableData().fullData
      if (fullDataList && fullDataList.length > 0) {
        for (let i = 0; i < fullDataList.length; i++) {
          let mxData = fullDataList[i]
          let isOk = mxData.name // 费用名称为空，默认不保存该条明细
          if (isOk) {
            // 新增
            if (!mxData.id) {
              this.dataForm.addMxList.push(mxData)
            } else {
              // 修改
              this.dataForm.updateMxList.push(mxData)
            }
          }
        }
      }

      let deleteMxIdList = []
      if (removeRecords && removeRecords.length > 0) {
        removeRecords.forEach(function (element) {
          if (element && element['id']) {
            deleteMxIdList.push(element['id'])
          }
        })
      }
      this.dataForm.deleteMxIdList = deleteMxIdList
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          if (this.activeTabName !== 'tab1') {
            this.activeTabName = 'tab1'  // 切换页签到第一页
            this.$message({
              message: "请先补充必填信息",
              type: 'warning',
              duration: 1500
            })
          }
          return false
        }
        this.setMxData()
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/safetyCost/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            if (this.activeTabName !== 'tab1') {
              this.activeTabName = 'tab1'  // 切换页签到第一页
            }
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>