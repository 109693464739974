<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div :style="divStyle">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        
      </el-form>
      <iframe style="margin-left: -5px" :src="iframeSrc" scrolling="auto" frameborder="0" id="iframe" width="99%" height="99%"></iframe>
    </div>
  </el-card>
</template>

<script>
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        prjName: '',
        contact: ''
      },
      iframeSrc: '',
      divStyle: {
        height: ''
      }
    }
  },
  created () {
    this.setHeight()
    this.getDataList()
  },
  components: {},
  methods: {
    getDataList () {
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        '_u': 'icloud-监理工作效率.ureport.xml'
      })
      this.iframeSrc = `${window.SITE_CONFIG['apiURL']}/ur2/ureport/preview?${params}`
    },
    setHeight () {
      this.$nextTick(() => {
        this.divStyle.height = this.$refs.element.$el.clientHeight - 80 + 'px'
      })
    }
  }
}
</script>
