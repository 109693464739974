<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>
    <!-- 以下是业务表单内容 -->
    <div class="mod-__personchange">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
        <el-card shadow="hover">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="人员类型" prop="typeId">
                <el-select v-model="dataForm.typeId" placeholder="请选择" class="w-percent-100">
                  <el-option
                      v-for="item in typeOptions"
                      :key="item.value+''"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>变更前</span>
          </div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" prop="name1">
                <el-input v-model="dataForm.name1" placeholder="姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idcard1">
                <el-input v-model="dataForm.idcard1" placeholder="身份证号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="mobile1">
                <el-input v-model="dataForm.mobile1" placeholder="手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位" prop="post1">
                <el-input v-model="dataForm.post1" placeholder="职位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职称" prop="rank1">
                <el-input v-model="dataForm.rank1" placeholder="职称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="从业年限" prop="years1">
                <el-input-number v-model="dataForm.years1" data-unit="年" :min="0" :controls="false" style="width: 100%;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证书" prop="certificate1">
                <el-input v-model="dataForm.certificate1" placeholder="证书"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age1">
                <el-input-number v-model="dataForm.age1" data-unit="岁" :min="0" :controls="false" style="width: 100%;"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>变更后</span>
          </div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="姓名" prop="name2">
                <el-input v-model="dataForm.name2" placeholder="姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idcard2">
                <el-input v-model="dataForm.idcard2" placeholder="身份证号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="mobile2">
                <el-input v-model="dataForm.mobile2" placeholder="手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位" prop="post2">
                <el-input v-model="dataForm.post2" placeholder="职位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职称" prop="rank2">
                <el-input v-model="dataForm.rank2" placeholder="职称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="从业年限" prop="years2">
                <el-input-number v-model="dataForm.years2" data-unit="年" :min="0" :controls="false" style="width: 100%;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证书" prop="certificate2">
                <el-input v-model="dataForm.certificate2" placeholder="证书"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age2">
                <el-input-number v-model="dataForm.age2" data-unit="岁" :min="0" :controls="false" style="width: 100%;"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <div style="margin-top: 20px">
        <my-upload ref="upload" :refId="dataForm.id"></my-upload>
      </div>
    </div>
  </el-card>
</template>

<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        typeId: '',
      },
      typeOptions: [{
        value: 1,
        label: '施工人员'
      }, {
        value: 2,
        label: '监理人员'
      }
      ],
      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/opm/personChange'
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    dataRule() {
      return {
        name1: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name2: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      //  通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/personChange/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>