<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree ref="wbsTree" lazy
                   class="left-tree" node-key="id" v-loading="wbsTreeLoading"
                   :data="wbsTreeData"
                   :props="defaultProps"
                   :load="loadWbsNode"
                   :expand-on-click-node="false"
                   @node-click="wbsTreeNodeClick"
                   :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__scheduleprogress">
          <div style="margin-top: 5px;display: flex;justify-content: flex-start;">
            <div style="white-space: nowrap">图例：</div>
            <div style="background-color:#ebae0a; text-align: center; color: white; margin-right: 10px">已开工</div>
            <div style="background-color:#37ddeb; text-align: center; color: white; padding-left: 4px;">已完工</div>
          </div>
          <div id="threeContainer" style="width: 1000px; height: 500px">
            <!-- 模型加载进度 -->
            <div id="progress-text-container" style="position: absolute; top: 0; right: 100px; display: none; color: red; font-weight: bold; font-size: 14px;">
              <p>加载进度：<span id="progress-text">0</span> %</p>
            </div>
          </div>
        </div>
      </template>
    </my-container>
    <!-- 构件属性 -->
    <ifc-prop v-if="ifcPropVisible" ref="ifcProp" @hide="closeIfcPropHandle"></ifc-prop>
  </el-card>
</template>
<!-- 基于IFC.js实现的形象进度 -->
<script>
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        wbsId: '',
      },

      wbsTreeLoading: false,
      wbsTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },

      loading: null,
      ifcUrl: '', // 加载ifc时的文件路径
      ifcPropVisible: false,
      ifcInstance: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      let containerWidth = window.innerWidth - 580
      let containerHeight = window.innerHeight - 180
      if (containerWidth < 900) {
        containerWidth = 900
      }
      if (containerHeight < 450) {
        containerHeight = 450
      }
      document.getElementById('threeContainer').style.width = containerWidth + 'px'
      document.getElementById('threeContainer').style.height = containerHeight + 'px'

      this.ifcInstance = new threeIfcScene.Instance({
        ele: 'threeContainer',
        width: containerWidth,
        height: containerHeight,
        onProgressCallback: this.ifcProgressCallback,
        startLoading: (txt) => {
          this.loading = this.$loading({
            lock: true,
            text: txt,
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        stopLoading: () => {
          this.loading.close()
        },
        onPickCallback: (expressId,props,propSets) => {
          this.openPropModal(expressId,props,propSets)
        }
      });
      this.ifcInstance.releaseMemory()
    })
  },
  async beforeDestroy () {
    await this.ifcInstance.releaseMemory()
  },
  methods: {
    // 合同标段切换事件
    contractChange (data) {
      this.dataForm.wbsId = ''
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId

      this.fileList = []
      this.getWbsTreeData()
    },

    //异步加载wbs子节点数据
    loadWbsNode (node,resolve) {
      if (node.level >= 1) {
        if (node && node.data) {
          let wbsData = node.data;
          if (wbsData.id == '-1') {
            return resolve([])
          } else {
            this.$http.get('/mps/wbs/children',
              {
                params: {
                  pid: wbsData.id,
                  contractId: wbsData.contractId,
                  subcontractorId: wbsData.subcontractorId
                }
              }
            ).then(({data: res}) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              }
              resolve(res.data);
            }).catch(() => {
              return this.$message.error('出错了')
            })
          }
        } else {
          return resolve([])
        }
      }
    },

    getWbsTreeData () {
      this.wbsTreeLoading = true
      // 清空wbs数据
      this.wbsTreeData = []
      this.$http.get('/mps/wbs/children',
        {
          params: {
            'pid': 0,
            'contractId': this.dataForm.contractId
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.wbsTreeData = res.data || []
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.wbsTreeLoading = false
      })
    },

    //  分部分项树节点点击事件
    async wbsTreeNodeClick (data,node,ele) {
      this.fileList = []
      this.dataForm.wbsId = data.id
      this.loading = this.$loading({
        text: '',
        spinner: "aircity-loading",
        customClass: 'aircity-loading',
        background: 'rgba(0, 0, 0, 0.4)'
      })
      // 清空场景
      await this.ifcInstance.releaseMemory()
      // 从服务器获取wbs最新的ifc文件地址URL
      let {data: res} = await this.$http.get(`/mps/wbs/${this.dataForm.wbsId}?withIfcUrl=1`)
      if (res.code !== 0) {
        this.loading.close()
        return this.$message.error(res.msg)
      }
      let ifcUrl = res.data ? res.data.ifc : ''
      if (!ifcUrl) {
        this.loading.close()
        return this.$message.warning("未上传模型！")
      } else {
        // 查询wbs和ifc的关系 (形象进度)
        let wbsIfcData = null
        let {data: res} = await this.$http.get(`/mps/wbsIfc/visualSchedule/data`,{
          params: {
            prjId: this.dataForm.prjId,
            wbsId: this.dataForm.wbsId
          }
        })
        if (res.code !== 0) {
          this.loading.close()
          return this.$message.error(res.msg)
        }
        wbsIfcData = res.data
        // 渲染模型
        await this.renderModel(ifcUrl,wbsIfcData)
      }
    },

    /**
     * 渲染模型
     * @param ifcUrl ifc文件路径
     * @param wbsIfcData wbs和ifc的关系
     */
    async renderModel (ifcUrl,wbsIfcData) {
      this.ifcUrl = ifcUrl
      if (ifcUrl) {
        // 获取ifc文件地址的签名URL
        let ifcSignedUrl = await this.getIfcSignedUrl(ifcUrl)
        if (ifcSignedUrl) {
          await this.ifcInstance.visualSchedule(ifcSignedUrl,wbsIfcData,() => {
            if (this.loading) {
              this.loading.close()
            }
          })
        } else {
          if (this.loading) {
            this.loading.close()
          }
        }
      } else {
        if (this.loading) {
          this.loading.close()
        }
      }
    },

    // 获取ifc文件地址的签名URL
    async getIfcSignedUrl (ifcUrl) {
      let {data: res} = await this.$http.get('/oss/file/signedUrl?url=' + ifcUrl)
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return null
      } else {
        return res.data
      }
    },

    // 打开构件属性面板
    openPropModal (expressId,props,propSets) {
      this.ifcPropVisible = true
      this.$nextTick(() => {
        this.$refs.ifcProp.init(this.dataForm.prjId,expressId,this.ifcUrl,props,propSets)
      })
    },
    closeIfcPropHandle () {
      this.ifcPropVisible = false
    },
    ifcProgressCallback (progress) {
      if (progress == '100') {
        setTimeout(() => {
          document.getElementById('progress-text').innerText = progress.toString();
          document.getElementById('progress-text-container').style.display = 'none'
        },3000);
      } else {
        document.getElementById('progress-text').innerText = progress.toString();
        document.getElementById('progress-text-container').style.display = 'block'
      }
    }
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 150px);
  }
</style>