import { render, staticRenderFns } from "./boq.vue?vue&type=template&id=68b97e07&scoped=true"
import script from "./boq.vue?vue&type=script&lang=js"
export * from "./boq.vue?vue&type=script&lang=js"
import style0 from "./boq.vue?vue&type=style&index=0&id=68b97e07&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b97e07",
  null
  
)

export default component.exports