<template>
    <vxe-table
        :loading="dataListLoading"
        border
        resizable
        show-overflow="tooltip"
        :tooltip-config="{theme: 'light'}"
        highlight-hover-row
        :height="tableHeight"
        :scroll-y="{enabled: true}"
        :data="dataList">
      <vxe-column :visible="ticketType == 2" field="code" title="计量单号" width="150">
        <template v-slot="{ row }">
          <el-tooltip v-if="row.isEntity && (row.qaFileState < 2)" effect="light" :content="codeTooltipContent(row)" placement="bottom">
            <el-button :style="{color: 'red'}" type="text" size="small" @click="clickHandle(row)">{{row.code}}</el-button>
          </el-tooltip>
          <el-button v-else type="text" size="small" @click="clickHandle(row)">{{row.code}}</el-button>
        </template>
      </vxe-column>
      <vxe-column field="subItemName" title="工程或费用名称" width="350">
        <template v-slot="{ row }">
          <el-button :style="(row.isEntity && (row.qaFileState < 2)) ? {color: 'red'} : {}" type="text" size="small" @click="clickHandle(row)">{{row.subItemName}}</el-button>
        </template>
      </vxe-column>
      <vxe-column field="name" title="计量部位" width="240"></vxe-column>
      <vxe-colgroup title="质保资料" header-align="center" >
        <vxe-column field="startWorkFileExist" title="开工报告" align="center" width="100">
          <template v-slot="{row}">
            <el-tooltip v-if="row.qaFileState >= 1" class="item oss-tooltip" effect="light" content="开工报告已上传" placement="bottom">
              <i class="el-icon-check" style="color: green"></i>
            </el-tooltip>
            <el-tooltip v-else-if="row.isEntity" class="item oss-tooltip" effect="light" content="开工报告未上传" placement="bottom">
              <i class="el-icon-close" style="color: red"></i>
            </el-tooltip>
            <span v-else></span>
          </template>
        </vxe-column>
        <vxe-column field="inspectionFileExist" title="检验申请批复" align="center" width="100">
          <template v-slot="{row}">
            <el-tooltip v-if="row.qaFileState >= 2" class="item oss-tooltip" effect="light" content="检验申请批复已上传" placement="bottom">
              <i class="el-icon-check"></i>
            </el-tooltip>
            <el-tooltip v-else-if="row.isEntity" class="item oss-tooltip" effect="light" content="检验申请批复未上传" placement="bottom">
              <i class="el-icon-close" style="color: red"></i>
            </el-tooltip>
            <span v-else></span>
          </template>
        </vxe-column>
        <vxe-column field="certificateFileExist" title="交工证书" align="center" width="100">
          <template v-slot="{row}">
            <el-tooltip v-if="row.qaFileState >= 3" class="item oss-tooltip" effect="light" content="交工证书已上传" placement="bottom">
              <i class="el-icon-check"></i>
            </el-tooltip>
            <el-tooltip v-else-if="row.isEntity" class="item oss-tooltip" effect="light" content="交工证书未上传" placement="bottom">
              <i class="el-icon-close" style="color: red"></i>
            </el-tooltip>
            <span v-else></span>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-column field="stakeNo" title="起讫桩号" width="150"></vxe-column>
      <vxe-column field="ctoa" title="交工证书号" width="200"></vxe-column>
      <!-- <vxe-column field="drawingNo" title="图号" width="150"></vxe-column> -->
    </vxe-table>
</template>
<script>
import XEUtils from "xe-utils";
export default {
  data () {
    return {
      dataForm: {
        measId: '',
        subItemId: '',
      },
      // 计量单类型，如果为2，则是一对多（说明计量单号与计量单一一对应，表格中需要显示计量单号）
      ticketType: '',
      dataList: [],
      dataListLoading: false,
    }
  },
  props: {
    tableHeight: Number
  },
  methods: {
    getDataList() {
      if (!this.dataForm.measId) {
        this.dataList = []
        return
      }
      this.dataListLoading = true
      this.$http.get(
          `/mps/measTicket/list`,
          {
            params: this.dataForm
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false
      }).catch(() => {
        return this.$message.error('获取基本信息数据出错，请重试！')
      })
    },
    clickHandle(row) {
      this.$emit('measTicketClick', row.subItemId)
    },
    codeTooltipContent(row) {
      if (row.qaFileState == 1) {
        return '缺少检验申请批复资料'
      } else if (row.qaFileState == 2) {
        return '缺少交工证书'
      } else if (row.qaFileState != 3) {
        return '缺少质保资料'
      }
    },
  }
}
</script>