<template>
  <el-dialog width="80%" title="查看" :visible.sync="visible" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeTabName" tab-position="left">
      <el-tab-pane label="开工报告" name="1">
        <my-upload key="a" ref="uploader1" :refId="dataForm.wbsId" typeId="开工报告" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 1)"></my-upload>
      </el-tab-pane>
      <el-tab-pane label="检验申请批复" name="2">
        <my-upload key="b" ref="uploader2" :refId="dataForm.wbsId" typeId="检验申请批复" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 2)"></my-upload>
        <!--        <el-container>-->
<!--          <el-aside width="180px">-->
<!--            <vxe-table-->
<!--                ref="xTable"-->
<!--                resizable-->
<!--                show-overflow-->
<!--                height="auto"-->
<!--                border="none"-->
<!--                :show-header="false"-->
<!--                :data="workstageList"-->
<!--                :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"-->
<!--                empty-text="该分项没有关联的工序"-->
<!--                cell-class-name="pointer-cursor"-->
<!--                @cell-click="treeCellClick"-->
<!--            >-->
<!--              <vxe-column field="name" width="160"></vxe-column>-->
<!--            </vxe-table>-->
<!--          </el-aside>-->
<!--          <el-main>-->
<!--            <span v-if="workstageList.length == 0"></span>-->
<!--            <my-upload v-else-if="workstageId" ref="uploader" :refId="workstageId" typeId="检验申请批复" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 2)"></my-upload>-->
<!--            <p v-else style="margin-left: 10px; font-size: 16px;">-->
<!--              请选择具体工序查看检验申请批复文件-->
<!--            </p>-->
<!--          </el-main>-->
<!--        </el-container>-->
      </el-tab-pane>
      <el-tab-pane label="交工证书" name="3">
        <my-upload key="c" ref="uploader3" :refId="dataForm.wbsId" typeId="交工证书" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 3)"></my-upload>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        wbsId: '',
      },
      canEdit: false,
      activeTabName: '1',
      workstageId: '',
      workstageList: [],
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    // 获取信息
    getInfo () {
      // 检验申请批复现在也关联在wbs上，所以此处不再查询工序
      // this.$http.get('/mps/wbsWorkstage/refList/' + this.dataForm.wbsId).then(({data: res}) => {
      //   if (res.code !== 0) {
      //     return this.$message.error(res.msg)
      //   }
      //   this.workstageList = res.data
      //   // 默认选中第一条
      //   if (res.data && res.data.length > 0) {
      //     this.$nextTick(() => {
      //       let defaultRow = this.$refs.xTable.getRowById(res.data[0].id)
      //       this.$refs.xTable.setCurrentRow(defaultRow)
      //       this.workstageId = defaultRow.id
      //     })
      //   }
      // }).catch()
    },
    treeCellClick({row}) {
      this.workstageId = row.id
    },
    updateWbsFileState(file, typeId) {
      this.$http.post(
          '/mps/wbs/updateFileState',
          {
            wbsId: this.dataForm.wbsId,
            typeId: typeId,
            fileName: file.name
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
      }).catch(() => {})
    }
  }
}
</script>