<template>
  <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractId">
            <contract-select v-model="dataForm.contractId" :prjId="dataForm.prjId" placeholder="标段选择" @change="contractChangeHandle" :defaultSelect="true"></contract-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="contractCode">
            <el-input v-model="dataForm.contractCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="计划编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="计划名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划验收日期" prop="planAcceptanceDate">
            <el-date-picker type="date" placeholder="计划验收日期" value-format="yyyy-MM-dd" v-model="dataForm.planAcceptanceDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收组织者" prop="organizer">
            <el-input v-model="dataForm.organizer" placeholder="验收组织者"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="外部参加人员" prop="outsideParticipant">
            <el-input type="textarea" v-model="dataForm.outsideParticipant" placeholder="外部参加人员" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="参加人员" prop="participant">
            <el-input type="textarea" v-model="dataForm.participant" placeholder="参加人员" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报人" prop="report">
            <el-input v-model="dataForm.report" placeholder="填报人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="reportDate">
            <el-date-picker type="date" placeholder="填报日期" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        planAcceptanceDate: '',
        organizer: '',
        outsideParticipant: '',
        participant: '',
        report: '',
        reportDate: '',
        remark: '',
        contractCode: '',
      }
    }
  },
  computed: {
    dataRule() {
      return {
        contractId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        planAcceptanceDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 填报日期
          this.dataForm.reportDate = XEUtils.toDateString(new Date())
          // 填报人
          this.dataForm.report = this.$store.state.user.realName
        }
      })
    },
    // 标段选择
    contractChangeHandle(data) {
      if (data) {
        this.dataForm.contractCode = data.code
      }
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionPlan/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/completionPlan/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>