<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__completionacceptance">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true" @change="projectChange"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="验收编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:completionacceptance:save')" @click="addOrUpdateHandle({id:''})"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:completionacceptance:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="code" label="验收编号" width="200" align="center" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.code }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="contractName" label="合同名称" width="250" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="contractCode" label="合同编号" width="200" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="startDate" label="开工日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="completionDate" label="竣工日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="conclusion" label="验收结论" width="120" align="center" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-tag v-if="row.conclusion==0" size="small" type="danger" effect="plain">不合格</el-tag>
            <el-tag v-else-if="row.conclusion==1" size="small" effect="plain">合格</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="deptName" label="施工单位" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="designName" label="设计单位" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operationName" label="运营单位" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="proxyName" label="代建单位" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supervisionName" label="监理单位" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="report" label="填报人" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reportDate" label="填报日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="180">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:completionacceptance:update')&& checkSelectable(row)" @click="addOrUpdateHandle(row)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:completionacceptance:delete')&& checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:completionacceptance:update')|| !checkSelectable(row))"></table-button>
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" fixed="right" align="center" width="120" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.contractName" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './completionacceptance-add-or-update'
import ViewPage from './completionacceptance-view'
import processModule from '@/mixins/process-module'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/completionAcceptance/page',
        getDataListIsPage: true,
        deleteURL: '/mps/completionAcceptance',
      },
      dataForm: {
        prjId: '',
        code: '',
        prjCode: '',
        prjName: ''
      }
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 项目切换事件
    projectChange(data) {
      this.dataForm.prjName = data.name
      this.dataForm.prjCode = data.code
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = row.id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.prjName = this.dataForm.prjName
        this.$refs.addOrUpdate.dataForm.prjCode = this.dataForm.prjCode
        this.$refs.addOrUpdate.init()
      })
    }
  }
}
</script>