<template>
  <p v-if="!refId" style="margin-left: 10px; font-size: 16px;">
    请选择具体分项
  </p>
  <div v-else>
    <my-upload ref="uploader" :refId="refId" typeId="普通附件" :view="!canEdit" :tableMaxHeight="height"></my-upload>
  </div>
</template>

<script>

export default {
  data () {
    return {
      refId: '',
      canEdit: false,
    }
  },
  props: {
    height: Number
  },
  methods: {
  }
}
</script>
