<template>
  <el-dialog width="90%" v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form key="first" :model="dataForm" ref="dataForm" label-width="140px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="变更编号" prop="code">
                <el-input v-model="dataForm.code" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="变更项目名称" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="变更类型" prop="typeId">
                <dict-select v-model="dataForm.typeId" dict-type="mps_change_order_type" style="width: 100%;" disabled></dict-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请时间" prop="changeDate">
                <el-date-picker type="date" :value-format="dateFormat" v-model="dataForm.changeDate" style="width: 100%;" readonly></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="桩号" prop="stakeNo">
                <el-input v-model="dataForm.stakeNo" readonly></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="变更原因及内容" prop="content">
                <el-input type="textarea" v-model="dataForm.content" :autosize="{ minRows: 2}" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="新增清单" name="tab2">
        <vxe-table id="vxeTable1" keep-source ref="mxNewAddTableRef"
              :loading="mxDataListLoading"
              size="mini"
              :column-config="{resizable: true}"
              border
              show-overflow
              :row-config="{isCurrent: true,isHover: true}"
              :data="mxDataList"
              :max-height="tableHeight"
              :edit-config="{trigger: 'click', mode: 'row', showStatus: true}">
          <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
          <!-- <vxe-column field="boqParentCode" title="上级清单编码" width="120" fixed="left"></vxe-column>
          <vxe-column field="boqParentName" title="上级清单名称" min-width="120" fixed="left"></vxe-column> -->
          <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
          <vxe-column field="boqName" title="清单名称" width="180" fixed="left"></vxe-column>
          <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
          <!--<vxe-column field="stakeNo" title="桩号" min-width="120" fixed="left" :visible="false"></vxe-column>-->
          <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
          <vxe-column field="price" title="单价(元)" width="150" align="right"></vxe-column>
          <vxe-column field="changedNum" title="数量" width="150" align="right" :formatter="numFormatter"></vxe-column>
          <vxe-column field="changedAmount" title="金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
        </vxe-table>
      </el-tab-pane>
      <el-tab-pane label="数量变更" name="tab3">
        <vxe-table id="vxeTable2" keep-source ref="mxNumChangeTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="mxDataList2"
                   :max-height="tableHeight"
                   :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
          <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
          <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
          <vxe-column field="boqName" title="清单名称" width="180" fixed="left"></vxe-column>
          <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
          <!--<vxe-column field="stakeNo" title="桩号" width="140" fixed="left" :visible="false"></vxe-column>-->
          <vxe-column field="unitName" title="单位" align="center" width="80"></vxe-column>
          <vxe-column field="price" title="单价(元)" align="right" width="150"></vxe-column>
          <vxe-column field="oldNum" title="变更前数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
          <vxe-column field="changedNum" title="变更增减" width="150" align="right" :formatter="numFormatter"></vxe-column>
          <vxe-column field="newNum" title="变更后数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
          <vxe-column field="changedAmount" title="变更增减金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import XEUtils from 'xe-utils'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          contractId: '',
          code: '',
          name: '',
          changeDate: '',
          typeId: '',
          stakeNo: '',
          content: ''
        },
        activeTabName: 'tab1',
        dateFormat: 'yyyy-MM-dd',
        amountAccuracy: 0, // 金额小数位精度
        tableHeight: 400,

        mxDataListLoading: false,
        mxDataList: [],   //  新增清单明细
        mxDataList2: [],   //  清单数量变更明细
        mxTypeId: "",

        Type_ChangeNum: 2, // 变更类型：数量变更
        Type_AddBoq: 3, // 变更类型：新增清单
        Type_AddSubBoq: 4, // 变更类型：新增分项清单
      }
    },
    mounted () {
      this.$nextTick(() => {
        //  表格高度设置
        this.tableHeight = window.innerHeight - 280
      })
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          }
        })
      },
      // 获取信息
      getInfo () {
        this.mxDataListLoading = true
        this.$http.get('/mps/changeOrder/' + this.dataForm.id).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            })
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }

          let detailsData = res.data.details || []
          // 遍历清单明细
          for (let i = 0; i < detailsData.length; i++) {
            let item = detailsData[i] || {}
            let typeId = item['typeId'] + '' || '';
            if (typeId == this.Type_ChangeNum) {
              this.mxDataList2.push(item)
            } else if (typeId == this.Type_AddBoq || typeId == this.Type_AddSubBoq) {
              this.mxDataList.push(item)
            }
          }

          this.mxDataListLoading = false
        }).catch(() => {
          this.mxDataListLoading = false
        })
      },
      // 数量格式化
      numFormatter ({cellValue,row}) {
        if (XEUtils.isNumber(row.unitAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: row.unitAccuracy})
        } else {
          return cellValue
        }
      },
      // 金额格式化
      amountFormatter ({cellValue}) {
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: this.amountAccuracy})
        } else {
          return cellValue
        }
      }
    }
  }
</script>
