<template>
  <div>
    <el-dialog width="90%" v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="tab1">
          <el-form :model="dataForm" ref="dataForm" label-width="140px" size="small">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="申请编号" prop="code">
                  <el-input v-model="dataForm.code" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="变更项目名称" prop="name">
                  <el-input v-model="dataForm.name" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="申请内容" prop="content">
                  <el-input type="textarea" v-model="dataForm.content" readonly :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请日期" prop="requestDate">
                  <el-date-picker style="width: 100%" v-model="dataForm.requestDate" :format="dateFormat" value-format="yyyy-MM-dd" type="date" readonly>
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所在地点及部位" prop="place">
                  <el-input v-model="dataForm.place" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="原设计图号" prop="oldDrawingNo">
                  <el-input v-model="dataForm.oldDrawingNo" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="变更设计图号" prop="changeDrawingNo">
                  <el-input v-model="dataForm.changeDrawingNo" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="变更理由" prop="reason">
                  <el-input type="textarea" v-model="dataForm.reason" readonly :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请类型" prop="tentativeMeas">
                  <el-select v-model="dataForm.tentativeMeas" clearable placeholder="请选择申请类型" class="w-percent-100" disabled>
                    <el-option :value="0" label="变更申请"></el-option>
                    <el-option :value="1" label="暂定计量申请"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <el-link type="primary" @click="openIfcModel" :underline="false">[查看模型]</el-link>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="uploadTypeId.before">
                  <my-upload :refId="dataForm.id" :typeId="uploadTypeId.before" view></my-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="uploadTypeId.after">
                  <my-upload :refId="dataForm.id" :typeId="uploadTypeId.after" view></my-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="其他附件">
                  <my-upload :refId="dataForm.id" :typeId="uploadTypeId.other" view></my-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="新增清单" name="tab2">
          <vxe-table id="vxeTable1" keep-source ref="mxNewAddTableRef"
                :loading="mxDataListLoading"
                size="mini"
                :column-config="{resizable: true}"
                border
                show-overflow
                :row-config="{isCurrent: true,isHover: true}"
                :data="mxDataList"
                :max-height="tableHeight"
                :edit-config="{trigger: 'click', mode: 'row', showStatus: true}">
            <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
            <!-- <vxe-column field="boqParentCode" title="上级清单编码" width="120" fixed="left"></vxe-column>
            <vxe-column field="boqParentName" title="上级清单名称" width="120" fixed="left"></vxe-column> -->
            <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
            <vxe-column field="boqName" title="清单名称" width="180" fixed="left"></vxe-column>
            <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
            <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
            <vxe-column field="price" title="单价(元)" align="right" width="150"></vxe-column>
            <vxe-column field="changedNum" title="数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="changedAmount" title="金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
          </vxe-table>
        </el-tab-pane>
        <el-tab-pane label="数量变更" name="tab3">
          <vxe-table id="vxeTable2" keep-source ref="mxNumChangeTableRef"
                :loading="mxDataListLoading"
                size="mini"
                :column-config="{resizable: true}"
                border
                show-overflow
                :row-config="{isCurrent: true,isHover: true}"
                :data="mxDataList2"
                :max-height="tableHeight"
                :edit-config="{trigger: 'click', mode: 'row', showStatus: true}">
            <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
            <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
            <vxe-column field="boqName" title="清单名称" width="180" fixed="left"></vxe-column>
            <vxe-column field="subItemName" title="工程或费用名称" fixed="left"></vxe-column>
            <vxe-column field="unitName" title="单位" align="center" width="100"></vxe-column>
            <vxe-column field="price" title="单价(元)" align="right" width="150"></vxe-column>
            <vxe-column field="oldNum" title="变更前数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="changedNum" title="变更增减" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="newNum" title="变更后数量" align="right" width="150" :formatter="numFormatter"></vxe-column>
            <vxe-column field="changedAmount" title="变更金额(元)" width="150" align="right" :formatter="amountFormatter"></vxe-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button @click="visible = false">关闭</el-button>
      </template>
      <!-- ifc模型对比组件 -->
      <ifc-compare-viewer v-if="ifcCompareViewerVisible" ref="ifcCompareViewer"></ifc-compare-viewer>
    </el-dialog>
  </div>
</template>

<script>
  import XEUtils from 'xe-utils'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          contractId: '',
          code: '',
          name: '',
          requestDate: '',
          oldDrawingNo: '',
          changeDrawingNo: '',
          place: '',
          content: '',
          reason: '',
          processInstanceId: '',
          processStatus: '',
          tentativeMeas: '' // 是否为暂定计量申请
        },
        activeName: "tab1",
        dateFormat: 'yyyy-MM-dd',
        amountAccuracy: 0, // 金额小数位精度
        
        //  清单明细
        mxDataListLoading: false,
        mxDataList: [],   //  新增清单明细
        mxDataList2: [],   //  清单数量变更明细

        Type_ChangeNum: 2, // 变更类型：数量变更
        Type_AddBoq: 3, // 变更类型：新增清单
        Type_AddSubBoq: 4, // 变更类型：新增分项清单

        tableHeight: 400,
        ifcCompareViewerVisible: false, // ifc模型对比
        uploadTypeId: {
          before: '变更前模型',
          after: '变更后模型',
          other: '其他'
        },
      }
    },
    components: {},
    mounted () {
      this.$nextTick(() => {
        //  表格高度设置
        this.tableHeight = window.innerHeight - 280
      })
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          }
        })
      },
      // 获取信息
      getInfo () {
        this.mxDataListLoading = true
        this.$http.get('/mps/changeRequest/' + this.dataForm.id).then(({data: res}) => {
          this.mxDataListLoading = false
          if (res.code !== 0) {
            return this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          let detailsData = res.data.details || []
          // 遍历清单明细
          for (let i = 0; i < detailsData.length; i++) {
            let item = detailsData[i] || {}
            let typeId = item['typeId'] + '' || '';
            if (typeId == this.Type_ChangeNum) {
              this.mxDataList2.push(item)
            } else if (typeId == this.Type_AddBoq || typeId == this.Type_AddSubBoq) {
              this.mxDataList.push(item)
            }
          }
        }).catch(() => {
          this.mxDataListLoading = false
        })
      },

      // 查看ifc模型对比
      openIfcModel () {
        this.ifcCompareViewerVisible = true
        this.$nextTick(() => {
          this.$refs.ifcCompareViewer.init(this.dataForm.id)
        })
      },

      // 数量格式化
      numFormatter ({cellValue,row}) {
        if (XEUtils.isNumber(row.unitAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: row.unitAccuracy})
        } else {
          return cellValue
        }
      },

      // 金额格式化
      amountFormatter ({cellValue}) {
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(cellValue,{digits: this.amountAccuracy})
        } else {
          return cellValue
        }
      }
    }
  }
</script>