<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners" width="60%">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同简称" prop="shortName">
            <el-input v-model="dataForm.shortName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标段编号" prop="section">
            <el-input v-model="dataForm.section" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同类型" prop="typeId">
            <el-select v-model="dataForm.typeId" clearable class="w-percent-100" disabled>
              <el-option value="1" label="监理合同"></el-option>
              <el-option value="2" label="施工合同"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工单位" prop="deptId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.deptId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标段长度（km）" prop="length">
            <el-input v-model="dataForm.length" placeholder="标段长度（km）" readonly></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="12">
          <el-form-item label="总监单位" prop="chiefSupervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.chiefSupervisionId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.supervisionId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="咨询单位" prop="consultationId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.consultationId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="合同金额" prop="cost">
            <el-input v-model="dataForm.cost" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="开标日期" prop="openingDate">
            <el-date-picker type="date" placeholder="开标日期" value-format="yyyy-MM-dd" v-model="dataForm.openingDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="开工令日期" prop="startDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.startDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划完工日期" prop="endDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.endDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划工期" prop="planMonths">
            <el-input v-model="dataForm.planMonths" readonly>
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="招标清单预算" prop="biddingBudget">
            <el-input v-model="dataForm.biddingBudget" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标价" prop="biddingPrice">
            <el-input v-model="dataForm.biddingPrice" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="标底/投标限价" prop="biddingLimit">
            <el-input v-model="dataForm.biddingLimit" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标下浮率" prop="floatingRate">
            <el-input-number v-model="dataForm.floatingRate" controls-position="right" :min="0" :max="1" disabled></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="法人代表" prop="lawPerson">
            <el-input v-model="dataForm.lawPerson" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码" prop="usco">
            <el-input v-model="dataForm.usco" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户名称" prop="accountName">
            <el-input v-model="dataForm.accountName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="depositBank">
            <el-input v-model="dataForm.depositBank" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="主要内容" prop="mainTerms">
            <el-input type="textarea" v-model="dataForm.mainTerms" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" disabled controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        name: '',
        code: '',
        typeId: '',
        deptId: '',
        sspo: '',
        chiefSupervisionId: '',
        supervisionId: '',
        consultationId: '',
        lawPerson: '',
        usco: '',
        accountName: '',
        depositBank: '',
        bankAccount: '',
        cost: '',
        startDate: '',
        endDate: '',
        remark: '',
        authority: '',
        sortNo: '',
        hasSubcontractor: '',
        delFlag: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        length: '',
        biddingBudget: '',
        biddingLimit: '',
        biddingPrice: '',
        openingDate: '',
        floatingRate: '',
        mainTerms: ''
      },
      autosize: {
        minRows: 3,
        maxRows: 16
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/contract/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
  }
}
</script>
