<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="计量规则" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <vxe-table
        ref="table"
        :data="dataList"
        resizable
        border
        :loading="dataListLoading"
        show-overflow="tooltip"
        highlight-hover-row
        :row-config="{isCurrent: true, isHover: true, useKey: true, keyField: 'id'}"
        :edit-config="{trigger: 'click', mode: 'row'}"
    >
      <vxe-column width="40" align="center" :show-overflow="false">
        <template v-slot:header>
          <i class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 20px" @click="addRow()"></i>
        </template>
        <template v-slot="{ row }">
          <i class="el-icon-remove" style="color:red;cursor:pointer;" @click.stop="deleteRow(row.id)"></i>
        </template>
      </vxe-column>
      <vxe-column field="title" title="规则名称" :edit-render="{name: '$input', events: {change: titleChange}}"></vxe-column>
      <vxe-column class-name="cell-underline" field="state1Percentage" title="开工报告计量比例" width="160" align="right" :edit-render="{name: '$input', props: {type: 'number', min: 0, max: 1, controls: false}, events: {change: percentageChange}}"></vxe-column>
      <vxe-column class-name="cell-underline" field="state2Percentage" title="检验申请批复计量比例" width="160" align="right" :edit-render="{name: '$input', props: {type: 'number', min: 0, max: 1, controls: false}, events: {change: percentageChange}}"></vxe-column>
      <vxe-column class-name="cell-underline" field="state3Percentage" title="交工证书计量比例" width="160" align="right" :edit-render="{name: '$input', props: {type: 'number', min: 0, max: 1, controls: false}, events: {change: percentageChange}}"></vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        contractId: '',
      },
      dataList: [],
      dataListLoading: false
    }
  },
  computed: {
    dataRule () {
      return {
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    // 获取信息
    getInfo () {
      this.dataListLoading = true
      this.$http.get(
          '/mps/measRule/list',
          {
            params: {
              contractId: this.dataForm.contractId
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {}).finally(() => {
        this.dataListLoading = false
      })
    },
    addRow() {
      this.$prompt('请填写计量规则标题', '新增计量规则', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.$http.post(
            '/mps/measRule/',
            {
              contractId: this.dataForm.contractId,
              title: value
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            type: 'success',
            message: '请继续填写计量规则的参数！'
          })
          this.dataList.push({
            id: res.data,
            contractId: this.dataForm.contractId,
            title: value
          })
          // 自动开启编辑
          this.$nextTick(() => {
            this.$refs.table.setEditRow(this.$refs.table.getRowById(res.data))
          })
        })
      }).catch()
    },
    deleteRow(id) {
      this.$confirm(this.$t('prompt.info', { 'handle': this.$t('delete') }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
            `/mps/measRule`,
            {
              'data': [id]
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataList = this.dataList.filter(row => row.id != id)
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
          })
        }).catch()
      }).catch()
    },
    titleChange(data,event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.$http.put(
            '/mps/measRule/',
            {
              id: data.row.id,
              title: event.value
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        })
      }
    },
    percentageChange(data,event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.$http.put(
            '/mps/measRuleMx/updateByStateId',
            {
              mainId: data.row.id,
              stateId: data.columnIndex - 1,
              percentage: event.value
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        })
      }
    }
  }
}
</script>