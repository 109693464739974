<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm"
             label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="上级" prop="parentName">
            <el-input v-model="dataForm.parentName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="部位" prop="name">
            <el-input v-model="dataForm.name" placeholder="工程名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="形象单元" prop="scheduleUnitId">
            <scheduleunit-single-select v-model="dataForm.scheduleUnitId" :prjId="dataForm.prjId" @changeScheduleUnit="changeScheduleUnitHandle" placeholder="请选择形象单元"></scheduleunit-single-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="桩号" prop="stakeNo">
            <el-input v-model="dataForm.stakeNo" placeholder="桩号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图号" prop="drawingNo">
            <el-input v-model="dataForm.drawingNo" placeholder="图号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitId" label="单位">
            <unit-select v-model="dataForm.unitId" :contract-id="dataForm.contractId"></unit-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数量" prop="num">
            <el-input-number v-model="dataForm.num" :controls="false" placeholder="数量" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" autosize placeholder="备注"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      defaultParentName: '无',
      dataForm: {
        id: '',
        pid: '0', // 根节点默认为0
        prjId: '',
        name: '',
        code:'',
        sortNo: 0,
        num: '',
        scheduleUnitId: '',// 形象单元
        unitId: '',// 单位
        remark: '',
        parentName: this.defaultParentName,
      },
      row: {},
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/wbsBatch/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = this.defaultParentName
        }
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/wbsBatch/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid, this.row)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
    // 形象单元选择
    changeScheduleUnitHandle (row) {
      // 选择了形象单元后，以形象单元的单位为准
      this.dataForm.unitId = row.unitId
    },
  }
}
</script>