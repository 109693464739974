<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <el-form :model="dataForm" ref="dataForm" :rules="dataRule" :validate-on-rule-change="false" label-width="90px" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="分部分项" prop="wbsId">
            <wbs-select v-model="dataForm.wbsId" :contractId="dataForm.contractId" placeholder="选择分部分项" showPNames :leaf-only="true" @change="changeWbsHandle"></wbs-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报验工序" prop="workstageId">
            <el-select style="width: 100%" v-model="dataForm.workstageId">
              <el-option v-for="opt in workstageList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        
        <el-col :span="12">
          <el-form-item label="检查项" prop="item">
            <el-input v-model="dataForm.item" placeholder="请填写检查项"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="报验人/班组" prop="submitter">
            <el-input v-model="dataForm.submitter" placeholder="请填写报验人/班组"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="检查编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="请填写检查编号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="报验部位" prop="position">
            <el-input type="textarea" v-model="dataForm.position" placeholder="请填写报验部位" :autosize="{ minRows: 2}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报验内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" placeholder="请填写报验内容" :autosize="{ minRows: 2}"></el-input>
          </el-form-item>
        </el-col>
        
        <el-col :span="12">
          <el-form-item label="报验日期" prop="submitDate">
            <el-date-picker style="width: 100%" v-model="dataForm.submitDate" value-format="yyyy-MM-dd" type="date" placeholder="请填写报验日期"></el-date-picker>
          </el-form-item>
        </el-col>
        
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>工程实体照片</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" typeId="工程实体照片"></my-upload>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>质检人员照片</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" typeId="质检人员照片"></my-upload>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>实测实量照片</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" typeId="实测实量照片"></my-upload>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>监理照片</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" typeId="监理照片"></my-upload>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>其他报验资料</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" typeId="其他报验资料"></my-upload>
          </el-card>
        </el-col>

      </el-row>
    </el-form>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data () {
    return {
      dataForm: {
        id: '',
        contractId: '',
        wbsId: '',
        workstageId: '',
        code: '',
        item: '',
        submitter: '',
        position: '',
        contents: '',
        submitDate: '',
        processInstanceId: '',
        processStatus: '',
      },
      workstageList: [],
      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/mps/workstageInspection'
      }
    }
  },
  computed: {
    dataRule () {
      return {
        wbsId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        workstageId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        item: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        submitter: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        position: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        submitDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/workstageInspection/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...res.data
        }
        if (this.dataForm.wbsId) {
          this.loadWbsWorkstage(this.dataForm.wbsId)
        }
      }).catch()
    },
    // 切换wbs时查询对应的工序选项
    changeWbsHandle (wbs) {
      this.loadWbsWorkstage(wbs.id)
    },
    loadWbsWorkstage(wbsId) {
      this.workstageList = []
      this.$http.get('/mps/wbsWorkstage/refList/' + wbsId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.workstageList = res.data
      }).catch()
    },
  }
}
</script>
