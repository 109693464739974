<template>
  <el-dialog top="5vh" width="80%" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <template slot="title">
      <div>{{title}}</div>
      <div style="padding-top: 10px">
        <el-button type="text" size="small" @click="changeViewType" style="padding-left: 10px;">
          {{viewType == 1 ? '[切换为分项视图]' : '[切换为清单视图]'}}
        </el-button>
      </div>
    </template>
    <div class="my-tree-contianer">
      <el-tree
        v-if="treeShow"
        v-loading="treeLoading"
        node-key="id"
        :props="props"
        ref="tree"
        lazy
        show-checkbox
        :load="load"
        :default-checked-keys="defaultCheckedKeys"
        highlight-current
        class="my-el-tree"
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ data.name || data.subItemName }}</span>
          &nbsp;
          <span>{{ data.subItemCode }}</span>
        </span>
      </el-tree>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        subcontractorId: '',
        measId: ''
      },
      props: {
        label: 'name',
        isLeaf: 'isSubBoq',
        disabled: this.checkBoxDisabled
      },
      treeLoading: false,
      defaultCheckedKeys: [],
      title: '分项清单选择',
      viewType: 1, // 1:按清单选择，2：按部位选择
      treeShow: true,
    }
  },
  computed: {
    dataRule() {
      return {}
    }
  },
  methods: {
    // 如果展开清单非叶节点，则加载下级清单
    // 如果展开清单叶节点, 则加载该清单已分解的零号清单
    load(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      let params
      let url
      let isSubBoq
      if (node.data && node.data.isLeaf == 1) {
        isSubBoq = true
        url = '/mps/subBoq/list'
        params = {
          'contractId': this.dataForm.contractId,
          'subcontractorId': this.dataForm.subcontractorId,
        }
        if (this.viewType == 1) {
          params.boqId = pid
        } else {
          params.subItemId = pid
        }
      } else {
        isSubBoq = false
        if (this.viewType == 1) {
          url = '/mps/boq/children'
        } else {
          url = '/mps/subItem/children'
        }
        params = {
          'contractId': this.dataForm.contractId,
          'subcontractorId': this.dataForm.subcontractorId,
          'pid': pid,
        }
      }
      this.$http.get(
        url,
        {
          params: params
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        if (res.data) {
          for (let obj of res.data) {
            obj.isSubBoq = isSubBoq
          }
          if (node.data && node.data.isLeaf == 1 && this.viewType == 2) {
            for (let obj of res.data) {
              obj.name = obj.boqName
            }
          }
        }
        resolve(res.data);
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    init() {
      this.visible = true
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.tree.getCheckedNodes(true)
      this.visible = false
      this.$emit('selectItem',checkedNodes)
    }, 1000, {'leading': true, 'trailing': false}),

    checkBoxDisabled(data, node) {
      return !data.isSubBoq || this.defaultCheckedKeys.includes(data.id)
    },
    changeViewType() {
      if (this.viewType == 1) {
        this.viewType = 2
      } else {
        this.viewType = 1
      }
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
      })
    }
  }
}
</script>
<style scoped>
  .my-tree-contianer {
    width: 100%;
    height: calc(100vh - 300px);
    overflow: auto;
  }
</style>