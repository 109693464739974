<template>
  <el-dialog :visible.sync="visible" title="引用模板" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-container>
      <el-aside>
        <el-tree
            :data="leftTreeData"
            :props="leftTreeProps"
            highlight-current
            @node-click="handleNodeClick">
        </el-tree>
      </el-aside>
      <el-main>
        <el-tree
            v-if="rightTreeShow"
            row-key="id"
            lazy
            :load="loadRightTreeData"
            :props="rightTreeProps">
        </el-tree>
      </el-main>
    </el-container>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      leftTreeData: [],
      leftTreeProps: {
        children: 'children',
        label: 'name',
      },
      rightTreeShow: false,
      rightTreeProps: {
        label: 'name',
        isLeaf: 'isLeaf',
      },
      versionId: '',
      contractId: '',
      subcontractorId: '',
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getLeftTreeData()
      })
    },
    // 获取信息
    getLeftTreeData() {
      this.$http.get('/mps/wbs/wbsTemplateVersionList').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.leftTreeData = res.data
      }).catch(() => {
        // do nothing
      })
    },
    loadRightTreeData(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get(
          '/mps/wbs/wbsTemplateChildren',
          {
            params: {
              'versionId': this.versionId,
              'pid': pid,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data);
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    handleNodeClick(data) {
      if (data.id == this.versionId) {
        return
      } else {
        this.versionId = data.id
        this.rightTreeShow = false
        this.$nextTick(() => {
          this.rightTreeShow = true
        })
      }
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      if (!this.versionId) {
        this.$message.error('请选择一个模板进行引用')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在处理数据...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(
          '/mps/wbs/quoteTemplate',
          {
            contractId: this.contractId,
            subcontractorId: this.subcontractorId,
            versionId: this.versionId
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>