<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__contract">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="合同名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="合同编号" clearable ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <participant-dept-select :prj-id="dataForm.prjId"  v-model="dataForm.deptId" placeholder="乙方单位" clearable></participant-dept-select>
        </el-form-item> -->
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" @click="addOrUpdateHandle()" v-if="$hasPermission('mps:contract:save')"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()" v-if="$hasPermission('mps:contract:delete')"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-loading="dataListLoading"
          :data="dataList"
          border
          highlight-current-row
          @selection-change="dataListSelectionChangeHandle"
          @sort-change="dataListSortChangeHandle"
          row-key="id"
          :expand-row-keys="expands"
          style="width: 100%;"
          :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="合同/标段名称" min-width="240" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="合同编号" width="190" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="section" label="标段编号" width="190" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column type="expand" width="1">
          <template v-slot="{row}">
            <el-button v-if="$hasPermission('mps:subcontractor:update')" size="mini" type="primary" @click="addOrUpdateSubcontractorHandle(row)">+</el-button>
            <el-table highlight-current-row border v-loading="dataListLoading" :data="row.subcontractorList" class="subcontractor-table">
              <el-table-column prop="name" label="工区名称" header-align="center" align="left" show-overflow-tooltip>
                <template v-slot="{row: subrow}">
                  <el-button type="text" size="small" @click="viewSubcontractorHandle(subrow.id)">{{subrow.name}}</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="code" label="工区编号" min-width="150" header-align="center" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="sspo" label="里程桩号" min-width="150" header-align="center" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column :label="$t('handle')" header-align="center" align="center" width="160">
                <template v-slot="{row: subrow}">
                  <table-button role="auth" @click="subcontractorAuthorityHandle(subrow.id)" v-if="$hasPermission('mps:subcontractor:update')"></table-button>
                  <table-button role="edit" @click="addOrUpdateSubcontractorHandle(row, subrow.id)" v-if="$hasPermission('mps:subcontractor:update')"></table-button>
                  <table-button role="delete" @click="deleteSubcontractorHandle(row, subrow.id)" v-if="$hasPermission('mps:subcontractor:update')"></table-button>
                  <!-- <el-button type="text" size="small" @click="uploadDrawerHandle(subrow.id, [], !$hasPermission('mps:subcontractor:update'))">附件</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="工区设置" width="80" header-align="center" align="center" :formatter="hasSubcontractorFormatter">
          <template v-slot="{row}">
            <el-button v-if="row.hasSubcontractor == 1 || row.tempHasSubcontractor" type="text" size="small" @click="openSubcontractorHandle(row)">{{ row.subcontractorList ? row.subcontractorList.length : 0 }} 个</el-button>
            <el-button v-else type="text" size="small" @click="addSubcontractorHandle(row)">添加</el-button>
          </template>
        </el-table-column>
        <el-table-column label="计量报表设置" fixed="right" header-align="center" align="center" width="140">
          <template v-slot="{row}">
            <el-button el-button v-if="$hasPermission('mps:contractor:report:authority')" type="text" size="small"  @click="reporttemplateAuthorityDialogHandle(row.id)">绑定报表</el-button>
            <el-button v-if="$hasPermission('mps:contractor:report:design')" type="text" size="small"  @click="luckysheetDialogHandle(row)">设计报表</el-button>
          </template>
        </el-table-column>
        <el-table-column label="计量规则" fixed="right" align="center" width="80">
          <template v-slot="{row}">
            <el-button v-if="$hasPermission('mps:contract:measrule')" type="text" size="small"  @click="editMeasRule(row.id)">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="220">
          <template v-slot="{row}">
            <table-button role="auth" @click="contractAuthorityHandle(row.id)" v-if="$hasPermission('mps:contract:update')"></table-button>
            <table-button role="setting" tooltip="参数设置" @click="paramHandle(row.id)" v-if="$hasPermission('mps:contract:update')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:contract:update')"></table-button>
            <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:contract:delete')"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:contract:update'), row.name)"></table-button>
            <!-- <el-button v-if="$hasPermission('mps:contract:update')" type="text" size="small" @click="addOrUpdateHandle(row.id)">{{ $t('update') }}</el-button>
            <el-button type="text" size="small" @click="contractAuthorityHandle(row.id)">用户授权</el-button>
            <el-button v-if="$hasPermission('mps:contract:update')" type="text" size="small" @click="paramHandle(row.id)">参数</el-button>
            <el-button v-if="$hasPermission('mps:contract:delete')" type="text" size="small" @click="deleteHandle(row.id)">{{ $t('delete') }}</el-button>
            <el-button type="text" size="small" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:contract:update'), row.name)">附件</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 合同标段参数设置 -->
      <param-setting v-if="paramSettingVisible" ref="paramSetting" @refreshDataList="getDataList" @close="closeParamSettingHandle"></param-setting>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 工区弹窗, 新增 / 修改 -->
      <add-or-update-subcontractor v-if="addOrUpdateSubcontractorVisible" ref="addOrUpdateSubcontractor" @refreshDataList="getDataList" @close="closeDialogHandleSubcontractor"></add-or-update-subcontractor>
      <!-- 工区弹窗, 查看 -->
      <view-subcontractor v-if="viewSubcontractorVisible" ref="viewSubcontractor" @close="closeDialogHandleSubcontractor"></view-subcontractor>
      <!-- 弹窗, 设置/分配计量报表模板 -->
      <reporttemplate-authority v-if="reporttemplateAuthorityVisible" ref="reporttemplateAuthority" @close="closeReporttemplateAuthorityHandle"></reporttemplate-authority>
      <!-- 弹窗, 已选计量报表列表 -->
      <reporttemplate-bindinglist v-if="reporttemplateBindinglistVisible" ref="reporttemplateBindinglist" @close="closeReporttemplateBindinglistHandle"></reporttemplate-bindinglist>
      <!-- 合同标段分配人员权限 -->
      <user-multi-select v-if="contractAuthorityVisible" ref="contractAuthority" @close="closeAuthorityDialogHandle" @submit="submitContractAuthority"></user-multi-select>
      <!-- 工区分配人员权限 -->
      <user-multi-select v-if="subcontractorAuthorityVisible" ref="subcontractorAuthority" @close="closeAuthorityDialogHandle" @submit="submitSubcontractorAuthority"></user-multi-select>
      <!-- 弹窗，计量规则编辑 -->
      <meas-rule v-if="measRuleVisible" ref="measRule"></meas-rule>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './contract-add-or-update'
import ViewPage from './contract-view'
import ParamSetting from './contract-param-setting'
import AddOrUpdateSubcontractor from './subcontractor-add-or-update'
import ViewSubcontractor from './subcontractor-view'
import SubcontractorAuthority from './subcontractor-authority'
import ReporttemplateAuthority from './reporttemplate-authority'
import ReporttemplateBindinglist from './reporttemplate-bindinglist'
import MeasRule from './contract-meas-rule'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/contract/page',
        getDataListIsPage: true,
        deleteURL: '/mps/contract',
      },
      dataForm: {
        prjId: '',
        name: '',
        code: '',
        deptId: '',
      },
      paramSettingVisible: false,
      addOrUpdateSubcontractorVisible: false,
      viewSubcontractorVisible:false,
      contractAuthorityVisible: false,
      subcontractorAuthorityVisible: false,
      // 计量报表模板分配窗口
      reporttemplateAuthorityVisible: false,
      // 已绑定计量报表列表
      reporttemplateBindinglistVisible: false,
      measRuleVisible: false,
      expands: [],
      currentContract: '',
      currentSubcontractorId: '',
    }
  },
  components: {
    AddOrUpdate,
    ParamSetting,
    ViewPage,
    AddOrUpdateSubcontractor,
    ViewSubcontractor,
    SubcontractorAuthority,
    // 计量报表模板分配窗口
    ReporttemplateAuthority,
    // 已选计量报表列表
    ReporttemplateBindinglist,
    // 计量规则编辑弹窗
    MeasRule
  },
  methods: {
    hasSubcontractorFormatter(row, column, cellValue, index) {
      if (cellValue == 0) {
        return '否'
      } else if (cellValue == 1) {
        return '是'
      } else {
        return ''
      }
    },
    // 打开工区table，如果已经打开，则关闭
    openSubcontractorHandle(row) {
      if (this.expands.indexOf(row.id) < 0) {
        this.expands = []
        this.expands.push(row.id)
      } else {
        this.expands = []
      }
    },
    // 弹窗确认是否添加工区，如果已经打开工区table，则关闭
    addSubcontractorHandle(row) {
      if (this.expands.indexOf(row.id) < 0) {
        this.$confirm(`确定要为该标段添加工区吗?`).then(() => {
          row.tempHasSubcontractor = true
          this.expands = []
          this.expands.push(row.id)
        }).catch(() => {
          // do nothing
        })
      } else {
        this.expands = []
      }
    },
    // 项目切换事件
    prjChangeHandle () {
      this.getDataList()
    },
    paramHandle (id) {
      this.paramSettingVisible = true
      this.$nextTick(() => {
        this.$refs.paramSetting.dataForm.id = id
        this.$refs.paramSetting.init()
      })
    },
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.init()
      })
    },
    viewSubcontractorHandle (id) {
      this.viewSubcontractorVisible = true
      this.$nextTick(() => {
        this.$refs.viewSubcontractor.prjId = this.dataForm.prjId
        this.$refs.viewSubcontractor.dataForm.id = id
        this.$refs.viewSubcontractor.init()
      })
    },
    addOrUpdateSubcontractorHandle (row, id) {
      this.addOrUpdateSubcontractorVisible = true
      this.$nextTick(() => {
        if (!id) {
          this.$refs.addOrUpdateSubcontractor.dataForm.contractId = row.id
          this.$refs.addOrUpdateSubcontractor.dataForm.contractName = row.name
        } else {
          this.$refs.addOrUpdateSubcontractor.dataForm.id = id
        }
        this.$refs.addOrUpdateSubcontractor.prjId = this.dataForm.prjId
        this.$refs.addOrUpdateSubcontractor.row = row
        this.$refs.addOrUpdateSubcontractor.init()
      })
    },
    refreshRow(row) {
      this.dataListLoading = true
      this.$http.get(
        '/mps/subcontractor/list',
        {
          params: {
            contract: row.id
          }
        }
      ).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          row.subcontractorList = []
          return this.$message.error(res.msg)
        }
        row.subcontractorList = res.data
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    // 删除
    deleteSubcontractorHandle (row, id) {
      this.$confirm(this.$t('prompt.info', { 'handle': this.$t('delete') }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
          `/mps/subcontractor`,
          {
            'data': [id]
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              // this.refreshRow(row)
              this.query()
            }
          })
        }).catch(() => { })
      }).catch(() => { })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandleSubcontractor () {
      this.addOrUpdateSubcontractorVisible = false
      this.viewSubcontractorVisible = false
    },
    
    // 打开计量报表模板分配窗口
    reporttemplateAuthorityDialogHandle (id) {
      this.reporttemplateAuthorityVisible = true
      this.$nextTick(() => {
        this.$refs.reporttemplateAuthority.refId = id
        // 1：中间计量报表，2：监理计量报表，3：概预算报表
        this.$refs.reporttemplateAuthority.typeId = '1,2'
        this.$refs.reporttemplateAuthority.init()
      })
    },
    // 关闭计量报表模板分配窗口
    closeReporttemplateAuthorityHandle () {
      this.reporttemplateAuthorityVisible = false
    },
    // 打开计量报表的设计窗口
    luckysheetDialogHandle (row) {
      this.reporttemplateBindinglistVisible = true
      this.$nextTick(() => {
        this.$refs.reporttemplateBindinglist.dataForm.refId = row.id
        this.$refs.reporttemplateBindinglist.dataForm.title = row.shortName + ' 的计量报表'
        this.$refs.reporttemplateBindinglist.init()
      })
    },
    // 关闭计量报表的设计窗口
    closeReporttemplateBindinglistHandle () {
      this.reporttemplateBindinglistVisible = false
    },

    // 关闭合同标段参数设置窗口
    closeParamSettingHandle () {
      this.paramSettingVisible = false
    },

    // 合同标段人员授权
    contractAuthorityHandle(id) {
      this.currentContractId = id
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/contract/userIds/${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.contractAuthorityVisible = true
        this.$nextTick(() => {
          this.$refs.contractAuthority.selectedList = res.data
          this.$refs.contractAuthority.init()
        })
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    },
    // 保存合同标段人员授权数据
    submitContractAuthority(selectedList) {
      this.$http['put']('/mps/contract/authority', {
        id: this.currentContractId,
        authority: selectedList ? selectedList.join(",") : ''
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000,
        })
      }).catch()
    },

    // 工区人员授权
    subcontractorAuthorityHandle(id) {
      this.currentSubcontractorId = id
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/subcontractor/userIds/${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.subcontractorAuthorityVisible = true
        this.$nextTick(() => {
          this.$refs.subcontractorAuthority.selectedList = res.data
          this.$refs.subcontractorAuthority.init()
        })
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    },
    // 保存工区人员授权数据
    submitSubcontractorAuthority(selectedList) {
      this.$http['put']('/mps/subcontractor/authority', {
        id: this.currentSubcontractorId,
        authority: selectedList ? selectedList.join(",") : ''
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000,
        })
      }).catch()
    },

    closeAuthorityDialogHandle () {
      this.contractAuthorityVisible= false
      this.subcontractorAuthorityVisible= false
    },
    // 编辑计量规则
    editMeasRule(contractId) {
      this.measRuleVisible = true
      this.$nextTick(() => {
        this.$refs.measRule.dataForm.contractId = contractId
        this.$refs.measRule.init()
      })
    }
  }
}
</script>
<style scoped>
.el-table__expanded-cell {
  padding: 5px 0px 20px 20px !important;
}
.subcontractor-table {
  border-bottom: 0 !important;
}
::v-deep .el-table__expand-icon {
  visibility: hidden;
}
</style>
