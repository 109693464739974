<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :leftWidth="300">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
          <el-form-item>
            <vxe-switch v-if="$hasPermission('mps:wbsworkstagebind:bind')&&dataForm.contractId" v-model="modeSwitch" open-label="关联模式" close-label="查看模式" @change="modeSwitchChange"></vxe-switch>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              v-if="treeShow"
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              :load="loadWbsNode"
              lazy
              node-key="id"
              :props="treeProps"
              ref="wbsTree"
              :show-checkbox="modeSwitch"
              :expand-on-click-node="false"
              @node-click="treeNodeClick"
              :highlight-current="true">
              <span slot-scope="{ node, data }">
                <template v-if="!modeSwitch">
                  <template v-if="!data.isLeaf && data.wbsWorkstageCount>=data.lastChildrenCount"><i class="my-circle"></i></template>
                  <template v-else-if="!data.isLeaf && data.wbsWorkstageCount>0"><i class="my-semi-circle"></i></template>
                  <template v-else-if="data.isLeaf && data.wbsWorkstageCount!=0"><i class="my-circle"></i></template>
                  <template v-else><i class="my-empty-circle"></i></template>
                </template>
                <span>{{ node.label }}</span>
              </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__wbsworkstagebind">
          <el-form :inline="true">
            <el-form-item v-if="modeSwitch">
              <el-button-group>
                <toolbar-button name="选择工序" @click="openWorkstageMultiSelect" v-if="$hasPermission('mps:wbsworkstagebind:bind')" key="1"></toolbar-button>
                <toolbar-button name="提交数据" @click="batchSaveWbsWorkstage" v-if="$hasPermission('mps:wbsworkstagebind:bind')" key="2"></toolbar-button>
              </el-button-group>
            </el-form-item>
            <el-form-item>
              <el-alert title="为分项工程设置工序" type="info" show-icon :closable="false" style="height: 28px; margin-top: 3px; margin-left: 5px;"></el-alert>
            </el-form-item>
          </el-form>
          <el-table
              ref="wbsTable"
              v-loading="tableLoading"
              :data="tableData"
              row-key="id"
              border
              tooltip-effect="light"
              highlight-current-row
              style="width: 100%;"
              :max-height="tableHeight"
              :row-style="{height: '0px'}"
              :cell-style="{padding: '2px 0'}">
            <el-table-column prop="name" label="工序名称" :width="!modeSwitch?220:300" show-overflow-tooltip></el-table-column>
            <el-table-column prop="wbsName" v-if="!modeSwitch" label="分部分项工程" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.wbsPnames ? scope.row.wbsPnames + '/' + scope.row.wbsName : scope.row.wbsName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="工序编号" :width="!modeSwitch?220:300" show-overflow-tooltip></el-table-column>
            <!-- 关联模式下不应该显示这一列 -->
            <el-table-column v-if="!modeSwitch" prop="inspectionProcessStatus" label="工序报验状态" header-align="center" align="center" width="110">
              <template v-slot="{row}">
                <span v-if="row.inspectionProcessStatus === 0" style="color: rgb(76,79,67)">已发起</span>
                <span v-else-if="row.inspectionProcessStatus === 1" style="color: #c7e614">审批中</span>
                <span v-else-if="row.inspectionProcessStatus === 2" style="color: #22b23a">已通过</span>
                <span v-else-if="row.inspectionProcessStatus === 3" style="color: #b22222">未通过</span>
                <span v-else-if="row.inspectionProcessStatus === 4" style="color: #b2228e">驳回中</span>
                <span v-else-if="row.inspectionProcessStatus === 5" style="color: #b22222">退回中</span>
                <span v-else-if="row.inspectionProcessStatus === 7" style="color: #b22222">流程被撤销</span>
                <span v-else-if="row.inspectionProcessStatus === 9" style="color: #b22222">流程被删除</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="110">
              <template slot-scope="scope">
                <table-button role="delete" v-if="$hasPermission('mps:wbsworkstagebind:bind') && (modeSwitch ||(!modeSwitch && currentSelectedWbs && currentSelectedWbs.isLeaf))" @click="deleteOneWbsWorkstage(scope.row.id,scope.row)"></table-button>
                <!-- 挂接模式下不应该显示附件 -->
                <table-button role="files" v-if="!modeSwitch" @click="uploadDrawerHandle(scope.row.id, ['工程实体照片','质检人员照片','实测实量照片','监理照片','其他报验资料'], true, '工序报验影像资料', null, true)"></table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </my-container>
    <!--工序选择-->
    <wbsworkstage-multi-select v-if="workstageMultiSelectVisible" ref="workstageMultiSelect" @selectItems="workstageMultiSelectSubmitHandle" @close="closeWorkstageMultiSelectDialog"></wbsworkstage-multi-select>
  </el-card>
</template>

<script>
import WbsworkstageMultiSelect from './wbsworkstage-multi-select'
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        showEmptyDataWarningMessage: true,
        deleteURL: `mps/wbsWorkstageBind`,
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
      },
      treeShow: false,
      treeLoading: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        disabled: this.wbsNodeDisabled,
        isLeaf: 'isLeaf'
      },
      tableLoading: false,
      tableData: [],
      tableHeight: 600,
      currentSelectedWbs: null, // 当前选中的分部分项工程
      selectMsg: '请先勾选分项工程',
      workstageMultiSelectVisible: false, // 工序库选择
      modeSwitch: false, // 模式切换：false:查看模式、true:关联模式
      msgInstance: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  components: {
    WbsworkstageMultiSelect
  },
  methods: {
    // 项目、标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId

      this.currentSelectedWbs = null

      this.getWbsTreeData()
      this.getWorkstageDataList()
    },

    // 右侧 加载该分部分项工程的工序列表以及报验状态
    getWorkstageDataList() {
      this.tableData = []
      if (this.currentSelectedWbs) {
        this.tableLoading = true
        this.$nextTick(() => {
          this.$http.get('/mps/wbsWorkstageBind/list',
              {
                params: {
                  wbsId: this.currentSelectedWbs.id,
                  ...this.dataForm
                }
              }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.tableData = res.data
            if (this.tableData == null || this.tableData.length == 0) {
              if (this.msgInstance) {
                this.msgInstance.close()
              }
              this.msgInstance = this.$message.warning('未查询到工序信息')
            }
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            this.tableLoading = false
          })
        })
      }
    },

    // 左侧 wbs树
    getWbsTreeData() {
      this.treeLoading = true
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
        this.$http.get('/mps/wbsWorkstageBind/wbs/children',
            {
              params: {
                pid: "0",
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
          this.treeLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },
    loadWbsNode(node, resolve) {
      if (node.level > 0) {
        let pid = node.data.id
        this.$http.get('/mps/wbsWorkstageBind/wbs/children',
            {
              params: {
                'pid': pid,
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },

    // wbs节点点击事件
    treeNodeClick(data, node, ele) {
      this.currentSelectedWbs = data
      if (this.msgInstance) {// 避免页面中同时打开多个message
        this.msgInstance.close()
      }
      if (!this.modeSwitch) {
        this.getWorkstageDataList()
      } else {
        this.msgInstance = this.$message({
          message: '当前模式仅用于关联操作，如需查看该分项已关联的工序，请切换至查看模式',
          type: 'info',
          duration: 8000
        })
      }
    },

    // wbs节点是否可选择
    wbsNodeDisabled(data, node) {
      if (data.isLeaf) {
        return false
      }
      return true  // 不可选择
    },

    // 获取勾选的wbsId集合
    getSelectWbsIds() {
      let ids = []
      let checkedWbsNodes = this.$refs.wbsTree.getCheckedNodes(true)
      if (checkedWbsNodes && checkedWbsNodes.length > 0) {
        for (let i = 0; i < checkedWbsNodes.length; i++) {
          ids.push(checkedWbsNodes[i].id)
        }
      }
      return ids
    },

    // 模式切换
    modeSwitchChange() {
      this.clearData()
    },

    // 打开工序选择弹窗
    openWorkstageMultiSelect() {
      let isOk = false;
      if (this.$refs.wbsTree) {
        let checkedWbsNodes = this.$refs.wbsTree.getCheckedNodes(true)
        if (checkedWbsNodes && checkedWbsNodes.length > 0) {
          isOk = true
        }
      }
      if (isOk) {
        this.workstageMultiSelectVisible = true
        this.$nextTick(() => {
          this.$refs.workstageMultiSelect.init()
        })
      } else {
        if (this.msgInstance) {
          this.msgInstance.close()
        }
        this.msgInstance = this.$message.warning(this.selectMsg)
      }
    },

    // 工序选择确定
    workstageMultiSelectSubmitHandle(workstageItems) {
      this.tableData = []
      if (workstageItems && workstageItems.length > 0) {
        this.tableData = workstageItems
      }
    },

    // 关闭工序选择弹窗
    closeWorkstageMultiSelectDialog() {
      this.workstageMultiSelectVisible = false
    },

    // 删除工序与wbs关系记录
    deleteOneWbsWorkstage(id) {
      // 关联模式下，直接删除工序列表中的数据
      if (this.modeSwitch) {
        this.tableData = this.tableData.filter(item => {
          if (item.id != id) {
            return true
          } else {
            return false
          }
        })
      } else {
        this.deleteWbsWorkstage(id)
      }
    },
    deleteWbsWorkstage(id) {
      this.deleteHandle(id, {deleteSuccessCallbackArgs: {id}, autoQuery: false})
    },
    deleteSuccessCallback({id}) {
      // 刷新工序数据
      this.tableData = this.tableData.filter(item => item.id !== id)
      if (this.currentSelectedWbs) {
        // 当前点击的是wbs的最末级节点，则根据工序列表中是否有数据来更新wbs节点上的圆球状态。
        if (this.currentSelectedWbs.isLeaf) {
          if (this.tableData.length <= 0) {
            let wbsNode = this.$refs.wbsTree.getNode(this.currentSelectedWbs.id)
            if (wbsNode) {
              wbsNode.data.wbsWorkstageCount = 0
              this.cascadeUpdateWbsNodes(wbsNode.data.pid, -1)
            }
          }
        }
      }
    },
    clearData() {
      // 清空wbs勾选项
      this.$refs.wbsTree.setCheckedKeys([])
      // 取消wbs当前选中项
      this.$refs.wbsTree.setCurrentKey(null)
      // 清空工序列表
      this.tableData = []
    },

    // 批量保存wbs与工序的关系
    batchSaveWbsWorkstage: debounce(function () {
      // 检查 wbs是否勾选，工序是否已选择
      if (this.tableData.length <= 0) {
        if (this.msgInstance) {
          this.msgInstance.close()
        }
        this.msgInstance = this.$message.warning('请先选择工序')
        return this.msgInstance
      }
      let wbsCheckNodes = this.$refs.wbsTree.getCheckedNodes(true)
      if (wbsCheckNodes && wbsCheckNodes.length <= 0) {
        return this.$message.warning(this.selectMsg)
      }
      const loading = this.$loading({
        text: '正在执行，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 获取工序ID集合
      let workstageDtoList = []
      for (let i = 0; i < this.tableData.length; i++) {
        workstageDtoList.push(this.tableData[i])
      }
      // 获取勾选的wbsID集合
      let selectWbsIds = this.getSelectWbsIds()
      let data = {
        wbsIds: selectWbsIds,
        workstageList: workstageDtoList
      }
      // 保存wbs与工序的关系
      this.$http.post('/mps/wbsWorkstageBind/batchSave', data).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$alert(res.msg, '', {
            confirmButtonText: '关闭',
            type: "error"
          })
        }

        this.$alert('已成功保存，请继续为其他分项选择工序！', '', {
          confirmButtonText: '关闭',
          type: "success"
        })

        // 递归计算当前勾选节点的父节点已关联工序的子孙节点数量
        const wbsPidmap = new Map();
        for (let i = 0; i < wbsCheckNodes.length; i++) {
          let wbsCheckNode = wbsCheckNodes[i]
          // wbs最末级子节点已经挂接了工序，则不更新上级节点已关联工序的子孙节点数量字段
          if (wbsCheckNode.wbsWorkstageCount != 1) {
            wbsCheckNode.wbsWorkstageCount = 1
            let pid = wbsCheckNodes[i].pid
            if (wbsPidmap.has(pid)) {
              let num = wbsPidmap.get(pid);
              wbsPidmap.set(pid, num + 1)
            } else {
              wbsPidmap.set(pid, 1)
            }
          }
        }
        wbsPidmap.forEach((item, key, mapObj) => {
          this.cascadeUpdateWbsNodes(key, item)
        });
        // 仅清空wbs勾选项即可，无需清空工序
        this.$refs.wbsTree.setCheckedKeys([])
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),

    // 级联更新wbs树节点的关联的工序数量
    cascadeUpdateWbsNodes(id, num) {
      let wbsNode = this.$refs.wbsTree.getNode(id)
      if (wbsNode) {
        wbsNode.data.wbsWorkstageCount = num + wbsNode.data.wbsWorkstageCount
        this.cascadeUpdateWbsNodes(wbsNode.data.pid, num)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}

.my-circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  background-color: #00b95e;
  border-radius: 50%;
}

.my-semi-circle {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #00b95e;
  border-radius: 50%;
}

.my-semi-circle:before {
  content: "";
  position: absolute;
  background: #00b95e;
  top: -1px;
  left: -1px;
  width: 5px;
  height: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #00b95e;
}

.my-empty-circle {
  display: inline-block;
  border: 1px solid #c1c1c1;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
