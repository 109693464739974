<template>
  <el-dialog width="80%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="期次" prop="periodId">
                <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId"
                               default-select :hasMeas="false" disabled></period-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标题" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item prop="sortNo" label="排序">
                <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序" disabled></el-input-number>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>

      </el-tab-pane>
      <el-tab-pane label="材料明细" name="tab2">
        <vxe-table id="mxTable"
                   keep-source
                   ref="mxTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="dataForm.details"
                   header-align="center"
                   :max-height="tableHeight"
                   :expand-config="{accordion: true,lazy: true,loadMethod: loadMxMxData,iconOpen: 'vxe-icon-square-minus', iconClose: 'vxe-icon-square-plus'}">
          <vxe-column field="materialName" title="材料名称" width="300" header-align="center"></vxe-column>
          <vxe-column field="spec" title="规格" min-width="110" align="right" header-align="center"></vxe-column>
          <vxe-column field="num1" type="expand" title="本期应耗数量" min-width="160" align="right" header-align="center">
            <template #default="scope">
              <span>{{scope.row.num1}}</span>
            </template>
            <template #content="scope">
              <div class="expand-wrapper">
                <vxe-table
                  border
                  :max-height=500
                  size="mini"
                  show-overflow
                  :row-config="{isCurrent: true,isHover: true}"
                  :column-config="{resizable: true}"
                  :data="scope.row.mxList">
                  <vxe-column field="subItemCode" title="部位编号" width="200" header-align="center" align="center"></vxe-column>
                  <vxe-column field="subItemName" title="部位名称" header-align="center" align="left"></vxe-column>
                  <vxe-column field="boqCode" title="清单编号" width="200"  header-align="center" align="center"></vxe-column>
                  <vxe-column field="boqName" title="清单名称" header-align="center" align="left"></vxe-column>
                  <vxe-column field="num" title="消耗数量" width="120" header-align="center" align="right"></vxe-column>
                  <vxe-column field="sortNo" title="排序号" width="80" align="center" header-align="center"></vxe-column>
                </vxe-table>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="price1" title="基准价" min-width="110" align="right" header-align="center"></vxe-column>
          <vxe-colgroup title="基本信息">
            <vxe-column field="price2" title="本期信息价" min-width="110" align="right" header-align="center"></vxe-column>
            <vxe-column field="dates" title="时间" min-width="110" align="right" header-align="center"></vxe-column>
            <vxe-column field="jiacha" title="价差" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="本期材料调差">
            <vxe-column field="riskRange" title="风险幅度" min-width="110" align="right" header-align="center"></vxe-column>
            <vxe-column field="youxiaojiacha" title="有效价差" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
            <vxe-column field="tiaochajine" title="调差金额" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="本期实耗分析">
            <vxe-column field="num2" title="本期实耗数量" min-width="110" align="right" header-align="center"></vxe-column>
            <vxe-column field="shuliangcha" title="数量差" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
            <vxe-column field="shuliangchajine" title="数量差金额" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
            <vxe-column field="shihaojiachajine" title="实耗价差金额" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
            <vxe-column field="jieyujine" title="节余金额" min-width="110" align="right" header-align="center" :formatter="formatterNum"></vxe-column>
          </vxe-colgroup>
          <vxe-column field="sortNo" title="排序号" width="80" align="center" header-align="center"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from "xe-utils";

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        periodId: '',
        name: '',
        processInstanceId: '',
        processStatus: '',
        sortNo: '',
        details:[]
      },
      activeTabName: 'tab1',
      mxDataListLoading:false,
      tableHeight: 400,
    }
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialAdjust/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        for (let row of res.data.details) {
          row.shuliangcha = row.num1 - row.num2
          row.shuliangchajine = row.price1 * (row.num1 - row.num2)
          row.jiacha = row.price2 - row.price1
          row.shihaojiachajine = (row.price2 - row.price1) * row.num2
          row.jieyujine = (row.price1 * (row.num1 - row.num2)) + (row.price2 * row.num2)
          row.youxiaojiacha = (row.price2 - row.price1) - row.price1 * row.riskRange / 100
          row.tiaochajine = ((row.price2 - row.price1) - row.price1 * row.riskRange / 100) * row.num1
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },
    formatterNum({ cellValue }) {
      return XEUtils.commafy(Number(cellValue), { digits: 3 });
    },
    loadMxMxData ({row}) {
      var p = new Promise((resolve,reject) => {
        this.$http.get('/mps/materialAdjustMxMx/list',
          {
            params: {
              'mainId': row.id,
            }
          }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          row.mxList = res.data
          resolve()
        }).catch(() => {
          reject()
        })
      })
      return p
    },
  }
}
</script>
<style scoped>
  .expand-wrapper {
    padding: 20px;
    width: 60%;
  }
</style>