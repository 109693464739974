<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__measplan">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <el-form-item>
              <contract-cascader include-sub @change="contractChange"></contract-cascader>
            </el-form-item>
          </el-button-group>
        </el-form-item>
      </el-form>
      <vxe-table
          border
          resizable
          auto-resize
          show-overflow
          ref="measPlanTableRef"
          :loading="dataListLoading"
          :height="tableHeight"
          :edit-config="editConfig"
          :cell-class-name="cellClassName"
          :footer-method="footerMethod"
          show-footer
          footer-cell-class-name="mytable-footer-summary"
          :data="dataList">
        <vxe-column type="seq" width="50" title="序号" align="center"></vxe-column>
        <vxe-column field="y" align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}">
          <template #header>
            <span v-if="cellCanEdit()" style="color: red;">*</span>
            <span>年份</span>
          </template>
        </vxe-column>
        <vxe-colgroup title="计划完成比例（%）" header-align="center">
          <vxe-column field="m1" title="1月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m2" title="2月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m3" title="3月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m4" title="4月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m5" title="5月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m6" title="6月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m7" title="7月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m8" title="8月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m9" title="9月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m10" title="10月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m11" title="11月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
          <vxe-column field="m12" title="12月" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: cellEdit}}" :formatter="formatterPercentage"></vxe-column>
        </vxe-colgroup>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <table-button role="delete" v-if="$hasPermission('mps:measplan:delete')&&row.id" @click="deleteHandle(row.id)"></table-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<!--计量计划-->
<script>
import XEUtils from 'xe-utils'

export default {
  data() {
    return {
      dataForm: {
        contractId: '',
        subcontractorId: ''
      },
      dataListLoading: false,
      dataList: [],
      editConfig: {
        enabled: false, // 默认计量计划不可以编辑
        trigger: 'click',
        mode: 'cell',
        beforeEditMethod: this.beforeEditMethod
      },
      commomEditRender: {autofocus: '.vxe-input--inner'},
      tableHeight: 500
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      // 设置计量计划是否可编辑
      this.editConfig.enabled = this.cellCanEdit()
      this.getDataList()
    },
    getDataList() {
      this.dataListLoading = true
      this.$http.get(
          '/mps/measPlan/list',
          {
            params: {
              ...this.dataForm
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$messge.error(res.msg)
        }
        this.dataList = res.data
        // 默认补充空白行数
        let addRowCount = 10 - this.dataList.length
        if (addRowCount > 0) {
          for (let i = 0; i < addRowCount; i++) {
            let data = {id: '', ...this.dataForm}
            this.dataList.push(data);
          }
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    beforeEditMethod({row, column}) {
      // 若点击的单元格所在列不是“年份”，且所在行的id为空，则不允许编辑，并且提示用户
      if (column.field !== 'y' && (row.id == '' || row.id == null)) {
        this.$message.warning('请先填写年份！')
        return false
      }
      return true
    },
    cellEdit({column, row}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.$http[!row.id ? 'post' : 'put'](
            '/mps/measPlan/',
            {
              ...this.dataForm,
              id: row.id,
              [column.field]: event.value
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1000,
            onClose: () => {
              if (!row.id) {
                row.id = res.data
              }
            }
          })
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 单元格是否可编辑
    cellCanEdit() {
      return this.$hasPermission('mps:measplan:edit') && this.dataForm.contractId
    },
    cellClassName({row, column}) {
      if (this.cellCanEdit(row, column)) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
            `/mps/measPlan`,
            {
              'data': [id]
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).catch(() => {
          // do nothing
        })
      }).catch(() => {
        // do nothing
      })
    },
    // 合计
    footerMethod({columns, data}) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '合计'
          }
          if (['y'].includes(column.field)) {
            return this.getFooterTotal(data, 'y')
          }
          return null
        }),
      ]
      return footerData
    },
    // 计算底部合计行中的所有年份的计划完成比例之和
    getFooterTotal(data, columnName) {
      if (columnName == 'y') {
        let total = 0
        // 循环年份
        for (let i = 0; i < data.length; i++) {
          // 每年12月份的计划完成比例之和
          for (let k = 1; k <= 12; k++) {
            total = XEUtils.add(total, data[i]['m' + k])
          }
        }
        return this.formatterPercentage({cellValue: total})
      }
    },
    // 格式化完成百分比
    formatterPercentage({cellValue}) {
      return cellValue || cellValue === 0 ? cellValue + '%' : ''
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .mytable-footer-summary {
  background-color: #D4E6F1;
  font-weight: bold;
}
</style>