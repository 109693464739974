<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__preprojectworklib">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <pre-project-select width="380px" v-model="dataForm.prjId" placeholder="前期项目选择" :defaultSelect="true" @change="preprojectSelectHandle"></pre-project-select>
        </el-form-item>
      </el-form>
      <vxe-table
          border
          resizable
          :max-height="tableHeight"
          ref="tree"
          show-overflow
          :row-config="treeRowConfig"
          :loading="treeLoading"
          :tree-config="treeConfig"
          :cell-class-name="cellClassName"
          :edit-config="treeEditConfig"
          :data="treeData"
          @edit-closed="editClosedEvent">
        <vxe-column field="workLibName" title="工作项" width="300" tree-node></vxe-column>
        <vxe-column field="planStart" title="计划开始日期" width="140" align="center" :edit-render="{name: '$input', props: {type: 'date', placeholder: '请选择', transfer: true}, events: {change: dateChange}}"></vxe-column>
        <vxe-column field="planEnd" title="计划完成日期" width="140" align="center" :edit-render="{name: '$input', props: {type: 'date', placeholder: '请选择', transfer: true}, events: {change: dateChange}}"></vxe-column>
        <vxe-column field="actualStart" title="实际开始日期" width="140" align="center" :edit-render="{name: '$input', props: {type: 'date', placeholder: '请选择', transfer: true}, events: {change: dateChange}}"></vxe-column>
        <vxe-column field="actualEnd" title="完成比例日期" width="140" align="center" :edit-render="{name: '$input', props: {type: 'date', placeholder: '请选择', transfer: true}, events: {change: dateChange}}"></vxe-column>
        <vxe-column field="percentage" title="完成百分比(%)" width="140" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false, placeholder: '请选择'}, events: {change: numChange}}"></vxe-column>
        <vxe-column title="操作" width="120" align="center" fixed="right">
          <template #default="{ row }">
            <template v-if="cellCanEdit(row)">
              <table-button role="delete" v-if="$hasPermission('opm:preprojectworklib:update')" @click="clearHandle(row)"></table-button>
              <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:preprojectworklib:update'))"></table-button>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/preProjectWorkLib/page',
        getDataListIsPage: true,
        deleteURL: '/opm/preProjectWorkLib',
      },
      dataForm: {
        prjId: ''
      },
      treeConfig: {
        transform: true,
        expandAll: true,
        rowField: 'workLibId',
        parentField: 'workLibPid'
      },
      treeRowConfig: {
        isCurrent: true,
        isHover: true
      },
      treeEditConfig: {
        enabled: this.$hasPermission('opm:preprojectworklib:update'),
        trigger: 'click',
        mode: 'cell',
        beforeEditMethod: this.beforeEditMethod
      },
      treeLoading: false,
      treeData: [],
      tableHeight: 500
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  components: {
  },
  methods: {
    preprojectSelectHandle(data) {
      this.getTreeData()
    },
    // 获取前期工作进度记录树列表
    getTreeData() {
      this.treeLoading = true
      this.$http.get(
          `/opm/preProjectWorkLib/treeList/${this.dataForm.prjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        }
        this.treeData = res.data
        // 展开所有节点
        this.$nextTick(() => this.$refs.tree.setAllTreeExpand(true))
      }).catch(() => {
        this.treeData = []
        this.$message.error('加载出错')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    // 单元格是否允许编辑
    cellCanEdit(row) {
      // 计划开始日期、计划完成日期,开始日期，完成日期只允许叶子节点编辑
      if (row.id) { // 通过id是否为空判断,因为父节点的id值为空
        return true
      }
      return false
    },

    beforeEditMethod({row}) {
      return this.cellCanEdit(row)
    },

    // 单元格样式
    cellClassName({row, column}) {
      if (this.cellCanEdit(row)) {
        return 'cell-underline'
      }
      return null
    },
    dateChange({row}) {
      // vxe的日期选择渲染器的change事件在每次修改后只会触发一次，所以此处不需要判断原生事件的类型
      this.updateDate(row)
    },
    numChange({row, column}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.updateDate({
          ...row,
          [column.field]: event.value
        })
      }
    },
    updateDate(row) {
      this.$http['put']('/opm/preProjectWorkLib/', row).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500
        })
      }).catch(() => {
        this.$message.error('出错了')
      })
    },
    // 清空前期工作进展内容，包括计划开始日期、计划完成日期、开始日期、完成日期、完成百分比等
    clearHandle(row) {
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        row.changeEdit = false
        row.planStart = ''
        row.planEnd = ''
        row.actualStart = ''
        row.actualEnd = ''
        row.percentage = ''
        // 更新前期工作进展
        this.$http['put'](`/opm/preProjectWorkLib/clear/${row.id}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500
          })
        }).catch(() => {
          this.$message.error('出错了')
        })
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>