<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" width="90%" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排序" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <vxe-table id="vxeTable" keep-source ref="table"
                 size="mini"
                 border
                 resizable
                 :loading="tableLoading"
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="mxList"
                 header-align="center"
                 :max-height="maxTableHeight"
                 :edit-rules="editRules"
                 :edit-config="{trigger: 'click', mode: 'row', showStatus:true}">
        <vxe-column type="seq" title="序号" width="80" align="center" fixed="left">
          <template v-slot:header>
            <vxe-button type="button" status="primary" content="添加" round @click="addRow()"></vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="tableName" title="表名" width="200" fixed="left" align="left" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column field="remark" title="备注" align="left" width="150" :edit-render="{name: '$input'}"></vxe-column>
        <vxe-column field="canCountByProject" title="可按项目统计" align="center" header-align="center" width="100">
          <template v-slot="{ row }">
            <vxe-checkbox v-model="row.canCountByProject" :checked-value="1" :unchecked-value="0"></vxe-checkbox>
          </template>
        </vxe-column>
        <vxe-colgroup title="按项目统计时需要关联其他表" align="right" header-align="center">
          <vxe-column field="joinTableName" title="关联表名" align="center" header-align="center" :edit-render="{autofocus: '.el-input__inner'}" width="160">
            <template #edit="{ row }">
              <el-autocomplete v-model="row.joinTableName" :popper-append-to-body="false" :fetch-suggestions="querySearchTableName"></el-autocomplete>
            </template>
          </vxe-column>
          <vxe-column field="joinTableColumn" title="关联字段" align="center" header-align="center" :edit-render="{autofocus: '.el-input__inner'}" width="160">
            <template #edit="{ row }">
              <el-autocomplete v-model="row.joinTableColumn" :popper-append-to-body="false" :fetch-suggestions="querySearchTableColumn"></el-autocomplete>
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-column field="manualSql" title="手动输入查询语句" align="left" :edit-render="{name: '$input'}" min-width="200">
          <template #header="{ row }">
            <span>手动输入查询语句<el-tooltip class="item" effect="light" content="例: select count(*) c, prj_id from mps_contract where tenant_code = [tenantCode] group by prj_id " placement="top"><i class="vxe-icon-question-circle"></i></el-tooltip>
            </span>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="160" align="center" fixed="right">
          <template #default="{row, rowIndex}">
            <el-button type="text" size="small" @click="previewSql(row, rowIndex)">预览查询语句</el-button>
            <el-button type="text" size="small" @click="removeRow(row, rowIndex)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        name: '',
        sortNo: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      mxList: [],
      tableLoading: false,
      editRules: {},
      maxTableHeight: 400,
      tableNames: [],
      tableColumns: []
    }
  },
  computed: {
    dataRule() {
      return {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
      this.tableNames = this.loadTableNameData()
      this.tableColumns = this.loadTableTableColumnData()
    })
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/dataStatistics/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxList = res.data.mxList
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.dataForm = {
          ...this.dataForm,
          mxList: this.mxList
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/dataStatistics/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        }).finally(() => {
          loading.close()
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
    // 添加行
    addRow() {
      this.mxList.push({canCountByProject: 1})
    },
    // 删除行
    removeRow(row, rowIndex) {
      this.mxList.splice(rowIndex, 1)
    },
    // 预览查询语句
    previewSql(row) {
      let sql = ''
      // 如果有手动写的sql，则以手写的为准
      if (row.manualSql) {
        sql = row.manualSql
      } else {
        sql += ` select count(*) c `
        let join = ''
        let groupBy = ''
        if (row.canCountByProject) {
          groupBy += ` group by `
          if (row.joinTableName) {
            sql += ` ,b.prj_id `
            join += ` left join ${row.joinTableName} b on b.id = a.${row.joinTableColumn} `
            groupBy += ` b.prj_id `
          } else {
            sql += ` ,a.prj_id`
            groupBy += ` a.prj_id `
          }
        }
        sql += ` from ${row.tableName} a `
        sql += join
        sql += ` where a.tenant_code = [tenantCode] `
        sql += groupBy
      }
      this.$alert(sql, 'sql预览', {
        customClass: 'mod-sys__log-error-view-info'
      })
    },
    loadTableNameData() {
      return [
        {"value": "mps_contract"}
      ]
    },
    loadTableTableColumnData() {
      return [
        {"value": "contract_id"}
      ]
    },
    querySearchTableName(queryString, cb) {
      var tabs = this.tableNames;
      var results = queryString ? tabs.filter(this.createFilter(queryString)) : tabs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearchTableColumn(queryString, cb) {
      var tabs = this.tableColumns;
      var results = queryString ? tabs.filter(this.createFilter(queryString)) : tabs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (data) => {
        return (data.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    }
  }
}
</script>