<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="审定日志" close-on-click-modal close-on-press-escape v-on="$listeners" append-to-body>
    <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border style="width: 100%;">
      <el-table-column prop="quantity" label="审定结果" width="130" header-align="center" align="center"></el-table-column>
      <el-table-column prop="opinion" label="审核意见" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="creatorName" label="操作人" width="150" header-align="center" align="center"></el-table-column>
      <el-table-column prop="updateDate" label="操作时间" width="150" header-align="center" align="center"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/measLog/list',
        createdIsNeed: false
      },
      visible: false,
      dataForm: {
      }
    }
  },
  watch: {
  },
  components: {
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getDataList()
      })
    }
  }
}
</script>