<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__personchange">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name2" placeholder="替换人姓名" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name1" placeholder="被替换人姓名" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:personchange:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:personchange:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="name2" label="替换人" sortable="custom" align="center" show-overflow-tooltip>
          <template v-slot="scope">
            <el-button type="text" @click="viewHandle(scope.row.id)">{{ scope.row.name2 }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="post2" label="替换人职位" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name1" label="被替换人" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="post1" label="被替换人职位" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="typeName" label="人员类型" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="processStatus" label="流程状态" align="center" width="140" sortable="custom">
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.name2" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="160">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:personchange:update')&& checkSelectable(row)" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:personchange:delete')&& checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:personchange:update') || !checkSelectable(row))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './personchange-add-or-update'
import ViewPage from './personchange-view'
import processModule from '@/mixins/process-module'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/personChange/page',
        getDataListIsPage: true,
        deleteURL: '/opm/personChange',
      },
      dataForm: {
        contractId: '',
        typeId: '',
        name1: '',
        name2: '',
      }
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },

    // 新增 / 修改
    addOrUpdateHandle(id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>