<template>
  <p v-if="!refId" style="margin-left: 10px; font-size: 16px;">
    请选择具体分项
  </p>
  <div v-else>
    <el-button-group v-if="canEdit">
      <el-button type="primary" size="mini" @click="openUploadPopup">上传草图</el-button>
      <el-button type="danger" size="mini" @click="deleteAttachmentHandle" v-if="fileList.length > 0">删除</el-button>
    </el-button-group>
    <el-upload ref="uploader" accept="image/jpg,image/jpeg,image/png,image/jfif" :disabled="!canEdit" :on-progress="onProgressHandle" :action="actionUrl" :file-list="fileList"
               :on-success="handleSuccess" :show-file-list="false" :before-upload="checkFileType" :auto-upload="true">
    </el-upload>
    <el-image v-if="imageUrl" :style="{height: (height - 30) + 'px'}" class="my-img-style" :src="imageUrl" fit="contain" :preview-src-list="previewImageUrl">
      <div slot="placeholder">图片加载中...</div>
    </el-image>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data () {
    return {
      imageUrl: '',
      fileList: [],
      refId: '',
      canEdit: false,
    }
  },
  computed: {
    // 大图预览Url
    previewImageUrl: function () {
      return this.imageUrl ? [this.imageUrl] : []
    },
    actionUrl() {
      return `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}&refId=${this.refId}&typeId=计量草图&statusId=1`
    }
  },
  props: {
    height: Number
  },
  methods: {
    loadFile (loading) {
      this.clearData()
      if (this.refId) {
        if (!loading) {
          loading = this.$loading({
            lock: true,
            text: '运行中...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        }
        this.$http.get(`/oss/file/list/${this.refId}?typeId=计量草图`)
            .then(({data: res}) => {
              if (res.code === 0) {
                this.fileList = res.data
                this.imageUrl = res.data ? res.data[0].url : '' // 图片预览只取第一个附件
              }
            })
            .catch(() => {
              // do nothing
            }).finally(() => {
          loading.close()
        })
      }
    },
    clearData () {
      this.imageUrl = ''
      this.fileList = []
    },
    // 打开附件上传文件框
    openUploadPopup () {
      // 触发upload组件内部点击事件，弹出文件选择框
      this.$refs.uploader.$refs['upload-inner'].handleClick()
    },
    deleteAttachmentHandle () {
      if (this.fileList.length <= 0) {
        return this.$message({
          message: '尚未上传计量草图',
          type: 'warning'
        });
      }
      this.$confirm('确定删除计量草图吗', this.$t('prompt.title'),{
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.handleSuccess()
      }).catch(() => {
        // do nothing
      })
    },
    // 上传中，显示loading
    onProgressHandle (event,file,fileList) {
      this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    handleSuccess (res,file,fileList) {
      const loading = this.$loading({
        lock: true,
        text: '运行中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      if (this.fileList.length > 0) {
        let deleteIds = []
        // 删除之前的附件
        for (let i = 0; i < this.fileList.length; i++) {
          deleteIds.push(this.fileList[i].id)
        }
        this.$http.delete(
            '/oss/file',
            {
              'data': deleteIds
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        }).finally(() => {
          // 删除成功后，重新读取服务器数据
          this.loadFile(loading)
          if (loading) {
            loading.close()
          }
        })
      } else {
        // 删除成功后，重新读取服务器数据
        this.loadFile(loading)
      }
    },
    // 附件上传前 文件类型检查
    checkFileType (file) {
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (extension !== 'jpg' 
          && extension !== 'png' 
          && extension !== 'jpeg'
          && extension !== 'jfif') {
        this.$message({
          message: '只能上传 jpg, jpeg, png格式的图片!',
          type: 'warning'
        })
        return false
      } else {
        return true
      }
    },
  }
}
</script>
