<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同标段" prop="contractName">
            <el-input v-model="dataForm.contractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区简称" prop="shortName">
            <el-input v-model="dataForm.shortName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工区编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="里程桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总监单位" prop="chiefSupervisionId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.chiefSupervisionId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.supervisionId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="咨询单位" prop="consultationId">
            <participant-dept-select :prj-id="prjId" v-model="dataForm.consultationId" disabled style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" disabled controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" readonly autosize></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      prjId: '',
      dataForm: {
        id: '',
        contractId: '',
        name: '',
        code: '',
        sspo: '',
        chiefSupervisionId: '',
        supervisionId: '',
        consultationId: '',
        remark: '',
        authority: '',
        sortNo: '',
        delFlag: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/subcontractor/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
