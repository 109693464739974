<template>
  <div>
    <el-dialog width="80%" v-dialog-drag
               :visible.sync="visible"
               :title="!dataForm.id ? $t('add') : $t('update')"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               v-on="$listeners">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="基本信息" name="wbsTab">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :validate-on-rule-change="false" label-width="80px" size="small">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="编号" prop="code">
                  <el-input v-model="dataForm.code" placeholder="编号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="dataForm.name" placeholder="工程名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="层级" prop="levelId">
                  <el-select v-model="dataForm.levelId" placeholder="请选择节点层级" :style="{ width: '100%' }" clearable>
                    <el-option label="单位工程" :value="1"/>
                    <el-option label="子单位工程" :value="2"/>
                    <el-option label="分部工程" :value="3"/>
                    <el-option label="子分部工程" :value="4"/>
                    <el-option label="分项工程" :value="5"/>
                    <el-option label="子分项工程" :value="6"/>
                    <el-option label="未知层级" :value="-1"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上级" prop="pid">
                  <wbs-select v-model="dataForm.pid" :contractId="dataForm.contractId" :subcontractor-id="dataForm.subcontractorId" :disabledId="dataForm.id" :leaf-only="false"></wbs-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="桩号" prop="stakeNo">
                  <el-input v-model="dataForm.stakeNo" placeholder="桩号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="图号" prop="drawingNo">
                  <el-input v-model="dataForm.drawingNo" placeholder="图号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="unitId" label="单位">
                  <unit-select v-model="dataForm.unitId" :contract-id="dataForm.contractId"></unit-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="数量" prop="num">
                  <el-input-number v-model="dataForm.num" :controls="false" placeholder="数量" style="width: 100%;"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="形象单元" prop="scheduleUnitId">
                  <scheduleunit-single-select v-model="dataForm.scheduleUnitId" :prjId="dataForm.prjId" @changeScheduleUnit="changeScheduleUnitHandle" placeholder="请选择形象单元"></scheduleunit-single-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="类型" prop="wbsTypeId">
                  <el-select v-model="dataForm.wbsTypeId" placeholder="请选择节点工程类型" :style="{ width: '100%' }">
                    <el-option label="未知类型" value="UNKNOWN"/>
                    <el-option label="路基工程" value="SUBGRADE"/>
                    <el-option label="路面工程" value="PAVEMENT"/>
                    <el-option label="桥梁工程" value="BRIDGE"/>
                    <el-option label="涵洞工程" value="CULVERT"/>
                    <el-option label="机电工程" value="ELECTRICAL"/>
                    <el-option label="环保工程" value="ENV"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 2,maxRows:10 }" placeholder="备注"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="实体工程" prop="isEntity">
                  <el-switch v-model="dataForm.isEntity" :active-value="true" :inactive-value="false"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="构件属性" name="wbsPropValTab">
          <el-button-group>
            <el-button v-if="$hasPermission('mps:wbs:save')" type="primary" size="mini" @click="openWbsPropSelectHandle()">添加</el-button>
          </el-button-group>
          <vxe-table id="wbsPropValTable"
                     keep-source
                     ref="mxAddWbsProValTableRef"
                     :loading="mxDataListLoading"
                     size="mini"
                     :column-config="{resizable: true}"
                     border
                     show-overflow
                     :row-config="{isCurrent: true,isHover: true}"
                     :data="dataForm.wbsPropValDetails"
                     :max-height="tableHeight"
                     :edit-rules="mxWbsPropValAddTableRules"
                     :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
            <vxe-column field="propName" title="构件属性名称" width="300" fixed="left"></vxe-column>
            <vxe-column field="propCode" title="构件属性编号" width="200" fixed="left"></vxe-column>
            <vxe-column field="val" title="构件属性值" min-width="110" fixed="left" :edit-render="{name: '$input'}"></vxe-column>
            <vxe-column title="操作" width="80" align="center" fixed="right">
              <template #default="{row}">
                <el-button v-if="$hasPermission('mps:wbs:delete')" type="text" size="small" @click="deleteOneWbsPropValHandle(row)">{{ $t('delete') }}</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <wbs-prop-select v-if="wbsPropSelectVisible" ref="wbsPropSelect" :selectedIds="getWbsPropIds()" @selectChange="wbsPropSelectChangeHandle" @close="closeWbsPropSelectHandle"></wbs-prop-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '0', // 根节点默认为0
        prjId: '',
        name: '',
        code: '',
        sortNo: 0,
        num: '',
        remark: '',
        scheduleUnitId: '',// 形象单元
        unitId: '',// 单位
        isEntity: '',
        levelId: '',
        wbsTypeId: 'UNKNOWN', // 工程类型： 默认 UNKNOWN：未知类型
        // 构件属性
        wbsPropValDetails: []
      },
      activeTabName: 'wbsTab',
      mxDataListLoading: false,
      // 构件属性 行校验
      mxWbsPropValAddTableRules: {
        val: [
          {required: true, type: 'string', message: '请填写构件属性值'}
        ],
      },
      tableHeight: 400,
      wbsPropSelectVisible: false,

      oldPid: '' // 记录数据原始的上级节点ID
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        pid: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        levelId: [
          {required: true, message: this.$t('validate.required'), trigger: 'change'}
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // // 获取排序序号
          // this.getNewSortNo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/mps/wbs/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.oldPid = this.dataForm.pid
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(async function () {

      let rs = await this.mxDataValidate('mxAddWbsProValTableRef', 'wbsPropValTab')
      if (!rs) {
        return false
      }

      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          if (this.activeTabName !== 'wbsTab') {
            this.activeTabName = 'wbsTab'  // 切换页签到第一页
            this.$message({
              message: "请先补充必填信息",
              type: 'warning',
              duration: 1500
            })
          }
          return false
        }

        this.setWbsPropVal()

        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/wbs/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),

    // 构件属性值等明细行 数据校验。验证不通过，返回false
    async mxDataValidate(vxeTableRef, elTabPaneName) {
      let $tableRef = this.$refs[vxeTableRef]
      let errMap = await $tableRef.validate(true).catch((errMap) => errMap)
      if (errMap) {
        const {row, rule} = Object.values(errMap)[0][0] // 取第一个错误信息
        const rowIndex = $tableRef.getVTRowIndex(row)
        if (this.activeTabName !== elTabPaneName) {
          this.activeTabName = elTabPaneName  // 切换页签
        }
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: '第' + (rowIndex + 1) + '行 校验错误：' + rule.$options.message + '',
          duration: 2000
        });
        return false
      }
      return true
    },

    // 删除一行构件属性值数据
    deleteOneWbsPropValHandle(row) {
      let $table = this.$refs.mxAddWbsProValTableRef
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        if ($table != null) {
          $table.remove(row)
        }
      }).catch(() => {
        // do Nothing
      })
    },

    //  打开构件属性选择框
    async openWbsPropSelectHandle(row) {
      this.wbsPropSelectVisible = true
      this.$nextTick(() => {
        this.$refs.wbsPropSelect.init()
      })
    },

    // 构件属性选择弹窗选择项变化事件
    wbsPropSelectChangeHandle(data) {
      if (data) {
        const $table = this.$refs.mxAddWbsProValTableRef
        let fullDataList = $table.getTableData().fullData
        let newItems = data.newItems || []
        // 删除构件属性
        let deleteWbsPropIds = data.deleteIds || []
        if (deleteWbsPropIds.length > 0) {
          for (let i = 0; i < fullDataList.length; i++) {
            let wbsPropValObj = fullDataList[i];
            if (this.isStr(deleteWbsPropIds, wbsPropValObj.propId + '')) {
              $table.remove(wbsPropValObj)
            }
          }
        }
        // 列表末尾新增构件属性
        for (let i = 0; i < newItems.length; i++) {
          let wbsProplObj = newItems[i];
          $table.insertAt({
            propId: wbsProplObj.id,
            propName: wbsProplObj.name,
            propCode: wbsProplObj.code,
            id: '',
            val: ''
          }, -1)
        }
      }
    },

    // 关闭 构件属性选择框
    closeWbsPropSelectHandle() {
      this.wbsPropSelectVisible = false;
    },

    // 获取wbs挂接的构件属性
    getWbsPropIds() {
      let ids = []
      const $table = this.$refs.mxAddWbsProValTableRef
      if ($table) {
        let fullDataList = $table.getTableData().fullData
        if (fullDataList && fullDataList.length > 0) {
          for (let i = 0; i < fullDataList.length; i++) {
            if (fullDataList[i].propId) {
              ids.push(fullDataList[i].propId)
            }
          }
        }
      }
      return ids
    },

    // 数组是否包含部分字符串
    isStr(arr, str) {
      let n = arr.length;
      for (let i = 0; i < n; i++) {
        if (arr[i].indexOf(str) != -1) {
          return true;
        }
      }
      return false;
    },

    setWbsPropVal() {
      // 获取构件属性值的删除行
      const {removeRecords} = this.$refs.mxAddWbsProValTableRef.getRecordset()
      this.dataForm.addWbsPropValList = []
      this.dataForm.updateWbsPropValList = []
      // 修正构件属性值的排序字段
      let fullDataList = this.$refs.mxAddWbsProValTableRef.getTableData().fullData
      if (fullDataList && fullDataList.length > 0) {
        for (let i = 0; i < fullDataList.length; i++) {
          let wbsPropValData = fullDataList[i]
          wbsPropValData.sortNo = i
          // 新增
          if (!wbsPropValData.id) {
            this.dataForm.addWbsPropValList.push(wbsPropValData)
          } else {
            // 修改
            this.dataForm.updateWbsPropValList.push(wbsPropValData)
          }
        }
      }

      let deleteMxIdList = []
      if (removeRecords && removeRecords.length > 0) {
        removeRecords.forEach(function (element) {
          if (element && element['id']) {
            deleteMxIdList.push(element['id'])
          }
        })
      }
      this.dataForm.deleteWbsPropValIdList = deleteMxIdList
    },
    // 形象单元选择
    changeScheduleUnitHandle(row) {
      // 选择了形象单元后，以形象单元的单位为准
      this.dataForm.unitId = row.unitId
    },

    // 获取排序序号
    getNewSortNo() {
      this.$http.get('/mps/wbs/newSortNo', {
        params: {
          contractId: this.dataForm.contractId,
          subcontractorId: this.dataForm.subcontractorId,
          pid: this.dataForm.pid
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>