<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__project">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="项目编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" @click="addOrUpdateHandle()" v-if="$hasPermission('mps:project:save')"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()" v-if="$hasPermission('mps:project:delete')"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border 
        @selection-change="dataListSelectionChangeHandle" 
        @sort-change="dataListSortChangeHandle" style="width: 100%" :cell-style="{padding: '2px 0'}">
        <!-- 1、多选框控制 -->
        <el-table-column type="selection" header-align="center" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="code" label="项目编号" fixed="left" min-width="160" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="name" label="项目名称" min-width="260" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="construction" label="建设单位" min-width="260" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="180">
          <template slot-scope="scope">
            <table-button role="auth" @click="authorityHandle(scope.row.id)" v-if="$hasPermission('mps:project:update')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(scope.row.id)" v-if="$hasPermission('mps:project:update') && checkSelectable(scope.row)"></table-button>
            <table-button role="delete" @click="deleteHandle(scope.row.id)" v-if="$hasPermission('mps:project:delete') && checkSelectable(scope.row)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('mps:project:update') || !checkSelectable(scope.row))"></table-button>
            <!-- 
            <el-button type="text" size="small" @click="authorityHandle(scope.row.id)">用户授权</el-button>
            <el-button v-if="$hasPermission('mps:project:update') && checkSelectable(scope.row)" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{ $t('update') }}</el-button>
            <el-button v-if="$hasPermission('mps:project:delete') && checkSelectable(scope.row)" type="text" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
            <el-button type="text" size="small" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('mps:project:update') || !checkSelectable(scope.row))">附件</el-button>
            -->
          </template>
        </el-table-column>
        <el-table-column label="概算报表设置" fixed="right" header-align="center" align="center" width="140">
          <template v-slot="{row}">
            <el-button el-button v-if="$hasPermission('mps:project:update')" type="text" size="small"  @click="reporttemplateAuthorityDialogHandle(row.id)">绑定报表</el-button>
            <el-button v-if="$hasPermission('mps:project:update')" type="text" size="small"  @click="luckysheetDialogHandle(row)">设计报表</el-button>
          </template>
        </el-table-column>
        <!-- 3、最后列：流程状态和各种相关按钮 -->
        <!-- <el-table-column label="流程状态" fixed="right" header-align="center" align="center" width="120">
          <template slot-scope="scope">
            <process-status
              :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList">
            </process-status>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @hide="closeDialogHandle"></add-or-update>
      <!-- 分配人员权限 -->
      <user-multi-select v-if="authorityVisible" ref="authority" @close="closeAuthorityHandle" @submit="submitAuthority"></user-multi-select>
      <!-- 弹窗, 设置/分配计量报表模板 -->
      <reporttemplate-authority v-if="reporttemplateAuthorityVisible" ref="reporttemplateAuthority" @close="closeReporttemplateAuthorityHandle"></reporttemplate-authority>
      <!-- 弹窗, 已选计量报表列表 -->
      <reporttemplate-bindinglist v-if="reporttemplateBindinglistVisible" ref="reporttemplateBindinglist" @close="closeReporttemplateBindinglistHandle"></reporttemplate-bindinglist>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './project-add-or-update'
import ViewPage from './project-view'
import processModule from '@/mixins/process-module'
import ReporttemplateAuthority from './reporttemplate-authority'
import ReporttemplateBindinglist from './reporttemplate-bindinglist'
export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/project/page',
        getDataListIsPage: true,
        exportURL: '/mps/project/export',
        deleteURL: '/mps/project',
      },
      dataForm: {
        name: '',
        code:''
      },
      authorityVisible: false,
      currentPrjId: '',
      // 计量报表模板分配窗口
      reporttemplateAuthorityVisible: false,
      // 已绑定计量报表列表
      reporttemplateBindinglistVisible: false,
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage,
    // 计量报表模板分配窗口
    ReporttemplateAuthority,
    // 已选计量报表列表
    ReporttemplateBindinglist
  },
  methods: {
    // 新的人员授权
    authorityHandle(id) {
      this.currentPrjId = id
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/project/userIds/${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.authorityVisible = true
        this.$nextTick(() => {
          this.$refs.authority.selectedList = res.data
          this.$refs.authority.init()
        })
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    },
    // 保存人员授权数据
    submitAuthority(selectedList) {
      this.$http['put']('/mps/project/authority', {
        id: this.currentPrjId,
        authority: selectedList ? selectedList.join(",") : ''
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000,
          onClose: () => {
            // do nothing
          }
        })
      }).catch(() => {
        // do nothing
      })
    },
    closeAuthorityHandle () {
      this.authorityVisible = false
    },

    // 打开计量报表模板分配窗口
    reporttemplateAuthorityDialogHandle (id) {
      this.reporttemplateAuthorityVisible = true
      this.$nextTick(() => {
        this.$refs.reporttemplateAuthority.refId = id
        this.$refs.reporttemplateAuthority.typeId = 3 // 只选择概预算报表模板
        this.$refs.reporttemplateAuthority.init()
      })
    },
    // 关闭计量报表模板分配窗口
    closeReporttemplateAuthorityHandle () {
      this.reporttemplateAuthorityVisible = false
    },
    // 打开计量报表的设计窗口
    luckysheetDialogHandle (row) {
      this.reporttemplateBindinglistVisible = true
      this.$nextTick(() => {
        this.$refs.reporttemplateBindinglist.dataForm.refId = row.id
        this.$refs.reporttemplateBindinglist.dataForm.title = row.shortName + ' 的造价管理台账报表'
        this.$refs.reporttemplateBindinglist.init()
      })
    },
    // 关闭计量报表的设计窗口
    closeReporttemplateBindinglistHandle () {
      this.reporttemplateBindinglistVisible = false
    },
    

  }
}
</script>
