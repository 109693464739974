<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="分部分项" prop="wbsId">
            <wbs-select v-model="dataForm.wbsId" :contractId="dataForm.contractId" placeholder="选择分部分项" showPNames :leaf-only="true" disabled></wbs-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报验工序" prop="workstageId">
            <el-select style="width: 100%" v-model="dataForm.workstageId" disabled>
              <el-option v-for="opt in workstageList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="检查项" prop="item">
            <el-input v-model="dataForm.item" placeholder="请填写检查项" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="检查编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="请填写检查编号" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="报验人/班组" prop="submitter">
            <el-input v-model="dataForm.submitter" placeholder="请填写报验人/班组" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="报验部位" prop="position">
            <el-input type="textarea" v-model="dataForm.position" placeholder="请填写报验部位" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报验内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" placeholder="请填写报验内容" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="报验日期" prop="submitDate">
            <el-input v-model="dataForm.submitDate" placeholder="请填写报验日期" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>工程实体照片</span>
            </div>
            <el-image
                :preview-teleported="true"
                v-for="(img, $index) in gcstzp"
                :key="$index"
                fit="cover"
                style="width: 100px; height: 100px; margin-right: 10px;"
                :src="img"
                :preview-src-list="gcstzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>质检人员照片</span>
            </div>
            <el-image
                :preview-teleported="true"
                v-for="(img, $index) in zjryzp"
                :key="$index"
                fit="cover"
                style="width: 100px; height: 100px; margin-right: 10px;"
                :src="img"
                :preview-src-list="zjryzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>实测实量照片</span>
            </div>
            <el-image
                :preview-teleported="true"
                v-for="(img, $index) in scslzp"
                :key="$index"
                fit="cover"
                style="width: 100px; height: 100px; margin-right: 10px;"
                :src="img"
                :preview-src-list="scslzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>监理照片</span>
            </div>
            <el-image
                :preview-teleported="true"
                v-for="(img, $index) in jlzp"
                :key="$index"
                fit="cover"
                style="width: 100px; height: 100px; margin-right: 10px;"
                :src="img"
                :preview-src-list="jlzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>其他报验资料</span>
            </div>
            <my-upload ref="upload" :refId="dataForm.id" :view="true" typeId="其他报验资料"></my-upload>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        wbsId: '',
        workstageId: '',
        code: '',
        item: '',
        submitter: '',
        position: '',
        contents: '',
        submitDate: '',
        processInstanceId: '',
        processStatus: '',
      },
      autosize: {
        minRows: 3,
        maxRows: 6
      },
      workstageList: [],
      // 工程实体照片
      gcstzp: [],

      // 质检人员照片
      zjryzp: [],

      // 实测实量照片
      scslzp: [],

      // 监理照片
      jlzp: [],
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/workstageInspection/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.wbsId) {
          this.loadWbsWorkstage(this.dataForm.wbsId)
          this.loadPictures(this.dataForm.id)
        }
      }).catch(() => {
      })
    },
    loadWbsWorkstage(wbsId) {
      this.$http.get('/mps/wbsWorkstage/refList/' + wbsId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.workstageList = res.data
      }).catch()
    },
    // 加载工序报验照片和附件
    loadPictures(refId) {
      this.gcstzp = [] // 工程实体照片
      this.zjryzp = [] // 质检人员照片
      this.scslzp = [] // 实测实量照片
      this.jlzp = [] // 监理照片
      this.$http.get('/oss/file/list/' + refId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let data = res.data
        if (data && data.length > 0) {
          data.forEach(element => {
            if (element.typeId == '工程实体照片') {
              this.gcstzp.push(element.signedUrl)
            } else if (element.typeId == '质检人员照片') {
              this.zjryzp.push(element.signedUrl)
            } else if (element.typeId == '实测实量照片') {
              this.scslzp.push(element.signedUrl)
            } else if (element.typeId == '监理照片') {
              this.jlzp.push(element.signedUrl)
            }
          })
        }
      }).catch()
    },
  }
}
</script>
