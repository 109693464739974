<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <el-form :model="dataForm" ref="dataForm" label-width="80px" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="otherContractId">
            <el-input v-model="dataForm.otherContractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编码" prop="otherContractCode">
            <el-input v-model="dataForm.otherContractCode" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="otherContractAmount">
            <el-input readonly v-model="dataForm.otherContractAmount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签订单位" prop="otherContractSecondParty">
            <el-input v-model="dataForm.otherContractSecondParty" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请日期" prop="applyDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.applyDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支付金额" prop="paymentAmount">
            <el-input readonly v-model="dataForm.paymentAmount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="请款人" prop="requestId">
            <user-select v-model="dataForm.requestId" style="width: 100%" disabled></user-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="用途" prop="purpose">
            <el-input type="textarea" v-model="dataForm.purpose" :autosize="{ minRows: 3 }" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="bank">
            <el-input v-model="dataForm.bank" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名称" prop="bankAccountName">
            <el-input v-model="dataForm.bankAccountName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data () {
    return {
      dataForm: {
        id: '',
        prjId: '',
        otherContractId: '',
        otherContractName: '',
        otherContractAmount: '',
        otherContractSecondParty: '',
        otherContractCode: '',
        applyDate: '',
        requestId: '',
        paymentAmount: '',
        purpose: '',
        bank: '',
        bankAccountName: '',
        bankAccount: '',
        remark: '',
        processInstanceId: '',
        processStatus: '',
      },
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/otherContractPay/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
