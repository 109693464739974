<template>
  <el-dialog top="5vh" v-dialog-drag width="60%" :visible.sync="visible" title="[选择质检套表]" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="6">
        <div class="tree-container">
          <el-tree ref="deptTree"
                   lazy
                   class="left-tree"
                   node-key="id"
                   v-loading="treeLoading"
                   :data="treeData"
                   :props="defaultProps"
                   :load="loadTreeNode"
                   :expand-on-click-node="true"
                   @node-click="treeNodeClick"
                   :default-expanded-keys="expandedKeys"
                   :highlight-current="true">
          </el-tree>
        </div>
      </el-col>
      <el-col :span="18">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-input v-model="dataForm.keyword" placeholder="表号或名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.typeId" clearable placeholder="施工步骤" @clear="typeClear" @change="typeChange">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button-group style="margin-top: -3px;">
              <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            </el-button-group>
          </el-form-item>
        </el-form>

        <vxe-table
            ref="myTable"
            border
            show-overflow
            :max-height="tableHeight"
            :loading="dataListLoading"
            :checkbox-config="checkboxConfig"
            :column-config="columnConfig"
            :row-config="rowConfig"
            :data="dataList">
          <vxe-column type="checkbox" align="center" width="60"></vxe-column>
          <vxe-column field="qcFileCode" title="表号" width="120"></vxe-column>
          <vxe-column field="qcFileName" title="名称"></vxe-column>
          <vxe-column field="qcFileTypeId" title="施工步骤" align="center" width="120">
            <template #default="{ row }">
              <span v-if="row.qcFileTypeId === 1" style="color: #5231e6">开工报告</span>
              <span v-else-if="row.qcFileTypeId === 2" style="color: #2278b2">检验申请批复</span>
              <span v-else-if="row.qcFileTypeId === 3" style="color: #8ea706">交工证书</span>
              <span v-else style="color: #ee0a30">未知</span>
            </template>
          </vxe-column>
        </vxe-table>
        <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 质检套表选择弹窗（左侧套表目录树，右侧质检表格列表）
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  name: 'QcTableFileSelect',
  data() {
    return {
      visible: false,
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/qcTableDirFile/page',
        getDataListIsPage: true
      },
      dataForm: {
        prjId: '', // 项目ID
        tableDirId: '',
        keyword: '', // 按表号或表格名称检索
        typeId: '' // 施工步骤
      },
      tableHeight: 450,
      treeLoading: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      expandedKeys: [],
      typeOptions: [{
        value: '1',
        label: '开工报告'
      }, {
        value: '2',
        label: '检验申请批复'
      }, {
        value: '3',
        label: '交工证书'
      }],
      checkboxConfig: {
        trigger: 'row',
        strict: true,
        checkMethod: this.checkSelect,
        reserve: true
      },
      columnConfig: {
        resizable: true
      },
      rowConfig: {
        keyField: 'id',
        isCurrent: true,
        isHover: true
      }
    }
  },
  props: {
    isNotSelectWithNotUpload: { // 没有上传质检资料的质检模板数据不能选中
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getTreeData()
        this.getDataList()
      })
    },
    // 加载目录树根节点
    getTreeData() {
      this.treeLoading = true
      this.treeData = []
      this.$http.get(`/mps/qcTableDir/children`, {
        params: {
          pid: "0",
          prjId: this.dataForm.prjId
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data || []
        // 实现默认展开第一级节点
        if (res.data && res.data.length > 0) {
          this.expandedKeys = [res.data[0].id]
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 异步加载目录树
    loadTreeNode(node, resolve) {
      if (node.level >= 1) {
        if (node && node.data) {
          let nodeData = node.data;
          this.$http.get(`/mps/qcTableDir/children`, {
            params: {
              pid: nodeData.id,
              prjId: this.dataForm.prjId
            }
          }).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data);
          }).catch(() => {
            return this.$message.error('出错了')
          })
        } else {
          return resolve([])
        }
      }
    },
    // 点击目录查询其下的表格
    treeNodeClick(data, node, ele) {
      // 点击高亮节点则取消高亮
      if (this.dataForm.tableDirId == data.id) {
        this.dataForm.tableDirId = ''
        this.$refs.deptTree.setCurrentKey(null)
      } else {
        this.dataForm.tableDirId = data.id
      }
      // 查询数据
      this.getDataList()
    },
    checkSelect({row}) {
      let rs = true; // 返回true，表示可以勾选
      if (this.isNotSelectWithNotUpload && !row.fileId) {
        rs = false
      }
      return rs;
    },
    // 提交数据
    dataFormSubmitHandle: debounce(function () {
      const $table = this.$refs.myTable
      const checkboxRecord = $table.getCheckboxRecords() // 获取当前列表选中的数据
      const checkboxReserveRecord = $table.getCheckboxReserveRecords() // 获取非当前列表选中的数据
      this.$emit('submit', [...checkboxRecord, ...checkboxReserveRecord])
      this.visible = false
    }, 1000, {'leading': true, 'trailing': false}),

    typeClear() {
      this.dataForm.typeId = ''
      this.getDataList()
    },
    typeChange() {
      this.getDataList()
    },
  }
}
</script>
<style scoped>
.tree-container {
  overflow: auto;
  height: 500px;
}
</style>
