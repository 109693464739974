<template>
  <el-dialog top="5vh" width="80%" append-to-body v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="80px">
      <el-row :gutter="10">
        <el-form-item label="上级节点" prop="pid">
          <wbs-template-parent-select v-model="dataForm.pid" :mainId="dataForm.versionId" :disabledId="dataForm.id"></wbs-template-parent-select>
        </el-form-item>
        <el-col :span="12">
          <el-form-item prop="code" label="编号">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="名称">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitName" label="单位">
            <el-input v-model="dataForm.unitName" placeholder="单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="boqCodes" label="清单编码">
            <el-tooltip placement="top">
              <div slot="content">多条清单，用竖杠分割 例如:202-1|202-1-a|202-1-b</div>
              <el-input v-model="dataForm.boqCodes" placeholder="例如：202-1|202-1-a|202-1-b" clearable v-popover:popover>
                <el-button slot="append" icon="el-icon-position" style="color: #409EFF" @click="boqTemplateVisibleHandle()">选择</el-button>
              </el-input>
            </el-tooltip>
            <!--选择清单范本中的清单-->
            <el-popover
              ref="popover"
              placement="bottom-start"
              trigger="manual"
              v-model="boqTemplateSelectVisible">
              <div style="text-align: left; margin: 0">
                <el-select v-model="boqTemplateMainId" filterable size="mini" style="width: 150px" placeholder="请选择清单范本项" @change="getBoqTemplateListHandle()">
                  <el-option
                    v-for="item in boqTemplateMainOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-button size="mini" type="text" @click="boqTemplateVisibleHandle()">取消</el-button>
                <el-button type="text" size="mini" @click="boqTemplateSelectHandle()">确定</el-button>
              </div>
              <div class="my-tree-contianer">
                <el-tree
                  v-if="boqTemplateSelectVisible"
                  highlight-current
                  show-checkbox
                  node-key="id"
                  ref="boqTemplateTree"
                  :data="boqTemplateDataList"
                  :empty-text="treeEmptyText"
                  :props="{children: 'children',label: 'name'}">
                </el-tree>
              </div>
            </el-popover>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import WbsTemplateParentSelect from './wbstemplate-parent-select'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          pid: '',
          name: '',
          code: '',
          unitName: '',
          remark: '',
          parentName: '',
          sortNo: 0,
          versionId: null,
          boqCodes: '',
        },
        boqTemplateSelectVisible: false,
        boqTemplateMainId: null,
        boqTemplateMainOptions: [],
        boqTemplateDataList: [],
        treeEmptyText: '',
        oldPid:'' // 记录数据原始的上级节点ID
      }
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          code: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          pid: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ]
        }
      }
    },
    components:{
      WbsTemplateParentSelect
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          } else {
            // 获取排序序号
            this.getNewSortNo()
          }
          this.getBoqTemplateMainList()
        })
      },
      // 获取排序序号
      getNewSortNo () {
        this.$http.get(`/mps/wbsTemplate/${this.dataForm.versionId}/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.sortNo = res.data
        }).catch(() => {
          // do nothing
        })
      },
      // 查询清单范本主表列表
      getBoqTemplateMainList () {
        this.$http.get('/mps/wbsTemplate/boqTemplateMain/list',{
          params: {}
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let dataList = res.data
          if (dataList && dataList.length > 0) {
            for (let i = 0; i < dataList.length; i++) {
              let data = dataList[i]
              let option = {
                label: data['name'],
                value: data['id']
              }
              this.boqTemplateMainOptions.push(option)
            }
          }
        }).catch(() => {
          // do nothing
        })
      },
      // 查询清单范本明细列表
      getBoqTemplateListHandle () {
        this.treeEmptyText = "加载中"
        this.$http.get('/mps/wbsTemplate/boqTemplate/treeList',{
          params: {
            mainId: this.boqTemplateMainId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (res.data || res.data.length == 0) {
            this.treeEmptyText = "暂无数据"
          }
          this.boqTemplateDataList = res.data
        }).catch(() => {
          // do nothing
        })
      },
      // 清单范本显示/隐藏
      boqTemplateVisibleHandle () {
        this.boqTemplateSelectVisible = !this.boqTemplateSelectVisible
      },
      // 清单范本确定按钮
      boqTemplateSelectHandle: debounce(function () {
        if (this.$refs.boqTemplateTree) {
          let checkNodes = this.$refs.boqTemplateTree.getCheckedNodes();
          let boqNameStr = ""
          let separator = "|"
          // 拼接选中清单范本的清单编号，格式： xx|yy|zz
          if (checkNodes.length > 0) {
            for (let i = 0; i < checkNodes.length; i++) {
              let node = checkNodes[i]
              if (i == 0) {
                boqNameStr = boqNameStr + node["code"]
              } else {
                boqNameStr = boqNameStr + separator + node["code"]
              }
            }
            // 组装分部分项模板的清单编号
            this.dataForm.boqCodes = this.dataForm.boqCodes || ""
            this.dataForm.boqCodes.trim()
            if (!this.dataForm.boqCodes) {
              this.dataForm.boqCodes = boqNameStr
            } else if (this.dataForm.boqCodes.endsWith(separator)) {
              this.dataForm.boqCodes = this.dataForm.boqCodes + boqNameStr
            } else {
              this.dataForm.boqCodes = this.dataForm.boqCodes + separator + boqNameStr
            }
          }

          this.boqTemplateVisibleHandle()
        }
      },1000,{'leading': true,'trailing': false}),

      // 获取信息
      getInfo () {
        this.$http.get(`/mps/wbsTemplate/${this.dataForm.id}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.oldPid = this.dataForm.pid
        }).catch(() => {
          // do nothing
        })
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/wbsTemplate',this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (this.dataForm.id) {
              this.$emit('updateNode',this.dataForm.pid,this.oldPid != this.dataForm.pid)
            } else {
              this.$emit('addNode',this.dataForm.pid)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
              }
            })
          }).catch(() => {
            // do nothing
          })
        })
      },1000,{'leading': true,'trailing': false})
    }
  }
</script>
<style scoped>
  .my-tree-contianer {
    width: 100%;
    max-height: calc(100vh - 40vh);
    overflow: auto;
  }
</style>
