<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__materialexit">
      <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="期次" prop="periodName">
              <el-input v-model="dataForm.periodName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="标题" prop="name">
              <el-input v-model="dataForm.name" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <my-upload :refId="dataForm.id" view></my-upload>
          </el-col>
        </el-row>
      </el-form>
      <vxe-table id="mxTable"
                 keep-source
                 ref="mxTableRef"
                 :loading="mxDataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataForm.details"
                 :max-height="tableHeight">
        <vxe-column field="materialName" title="材料名称" min-width="300" fixed="left"></vxe-column>
        <vxe-column field="spec" title="规格型号" width="200"></vxe-column>
        <vxe-column field="price" title="单价(元)" width="150" align="right"></vxe-column>
        <vxe-column field="num" title="计量数量" width="150" align="right"></vxe-column>
        <vxe-column field="unit" title="单位" width="150" align="center"></vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<script>
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        contractId: '',
        periodId: '',
        name: '',
        processInstanceId: '',
        processStatus: '',
        sortNo: '',
        details: [],
        periodName: ''
      },
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  methods: {
    init() {
      //  通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialExit/' + this.dataForm.id).then(({data: res}) => {
        this.mxDataListLoading = false
        if (res.code !== 0) {
          return this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        this.mxDataListLoading = false
      })
    }
  }
}
</script>
