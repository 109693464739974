<template>
  <el-dialog top="5vh" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body v-on="$listeners">
    <div class="my-tree-contianer">
      <el-tree
        v-if="treeShow"
        v-loading="treeLoading"
        node-key="id"
        :props="props"
        ref="tree"
        show-checkbox
        :data="dataList"
        :default-checked-keys="defaultCheckedKeys"
        check-on-click-node
        highlight-current
      >
        <span class="custom-tree-node" slot-scope="{ data }">
          <span>{{ data.boqName }}</span>
          &nbsp;
          <span>{{ data.boqCode }}</span>
        </span>
      </el-tree>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>
<!-- 分项计量模式下的计量明细选择 -->
<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        mainId: '',
      },
      dataList: [],
      props: {
        label: 'name',
        isLeaf: true,
        disabled: this.checkBoxDisabled
      },
      treeLoading: true,
      defaultCheckedKeys: [],
      title: '工程量清单',
      treeShow: true,
    }
  },
  methods: {
    getDataList() {
      this.$http.get(`/mps/measMx/subBoqList/${this.dataForm.mainId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.getExistList()
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    init() {
      this.visible = true
      this.getDataList()
    },
    // 查询已存在的计量明细对应的零号清单id
    getExistList() {
      this.$http.get(`/mps/measMx/existSubBoqIdListByTicketId/${this.dataForm.mainId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.defaultCheckedKeys = res.data
        this.treeLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.tree.getCheckedNodes(true)
      let newData = []
      // 将subItemId和boqId传至后台，避免了后台还需要通过id去查询
      for (let node of checkedNodes) {
        if (!this.defaultCheckedKeys.includes(node.id)) {
          newData.push({
            subItemId: node.subItemId,
            boqId: node.boqId
          })
        }
      }
      let params = {
        mainId: this.dataForm.mainId,
        newData: newData
      }
      this.$http.post(
          '/mps/measMx/batchAdd',
          params
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
      })
    }, 1000, {'leading': true, 'trailing': false}),
    /*
    checkbox不允许勾选和反选的判断
    规则如下:
    该节点已生成了对应的计量明细
     */
    checkBoxDisabled(data, node) {
      return this.defaultCheckedKeys.includes(data.id)
    },
  }
}
</script>
<style scoped>
.my-tree-contianer {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}
</style>