<template>
  <el-dialog top="5vh" width="80%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-press-escape="false" v-on="$listeners">
    <el-form key="mxAddOrUpdate" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <template>
          <el-col :span="12">
            <el-form-item label="工程或费用名称" prop="subItemId">
              <el-popover v-model="subItemListVisible" ref="wbsListPopover" placement="bottom-start" trigger="click">
                <div class="my-tree-contianer">
                  <el-tree
                    :load="loadSubItemNode"
                    lazy
                    :props="treeProp"
                    node-key="id"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    accordion
                    @current-change="wbsListTreeCurrentChangeHandle">
                  </el-tree>
                </div>
              </el-popover>
              <el-tooltip placement="top-start" :content="pnames" effect="light" :enterable="false" :open-delay="500">
                <el-input placeholder="请选择变更部位" v-model="dataForm.subItemName" v-popover:wbsListPopover :readonly="true"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </template>
        <!--新增清单-->
        <template v-if="this.requestTypeId == 3">
          <el-col :span="12">
            <el-form-item label="上级清单" prop="boqPid">
              <el-popover v-model="boqPidListVisible" ref="boqPidListPopover" placement="bottom-start" trigger="click">
                <div class="my-tree-contianer">
                  <el-tree
                    :data="boqList"
                    :props="{ label: 'name', children: 'children' }"
                    node-key="id"
                    ref="boqPidListTree"
                    highlight-current
                    :expand-on-click-node="false"
                    :default-expanded-keys="[dataForm.boqPid]"
                    accordion
                    @current-change="boqPidListTreeCurrentChangeHandle">
                    <template slot-scope="{ data }">
                      <span>{{data.code + " " + data.name}} </span>
                    </template>
                  </el-tree>
                </div>
              </el-popover>
              <el-input placeholder="请选择上级清单" v-model="dataForm.boqParentName" v-popover:boqPidListPopover :readonly="true"></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <!--清单数量变更-->
          <template v-if="this.requestTypeId == 2">
            <el-form-item label="清单名称" prop="boqId">
              <el-popover v-model="boqListVisible" ref="boqListPopover" placement="bottom-start" trigger="click">
                <div class="my-tree-contianer">
                  <el-tree
                    :data="boqList"
                    :props="{ label: 'name', children: 'children' }"
                    node-key="id"
                    ref="boqListTree"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    :default-expanded-keys="[dataForm.boqId]"
                    accordion
                    @current-change="boqListTreeCurrentChangeHandle">
                    <template slot-scope="{ data }">
                      <span>{{data.code + " " + data.name}} </span>
                    </template>
                  </el-tree>
                </div>
              </el-popover>
              <el-input placeholder="请选择清单" v-model="dataForm.boqName" v-popover:boqListPopover :readonly="true"></el-input>
            </el-form-item>
          </template>
          <!--新增清单-->
          <template v-if="this.requestTypeId===3">
            <el-form-item label="清单名称" prop="boqName">
              <el-input v-model="dataForm.boqName" placeholder="清单名称"></el-input>
            </el-form-item>
          </template>
        </el-col>
        <el-col :span="12">
          <el-form-item label="清单编号" prop="boqCode">
            <el-input v-model="dataForm.boqCode" placeholder="清单编号" :disabled="this.requestTypeId===2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计量单位" prop="unitId">
            <unit-select v-model="dataForm.unitId" :placeholder="this.requestTypeId===2?'':'请选择计量单位'" :contract-id="contractId" :disabled="this.requestTypeId===2" @change="unitSelectChangeHandle"></unit-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单价" prop="price">
            <el-input placeholder="单价(元)" v-model="dataForm.price" @input="calculateNewAmount" :disabled="this.requestTypeId===2">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <template v-if="this.requestTypeId===2">
          <el-col :span="12">
            <el-form-item label="合同数量" prop="oldNum">
              <el-input v-model="dataForm.oldNum" @input="calculateNewNum" disabled></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <el-form-item label="变更数量" prop="changedNum">
            <el-input v-model="dataForm.changedNum" placeholder="变更数量" @input="calculateNewAmountAndNewNum"></el-input>
          </el-form-item>
        </el-col>
        <template v-if="this.requestTypeId == 2">
          <el-col :span="12">
            <el-form-item label="变更后数量" prop="newNum">
              <el-input v-model="dataForm.newNum" disabled></el-input>
            </el-form-item>
          </el-col>
        </template>

      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import {checkNumber} from '@/utils/form-validate'
  import XEUtils from 'xe-utils'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          mainId: '',
          boqPid: '',
          boqParentName: '',
          boqParentCode: '',
          boqId: '',
          boqName: '',
          boqCode: '',
          typeId: this.requestTypeId,
          unitName: '',
          price: '',
          oldNum: '',
          changedNum: '',
          newNum: '',
          subItemName: '',
          subItemId: '',
          unitId: '',
          changedAmount: '' // 变更金额
        },
        boqList: [],
        boqListVisible: false,
        boqPidListVisible: false,
        // 分项条目（wbs或sbs）
        subItemListVisible: false,
        pnames: '',

        treeProp: {
          label: 'name',
          children: 'children',
          isLeaf: 'isLeaf'
        }
      }
    },
    props: {
      requestTypeId: { // 变更类型
        type: Number,
        required: true
      },
      contractId: { //  合同标段ID
        type: String,
        required: true
      },
    },
    computed: {
      dataRule () {
        let commonRule = {
          changedNum: [
            {validator: checkNumber, trigger: 'change'},
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          price: [
            {validator: checkNumber, trigger: 'change'}
          ],
          subItemId: [
            {required: true, message: this.$t('validate.required'), trigger: 'change'}
          ]
        }
        let otherRule = {}
        if (this.requestTypeId === 2) { // 清单数量变更
          otherRule = {
            boqId: [
              {required: true,message: this.$t('validate.required'),trigger: 'change'}
            ]
          }
        } else if (this.requestTypeId === 3) { // 新增清单
          otherRule = {
            boqPid: [
              {required: true,message: this.$t('validate.required'),trigger: 'change'}
            ],
            boqName: [
              {required: true,message: this.$t('validate.required'),trigger: 'blur'}
            ],
            boqCode: [
              {required: true,message: this.$t('validate.required'),trigger: 'blur'}
            ],
            unitId: [
              {required: true,message: this.$t('validate.required'),trigger: 'change'}
            ],
            price: [
              {validator: checkNumber,trigger: 'change'},
              {required: true,message: this.$t('validate.required'),trigger: 'blur'}
            ]
          }
        }
        return {...commonRule,...otherRule}
      }
    },
    methods: {
      init () {
        this.visible = true
        this.getBoqList()
      },

      // 异步加载工程或费用名称（wbs或sbs）
      loadSubItemNode (node,resolve) {
        let pid = node.level == 0 ? 0 : pid = node.data.id
        // 加载节点数据
        this.$http.get('/mps/changeRequest/subItem/children',{
          params: {
            pid: pid,
            contractId: this.contractId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          this.$message.error("出错了")
        })
      },

      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('newData',this.dataForm)
            }
          })
        })
      },1000,{'leading': true,'trailing': false}),

      // 计算变更后数量
      calculateNewNum () {
        let oldNum = XEUtils.toNumber(this.dataForm.oldNum)
        let changedNum = XEUtils.toNumber(this.dataForm.changedNum)
        this.dataForm.newNum = XEUtils.add(oldNum,changedNum)
      },

      // 计算变更增减金额
      calculateNewAmount () {
        let price = XEUtils.toNumber(this.dataForm.price)
        let changedNum = XEUtils.toNumber(this.dataForm.changedNum)
        this.dataForm.changedAmount = XEUtils.multiply(price,changedNum)
      },

      // 计算变更增减金额和变更后数量
      calculateNewAmountAndNewNum () {
        this.calculateNewNum()
        this.calculateNewAmount()
      },

      // 获取清单列表
      getBoqList () {
        return this.$http.get('/mps/changeRequest/boqTree',{
          params: {
            contractId: this.contractId,
            subItemId: this.dataForm.subItemId
          }
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.boqList = res.data
          // 数量变更，工程或费用名称关联的分项清单（零号清单）只有一条记录时。默认选中合同清单
          if (this.boqList && this.boqList.length == 1) {
            this.$nextTick(function () {
              if (this.$refs.boqListTree) {
                this.$refs.boqListTree.setCurrentKey(this.boqList[0].id)
                this.boqListTreeCurrentChangeHandle(this.boqList[0])
              }
            })
          }
        }).catch()
      },

      // 合同清单节点, 选中
      boqListTreeCurrentChangeHandle (data) {
        if (data.isLeaf) {
          this.dataForm.boqId = data.id
          this.dataForm.boqName = data.name
          this.dataForm.price = data.price
          this.dataForm.boqCode = data.code
          this.dataForm.unitId = data.unitId
          this.dataForm.oldNum = data.num
          this.calculateNewAmountAndNewNum()
          this.boqListVisible = false
        } else {
          this.$message.warning("请选择最末级节点")
        }
      },

      // 上级合同清单节点, 选中
      boqPidListTreeCurrentChangeHandle (data) {
        this.dataForm.boqPid = data.id
        this.dataForm.boqParentName = data.name
        this.dataForm.boqParentCode = data.code
        this.dataForm.boqCode = data.code + "-"
        this.boqPidListVisible = false
      },

      // 分项条目（wbs或sbs）节点选中
      wbsListTreeCurrentChangeHandle (data) {
        if (data.isLeaf) {
          this.dataForm.subItemId = data.id
          this.dataForm.subItemName = '(' + data.code + ')' + data.name
          this.pnames = data.pnames ? data.pnames + '/' + data.name : data.name
          this.subItemListVisible = false
          // 数量变更时，重新获取清单列表
          if (this.requestTypeId === 2) {
            this.getBoqList()
          }
        } else {
          this.$message.warning("请选择最末级节点")
        }
      },

      // 单位选择项选中
      unitSelectChangeHandle (item) {
        if (item) {
          this.dataForm.unitName = item['name']
          this.dataForm.unitAccuracy = item['accuracy']
        }
      }
    }
  }
</script>
<style scoped>
  .my-tree-contianer {
    width: 100%;
    max-height: calc(60vh);
    overflow: auto;
  }
</style>