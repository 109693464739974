<template>
  <el-dialog v-dialog-drag width="70%" top="5vh" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目编码" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划开工日期" prop="planStart">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.planStart" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划完工日期" prop="planEnd">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.planEnd" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划总投资" prop="planCost">
            <el-input v-model="dataForm.planCost" readonly>
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="立项批复日期" prop="approvalDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.approvalDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="项目概况" prop="overview">
            <el-input type="textarea" v-model="dataForm.overview" readonly :autosize="{ minRows: 4 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input type="textarea" v-model="dataForm.remark" readonly :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        code: '',
        name: '',
        overview: '',
        planStart: '',
        planEnd: '',
        approvalDate: '',
        planCost: '',
        remark: '',
        sortNo: ''
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/preProject/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
