<template>
  <el-dialog width="60%" top="5vh" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
      <el-card shadow="hover">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="人员类型" prop="typeId">
              <el-select v-model="dataForm.typeId" placeholder="请选择" class="w-percent-100">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.value+''"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>变更前</span>
        </div>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="姓名" prop="name1">
              <el-input v-model="dataForm.name1" placeholder="姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idcard1">
              <el-input v-model="dataForm.idcard1" placeholder="身份证号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile1">
              <el-input v-model="dataForm.mobile1" placeholder="手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职位" prop="post1">
              <el-input v-model="dataForm.post1" placeholder="职位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职称" prop="rank1">
              <el-input v-model="dataForm.rank1" placeholder="职称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="从业年限" prop="years1">
              <el-input-number v-model="dataForm.years1" data-unit="年" :min="0" :controls="false" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书" prop="certificate1">
              <el-input v-model="dataForm.certificate1" placeholder="证书"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄" prop="age1">
              <el-input-number v-model="dataForm.age1" data-unit="岁" :min="0" :controls="false" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>变更后</span>
        </div>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="姓名" prop="name2">
              <el-input v-model="dataForm.name2" placeholder="姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idcard2">
              <el-input v-model="dataForm.idcard2" placeholder="身份证号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile2">
              <el-input v-model="dataForm.mobile2" placeholder="手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职位" prop="post2">
              <el-input v-model="dataForm.post2" placeholder="职位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职称" prop="rank2">
              <el-input v-model="dataForm.rank2" placeholder="职称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="从业年限" prop="years2">
              <el-input-number v-model="dataForm.years2" data-unit="年" :min="0" :controls="false" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书" prop="certificate2">
              <el-input v-model="dataForm.certificate2" placeholder="证书"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄" prop="age2">
              <el-input-number v-model="dataForm.age2" data-unit="岁" :min="0" :controls="false" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        typeId: ''
      },
      typeOptions: [{
        value: 1,
        label: '施工人员'
      }, {
        value: 2,
        label: '监理人员'
      }
      ]
    }
  },
  computed: {
    dataRule() {
      return {
        name1: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name2: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/personChange/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid, obj) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/personChange/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>