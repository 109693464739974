<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__materialmonthplan">
      <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="计划年月" prop="ym">
              <el-input v-model="dataForm.ym" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <vxe-table id="mxTable"
                 ref="mxTableRef"
                 :loading="mxDataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataForm.details"
                 :max-height="tableHeight">
        <vxe-column type="seq" title="序号" width="50" fixed="left"></vxe-column>
        <vxe-column field="materialName" title="材料名称" min-width="300" fixed="left"></vxe-column>
        <vxe-column field="spec" title="规格型号" width="200"></vxe-column>
        <vxe-column field="num" title="数量" width="150" align="right"></vxe-column>
        <vxe-column field="unit" title="单位" width="150" align="center"></vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<script>
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        contractId: '',
        y: '',
        m: '',
        ym: ''
      },
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 240
    })
  },
  methods: {
    init() {
      //  通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialMonthPlan/' + this.dataForm.id).then(({data: res}) => {
        this.mxDataListLoading = false
        if (res.code !== 0) {
          return this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.y && this.dataForm.m) {
          this.dataForm.ym = this.dataForm.y + '年' + this.dataForm.m + '月'
        }
      }).catch(() => {
        this.mxDataListLoading = false
      })
    }
  }
}
</script>
