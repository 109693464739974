<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :leftWidth="300">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              v-if="treeShow"
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              :load="loadNode"
              lazy
              node-key="id"
              :check-strictly="true"
              :props="treeProps"
              ref="wbsWorkstageTree"
              :show-checkbox="false"
              @node-click="treeNodeClick"
              :expand-on-click-node="false"
              :highlight-current="true">
              <span slot-scope="{ node }">
                <span>{{ node.label }}</span>
              </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__qcfilebind">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item v-if="dataForm.contractId">
              <el-select v-model="dataForm.typeId" clearable placeholder="施工步骤" @clear="typeClear" @change="typeChange">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="add" @click="openQcFileSelectModal('file')" v-if="$hasPermission('mps:qcinspectiondatasupervise:save')"></toolbar-button>
                <toolbar-button icon="el-icon-plus" name="套表" @click="openQcFileSelectModal('table')" v-if="$hasPermission('mps:qcinspectiondatasupervise:save')"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:qcinspectiondatasupervise:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border
                    @selection-change="dataListSelectionChangeHandle"
                    @sort-change="dataListSortChangeHandle" style="width: 100%" :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
            <el-table-column prop="name" label="名称" sortable="custom">
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="openDocument(row)">{{ row.name }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="表号" width="120" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="typeId" label="施工步骤" width="120" align="center" sortable="custom" show-overflow-tooltip>
              <template v-slot="{row}">
                <span v-if="row.typeId === 1" style="color: #5231e6">开工报告</span>
                <span v-else-if="row.typeId === 2" style="color: #2278b2">检验申请批复</span>
                <span v-else-if="row.typeId === 3" style="color: #8ea706">交工证书</span>
                <span v-else style="color: #ee0a30">未知</span>
              </template>
            </el-table-column>
            <el-table-column prop="createDate" label="填报日期" width="100" align="center" sortable="custom" show-overflow-tooltip>
              <template slot-scope="{row}">
                <span>{{ getReportDate(row) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="creatorName" label="填报人" width="120" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="processStatus" label="状态" header-align="center" align="center" width="140" sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <process-status v-if="scope.row.ossUpdateDate" :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
                <span v-else style="color:#8c8c8c ">未填写</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="150">
              <template v-slot="{row}">
                <!--                <table-button role="edit" @click="openFillModal(row)" v-if="$hasPermission('mps:qcinspectiondatasupervise:update') && checkSelectable(row)"></table-button>-->
                <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:qcinspectiondatasupervise:delete') && checkSelectable(row)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:qcinspectiondatasupervise:update') || !checkSelectable(row))"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
        </div>
      </template>
    </my-container>
    <!--质检用表选择弹窗-->
    <qcfile-select v-if="qcfileSelectVisible" ref="qcfileSelect" @submit="qcfileSelectSubmitHandle" @close="closeQcfileSelectDialog" isNotSelectWithNotUpload></qcfile-select>
    <!-- 质检套表 选择弹窗  -->
    <qctablefile-select v-if="qctablefileSelectVisible" ref="qctablefileSelect" @submit="qctablefileSelectSubmitHandle" @close="closeQctablefileSelectDialog" isNotSelectWithNotUpload></qctablefile-select>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    <vxe-modal :title="fillModalTitle" v-model="fillModalVisible" :zIndex="1500" width="90%" height="80%" type="confirm" showFooter destroy-on-close confirm-button-text="提交" :before-hide-method="fillModalBeforeHideMethod">
      <fill ref="fill" @refreshDataList="getDataList"></fill>
    </vxe-modal>
  </el-card>
</template>

<script>
import QcfileSelect from './qcfile-select'
import QctablefileSelect from './qctablefile-select'
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import AddOrUpdate from './qcinspectiondata-supervise-add-or-update'
import XEUtils from 'xe-utils'
import Fill from './qcinspectiondata-fill'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/qcInspectionData/page',
        getDataListIsPage: true,
        deleteURL: '/mps/qcInspectionData',
        showEmptyDataWarningMessage: true
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
        wbsId: '',
        workstageId: '', // 工序ID
        typeId: '',  // 施工步骤ID
        sourceId: 2 // 1：施工，2：监理
      },
      treeShow: false,
      treeLoading: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: this.getNodeIsLeaf
      },
      currentSelectNode: null,
      qcfileSelectVisible: false, // 质检用表选择弹窗
      qctablefileSelectVisible: false, // 质检套表选择弹窗
      typeOptions: [{ // 施工步骤
        value: '1',
        label: '开工报告'
      }, {
        value: '2',
        label: '检验申请批复'
      }, {
        value: '3',
        label: '交工证书'
      }],
      fillModalVisible: false, // 检测值填报弹窗
      fillModalTitle: '', // 检测值填报弹窗标题
      msgInstance: null // 消息提示的实例
    }
  },
  components: {
    QcfileSelect,
    QctablefileSelect,
    AddOrUpdate,
    Fill
  },
  methods: {
    // 项目、标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.dataForm.wbsId = ''
      this.dataForm.workstageId = ''
      this.currentSelectNode = null

      this.getTreeData()
      this.dataList = []
    },
    getReportDate(row) {
      return row.ossUpdateDate ? XEUtils.toDateString(row.ossUpdateDate, 'yyyy-MM-dd') : ''
    },
    // 施工类型选择事件
    typeChange(value) {
      this.getDataList()
    },
    typeClear() {
      this.dataForm.typeId = ''
      this.getDataList()
    },
    // 加载左侧分部分项工程和工序树
    getTreeData() {
      this.treeLoading = true
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
        this.$http.get('/mps/qcInspectionData/children',
            {
              params: {
                wbsIsLeaf: false,
                wbsPid: '0',
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
          this.treeLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },

    loadNode(node, resolve) {
      if (node.level > 0) {
        let pid = node.data.wbsId
        this.$http.get('/mps/qcInspectionData/children',
            {
              params: {
                wbsPid: pid,
                wbsIsLeaf: node.data.wbsIsLeaf + '',
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },

    // 左侧分部分项工程和工序节点点击事件
    treeNodeClick(data) {
      this.currentSelectNode = data
      this.dataForm.wbsId = data.wbsId
      this.dataForm.workstageId = data.workstageId
      if (this.msgInstance) {
        this.msgInstance.close();
      }
      this.getDataList()
    },

    getNodeIsLeaf(data, node) {
      let rs = true
      if (data.wbsIsLeaf) {
        if (data.hasWorkstage) {
          rs = false
        }
      } else {
        if (!data.isWorkstage) {
          rs = false
        }
      }
      return rs
    },

    // 打开质检模板用表或质检套表 选择弹窗 type: 'file'=质检模板用表、‘table’=质检套表
    openQcFileSelectModal(type) {
      if (!this.currentSelectNode) {
        if (this.msgInstance) {
          this.msgInstance.close();
        }
        this.msgInstance = this.$message.warning("请先选择一个分部分项工程或工序")
        return this.msgInstance
      }
      if ('table' === type) { // 打开质检套表弹窗
        this.qctablefileSelectVisible = true
        this.$nextTick(() => {
          this.$refs.qctablefileSelect.dataForm.prjId = this.dataForm.prjId
          this.$refs.qctablefileSelect.init()
        })
      } else {  // 默认 打开质检模板用表弹窗
        this.qcfileSelectVisible = true
        this.$nextTick(() => {
          this.$refs.qcfileSelect.dataForm.prjId = this.dataForm.prjId
          this.$refs.qcfileSelect.init()
        })
      }
    },

    // 质检模板用表弹窗确认回调
    qcfileSelectSubmitHandle: debounce(function (selectedList) {
      if (selectedList && selectedList.length > 0) {
        const loading = this.$loading({
          text: '正在执行，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let qcfiles = []
        selectedList.forEach(item => {
          qcfiles.push({
            id: null,
            wbsId: this.dataForm.wbsId,
            workstageId: this.dataForm.workstageId,
            qcfileId: item.id,
            name: item.name,
            code: item.code,
            dsId: item.dsId, // 质检用表模板中绑定的数据源ID
            templateFileId: item.fileId,   // 质检用表模板上传的质检资料的附件ID
            typeId: item.typeId, // 施工步骤
            sourceId: this.dataForm.sourceId
          })
        })
        this.$http.post('/mps/qcInspectionData/batchSave', qcfiles).then(({data: res}) => {
          if (res.code !== 0) {
            loading.close()
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            })
          }
          this.$alert('操作成功！', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          this.getDataList()
          loading.close()
        })
      }
    }, 1000, {'leading': true, 'trailing': false}),

    // 质检套表弹窗确认回调
    qctablefileSelectSubmitHandle: debounce(function (selectedList) {
      if (selectedList && selectedList.length > 0) {
        const loading = this.$loading({
          text: '正在执行，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let qcfiles = []
        selectedList.forEach(item => {
          qcfiles.push({
            id: null,
            wbsId: this.dataForm.wbsId,
            workstageId: this.dataForm.workstageId,
            qcfileId: item.qcFileId,
            name: item.qcFileName,
            code: item.qcFileCode,
            dsId: item.dsId, // 质检用表模板中绑定的数据源ID
            templateFileId: item.fileId,   // 质检用表模板的质检资料的附件ID
            typeId: item.qcFileTypeId, // 施工步骤
            sourceId: this.dataForm.sourceId
          })
        })
        this.$http.post('/mps/qcInspectionData/batchSave', qcfiles).then(({data: res}) => {
          if (res.code !== 0) {
            loading.close()
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            })
          }
          this.$alert('操作成功！', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          this.getDataList()
          loading.close()
        })
      }
    }, 1000, {'leading': true, 'trailing': false}),

    closeQcfileSelectDialog() {
      this.qcfileSelectVisible = false
    },

    closeQctablefileSelectDialog() {
      this.qctablefileSelectVisible = false
    },

    // 打开在线文档
    openDocument(row) {
      if (this.isProcessExist(row.processStatus)) {
        // 流程存在 打开查看页面
        this.openDocumentEditor(row.pdfId, 'view')
      } else {
        // 流程不存在 打开编辑或查看页面
        let canEdit = this.$hasPermission('mps:qcinspectiondataconstruction:update')
        // 如果是“检验报告单”，则打开表格填报页面，否则打开onlyoffice编辑页面
        if (row.name.includes("检验报告单")) {
          if (canEdit) {
            this.openFillModal(row)
          } else {
            if (this.msgInstance) {
              this.msgInstance.close();
            }
            this.msgInstance = this.$message({
              message: '您暂无编辑权限!',
              type: 'warning'
            });
          }
        } else {
          if (canEdit) {
            this.openDocumentEditor(row.fileId, 'edit')
          } else {
            //  没有编辑权限，打开查看页面
            this.openDocumentEditor(row.fileId, 'view')
          }
        }
      }
    },
    // 打开检验表填报弹窗
    openFillModal(row) {
      this.fillModalVisible = true
      this.fillModalTitle = row.name
      this.$nextTick(() => {
        this.$refs.fill.qcInspectionDataId = row.id
        this.$refs.fill.init()
      })
    },
    fillModalBeforeHideMethod(event) {
      // 点击"提交"按钮时，调用组件内的提交方法
      if (event && event.type == 'confirm') {
        return new Promise((resolve, reject) => {
          this.$refs.fill.dataFormSubmitHandle(resolve, reject)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}
</style>
