<template>
  <el-dialog width="80%" v-dialog-drag :visible.sync="visible" title="安全生产费详情" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body v-on="$listeners">
    <el-button type="primary" size="mini" v-if="dataForm.processInstanceId" @click="showProcessDetail()">查看审批意见</el-button>
    <!-- 以下是业务表单内容 -->
    <div style="height: 5px"></div>
    <div class="mod-__safetycost">
      <vxe-table id="mxTable"
                 ref="mxTableRef"
                 :loading="mxDataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true, isHover: true}"
                 :data="dataForm.details"
                 :max-height="tableHeight">
        <vxe-column field="sortNo" title="序号" width="60" align="center" fixed="left"></vxe-column>
        <vxe-column field="name" title="费用条目" fixed="left"></vxe-column>
        <vxe-column field="money" title="本期发生金额（元）" width="160" align="right"></vxe-column>
        <vxe-column field="remark" title="备注说明"></vxe-column>
      </vxe-table>
      
      <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
        <!-- <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="期次" prop="periodName">
              <el-input v-model="dataForm.periodName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="标题" prop="name">
              <el-input v-model="dataForm.name" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows: 10 }" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="10">
          <el-col :span="24">
            <my-upload-plain v-if="myUploadVisible" :refId="dataForm.id" :tableMaxHeight="350" view></my-upload-plain>
          </el-col>
        </el-row>
      </el-form>
      
      <!-- 流程审批记录和流程图 -->
      <process-detail v-if="processDetailComponentVisible" ref="processDetail" @close="closeProcessDetailHandle()"></process-detail>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data () {
      return {
        visible: false,
        measId: '',
        dataForm: {
          id: '',
          contractId: '',
          periodId: '',
          name: '',
          processInstanceId: '',
          processStatus: '',
          sortNo: '',
          details: [],
          periodName: ''
        },
        mxDataListLoading: false,
        processDetailComponentVisible: false,
        tableHeight: 400,
        myUploadVisible: false
      }
    },
    created () {
      this.init()
    },
    mounted(){
      this.$nextTick(()=>{
        //  表格高度设置
        this.tableHeight = window.innerHeight-300
      })
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          // 通过measId获取periodId
          this.$http.get('/mps/meas/' + this.measId).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            // 通过periodId查询安全生产费详情
            this.getInfo(res.data.periodId)
          }).catch()
        })
      },
      // 获取信息
      getInfo (periodId) {
        this.mxDataListLoading = true
        this.$http.get('/opm/safetyCost/period/' + periodId).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.mxDataListLoading = false
          this.myUploadVisible = true
        }).catch(() => {
          this.mxDataListLoading = false
        })
      },
      showProcessDetail () {
        this.processDetailComponentVisible = true
        this.$nextTick(() => {
          this.$refs.processDetail.dataForm.processInstanceId = this.dataForm.processInstanceId
          this.$refs.processDetail.init()
        })
      },
      closeProcessDetailHandle () {
        this.processDetailComponentVisible = false
      },
    }
  }
</script>
