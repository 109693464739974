<template>
  <el-dialog :modal-append-to-body="true" :append-to-body="true" :visible.sync="visible" :title="title" v-on="$listeners" width="95%" top="30px">
    <el-row>
      <el-col :span="24">
        <div :style="[divStyle1]">
          <div id="luckysheet" style="margin:0px;padding:0px;position:absolute;width:100%;height:100%;left: 0px;"></div>
        </div>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="closeMe">{{ $t('cancel') }}</el-button>
    </template>
  </el-dialog>
</template>
 
<script>
import LuckyExcel from 'luckyexcel';
export default {
  name: "MyLuckysheetPreview",
  data() {
    return {
      visible: false,
      divStyle1: {
        width: '100%',
        height: '560px',
        border: '1px solid #ddd',
        position: 'relative'
      },
      title: '报表预览',
      jsonData: ''
    }
  },
  components: {
    LuckyExcel
  },
  methods: {
    initByUrl (url, title) {
      this.visible = true
      this.divStyle2.display ='none'
      this.divStyle1.height = `${(document.documentElement.clientHeight || document.body.clientHeight) - 200}px`
      this.title = title
      let _this = this

      _this.luckysheet_preview_loading = this.$loading({
        text: '正在加载报表数据...',
        spinner: 'el-icon-loading',
        body: true,
        target: document.querySelector('.testxxxxx'),
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$nextTick(() => {
        $(function () {
          LuckyExcel.transformExcelToLuckyByUrl(url, title, function (exportJson, luckysheetfile) {
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              _this.luckysheet_preview_loading.close()
              _this.divStyle2.display ='block'
              return
            }
            _this.divStyle2.display ='block'
            _this.luckysheet_preview_loading.close()
            window.luckysheet.destroy()
            window.luckysheet.create({
              container: 'luckysheet',
              allowCopy: false, // 是否允许拷贝
              showtoolbar: true, // 是否显示工具栏
              showinfobar: false, // 是否显示顶部信息栏
              showstatisticBar: false, // 是否显示底部计数栏
              sheetBottomConfig: false, // sheet页下方的添加行按钮和回到顶部按钮配置
              allowEdit: false, // 是否允许前台编辑
              enableAddRow: false, // 允许增加行
              enableAddCol: false, // 允许增加列
              userInfo: false, // 右上角的用户信息展示样式
              showRowBar: false, // 是否显示行号区域
              showColumnBar: false, // 是否显示列号区域
              sheetFormulaBar: false, // 是否显示公式栏
              enableAddBackTop: false,//返回头部按钮
              data: exportJson.sheets,
              title: title,
              lang: 'zh'
            })
          })
        })
      })
    },
    initByJson () {
      this.visible = true
      this.divStyle1.height = `${(document.documentElement.clientHeight || document.body.clientHeight) - 200}px`
      var _this = this
      this.$nextTick(() => {
        $(function () {
          let jsonData = eval("(" + _this.jsonData + ")").data
          if (jsonData && jsonData.length > 0) {
            // 隐藏网格线
            jsonData[0].showGridLines = 0
          }

          luckysheet.create({
            container: "luckysheet", // 设定DOM容器的id
            title: "Luckysheet", // 设定表格名称
            lang: "zh", // 设定表格语言
            allowEdit: false,
            data:  jsonData,
            // data: [
            //   {
            //     name: "sheet1", //工作表名称
            //     index: 0, //工作表索引(新增一个工作表时该值是一个随机字符串)
            //     status: 1, //激活状态
            //     order: 0, //工作表的下标
            //     hide: 0, //是否隐藏
            //     row: 36, //行数
            //     column: 15, //列数
            //     defaultRowHeight: 28, //自定义行高,单位px
            //     defaultColWidth: 100, //自定义列宽,单位px
            //     celldata: [], //初始化使用的单元格数据,r代表行，c代表列，v代表该单元格的值，最后展示的是value1，value2
            //     isPivotTable: false, //是否数据透视表
            //     zoomRatio: 1, // 缩放比例
            //     showGridLines: 1, //是否显示网格线
            //   },
            // ],
            showtoolbar: false,
            showsheetbar: false, // 是否显示底部sheet页按钮
            showinfobar: false, // 是否显示顶部信息栏
            showstatisticBar: false, // 是否显示底部计数栏
            sheetFormulaBar: false, // 是否显示公式栏
            allowCopy: true, // 是否允许拷贝
            enableAddRow: false, // 允许添加行
            enableAddBackTop: false // 回头顶部
          })
        })
      })
    },
    closeMe () {
      this.visible = false
      luckysheet.exitEditMode()
    }
  }
}
</script>
<style>
  .luckysheet-cols-menu {z-index: 9004;}
  .luckysheet-input-box {z-index: 3000}
  .rlit {
    font-size: 14px; padding-bottom: 2px;
  }
</style>