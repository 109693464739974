<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__safetycost">
      <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="期次" prop="periodName">
              <el-input v-model="dataForm.periodName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="标题" prop="name">
              <el-input v-model="dataForm.name" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows: 10 }" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <my-upload-plain :refId="dataForm.id" :tableMaxHeight="350" view></my-upload-plain>
          </el-col>
        </el-row>
      </el-form>
      <vxe-table id="mxTable"
                 ref="mxTableRef"
                 :loading="mxDataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataForm.details"
                 :max-height="tableHeight">
        <vxe-column field="sortNo" title="序号" width="60" align="center" fixed="left"></vxe-column>
        <vxe-column field="name" title="费用条目" fixed="left"></vxe-column>
        <vxe-column field="money" title="本期发生金额（元）" width="160" align="right"></vxe-column>
        <vxe-column field="remark" title="备注说明"></vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<script>
  import processModule from '@/mixins/process-module'
  export default {
    mixins: [processModule],
    data () {
      return {
        dataForm: {
          id: '',
          contractId: '',
          periodId: '',
          name: '',
          processInstanceId: '',
          processStatus: '',
          sortNo: '',
          details:[],
          periodName:''
        },
        mxDataListLoading: false,
        tableHeight:400,
      }
    },
    created () {
      this.init()
    },
    mounted(){
      this.$nextTick(()=>{
        //  表格高度设置
        this.tableHeight=window.innerHeight-300
      })
    },
    methods: {
      init () {
        //  通过流程获取业主主表ID
        this.dataForm.id = this.$route.params.businessKey
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          }
        })
      },
      // 获取信息
      getInfo () {
        this.mxDataListLoading = true
        this.$http.get('/opm/safetyCost/' + this.dataForm.id).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.mxDataListLoading = false
        }).catch(() => {
          this.mxDataListLoading = false
        })
      }
    }
  }
</script>
