<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree ref="wbsTree" lazy
                   class="left-tree" node-key="id" v-loading="wbsTreeLoading"
                   :data="wbsTreeData"
                   :props="defaultProps"
                   :load="loadWbsNode"
                   :expand-on-click-node="false"
                   @node-click="wbsTreeNodeClick"
                   :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__scheduleprogress">
          <el-button-group>
            <toolbar-button name="选取文件" icon="el-icon-thumb" v-if="$hasPermission('opm:ifc:upload')" @click="openUploadPopup('uploadModel')"></toolbar-button>
            <toolbar-button name="上传到服务器" icon="el-icon-upload2" v-if="$hasPermission('opm:ifc:upload')" @click="submitUpload"></toolbar-button>
            <toolbar-button name="自动解析模型并关联分部分项" icon="el-icon-connection" v-if="$hasPermission('opm:ifc:upload')" @click="ifcWbsCodePropNameVisible = true"></toolbar-button>
          </el-button-group>
          <!-- <span style="color: #888888;font-size: small"> 支持的ifc标准：Ifc2x3tc1</span> -->
          <el-upload ref="uploadModel" accept=".ifc" :action="uploadUrl"
                     :file-list="fileList"
                     :on-change="handleUploadChange"
                     :on-progress="onProgressHandle"
                     :on-success="handleSuccess"
                     :show-file-list="true" :auto-upload="false">
          </el-upload>
          <div id="threeContainer" style="width: 1000px; height: 500px">
            <!-- 模型加载进度 -->
            <div id="progress-text-container" style="position: absolute; top: 0; right: 100px; display: none; color: red; font-weight: bold; font-size: 14px;">
              <p>加载进度：<span id="progress-text">0</span> %</p>
            </div>
          </div>
        </div>
      </template>
    </my-container>
    <el-dialog :visible.sync="ifcWbsCodePropNameVisible" title="请填写关联分部分项编码的属性名" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-input v-model="ifcWbsCodePropName"></el-input>
      <template slot="footer">
        <el-button @click="ifcWbsCodePropNameVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="parseWbsCode()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <!-- 构件属性 -->
    <ifc-prop v-if="ifcPropVisible" ref="ifcProp" @hide="closeIfcPropHandle"></ifc-prop>
  </el-card>
</template>
<!-- 基于IFC.js实现的针对IFC文件的解析和3D浏览 -->
<script>
  import Cookies from 'js-cookie'
  export default {
    data () {
      return {
        dataForm: {
          prjId: '',
          contractId: '',
          wbsId: '',
        },

        wbsTreeLoading: false,
        wbsTreeData: [],
        defaultProps: {
          children: 'children',
          label: 'name',
          isLeaf: 'isLeaf'
        },

        // 模型文件上传
        fileList: [],
        typeId: 'ifc',
        uploadUrl: '',

        loading: null,
        // 该弹窗用于填写ifc模型中代表分部分项工程编码的模型属性名称
        ifcWbsCodePropNameVisible: false,
        ifcWbsCodePropName: '分部分项工程编码',
        ifcUrl: '', // 加载ifc时的文件路径
        ifcPropVisible: false,
        ifcInstance: null
      }
    },
    mounted () {
      this.$nextTick(() => {
        let containerWidth = window.innerWidth - 580
        let containerHeight = window.innerHeight - 180
        if (containerWidth < 900) {
          containerWidth = 900
        }
        if (containerHeight < 450) {
          containerHeight = 450
        }
        document.getElementById('threeContainer').style.width = containerWidth + 'px'
        document.getElementById('threeContainer').style.height = containerHeight + 'px'

        this.ifcInstance = new threeIfcScene.Instance({
          ele: 'threeContainer',
          width: containerWidth,
          height: containerHeight,
          onProgressCallback: this.ifcProgressCallback,
          startLoading: (txt)=>{
            this.loading = this.$loading({
              lock: true,
              text: txt,
              spinner: 'el-icon-loading',
              customClass: 'my-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
          },
          stopLoading: ()=>{
            this.loading.close()
          },
          onPickCallback: (expressId, props, propSets)=>{
            this.openPropModal(expressId, props, propSets)
          }
        });
        this.ifcInstance.releaseMemory()
      })
    },
    async beforeDestroy () {
      await this.ifcInstance.releaseMemory()
    },
    methods: {
      // 合同标段切换事件
      contractChange (data) {
        this.dataForm.wbsId = ''
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId

        this.fileList = []
        this.getWbsTreeData()
      },

      //异步加载wbs子节点数据
      loadWbsNode (node,resolve) {
        if (node.level >= 1) {
          if (node && node.data) {
            let wbsData = node.data;
            if (wbsData.id == '-1') {
              return resolve([])
            } else {
              this.$http.get('/mps/wbs/children',
                {
                  params: {
                    pid: wbsData.id,
                    contractId: wbsData.contractId,
                    subcontractorId: wbsData.subcontractorId
                  }
                }
              ).then(({data: res}) => {
                if (res.code !== 0) {
                  return this.$message.error(res.msg)
                }
                resolve(res.data);
              }).catch(() => {
                return this.$message.error('出错了')
              })
            }
          } else {
            return resolve([])
          }
        }
      },

      getWbsTreeData () {
        this.wbsTreeLoading = true
        // 清空wbs数据
        this.wbsTreeData = []
        this.$http.get('/mps/wbs/children',
          {
            params: {
              'pid': 0,
              'contractId': this.dataForm.contractId
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.wbsTreeData = res.data || []
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.wbsTreeLoading = false
        })
      },

      //  分部分项树节点点击事件
      async wbsTreeNodeClick (data,node,ele) {
        this.fileList = []
        this.dataForm.wbsId = data.id
        this.loading = this.$loading({
          text: '',
          spinner: "aircity-loading",
          customClass: 'aircity-loading',
          background: 'rgba(0, 0, 0, 0.4)'
        })
        // 清空场景
        await this.ifcInstance.releaseMemory()
        // 从服务器获取wbs最新的ifc文件地址URL
        let {data: res} = await this.$http.get(`/mps/wbs/${this.dataForm.wbsId}?withIfcUrl=1`)
        if (res.code !== 0) {
          this.loading.close()
          return this.$message.error(res.msg)
        }
        let ifcUrl = res.data ? res.data.ifc : ''
        if (!ifcUrl) {
          this.loading.close()
          return this.$message.warning("未上传模型！")
        } else {
          // 查询wbs和ifc的关系
          let wbsIfcData = null
          let {data: res} = await this.$http.get(`/mps/wbsIfc?wbsId=${this.dataForm.wbsId}`)
          if (res.code === 0) {
            wbsIfcData = res.data
          }
          // 渲染模型
          await this.renderModel(ifcUrl, wbsIfcData)
        }
      },

      // 打开附件上传文件框
      openUploadPopup (refUploadName) {
        this.checkRefIdCreated()
        if (this.checkRefIdCreated() && refUploadName) {
          // 触发upload组件内部点击事件，弹出文件选择框
          this.$refs[refUploadName].$refs['upload-inner'].handleClick()
        }
      },

      // 模型上传前,检查wbs是否已选择
      checkRefIdCreated () {
        if (!this.dataForm.wbsId) {
          this.$message.warning('请先选择左侧的分部分项工程!')
          return false
        } else {
          return true
        }
      },

      handleUploadChange (file, fileList) {
        fileList.forEach((item, index) => {
          // 上传文件格式限定.ifc格式
          let endName = item.raw.name.substring(item.raw.name.lastIndexOf("."))
          if (endName != '.ifc') {
            this.$message.error('上传的文件格式必须是.ifc格式!')
            fileList.splice(index,1);
          }
          // 上传文件大小限定
          let imgSize = Number(item.size / 1024 / 1024);
          if (imgSize > 500) {
            this.$message.error('文件不能超过500MB，请将模型拆分后上传!')
            fileList.splice(index,1);
          }
        })
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]]
        }
        this.uploadUrl = this.getUrl(this.dataForm.wbsId, this.typeId)
      },

      // 上传到服务器
      submitUpload () {
        if (this.fileList && this.fileList.length > 0) {
          // 上传文件到服务器
          this.$refs.uploadModel.submit()
        } else {
          this.$message.warning("请先选择ifc文件！")
        }
      },

      getUrl (refId,typeId) {
        return `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}&refId=${refId}&typeId=${typeId}&statusId=1`
      },

      // 上传中，显示loading
      onProgressHandle (event,file,fileList) {
        this.loading = this.$loading({
          lock: true,
          text: '正在上传，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      },

      // 上传ifc文件成功后
      async handleSuccess (res,file,fileList) {
        if (this.loading) {
          this.loading.close()
        }
        this.loading = this.$loading({
          text: '',
          spinner: "aircity-loading",
          customClass: 'aircity-loading',
          background: 'rgba(0, 0, 0, 0.4)'
        })
        // ifc文件地址
        let ifcUrl = file.response.data.url;
        // 级联更新该wbs节点以子节点的ifc字段的值为最新上传的ifc文件路径
        await this.$http['put']('/mps/wbs/cascadeUpdateIfc',{
          id: this.dataForm.wbsId,
          ifc: ifcUrl
        })
        // 清空场景
        await this.ifcInstance.releaseMemory()
        // 渲染模型
        await this.renderModel(ifcUrl, null)
        this.fileList = []
      },

      /**
       * 渲染模型
       * @param ifcUrl ifc文件路径
       * @param wbsIfcData wbs和ifc的关系
       */
      async renderModel (ifcUrl, wbsIfcData) {
        this.ifcUrl = ifcUrl
        if (ifcUrl) {
          // 获取ifc文件地址的签名URL
          let ifcSignedUrl = await this.getIfcSignedUrl(ifcUrl)
          if(ifcSignedUrl) {
            await this.ifcInstance.loadIfc(ifcSignedUrl, wbsIfcData, ()=>{
              if (this.loading) {
                this.loading.close()
              }
            })
          } else {
            if (this.loading) {
              this.loading.close()
            }
          }
        } else {
          if (this.loading) {
            this.loading.close()
          }
        }
      },

      // 获取ifc文件地址的签名URL
      async getIfcSignedUrl (ifcUrl) {
        let {data: res} = await this.$http.get('/oss/file/signedUrl?url=' + ifcUrl)
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return null
        } else {
          return res.data
        }
      },
      // 解析ifc模型遍历元素，从扩展属性中查找wbs编码，并建立元素和wbs的关系
      async parseWbsCode () {
        this.ifcWbsCodePropNameVisible = false
        this.loading = this.$loading({
          lock: true,
          text: '正在解析模型，并自动匹配分部分项工程。',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.1)'
        })
        await this.ifcInstance.parseWbsCode(this.dataForm.prjId, this.ifcUrl, this.ifcWbsCodePropName, this.loading, (submitData, totalCount, minutes)=>{
          if (submitData && submitData.length > 0) {
            this.$http.post('/mps/wbsIfc', submitData).then(({ data: res }) => {
              if (res.code !== 0) {
                if (this.loading) {
                  this.loading.close()
                }
                return this.$message.error(res.msg)
              }
              this.$alert(`耗时${minutes}分，共解析${totalCount}个属性，其中${submitData.length}个与分部分项建立关联。`, '已完成解析', {
                confirmButtonText: '确定'
              })
            }).catch((e) => {
              this.$message.error('出现异常：' + e)
            }).finally(() => {
              if (this.loading) {
                this.loading.close()
              }
            })
          } else {
            if (this.loading) {
              this.loading.close()
            }
            this.$alert(`耗时${minutes}分，共解析${totalCount}个属性，0个与分部分项建立关联，请确认模型中是否设置了分部分项工程编码。`, '已完成解析', {
              confirmButtonText: '确定'
            })
          }
        })
      },
      // 打开构件属性面板
      openPropModal(expressId, props, propSets) {
        this.ifcPropVisible = true
        this.$nextTick(() => {
          this.$refs.ifcProp.init(this.dataForm.prjId, expressId, this.ifcUrl, props, propSets)
        })
      },
      closeIfcPropHandle () {
        this.ifcPropVisible = false
      },
      ifcProgressCallback (progress) {
        if (progress == '100') {
            setTimeout(() => {
                document.getElementById('progress-text').innerText = progress.toString();
                document.getElementById('progress-text-container').style.display = 'none'
            }, 3000);
        } else {
            document.getElementById('progress-text').innerText = progress.toString();
            document.getElementById('progress-text-container').style.display = 'block'
        }
      }
    }
  }
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 150px);
  }
</style>