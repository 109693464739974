<template>
  <el-dialog :visible.sync="visible" :title="dataForm.title" v-on="$listeners">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.name" placeholder="报表名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">{{ $t('query') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border @selection-change="dataListSelectionChangeHandle" style="width: 100%">
      <el-table-column prop="name" label="报表名称" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="designHandle(scope.row)">设计</el-button>
          <el-button type="danger" size="mini" @click="myDeleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="临时按钮" fixed="right" header-align="center" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showExcelReport(scope.row)">下载报表实例excel</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
    <!-- 计量报表设计 -->
    <my-luckysheet-design v-if="luckysheetVisible" ref="luckysheet" @saveData="saveLuckySheetDataHandle" @close="closeLuckySheetHandle"></my-luckysheet-design>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import MyLuckysheetDesign from '@/components/my-luckysheet-design'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/reportBinding/briefs',
        getDataListIsPage: false,
        deleteURL: '/mps/reportBinding',
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      visible: false,
      dataList: [],
      luckysheetVisible: false,
      dataForm: {
        name: '',
        refId: '',
        title: '计量报表'
      }
    }
  },
  components: {
    MyLuckysheetDesign
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.refId) {
          this.getDataList()
        }
      })
    },
    getDataList () {
      this.$http.get('/mps/reportBinding/briefs', {
        params: {
          refId: this.dataForm.refId,
          name: this.dataForm.name
        }
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {
        // do nothing
      })
    },
    deleteSuccessCallback() {
      this.getDataList()
    },
    // 删除
    myDeleteHandle (id) {
      this.deleteHandle(id, {promptMessage: '将同时删除各期计量已生成的此项报表，确定继续吗？', autoQuery: false})
    },
    // 打开luckysheet报表设计窗口
    designHandle (row) {
      const loading = this.$loading({
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/reportBinding/jsonData/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.luckysheetVisible = true
        this.$nextTick(() => {
          loading.close()
          this.$refs.luckysheet.refId = row.id
          this.$refs.luckysheet.title = row.name
          this.$refs.luckysheet.jsonData = res.data
          this.$refs.luckysheet.initLuckysheet()
        })
      }).catch(() => {
        loading.close()
      })
    },
    // 关闭计量报表设计窗口
    closeLuckySheetHandle () {
      this.luckysheetVisible = false
    },
    // 保存设计的luckysheet的jsonData，同时重新生成Excel模板文件（用于EasyExcel模板填充）
    saveLuckySheetDataHandle (id, jsonData) {
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.put(`/mps/reportBinding/jsonData/${id}`, {data: jsonData}).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        // 重新生成excel模板文件
        loading.close()
        this.generateExcelTemplate(id)
      }).catch(() => {
        loading.close()
      })
    },
    
    // 生成excel文件，并存储到minio
    generateExcelTemplate (id) {
      let _this = this
      const loading2 = this.$loading({
        text: '正在重新生成报表模板...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(`/mps/reportBinding/generateExcel/${id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading2.close()
          return this.$message.error(res.msg)
        }
        loading2.close()
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1500,
          onClose: () => {
            _this.luckysheetVisible = false
          }
        })
      }).catch(() => {
        loading2.close()
      })
    },
    // 打开计量报表实例的Excel文件
    showExcelReport (row) {
      // TODO 该处的计量ID参数，日后需改成动态获取
      let measId = 0
      this.$http.get(`/mps/measReport/${measId}/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.excelUrl}`)
        .then(({ data: res }) => {
          if (res.code === 0) {
            window.open(res.data)
          }
        }).catch(() => {
          // do nothing
        })
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
