<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
            class="left-tree"
            v-loading="treeLoading"
            :data="treeData"
            node-key="id"
            :props="defaultTreeProps"
            ref="myTree"
            :expand-on-click-node="false"
            :highlight-current="true"
            default-expand-all
            @node-click="treeNodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseEnter(data,node)" @mouseleave="mouseLeave(data,node)">
              <span>{{ node.label }}</span>
              &nbsp;
              <span>
                <el-button v-if="$hasPermission('opm:weeklyschedule:update')" v-show="data.show" type="text" size="mini" @click.stop="() => updateHandle(data.id)">
                  修改
                </el-button>
                <el-button v-if="$hasPermission('opm:weeklyschedule:delete')" v-show="data.show" type="text" size="mini" @click.stop="() => mainDeleteHandle(data)">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__weeklyschedule">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="add" v-if="$hasPermission('opm:weeklyschedule:save')" @click="addHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('opm:weeklyschedule:delete')" @click="deleteHandle()"></toolbar-button>
                <toolbar-button name="查看甘特图" icon="el-icon-c-scale-to-original" @click="openGanttHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column prop="wbsName" label="分部分项" fixed="left" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.wbsPnames?scope.row.wbsPnames+','+ scope.row.wbsName:scope.row.wbsName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="wbsUnitName" label="单位" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="num" label="计划工程量" width="120" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startDate" label="计划开始" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="endDate" label="计划结束" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="duration" label="工期(天)" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('opm:weeklyschedule:update')" @click="mxUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('opm:weeklyschedule:delete')" @click="deleteHandle(row.id)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <my-add v-if="myAddVisible" ref="myAdd" @refreshDataList="refreshDataList" @close="closeMyAddOrUpdateDialogHandle"></my-add>
          <my-update v-if="myUpdateVisible" ref="myUpdate" @refreshDataList="refreshDataList" @close="closeMyAddOrUpdateDialogHandle"></my-update>
          <my-mx-update v-if="myMxUpdateVisible" ref="myMxUpdate" @refreshDataList="getDataList" @close="closeMyAddOrUpdateDialogHandle"></my-mx-update>
          <!-- 甘特图 -->
          <my-gantt v-if="myGanttVisible" ref="myGantt"></my-gantt>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import MyAdd from './weeklyschedule-add'
import MyMxUpdate from './weeklyschedulemx-update'
import MyUpdate from './weeklyschedule-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/weeklyScheduleMx/page',
        getDataListIsPage: true,
        deleteURL: '/opm/weeklyScheduleMx',
        createdIsNeed: false
      },
      dataForm: {
        prjId: '',
        contractId: '',
        mainId: '', // 进度周计划主表ID
        y: '', // 年份
        m: ''  // 月份
      },
      prefix: "my-",
      lastSelectedTreeId:'',

      treeLoading: false,
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'name'
      },
      myAddVisible: false,
      myMxUpdateVisible: false,
      myUpdateVisible: false,
      myGanttVisible: false,
      // 甘特图配置
      tasks: {
        data: [],
      },
    }
  },
  watch: {},
  components: {
    MyAdd,
    MyMxUpdate,
    MyUpdate,
  },
  methods: {
    // 合同标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.refreshDataList()
    },

    refreshDataList () {
      this.dataForm.mainId = ''
      this.dataForm.y = ''
      this.dataForm.m = ''
      this.getDataList()
      this.loadTreeData()
    },
    // 加载进度周计划数据
    loadTreeData () {
      this.treeLoading = true
      // 清空周计划数据
      this.treeData = []
      this.$refs.myTree.store.nodesMap = []

      this.$http.get('/opm/weeklySchedule/list',
        {
          params: {
            'contractId': this.dataForm.contractId
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let resultData = res.data || []
        if (resultData.length <= 0) {
          this.treeData = resultData
        } else {
          // 前端拼接树形结构 按照年月分组的树形数据结构
          let myTree = this.$refs.myTree
          for (let i = 0; i < resultData.length; i++) {
            let data = resultData[i]
            data['name'] = data['startDate'] + " 至 " + data['endDate']
            data['children'] = []
            let y = data['y'] ? data['y'] + '' : ''
            let m = data['m'] ? data['m'] + '' : ''
            let yId = this.prefix + y
            let mId = this.prefix + y + m
            let yearNode = myTree.getNode(yId)
            if (!yearNode) {
              myTree.append({
                id: yId,
                name: y + " 年",
                y: y,
                m: '',
                children: []
              }, null)
            }
            if (m) {
              let monthNode = myTree.getNode(mId)
              if (!monthNode) {
                myTree.append({
                  id: mId,
                  name: m + " 月",
                  y: y,
                  m: m,
                  children: []
                }, yId)
              }
              myTree.append(data, mId)
            } else {
              myTree.append(data, yId)
            }
          }
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    //  进度周计划节点点击事件
    treeNodeClick (data,node,ele) {
      // 点击高亮节点则取消高亮
      if (this.lastSelectedTreeId==(data.id+this.dataForm.contractId)) {
        this.lastSelectedTreeId=''
        this.dataForm.mainId = ''
        this.dataForm.y = ''
        this.dataForm.m = ''
        this.$refs.myTree.setCurrentKey(null)
      } else {
        // 根据当前点击的节点，设置查询条件
        if (data.id.indexOf(this.prefix) != -1) {
          // 按年月查询
          this.dataForm.mainId = ''
          this.dataForm.y = data.y
          this.dataForm.m = data.m
        } else {
          // 按主表ID查询
          this.dataForm.mainId = data.id
          this.dataForm.y = ''
          this.dataForm.m = ''
        }
        this.lastSelectedTreeId=data.id+this.dataForm.contractId
      }
      // 查询数据
      this.getDataList()
    },

    // 新增 周计划
    addHandle () {
      this.myAddVisible = true
      this.$nextTick(() => {
        this.$refs.myAdd.dataForm.id = ''
        this.$refs.myAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.myAdd.init()
      })
    },

    closeMyAddOrUpdateDialogHandle () {
      this.myAddVisible = false
      this.myMxUpdateVisible = false
      this.myUpdateVisible = false
    },

    // 周计划明细修改
    mxUpdateHandle (id) {
      this.myMxUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.myMxUpdate.dataForm.id = id
        this.$refs.myMxUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.myMxUpdate.init()
      })
    },

    // 周计划 修改
    updateHandle (id) {
      this.myUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.myUpdate.dataForm.id = id
        this.$refs.myUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.myUpdate.init()
      })
    },

    // 周计划 删除
    mainDeleteHandle (data) {
      this.$confirm(`确定删除 ${data.startDate} 至 ${data.endDate} 的周计划吗？`,this.$t('prompt.title'),{
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(`/opm/weeklySchedule`,
          {
            'data': [data.id]
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.refreshDataList()
            }
          })
        }).catch()
      }).catch()
    },
    mouseEnter (data,node) {
      // 叶子节点才显示
      if (node.isLeaf) {
        this.$set(data,'show',true)
      }
    },
    mouseLeave (data,node) {
      if (node.isLeaf) {
        this.$set(data,'show',false)
      }
    },
    // 打开甘特图组件
    openGanttHandle () {
      this.tasks.data = []
      // 查询周计划并组装为dhtmlx-gantt所需的数据格式
      this.$http.get('/opm/weeklyScheduleMx/gantt',
        {
          params: {
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tasks.data = res.data || []
        this.myGanttVisible = true
        this.$nextTick(() => {
          this.$refs.myGantt.init(this.tasks, true)
        })
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        // do nothing
      })
    },
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
  }
  ::v-deep .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>