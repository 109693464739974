<template>
  <vxe-modal :zIndex="1500" v-model="visible" width="80%" :title="!dataForm.id ? $t('add') : $t('update')" destroy-on-close showFooter v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="项目名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="项目编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目简称" prop="shortName">
            <el-input v-model="dataForm.shortName" placeholder="项目简称" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目类型" prop="typeId">
              <el-select v-model="dataForm.typeId" placeholder="请选择项目类型" :style="{ width: '100%' }">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建设单位" prop="construction">
            <el-input v-model="dataForm.construction" placeholder="建设单位"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="deptName" label="项目公司">
            <dept-tree v-model="dataForm.deptId" placeholder="请选择公司" :dept-name.sync="dataForm.deptName"></dept-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目地址" prop="address">
            <el-input v-model="dataForm.address" placeholder="项目地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="投资总额" prop="totalCost">
            <el-input placeholder="总投资(万元)" v-model="dataForm.totalCost">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建安费用" prop="ceCost">
            <el-input placeholder="建安费(万元)" v-model="dataForm.ceCost">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="计划工期" prop="months">
            <el-input placeholder="计划工期(月)" v-model="dataForm.months">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" placeholder="起讫桩号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="开工日期" prop="startDate">
            <el-date-picker style="width: 100%" v-model="dataForm.startDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择开工日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="完工日期" prop="endDate">
            <el-date-picker style="width: 100%" v-model="dataForm.endDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择完工日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="建设里程" prop="km">
            <el-input placeholder="公里数" v-model="dataForm.km">
              <template slot="append">公里</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建筑面积" prop="area">
            <el-input placeholder="建筑面积" v-model="dataForm.area">
              <template slot="append">平米</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="项目概况" prop="remark">
        <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 2 }"></el-input>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目状态" prop="statusId">
            <el-select v-model="dataForm.statusId" clearable placeholder="请选择项目状态">
              <el-option :value="1" label="建设中"></el-option>
              <el-option :value="2" label="已完工"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序" style="width: 100%"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8" style="text-align: center;">
          <el-button style="margin-bottom: 5px" type="success" size="mini" icon="el-icon-map-location" @click="openPositionMap">选择项目坐标位置</el-button>
        </el-col>
        <el-col :span="8">
          <el-form-item label="经度" prop="longitude">
            <el-input v-model="dataForm.longitude" placeholder="项目坐标的经度" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="纬度" prop="latitude">
            <el-input v-model="dataForm.latitude" placeholder="项目坐标的经度" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="数字孪生工程ID" prop="dtsPid">
            <el-input v-model="dataForm.dtsPid" placeholder="三维数字孪生平台的工程ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="数字孪生默认项目" prop="dtsDefault">
            <el-switch v-model="dataForm.dtsDefault" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="数字孪生标注高度" prop="dtsZ">
            <el-input v-model="dataForm.dtsZ" placeholder="marker标注默认的z值"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="启用数字证书" prop="digitalCertificate">
            <el-checkbox v-model="dataForm.digitalCertificate" :true-label="1" :false-label="0">启用数字证书</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <baidu-map-select v-if="positionMapVisible"
        :longitude="dataForm.longitude"
        :latitude="dataForm.latitude"
        @close="closeMapDialogHandle" @setCoordinate="setProjectCoordinate">
    </baidu-map-select>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </vxe-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import {checkNumber, checkInt} from '@/utils/form-validate'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        tenantCode: '',
        deptId: '',
        deptName: '',
        name: '',
        shortName: '',
        code: '',
        typeId: '',
        months: null,
        construction: '',
        address: '',
        totalCost: null,
        ceCost: null,
        sspo: '',
        startDate: '',
        endDate: '',
        sortNo: null,
        remark: '',
        dtsPid: '',
        dtsDefault: '',
        dtsZ: '',
        km: '',
        area: '',
        statusId: '',
        digitalCertificate: '', // 是否启用数字证书
      },
      dateFormat: 'yyyy 年 MM 月 dd 日',
      positionMapVisible: false,
      typeOptions: [{ // 项目类型
        value: 1,
        label: '高速公路'
      }, {
        value: 2,
        label: '一级公路'
      }, {
        value: 3,
        label: '二级公路'
      },{
        value: 99,
        label: '其他'
      }],
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        deptName: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        shortName: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        typeId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        construction: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        totalCost: [
          {validator: checkNumber, trigger: 'change'}
        ],
        ceCost: [
          {validator: checkNumber, trigger: 'change'}
        ],
        months: [
          {validator: checkInt, trigger: 'change'}
        ],
        area: [
          {validator: checkNumber, trigger: 'change'}
        ],
        km: [
          {validator: checkNumber, trigger: 'change'}
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/project/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    openPositionMap() {
      this.positionMapVisible = true
    },
    // 接收地图弹窗中选择的坐标
    setProjectCoordinate(data) {
      this.dataForm.longitude = data.lng
      this.dataForm.latitude = data.lat
    },
    closeMapDialogHandle() {
      this.positionMapVisible = false
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/project/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }

          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch()
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>
<style>
/*计数器 样式调整*/
.el-input-number .el-input__inner {
  text-align: left !important;
}
</style>