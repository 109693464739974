<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="180">
      <template v-slot:left>
        <div class="tree-container">
          <el-tree class="left-tree" v-loading="treeLoading" node-key="id" ref="myTree"
              :data="treeData"
              :props="defaultTreeProps"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="treeNodeClick" default-expand-all>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__othercontract">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.name" placeholder="合同名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.code" placeholder="合同编号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('mps:othercontract:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:othercontract:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
            <el-table-column prop="name" label="合同名称" min-width="280" sortable="custom" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="合同编号" width="160" sortable="custom" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="typeName" label="合同类型" width="150" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="cost" label="合同金额（元）" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="firstParty" label="甲方" width="150" header-align="center" align="left" sortable="custom" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="signDate" label="签订日期" width="110" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="150">
              <template v-slot="{row}">
                <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:othercontract:update') && checkSelectable(row)"></table-button>
                <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:othercontract:delete') && checkSelectable(row)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:othercontract:update') || !checkSelectable(row))"></table-button>
              </template>
            </el-table-column>
            <el-table-column prop="processStatus" label="流程状态" fixed="right" header-align="center" align="center" width="120" sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './othercontract-add-or-update'
  import ViewPage from './othercontract-view'
  import processModule from '@/mixins/process-module'

  export default {
    mixins: [mixinViewModule,processModule],
    data () {
      return {
        mixinViewModuleOptions: {
          getDataListURL: '/mps/otherContract/page',
          getDataListIsPage: true,
          exportURL: '/mps/otherContract/export',
          deleteURL: '/mps/otherContract',
          createdIsNeed: false
        },
        dataForm: {
          prjId: '',
          code: '',
          name: '',
          typeId: ''
        },
        treeLoading: false,
        treeData: [],
        defaultTreeProps: {
          children: 'children',
          label: 'name'
        },
      }
    },
    created: function () {
      this.treeData = []
      this.treeData.push({
        name: '全部合同',value: '-1',children: [
          {name: '设计合同',value: '1'},
          {name: '其他合同',value: '9'}
        ]
      })
    },
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      prjChangeHandle (data) {
        this.getDataList()
      },
      // 查看
      viewHandle (id) {
        this.viewVisible = true
        this.$nextTick(() => {
          this.$refs.view.dataForm.id = id
          this.$refs.view.dataForm.prjId = this.dataForm.prjId
          this.$refs.view.init()
        })
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        if (this.dataForm.prjId) {
          this.addOrUpdateVisible = true
          this.$nextTick(() => {
            this.$refs.addOrUpdate.dataForm.id = id
            this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
            this.$refs.addOrUpdate.init()
          })
        } else {
          this.$message({
            message: '请先选择一个项目！',
            type: 'warning',
            duration: 3000,
          })
        }
      },
      //  左侧合同类型节点点击事件
      treeNodeClick (data,node,ele) {
        // 点击高亮节点则取消高亮
        if (this.dataForm.typeId == data.value) {
          this.dataForm.typeId = ''
          this.$refs.myTree.setCurrentKey(null)
        } else {
          this.dataForm.typeId = data.value
        }
        // 查询数据
        this.getDataList()
      },
    }
  }
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 140px);
  }
</style>