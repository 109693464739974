<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__sheettemplate">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" @click="addOrUpdateHandle()" v-if="$hasPermission('mps:sheettemplate:save')"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()" v-if="$hasPermission('mps:sheettemplate:delete')"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="200" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="150" sortable="custom" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="sortNo" label="排序" width="150" header-align="center" align="center" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="design" @click="designHandle(row)" v-if="$hasPermission('mps:sheettemplate:update')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:sheettemplate:update')"></table-button>
            <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:sheettemplate:delete')"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], true, row.name)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- luckysheet设计界面 -->
      <my-luckysheet-design v-if="luckysheetVisible" ref="luckysheet" @saveData="saveSheetDataHandle" @close="closeLuckysheetHandle"></my-luckysheet-design>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './sheettemplate-add-or-update'
import ViewPage from './sheettemplate-view'
import MyLuckysheetDesign from '@/components/my-luckysheet-design'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/sheetTemplate/page',
        getDataListIsPage: true,
        deleteURL: '/mps/sheetTemplate',
      },
      dataForm: {
        prjId: '',
        name: '',
      },
      luckysheetVisible: false
    }
  },
  components: {
    AddOrUpdate,
    ViewPage,
    MyLuckysheetDesign
  },
  methods: {
    prjChangeHandle(data) {
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      if (this.dataForm.prjId) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.init()
        })
      } else {
        this.$message({
          message: '请先选择一个项目！',
          type: 'warning',
          duration: 3000,
          onClose: () => {
            // do nothing
          }
        })
      }
    },
    designHandle(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/sheetTemplate/json/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.luckysheetVisible = true
        this.$nextTick(() => {
          loading.close()
          this.$refs.luckysheet.refId = row.id
          this.$refs.luckysheet.title = row.name
          this.$refs.luckysheet.jsonData = res.data
          this.$refs.luckysheet.initLuckysheet()
        })
      }).catch(() => {
        loading.close()
      })
    },
    // 保存luckysheet的sheetData
    saveSheetDataHandle (id, jsonData) {
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.put(`/mps/sheetTemplate/json/${id}`, {data: jsonData}).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            loading.close()
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        loading.close()
      })
    },
    closeLuckysheetHandle () {
      this.luckysheetVisible = false
    }
  }
}
</script>