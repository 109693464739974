import { addDynamicRoute } from '@/router'

export default {
  data () {
    return {
      process: {
        // 流程驳回时，起草人在待办流程中修改后点击提交，保存业务表单的路径
        updateURL: ''
      },
      // 0:已发起,1:运行中,2:已通过,3:未通过,4:被驳回,5:被退回,9:已删除
      PROCESS_STATE: {
        NOT_EXIST: -1, // 流程不存在（即：未发起流程）
        PENDING: 0, // 已发起（即：尚未审批）
        RUNNING: 1, // 运行中（即：已经有人审批了，但整个流程仍在运行中）
        SUCCESS: 2, // 已审批通过（流程结束）
        FAIL: 3,    // 未审批通过（流程结束）
        REJECT: 4,  // 被驳回（即：流程处于起草人待办流程中）
        ROLLBACK: 5,  // 被退回（即：流程处于被退回，还未办理的状态）
        REVOKE: 7,  // 撤销
        DELETED: 9, // 已删除（流程实例和审批记录都删除了）
      }
    }
  },
  methods: {
    // 关闭当前窗口
    closeCurrentTab () {
      let tabName = this.$store.state.contentTabsActiveName
      this.$store.state.contentTabs = this.$store.state.contentTabs.filter(item => item.name !== tabName)
      if (this.$store.state.contentTabs.length <= 0) {
        this.$store.state.sidebarMenuActiveName = this.$store.state.contentTabsActiveName = 'home'
        return false
      }
      if (tabName === this.$store.state.contentTabsActiveName) {
        this.$router.push({ name: this.$store.state.contentTabs[this.$store.state.contentTabs.length - 1].name })
      }
    },
    // 获取流程定义的表单路由配置信息
    getProcDefRouteSet (data, callback) {
      this.$http.get(`/flowable/process/bizRoute/${data.menuId}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data || !res.data.bizRoute) {
          return this.$message.error(this.$t('process.routeError'))
        }
        let param = {
          ...data,
          ...res.data
        }
        callback(param)
      }).catch(() => { })
    },
    // 查看流程详情页面
    forwardDetail (data) {
      let routeParams = {
        routeName: `${this.$route.name}__detail_${data.processInstanceId}`,
        menuId: `${this.$route.meta.menuId}`,
        title: `${this.$route.meta.title} - ${data.processTitle}`,
        path: data.bizRoute,
        params: {
          processInstanceId: data.processInstanceId,
          businessStatus: data.businessStatus,
          processTitle: data.processTitle,
          businessKey: data.businessKey
        }
      }
      addDynamicRoute(routeParams, this.$router)
    },
    // 打开办理详细页面
    forwardHandleUrl (data) {
      // 区分正常办理待办流程和起草人办理被驳回的流程
      let processShowType = data.businessStatus == this.PROCESS_STATE.REJECT ? 'draftTaskHandle' : 'taskHandle'
      let routeParams = {
        routeName: `${this.$route.name}__handle_${data.taskId}`,
        menuId: `${this.$route.meta.menuId}`,
        title: `${this.$route.meta.title} - ${data.processTitle}`,
        // 流程相关的业务表单详情页面有两套路由页面，一个是用于驳回起草人使用，一个是用于正常的流程审批
        path: data.businessStatus == this.PROCESS_STATE.REJECT ? data.bizRouteEdit : data.bizRoute,
        params: {
          taskId: data.taskId,
          processInstanceId: data.processInstanceId,
          businessStatus: data.businessStatus,
          processTitle: data.processTitle,
          processShowType: processShowType,
          businessKey: data.businessKey,
          delegationState: data.delegationState, // 用于标识出当前任务是否为委派任务
        }
      }
      // 先关闭待办流程列表页面，再打开该流程的办理详情页面（避免用户办理后，仍然能在待办流程中看到该待办流程）
      //this.closeCurrentTab() 已通过全局事件总线实现了待办流程列表页面的刷新，所以在打开某个待办流程时不需要先自动关闭待办列表页面了
      addDynamicRoute(routeParams, this.$router)
    },
    // 根据流程状态控制列表页面的checkbox是否允许勾选
    checkSelectable (row) {
      // 流程状态为“已发起”、“运行中”、“已通过”、“未通过”和“被驳回”的时候业务菜单列表数据都不允许勾选
      return !row || (
        row.processStatus !== this.PROCESS_STATE.PENDING
          && row.processStatus !== this.PROCESS_STATE.RUNNING
          && row.processStatus !== this.PROCESS_STATE.SUCCESS
          && row.processStatus !== this.PROCESS_STATE.FAIL
          && row.processStatus !== this.PROCESS_STATE.REJECT
      )
    },
    // 流程提交成功时的回调函数
    successCallback (res) {
      this.closeCurrentTab()
    },
    // 流程提交失败时的回调函数
    errorCallback (res) {
      this.$message.error('提交失败，该条数据或已不存在，请重新打开待办流程!')
    },

    // 回调函数：用于给起草人修改保存被驳回的流程
    submitCallback (processCallback) {
      if (this.process.updateURL) {
        if (this.$refs['dataForm']) {
          this.$refs['dataForm'].validate((valid) => {
            if (!valid) {
              return false
            }
            this.submitDataForm(processCallback)
          })
        } else {
          this.submitDataForm(processCallback)
        }
      } else {
        // 如果没有在xxx-process-edit.vue中配置updateURL属性，则不需要执行该接口，直接将该被驳回的流程提交到流程下一步阶段。
        // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
        const loading = this.$loading({
          lock: true,
          text: '正在提交...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        processCallback(() => {
          // 最后一步执行完毕后的回调函数
          loading.close()
        })
      }
    },

    submitDataForm(processCallback) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 提交业务表单信息
      this.$http.put(this.process.updateURL, this.dataForm).then(({ data: res }) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        // 非常重要：将当前流程从被驳回节点跳转回到流程审批环节中（process-running组件中的函数）
        processCallback(() => {
          // 最后一步执行完毕后的回调函数
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    },

    /******************************************************** 
                      以下方法用于流程状态的判断
    ******************************************************* */

    // 流程是否存在（如果流程存在，一般不允许修改对应的业务表单）
    isProcessExist(processStatus){
      return processStatus != this.PROCESS_STATE.NOT_EXIST && processStatus != this.PROCESS_STATE.DELETED
    },

    formatBusinessStatus(row, column) {
      let resultStatus = '未知状态'
      let status = row[column.property]
      if (status == this.PROCESS_STATE.PENDING) {
        resultStatus = '已发起'
      } else if (status == this.PROCESS_STATE.RUNNING) {
        resultStatus = '运行中'
      } else if (status == this.PROCESS_STATE.SUCCESS) {
        resultStatus = '已通过'
      } else if (status == this.PROCESS_STATE.FAIL) {
        resultStatus = '未通过'
      } else if (status == this.PROCESS_STATE.REJECT) {
        resultStatus = '被驳回'
      } else if (status == this.PROCESS_STATE.ROLLBACK) {
        resultStatus = '被退回'
      } else if (status == this.PROCESS_STATE.REVOKE) {
        resultStatus = '撤销'
      } else if (status == this.PROCESS_STATE.DELETED) {
        resultStatus = '已删除'
      } else if (status == this.PROCESS_STATE.NOT_EXIST) {
        resultStatus = '未发起'
      }
      return resultStatus;
    },
    getBusinessStatusColor(status){
      if (status == this.PROCESS_STATE.PENDING) {
        return "";
      } else if (status == this.PROCESS_STATE.RUNNING) {
        return "";
      } else if (status == this.PROCESS_STATE.SUCCESS) {
        return "green";
      } else if (status == this.PROCESS_STATE.FAIL) {
        return "red";
      } else if (status == this.PROCESS_STATE.REJECT) {
        return "red";
      } else if (status == this.PROCESS_STATE.ROLLBACK) {
        return "red";
      } else if (status == this.PROCESS_STATE.REVOKE) {
        return "red";
      } else if (status == this.PROCESS_STATE.DELETED) {
        return "red";
      } else if (status == this.PROCESS_STATE.NOT_EXIST) {
        return "";
      }
      return "";
    },
    getBusinessStatusTxt(status){
      let resultStatus = '未知状态'
      if (status == this.PROCESS_STATE.PENDING) {
        resultStatus = '已发起'
      } else if (status == this.PROCESS_STATE.RUNNING) {
        resultStatus = '运行中'
      } else if (status == this.PROCESS_STATE.SUCCESS) {
        resultStatus = '已通过'
      } else if (status == this.PROCESS_STATE.FAIL) {
        resultStatus = '未通过'
      } else if (status == this.PROCESS_STATE.REJECT) {
        resultStatus = '被驳回'
      } else if (status == this.PROCESS_STATE.ROLLBACK) {
        resultStatus = '被退回'
      } else if (status == this.PROCESS_STATE.REVOKE) {
        resultStatus = '撤销'
      } else if (status == this.PROCESS_STATE.DELETED) {
        resultStatus = '已删除'
      } else if (status == this.PROCESS_STATE.NOT_EXIST) {
        resultStatus = '未发起'
      }
      return resultStatus;
    },
    // 判断当前流程状态是否能撤销(已发起，运行中，被驳回，被退回)
    canRevoke(status) {
      return (status == this.PROCESS_STATE.PENDING || status == this.PROCESS_STATE.RUNNING || status == this.PROCESS_STATE.REJECT || status == this.PROCESS_STATE.ROLLBACK)
    }
  }
}

