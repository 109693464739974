<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__workstageinspection">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.wbsName" placeholder="分部分项" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.item" placeholder="检查项" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="dataForm.code" placeholder="编号" clearable></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="export" v-if="$hasPermission('mps:workstageinspection:export')" @click="exportHandle()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:workstageinspection:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:workstageinspection:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="请使用微信小程序或App进行工序报验" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="code" label="编号" width="160" header-align="center" align="center" sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.code}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="wbsName" label="分部分项" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.pnames + "/" + row.wbsName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="workstageName" label="工序名称" width="140" sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.workstageName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="item" label="检查项" show-overflow-tooltip width="200" sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.item}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="submitter" label="报验人/班组" width="150" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="position" label="报验部位" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="submitDate" label="报验日期" width="110" header-align="center" align="center" sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:workstageinspection:update') && checkSelectable(row)" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:workstageinspection:delete') && checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, ['工程实体照片','质检人员照片','实测实量照片','监理照片','其他报验资料'], !$hasPermission('mps:workstageinspection:update') || !checkSelectable(row), row.workstageName, null, true)"></table-button>
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" fixed="right" header-align="center" align="center" width="120" sortable="custom">
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.pnames + '/' + scope.row.wbsName + '【' + scope.row.workstageName + '】工序报验'" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import AddOrUpdate from './workstageinspection-add-or-update'
import ViewPage from './workstageinspection-view'
export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/workstageInspection/page',
        getDataListIsPage: true,
        exportURL: '/mps/workstageInspection/export',
        deleteURL: '/mps/workstageInspection',
      },
      dataForm: {
        item: '',
        code: '',
        wbsName: '',
        prjId: '',
        contractId: ''
      }
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },

    // 新增或修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.init()
      })
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.contractId = this.dataForm.contractId
        this.$refs.view.dataForm.prjId = this.dataForm.prjId
        this.$refs.view.init()
      })
    }
  }
}
</script>