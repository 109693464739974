<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <el-button type="text" size="mini" icon="el-icon-plus" @click="typeAddOrUpdateHandle()" v-if="$hasPermission('opm:scheduleunit:save')">新增分类</el-button>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="treeProps"
              ref="tree"
              highlight-current
              @node-click="treeNodeClick">
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseEnter(data)" @mouseleave="mouseLeave(data)">
              <span>{{ node.label }}</span>
              &nbsp;
              <span>
                <el-button v-show="data.show" type="text" size="mini" @click.stop="() => typeAddOrUpdateHandle(data.id)" v-if="$hasPermission('opm:scheduleunit:update')">修改</el-button>
                <el-button v-show="data.show" type="text" size="mini" @click.stop="() => deleteType(data.id)" v-if="$hasPermission('opm:scheduleunit:delete')">删除</el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__scheduleunit">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item v-show="projectSelectShow">
              <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true" @change="projectChangeHandle" @hide="hideProjectSelect" style="width: 240px;"></project-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.name" placeholder="形象工程名称" clearable ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('opm:scheduleunit:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="import" v-if="$hasPermission('opm:scheduleunit:import')" @click="importHandle"></toolbar-button>
                <toolbar-button role="export" v-if="$hasPermission('opm:scheduleunit:export')" @click="exportHandle"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('opm:scheduleunit:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column prop="name" label="形象工程名称" sortable="custom" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row)">{{row.name}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="unitName" label="单位" width="100" header-align="center" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
            <el-table-column prop="typeName" label="类型" width="170" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
            <el-table-column prop="inCharts" label="参与图表统计" width="150" header-align="center" align="center" sortable="custom" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-tag v-if="row.inCharts === 1" type="success" effect="plain" size="mini">是</el-tag>
                <el-tag v-if="row.inCharts === 0" type="danger" effect="plain" size="mini">否</el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('opm:scheduleunit:update')" @click="addOrUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('opm:scheduleunit:delete')" @click="deleteHandle(row.id)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:scheduleunit:update'))"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
          <!-- 弹窗, 新增 / 修改 -->
          <type-add-or-update v-if="typeAddOrUpdateVisible" ref="typeAddOrUpdate" @refreshDataList="getTreeData" @close="closeDialogHandle"></type-add-or-update>
          <!-- excel文件导入弹窗 -->
          <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="projectChangeHandle" url="/opm/scheduleUnit/import" thumbnail="import_scheduleunit.jpg"></excel-import>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './scheduleunit-add-or-update'
import TypeAddOrUpdate from './scheduleunittype-add-or-update'
import ViewPage from './scheduleunit-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/scheduleUnit/page',
        getDataListIsPage: true,
        exportURL: '/opm/scheduleUnit/export',
        deleteURL: '/opm/scheduleUnit',
      },
      dataForm: {
        prjId: '',
        typeId: '',
        name: '',
      },
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
      },
      projectSelectShow: true,
      treeLoading: false,
      typeAddOrUpdateVisible: false,

      // 默认排序
      order:'asc',
      orderField:'sort_no',
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage,
    TypeAddOrUpdate,
  },
  methods: {
    treeNodeClick(data, node, ele) {
      // 点击高亮节点则取消高亮，并查全部的数据
      if (this.dataForm.typeId == data.id) {
        this.dataForm.typeId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.dataForm.typeId = data.id
      }
      this.getDataList()
    },
    projectChangeHandle() {
      this.dataForm.typeId = ''
      this.getTreeData()
      this.getDataList()
    },
    getTreeData() {
      this.treeLoading = true
      this.$http.get(
          '/opm/scheduleUnitType/list',
          {
            params: {
              prjId: this.dataForm.prjId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          this.$message.error(res.msg)
          return
        }
        this.treeData = res.data
        this.dataForm.typeId = ''
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    hideProjectSelect() {
      this.projectSelectShow = false
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      if (id || this.dataForm.typeId) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.dataForm.typeId = this.dataForm.typeId
          this.$refs.addOrUpdate.init()
        })
      } else {
        this.$message.error('请先在左侧选择一个分类')
      }
    },
    // 查看
    viewHandle (row) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = row.id
        this.$refs.view.extraForm.unitName = row.unitName
        this.$refs.view.extraForm.typeName = row.typeName
        this.$refs.view.init()
      })
    },
    // 新增 / 修改形象单元分类
    typeAddOrUpdateHandle (id) {
      if (!this.dataForm.prjId) {
        this.$message.error('请先选择一个项目')
        return
      }
      this.typeAddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.typeAddOrUpdate.dataForm.id = id
        this.$refs.typeAddOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.typeAddOrUpdate.init()
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.addOrUpdateVisible = false
      this.viewVisible = false
      this.typeAddOrUpdateVisible = false
    },
    // 删除
    deleteType (id) {
      this.$confirm('删除分类会同时删除分类下的所有形象单元', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
            `/opm/scheduleUnitType`,
            {
              'data': [id]
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.projectChangeHandle()
            }
          })
        }).catch()
      }).catch()
    },
    mouseEnter(data) {
      this.$set(data, 'show', true)
    },
    mouseLeave(data) {
      this.$set(data, 'show', false)
    },

  }
}
</script>
<style scoped>
::v-deep .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 160px);
}
</style>