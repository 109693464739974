<template>
  <el-dialog width="70%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="期次" prop="periodId">
                <el-input v-model="dataForm.periodName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标题" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <my-upload :refId="dataForm.id" view></my-upload>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="费用明细" name="tab2">
        <vxe-table id="mxTable"
                   ref="mxTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="dataForm.details"
                   :max-height="tableHeight">
          <vxe-column field="sortNo" title="序号" width="60" align="center" fixed="left"></vxe-column>
          <vxe-column field="name" title="费用条目" fixed="left"></vxe-column>
          <vxe-column field="money" title="本期发生金额（元）" width="160" align="right"></vxe-column>
          <vxe-column field="remark" title="备注说明"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        periodId: '',
        name: '',
        sortNo: '',
        remark: '',
        details:[],
        periodName:''
      },
      activeTabName: 'tab1',
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.mxDataListLoading = true
      this.$http.get('/opm/safetyCost/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },
  }
}
</script>
