<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running v-if="meas" ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>
    <div style="position: absolute;display: inline;right: 15px;top: 12px">
      本期计量金额&nbsp;<span style="color: blue; font-size: 25px">{{ totalAmount }}</span>&nbsp;元
    </div>
    <!-- 以下是业务表单内容 -->
    <el-tabs type="border-card">
      <el-tab-pane label="计量明细">
        <my-container :left-width="300">
          <template v-slot:left>
            <div class="tree-container">
              <vxe-table
                  ref="tree"
                  resizable
                  :loading="treeLoading"
                  show-overflow
                  :height="leftTreeHeight"
                  border="none"
                  :show-header="false"
                  :row-config="{isCurrent: true, isHover: true, useKey: true, height: 27, keyField: 'id'}"
                  :tree-config="{transform: true, showLine: true, iconOpen: 'vxe-icon-caret-right rotate45', iconClose: 'vxe-icon-caret-right', parentField: 'pid'}"
                  cell-class-name="pointer-cursor"
                  :data="treeData"
                  @cell-click="treeCellClick"
              >
                <vxe-column field="name" width="700" tree-node>
                  <template v-slot="{ row }">
                    <span>
                      <span v-if="row.isLeaf" style="color: blue">{{ row.name }}</span>
                      <span v-else>{{ row.name }}</span>
                    </span>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </template>
          <template v-slot:right>
            <el-tabs v-model="tabActiveName" type="border-card" :style="{height: rightTabsHeight + 'px'}">
              <el-tab-pane label="基本内容" name="0">
                <meas-ticket-list v-show="measTicketInfoTabType == 1" ref="measTicketList" @measTicketClick="measTicketListClickHandle" :tableHeight="measMxTableHeight"></meas-ticket-list>
                <meas-ticket-info-view v-show="measTicketInfoTabType == 3" ref="measTicketInfoView"></meas-ticket-info-view>
              </el-tab-pane>
              <el-tab-pane label="清单明细" name="1">
                <meas-mx ref="mx" :tableHeight="measMxTableHeight"></meas-mx>
              </el-tab-pane>
              <el-tab-pane label="计量草图" name="2" v-if="ticketDataForm.measTicketId">
                <meas-ticket-draft-pic ref="measTicketDraftPic" :height="measMxTableHeight"></meas-ticket-draft-pic>
              </el-tab-pane>
              <el-tab-pane label="质保资料" name="3" v-if="ticketDataForm.measTicketId">
                <meas-ticket-quality-file ref="measTicketQualityFile" :height="measMxTableHeight"></meas-ticket-quality-file>
              </el-tab-pane>
              <el-tab-pane label="计量附件" name="4" v-if="ticketDataForm.measTicketId">
                <meas-ticket-file-list ref="measTicketFileList" :height="measMxTableHeight"></meas-ticket-file-list>
              </el-tab-pane>
            </el-tabs>
          </template>
        </my-container>
      </el-tab-pane>
      <el-tab-pane label="费用明细">
        <meas-cost :task-handling="taskHandling"></meas-cost>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import MeasCost from './meascost-process'
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import MeasMx from './meas-mx-process'
import MeasTicketInfoUpdate from "@/views/modules/mps/meas-ticket-info-update";
import MeasTicketInfoView from "@/views/modules/mps/meas-ticket-info-view";
import MeasTicketList from "@/views/modules/mps/meas-ticket-list";
import MeasTicketDraftPic from "@/views/modules/mps/meas-ticket-draft-pic";
import MeasTicketFileList from "@/views/modules/mps/meas-ticket-file-list";
import MeasTicketQualityFile from "@/views/modules/mps/meas-ticket-quality-file";
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        deleteURL: '/mps/measTicket',
        createdIsNeed: false,
      },
      dataForm: {
        prjId: '',
        contractId: '',
        measId: '',
      },
      ticketDataForm: {
        measTicketId: '',
        subItemId: '',
        subItemName: '',
      },
      treeLoading: false,
      treeData: [],
      meas: {},
      leftTreeHeight: 550,
      rightTabsHeight: 550,
      measMxTableHeight: 400,
      // 计量单页签显示的类型：1.列表，2.编辑表单，3.查看表单
      measTicketInfoTabType: 1,
      taskId: '',
      taskHandling: false,
      allowExceed: 0,
      // 计量总金额
      totalAmount: 0,
      // 计量单类型（1：一对一，2：一对多）
      ticketType: '',
      tabActiveName: '0',
    }
  },
  components: {
    MeasMx,
    MeasCost,
    MeasTicketInfoUpdate,
    MeasTicketInfoView,
    MeasTicketList,
    MeasTicketDraftPic,
    MeasTicketFileList,
    MeasTicketQualityFile,
  },
  created() {
    let _height = window.innerHeight - 200
    this.leftTreeHeight = _height
    this.rightTabsHeight = _height
    this.measMxTableHeight = _height - 50
    this.$bus.$on('measAmountChange', () => {
      this.getTotalAmount()
    })
    this.init()
  },
  beforeDestroy() {
    this.$bus.$off('measAmountChange')
  },
  methods: {
    init () {
      // 4、通过流程获取主表ID
      this.dataForm.measId = this.$route.params.businessKey
      if (this.$route.params.processShowType == 'taskHandle') {
        this.taskHandling = true
      }
      this.$nextTick(() => {
        this.taskId = this.$route.params.taskId
        if (this.dataForm.measId) {
          this.getInfo()
          this.getTotalAmount()
        }
      })
    },
    // 获取信息
    async getInfo () {
      // 查询计量主表信息
      let {data: res} = await this.$http.get('/mps/meas/' + this.dataForm.measId)
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.meas = res.data
      this.dataForm.contractId = res.data.contractId
      this.dataForm.processStatus = res.data.processStatus

      // 查询标段信息--是否允许超计量
      let {data: allowExceedRes} = await this.$http.get('/mps/contract/allowExceed/' + this.dataForm.contractId)
      if (allowExceedRes.code !== 0) {
        return this.$message.error(allowExceedRes.msg)
      }
      this.allowExceed = allowExceedRes.data

      // 查询标段信息--计量单模式（一对一或一对多）
      let {data: ticketTypeRes} = await this.$http.get('/mps/contract/ticketType/' + this.dataForm.contractId)
      if (ticketTypeRes.code !== 0) {
        return this.$message.error(ticketTypeRes.msg)
      }
      this.ticketType = ticketTypeRes.data

      this.getTreeData()
    },
    // 获取本期计量总金额
    getTotalAmount() {
      if (this.dataForm.measId) {
        this.$http.get('/mps/meas/totalAmount/' + this.dataForm.measId).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.totalAmount = XEUtils.commafy(res.data) || 0
        }).catch(() => {
          // do nothing
        })
      } else {
        this.totalAmount = null
      }
    },
    /**
     * @param defaultExpandId 需要自动展开并点击的节点id
     * 用于新增计量单后，在树上自动展开其父节点，便于用户操作
     */
    getTreeData(defaultExpandId) {
      if (this.dataForm.measId) {
        this.treeLoading = true
        this.$http.get(
            `/mps/measTicket/treeList/${this.dataForm.measId}`).then(({data: res}) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          }
          this.treeData = res.data
          if (this.treeData.length > 0) {
            let rootRow = {
              id: '0',
              pid: '-1',
              name: '全部',
            }
            this.treeData.push(rootRow)
            this.$nextTick(() => {
              if (defaultExpandId) {
                this.treeAutoExpandAndClick(defaultExpandId)
              } else {
                // 动态判断需要默认展开的节点
                this.$refs.tree.setTreeExpand(this.getDefaultExpandNodes(), true)
              }
            })
          }
        }).catch(() => {
          this.$message.error('加载出错')
        }).finally(() => {
          this.treeLoading = false
        })
        // 如果不需要自动点击树的节点，则直接刷新右侧tab页数据（目的：1.清空某些页；2.某些页在不点击左侧树的情况下需要查全量数据）
        if (!defaultExpandId) {
          this.fetchRightTabData()
        }
      } else {
        this.treeData = []
        // 此处是为了清空右侧tab页中的数据
        this.fetchRightTabData()
      }
    },
    // 动态判断出来需要默认展开的节点
    getDefaultExpandNodes(){
      let expandNodes = []
      if (this.treeData) {
        if (this.treeData.length <= 20) {// 如果少于20个，则全部展开
          expandNodes = this.treeData
        } else {
          this.treeData.forEach(element => {// 否则要展开第一级和第二级
            if (element.id == '0' || element.pid == '0') {
              expandNodes.push(element)
            }
          })
        }
      }
      return expandNodes
    },
    // 将左侧树展开至给定的subItemId的节点处，并模拟点击选中
    treeAutoExpandAndClick(subItemId) {
      let expandRows = []
      let parentRow
      let rowId = subItemId
      do {
        parentRow = this.$refs.tree.getParentRow(rowId)
        expandRows.push(parentRow)
        rowId = parentRow.id
      } while (parentRow.pid != -1)
      this.$refs.tree.setTreeExpand(expandRows, true)
      // 找到当前行，将其选中并触发点击事件
      let currentRow = this.$refs.tree.getRowById(subItemId)
      this.$refs.tree.setCurrentRow(currentRow)
      this.treeCellClick({row: currentRow, triggerTreeNode: false})
    },
    // 左侧树节点的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.$refs.tree.setTreeExpand(row, true)
        this.ticketDataForm.measTicketId = row.measTicketId
        this.ticketDataForm.subItemName = row.pnames + '/' + row.name
        this.ticketDataForm.subItemId = row.id
        if (this.tabActiveName > '1') {
          this.tabActiveName = '0'
        }
        this.$nextTick(() => {
          this.fetchRightTabData()
        })
      }
    },
    measTicketListClickHandle(subItemId) {
      this.treeAutoExpandAndClick(subItemId)
    },
    // 重新获取右侧tab各页签中的数据
    fetchRightTabData() {
      this.fetchMeasTicketInfo()
      this.fetchMeasMx()
      if (this.ticketDataForm.measTicketId) {
        this.fetchMeasTicketDraftPic()
        this.fetchMeasTicketQualityFile()
        this.fetchMeasTicketFileList()
      }
    },
    // 获取计量单信息页签中的数据
    fetchMeasTicketInfo() {
      if (!this.ticketDataForm.measTicketId) {
        this.measTicketInfoTabType = 1
        this.$refs.measTicketList.dataForm.measId = this.dataForm.measId
        this.$refs.measTicketList.dataForm.subItemId = this.ticketDataForm.subItemId
        this.$refs.measTicketList.ticketType = this.ticketType
        this.$refs.measTicketList.getDataList()
      } else {
        this.measTicketInfoTabType = 3
        this.$refs.measTicketInfoView.dataForm.id = this.ticketDataForm.measTicketId
        this.$refs.measTicketInfoView.getInfo()
      }
    },
    // 获取计量明细页签中的数据
    fetchMeasMx() {
      this.$refs.mx.taskHandling = this.taskHandling
      this.$refs.mx.taskId = this.taskId
      this.$refs.mx.dataForm.mainId = this.ticketDataForm.measTicketId
      this.$refs.mx.dataForm.subItemId = this.ticketDataForm.subItemId
      this.$refs.mx.dataForm.measId = this.dataForm.measId
      this.$refs.mx.subItemName = this.ticketDataForm.subItemName
      this.$refs.mx.processStatus = this.meas ? this.meas.processStatus : null
      this.$refs.mx.allowExceed = this.allowExceed
      this.$refs.mx.ticketType = this.ticketType
      this.$refs.mx.getDataList()
    },
    // 获取计量草图页签中的数据
    fetchMeasTicketDraftPic() {
      this.$refs.measTicketDraftPic.canEdit = this.canEdit()
      this.$refs.measTicketDraftPic.refId = this.ticketDataForm.measTicketId
      this.$refs.measTicketDraftPic.loadFile()
    },
    // 获取质保资料页签中的数据
    fetchMeasTicketQualityFile() {
      this.$refs.measTicketQualityFile.canEdit = this.canEdit()
      this.$refs.measTicketQualityFile.measTicketId = this.ticketDataForm.measTicketId
      this.$refs.measTicketQualityFile.init()
    },
    // 获取计量附件页签中的数据
    fetchMeasTicketFileList() {
      this.$refs.measTicketFileList.canEdit = this.canEdit()
      this.$refs.measTicketFileList.refId = this.ticketDataForm.measTicketId
    },
    // 根据流程状态判断是否可以新增/修改
    canEdit() {
      if (this.isProcessExist(this.meas.processStatus)) {
        return false
      } else {
        return true
      }
    },
  }
}
</script>