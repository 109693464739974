<template>
  <el-dialog width="70%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="otherContractId">
            <el-input v-model="dataForm.otherContractName" placeholder="合同名称" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编码" prop="otherContractCode">
            <el-input v-model="dataForm.otherContractCode" placeholder="合同编码" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="otherContractAmount">
            <el-input-number disabled v-model="dataForm.otherContractAmount" data-unit="元" placeholder="合同金额" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签订单位" prop="otherContractSecondParty">
            <el-input disabled v-model="dataForm.otherContractSecondParty" placeholder="签订单位" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请日期" prop="applyDate">
            <el-date-picker type="date" placeholder="申请日期" value-format="yyyy-MM-dd" v-model="dataForm.applyDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支付金额" prop="paymentAmount">
            <el-input-number v-model="dataForm.paymentAmount" data-unit="元" placeholder="支付金额" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="请款人" prop="requestId">
            <user-select v-model="dataForm.requestId" style="width: 100%"></user-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="用途" prop="purpose">
            <el-input type="textarea" v-model="dataForm.purpose" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="bank">
            <el-input v-model="dataForm.bank" placeholder="开户银行"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名称" prop="bankAccountName">
            <el-input v-model="dataForm.bankAccountName" placeholder="账户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" placeholder="银行账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        otherContractId: '',
        otherContractName: '',
        otherContractAmount: '',
        otherContractSecondParty: '',
        otherContractCode: '',
        applyDate: '',
        requestId: '',
        paymentAmount: '',
        purpose: '',
        bank: '',
        bankAccountName: '',
        bankAccount: '',
        remark: '',
        processInstanceId: '',
        processStatus: '',
      }
    }
  },
  computed: {
    dataRule () {
      return {
        applyDate: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        paymentAmount: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else if (this.dataForm.otherContractId) {
          this.getOtherContractInfo()
        }

        if(!this.dataForm.id){
          this.dataForm.applyDate=new Date()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/otherContractPay/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 根据合同登记ID，获取合同登记记录
    getOtherContractInfo () {
      this.$http.get('/mps/otherContract/' + this.dataForm.otherContractId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data) {
          this.dataForm.otherContractName = res.data.name
          this.dataForm.otherContractCode = res.data.code
          this.dataForm.otherContractAmount = res.data.cost
          this.dataForm.otherContractSecondParty = res.data.secondParty
          if (!this.dataForm.id) {
            this.dataForm.bank = res.data.bank1
            this.dataForm.bankAccount = res.data.bankaccount1
            this.dataForm.bankAccountName = res.data.bankname1
          }
        }
      }).catch(() => {
        // do nothing
      })
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/otherContractPay/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    },1000,{'leading': true,'trailing': false})
  }
}
</script>