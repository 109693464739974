<template>
  <div>
    <el-dialog width="80%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="基本信息" name="tab1">
          <el-form key="first" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="期次" prop="periodId">
                  <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId"
                                 placeholder="期次选择" default-select :hasMeas="false"></period-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="标题" prop="name">
                  <el-input v-model="dataForm.name" placeholder="标题"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item prop="sortNo" label="排序">
                  <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>

        </el-tab-pane>
        <el-tab-pane label="材料明细" name="tab2">
          <vxe-table id="mxTable"
                     keep-source
                     ref="mxTableRef"
                     :loading="mxDataListLoading"
                     size="mini"
                     :column-config="{resizable: true}"
                     border
                     show-overflow
                     :row-config="{isCurrent: true, isHover: true}"
                     :data="dataForm.details"
                     header-align="center"
                     :max-height="tableHeight"
                     :edit-rules="mxTableRules"
                     :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
                     :expand-config="{accordion: true, lazy: true, loadMethod: loadMxMxData, iconOpen: 'vxe-icon-square-minus', iconClose: 'vxe-icon-square-plus'}">
            <vxe-column field="materialName" title="材料名称" width="300" header-align="center">
              <template #header>
                <el-button v-if="$hasPermission('mps:materialadjust:save')" type="primary" size="mini" @click="openMaterialPropSelectHandle" style="float: left">添加</el-button>
                <span>材料名称</span>
              </template>
            </vxe-column>
            <vxe-column field="spec" title="规格" min-width="110" align="center" :edit-render="{name: '$input'}"></vxe-column>
            <vxe-column field="num1" type="expand" title="本期应耗数量" min-width="160" align="right" header-align="center">
              <template #default="scope">
                <vxe-input v-model="scope.row.num1" style="display:table-cell;width: 100px" type="number" @change="num1Change(scope.row)"></vxe-input>
              </template>
              <template #content="scope">
                <div class="expand-wrapper">
                  <vxe-table
                    border
                    :max-height=500
                    size="mini"
                    show-overflow
                    :row-config="{isCurrent: true,isHover: true}"
                    :column-config="{resizable: true}"
                    :data="scope.row.mxList"
                    :edit-config="{trigger: 'click', mode: 'row'}">
                    <vxe-column field="subItemCode" title="部位编号" width="200" align="center">
                      <template #header>
                        <el-button v-if="$hasPermission('mps:materialadjust:save')" type="primary" size="mini" @click="openBatchAddSub(scope.row)" style="float: left">添加</el-button>
                        <span>部位编号</span>
                      </template>
                    </vxe-column>
                    <vxe-column field="subItemName" title="部位名称"></vxe-column>
                    <vxe-column field="boqCode" title="清单编号" width="200" header-align="center" align="center"></vxe-column>
                    <vxe-column field="boqName" title="清单名称" align="left"></vxe-column>
                    <vxe-column field="num" title="消耗数量" width="150" :edit-config="{name:'$input'}" header-align="center" align="right">
                      <template #default="scopeMx">
                        <vxe-input v-model="scopeMx.row.num" type="number" @blur="calculateNum1(scope.row)"></vxe-input>
                      </template>
                    </vxe-column>
                    <vxe-column field="sortNo" title="排序号" width="80" align="center" header-align="center">
                      <template #default="{row}">
                        <vxe-input v-model="row.sortNo" type="integer" min="0"></vxe-input>
                      </template>
                    </vxe-column>
                    <vxe-column title="操作" width="80" fixed="right" align="center">
                      <template #default="scopeMx">
                        <el-button v-if="$hasPermission('mps:materialadjust:delete')" type="text" size="small" @click="deleteOneMxMxHandle(scope.row,scopeMx)">{{ $t('delete') }}</el-button>
                      </template>
                    </vxe-column>
                  </vxe-table>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="price1" title="基准价" min-width="110" align="right" :edit-render="{name: '$input'}">
              <template #edit="scope">
                <vxe-input v-model="scope.row.price1" type="number" @change="price1Change(scope.row)"></vxe-input>
              </template>
            </vxe-column>
            <vxe-colgroup title="基本信息">
              <vxe-column field="price2" title="本期信息价" min-width="110" align="right" :edit-render="{name: '$input'}">
                <template #edit="scope">
                  <vxe-input v-model="scope.row.price2" type="number" @change="price2Change(scope.row)"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="dates" title="时间" min-width="110" align="right" header-align="center" :edit-render="{name: '$input'}"></vxe-column>
              <vxe-column field="jiacha" title="价差" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="本期材料调差">
              <vxe-column field="riskRange" title="风险幅度" min-width="110" align="right" :edit-render="{name: '$input'}">
                <template #edit="scope">
                  <vxe-input v-model="scope.row.riskRange" type="number" @change="riskRangeChange(scope.row)"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="youxiaojiacha" title="有效价差" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
              <vxe-column field="tiaochajine" title="调差金额" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="本期实耗分析">
              <vxe-column field="num2" title="本期实耗数量" min-width="110" align="right" :edit-render="{name: '$input'}">
                <template #edit="scope">
                  <vxe-input v-model="scope.row.num2" type="number" @change="num2Change(scope.row)"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="shuliangcha" title="数量差" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
              <vxe-column field="shuliangchajine" title="数量差金额" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
              <vxe-column field="shihaojiachajine" title="实耗价差金额" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
              <vxe-column field="jieyujine" title="节余金额" min-width="110" align="right" :formatter="formatterNum"></vxe-column>
            </vxe-colgroup>
            <vxe-column field="sortNo" title="排序号" width="80" align="center" :edit-render="{name: '$input', props: {type: 'integer', min: 0}}"></vxe-column>
            <vxe-column title="操作" width="80" align="center">
              <template #default="{row}">
                <el-button v-if="$hasPermission('mps:materialadjust:delete')" type="text" size="small" @click="deleteOneMxHandle(row)">{{ $t('delete') }}</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <!--物资明细多选弹窗-->
    <material-more-select v-if="materialPropSelectVisible" ref="materialPropSelect" @submit="materialPropSelectChangeHandle" @close="closeMaterialPropSelectHandle"></material-more-select>
    <!--分项清单多选-->
    <materialadjust-mx-mx-batch-add-sub v-if="batchAddVisible" ref="batchAdd" @selectItem="batchAddItem" @close="closeBatchAddSubDialog"></materialadjust-mx-mx-batch-add-sub>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils';
import MaterialadjustMxMxBatchAddSub from './materialadjust-mx-mx-batch-add-sub'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        periodId: '',
        name: '',
        processInstanceId: '',
        processStatus: '',
        sortNo: '',
        details: []
      },
      activeTabName: 'tab1',
      // 明细 行校验
      mxTableRules: {},
      mxDataListLoading: false,
      materialPropSelectVisible: false,
      tableHeight: 400,

      batchAddVisible: false,
      currentMaterialadjustMxMxRow: null
    }
  },
  components: {
    MaterialadjustMxMxBatchAddSub
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule () {
      return {
        name: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        periodId: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialAdjust/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        for (let row of res.data.details) {
          this.num1Change(row)
          this.num2Change(row)
          this.price1Change(row)
          this.price2Change(row)
          this.riskRangeChange(row)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    },

    //  打开物资明细选择框
    async openMaterialPropSelectHandle (row) {
      this.materialPropSelectVisible = true
      this.$nextTick(() => {
        this.$refs.materialPropSelect.init()
      })
    },

    // 关闭 物资明细选择弹窗
    closeMaterialPropSelectHandle () {
      this.materialPropSelectVisible = false;
    },

    // 删除一行明细数据
    deleteOneMxHandle (row) {
      let $table = this.$refs.mxTableRef
      this.$confirm(this.$t('prompt.info',{'handle': this.$t('delete')}),this.$t('prompt.title'),{
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        if ($table != null) {
          $table.remove(row)
        }
      }).catch(() => {
        // do Nothing
      })
    },

    // 批量选择物资明细的回调方法
    materialPropSelectChangeHandle (result) {
      if (result && result.length > 0) {
        const $table = this.$refs.mxTableRef
        // 列表末尾新增材料明细
        for (let i = 0; i < result.length; i++) {
          let obj = result[i];
          $table.insertAt({
            materialId: obj.id,
            materialName: obj.name,
            id: '',
            num1: 0,
            sortNo: null
          }, -1)
        }
        this.$message({
          message: '请继续填写本期应耗数量！',
          type: 'info',
          duration: 5000,
        })
      }
    },

    setMaterialAdjustMx () {
      const {removeRecords} = this.$refs.mxTableRef.getRecordset()
      this.dataForm.addMxList = []
      this.dataForm.updateMxList = []
      let fullDataList = this.$refs.mxTableRef.getTableData().fullData
      if (fullDataList && fullDataList.length > 0) {
        for (let i = 0; i < fullDataList.length; i++) {
          let mxData = fullDataList[i]
          // 新增
          if (!mxData.id) {
            this.dataForm.addMxList.push(mxData)
          } else {
            // 修改
            this.dataForm.updateMxList.push(mxData)
          }
        }
      }

      let deleteMxIdList = []
      if (removeRecords && removeRecords.length > 0) {
        removeRecords.forEach(function (element) {
          if (element && element['id']) {
            deleteMxIdList.push(element['id'])
          }
        })
      }
      this.dataForm.deleteMxIdList = deleteMxIdList
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          if (this.activeTabName !== 'tab1') {
            this.activeTabName = 'tab1'  // 切换页签到第一页
            this.$message({
              message: "请先补充必填信息",
              type: 'warning',
              duration: 1500
            })
          }
          return false
        }
        this.setMaterialAdjustMx()

        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/materialAdjust/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    },1000,{'leading': true,'trailing': false}),
    num1Change (row) {
      row.tiaochajine = ((row.price2 - row.price1) - row.price1 * row.riskRange / 100) * row.num1
      row.shuliangcha = row.num1 - row.num2
      row.shuliangchajine = row.price1 * (row.num1 - row.num2)
      row.jieyujine = (row.price1 * (row.num1 - row.num2)) + (row.price2 * row.num2)
    },
    num2Change (row) {
      row.shuliangcha = row.num1 - row.num2
      row.shuliangchajine = row.price1 * (row.num1 - row.num2)
      row.shihaojiachajine = (row.price2 - row.price1) * row.num2
      row.jieyujine = (row.price1 * (row.num1 - row.num2)) + (row.price2 * row.num2)
    },
    price1Change (row) {
      row.jiacha = row.price2 - row.price1
      row.youxiaojiacha = (row.price2 - row.price1) - row.price1 * row.riskRange / 100
      row.tiaochajine = ((row.price2 - row.price1) - row.price1 * row.riskRange / 100) * row.num1
      row.shuliangchajine = row.price1 * (row.num1 - row.num2)
      row.jieyujine = (row.price1 * (row.num1 - row.num2)) + (row.price2 * row.num2)
    },
    price2Change (row) {
      row.jiacha = row.price2 - row.price1
      row.youxiaojiacha = (row.price2 - row.price1) - row.price1 * row.riskRange / 100
      row.tiaochajine = ((row.price2 - row.price1) - row.price1 * row.riskRange / 100) * row.num1
      row.shihaojiachajine = (row.price2 - row.price1) * row.num2
      row.jieyujine = (row.price1 * (row.num1 - row.num2)) + (row.price2 * row.num2)
    },
    riskRangeChange (row) {
      row.youxiaojiacha = (row.price2 - row.price1) - row.price1 * row.riskRange / 100
      row.tiaochajine = ((row.price2 - row.price1) - row.price1 * row.riskRange / 100) * row.num1
    },
    formatterNum ({cellValue}) {
      return XEUtils.commafy(Number(cellValue),{digits: 3});
    },

    loadMxMxData ({row}) {
      var p = new Promise((resolve,reject) => {
        this.$http.get('/mps/materialAdjustMxMx/list',
          {
            params: {
              'mainId': row.id,
            }
          }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          row.mxList = res.data
          resolve()
        }).catch(() => {
          reject()
        })
      })
      return p
    },

    deleteOneMxMxHandle (superRow,scope) {
      this.$confirm(this.$t('prompt.info',{'handle': this.$t('delete')}),this.$t('prompt.title'),{
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        let dataList = superRow.mxList
        if (dataList && dataList.length > 0) {
          let rowIndex = scope.rowIndex
          dataList.splice(rowIndex,1)
          scope.data.splice(rowIndex,1)
        }
        this.calculateNum1(superRow)
        this.refreshMxMxTable(superRow)
      }).catch(() => {
        // do Nothing
      })
    },
    // 级联计算本期应耗数量
    calculateNum1 (superRow) {
      let dataList = superRow.mxList
      if (dataList) {
        let totalNum = 0
        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i];
          if (data && data.num) {
            totalNum = totalNum + Number(data.num)
          }
        }
        superRow.num1 = totalNum
        this.num1Change(superRow)
      }
    },

    openBatchAddSub (row) {
      this.currentMaterialadjustMxMxRow = row
      this.batchAddVisible = true
      this.$nextTick(() => {
        this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchAdd.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.batchAdd.dataForm.measId = null
        this.$refs.batchAdd.dataForm.mainId = null
        this.$refs.batchAdd.init()
      })
    },
    closeBatchAddSubDialog () {
      this.batchAddVisible = false
    },
    refreshMxMxTable (row) {
      // 明细重新展开,用于刷新展开的表格数据。
      this.$refs.mxTableRef.setRowExpand(row,false).then(() => {
        this.$refs.mxTableRef.setRowExpand(row,true)
      })
    },
    batchAddItem (dataList) {
      if (dataList && dataList.length != 0) {
        if (this.currentMaterialadjustMxMxRow) {
          this.currentMaterialadjustMxMxRow.mxList = this.currentMaterialadjustMxMxRow.mxList || []
          for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i]
            if (data) {
              let addItem = {
                refId: data.id,
                ...data
              }
              addItem.id = ''
              addItem.num = 0
              this.currentMaterialadjustMxMxRow.mxList.push(addItem)
              this.refreshMxMxTable(this.currentMaterialadjustMxMxRow)
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .expand-wrapper {
    padding: 20px;
    width: 60%;
  }
</style>