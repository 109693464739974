<template>
  <el-dialog top="5vh" width="80%" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-form-item label="工程或费用名称" prop="subItemId">
        <el-popover v-model="subItemListVisible" ref="wbsListPopover" placement="top-start" trigger="click">
          <div class="my-tree-contianer">
            <el-tree
              node-key="id"
              lazy
              accordion
              :load="loadSubItemNode"
              :props="treeProp"
              :highlight-current="true"
              :expand-on-click-node="false"
              @current-change="wbsListTreeCurrentChangeHandle">
            </el-tree>
          </div>
        </el-popover>
        <el-tooltip placement="top-start" :content="pnames" effect="light" :enterable="false" :open-delay="500">
          <el-input placeholder="请选择变更部位" v-model="dataForm.subItemName" v-popover:wbsListPopover :readonly="true"></el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="工程量清单选择">
        <div class="my-tree-contianer">
          <el-tree
            v-loading="treeLoading"
            node-key="id"
            :props="props"
            ref="boqTree"
            lazy
            show-checkbox
            :load="load"
            :default-checked-keys="defaultCheckedKeys"
            highlight-current
            class="my-el-tree">
          </el-tree>
        </div>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 变更申请、变更令：添加分项下面的分项清单（批量选择清单）
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        subItemId: '',
        subItemName: '',
        stakeNo: '',
      },
      props: {
        //label: 'name',
        label: 'title', // dto中新增加个title属性（编码+名称）
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      treeLoading: true,
      title: '',

      treeProp: {
        label: 'name',
        children: 'children',
        isLeaf: 'isLeaf'
      },
      pnames: '',
      // 分项条目（wbs或sbs）
      subItemListVisible: false,
      defaultCheckedKeys: [],
    }
  },
  props: {
    contractId: String,
  },
  computed: {
    dataRule () {
      return {
        subItemId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
    },

    // 异步加载工程部位（wbs或sbs）
    loadSubItemNode (node, resolve) {
      let pid = node.level == 0 ? 0 : pid = node.data.id
      this.$http.get('/mps/changeRequest/subItem/children',{
        params: {
          pid: pid,
          contractId: this.contractId
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data)
      }).catch(() => {
        this.$message.error("出错了")
      })
    },

    // 异步加载合同清单
    load(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get('/mps/boq/children',
        {
          params: {
            'contractId': this.contractId,
            'pid': pid,
          }
        }
      ).then(({data: res}) => {
        this.treeLoading = false
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data)
      }).catch(() => {
        this.treeLoading = false
        return this.$message.error('出错了')
      })
    },

    // 分项条目节点选中
    wbsListTreeCurrentChangeHandle (data) {
      if (data.isLeaf) {
        this.dataForm.subItemId = data.id
        this.dataForm.subItemName = data.code ? '(' + data.code + ')' + data.name : data.name
        this.dataForm.stakeNo = data.stakeNo
        this.pnames = data.pnames ? data.pnames + '/' + data.name : data.name
        this.subItemListVisible = false
      } else {
        this.$message.warning("请选择最末级分项节点")
      }
    },
    
    // 数据提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        let checkedNodes = this.$refs.boqTree.getCheckedNodes(true)
        if (checkedNodes == null || checkedNodes.length == 0) {
          return this.$message.warning("请勾选工程量清单")
        }
        let rows = []
        for (let node of checkedNodes) {
          //if (!this.defaultCheckedKeys.includes(node.id)) {
          //}
          rows.push({
            boqId: node.id,
            boqCode: node.code,
            boqName: node.name,
            price: node.price,
            unitName: node.unitName,
            unitId: node.unitId,
            unitAccuracy: node.accuracy,
            subItemId: this.dataForm.subItemId,
            subItemName: this.dataForm.subItemName,
            typeId: 4  // 4 代表新增分项清单
          })
        }
        this.visible = false
        this.$emit('addNewData', rows)
      })
    }, 1000, {'leading': true, 'trailing': false}),

    checkBoxDisabled(data, node) {
      return !data.isLeaf || this.defaultCheckedKeys.includes(data.id)
    },
  }
}
</script>
<style scoped>
.my-tree-contianer {
  width: 100%;
  max-height: 500px;
  overflow: auto;
}
</style>