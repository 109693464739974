<template>
  <el-dialog width="80%" v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="进场日期" prop="entryDate">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.entryDate" readonly style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年份" prop="y">
                <el-input v-model="dataForm.y" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="月份" prop="m">
                <el-input v-model="dataForm.m" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="材料明细" name="tab2">
        <vxe-table id="mxTable"
              ref="mxTableRef"
              :loading="mxDataListLoading"
              size="mini"
              :column-config="{resizable: true}"
              border
              show-overflow
              :row-config="{isCurrent: true, isHover: true}"
              :data="dataForm.details"
              :max-height="tableHeight">
          <vxe-column field="materialName" title="材料名称" min-width="300" fixed="left"></vxe-column>
          <vxe-column field="spec" title="规格型号" width="200"></vxe-column>
          <vxe-column field="num" title="数量" width="150" align="right"></vxe-column>
          <vxe-column field="unit" title="单位" width="150" align="center"></vxe-column>
          <vxe-column field="sortNo" title="排序号" width="80" align="center"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        y: '',
        m: '',
        entryDate: '',
        details: []
      },
      activeTabName: 'tab1',
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/materialEntry/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {})
    },
  }
}
</script>
