<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同类型" prop="typeId">
            <el-select v-model="dataForm.typeId" clearable class="w-percent-100" disabled>
              <el-option value="1" label="设计合同"></el-option>
              <el-option value="2" label="其他合同"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="cost">
            <el-input v-model="dataForm.cost" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="甲方" prop="firstParty">
            <el-input v-model="dataForm.firstParty" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="乙方" prop="secondParty">
            <el-input v-model="dataForm.secondParty" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="开户银行1" prop="bank1">
            <el-input v-model="dataForm.bank1" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行2" prop="bank2">
            <el-input v-model="dataForm.bank2" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="银行账号1" prop="bankaccount1">
            <el-input v-model="dataForm.bankaccount1" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行账号2" prop="bankaccount2">
            <el-input v-model="dataForm.bankaccount2" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名称1" prop="bankname1">
            <el-input v-model="dataForm.bankname1" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名称2" prop="bankname2">
            <el-input v-model="dataForm.bankname2" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="签订日期" prop="signDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.signDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编制部门" prop="deptId">
            <dept-select v-model="dataForm.deptId" style="width: 100%" disabled></dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="主要条款" prop="mainTerms">
            <el-input type="textarea" v-model="dataForm.mainTerms" :autosize="{ minRows: 4, maxRows: 16}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 4, maxRows: 16}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标段长度" prop="length">
            <el-input v-model="dataForm.length" readonly>
              <template slot="append">km</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="招标清单预算" prop="biddingBudget">
            <el-input v-model="dataForm.biddingBudget" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="中标价" prop="biddingPrice">
            <el-input v-model="dataForm.biddingPrice" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标底/投标限价" prop="biddingLimit">
            <el-input v-model="dataForm.biddingLimit" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开标日期" prop="openingDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.openingDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="中标下浮率" prop="floatingRate">
            <el-input-number v-model="dataForm.floatingRate" controls-position="right" :min="0" :max="1" disabled></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'
export default {
  mixins: [processModule],
  data () {
    return {
      dataForm: {
        id: '',
        prjId: '',
        name: '',
        code: '',
        typeId: '',
        cost: '',
        firstParty: '',
        secondParty: '',
        bank1: '',
        bankaccount1: '',
        bankname1: '',
        bank2: '',
        bankaccount2: '',
        bankname2: '',
        signDate: '',
        deptId: '',
        mainTerms: '',
        remark: '',
        sortNo: '',
        processInstanceId: '',
        processStatus: '',
      },
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/otherContract/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
