<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__completionplan">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :validate-on-rule-change="false" label-width="auto" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="项目名称" prop="prjName">
              <el-input v-model="dataForm.prjName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号" prop="prjCode">
              <el-input v-model="dataForm.prjCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同名称" prop="contractName">
              <el-input v-model="dataForm.contractName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractCode">
              <el-input v-model="dataForm.contractCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划编号" prop="code">
              <el-input v-model="dataForm.code" placeholder="计划编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="计划名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划验收日期" prop="planAcceptanceDate">
              <el-date-picker type="date" placeholder="计划验收日期" value-format="yyyy-MM-dd" v-model="dataForm.planAcceptanceDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验收组织者" prop="organizer">
              <el-input v-model="dataForm.organizer" placeholder="验收组织者"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外部参加人员" prop="outsideParticipant">
              <el-input type="textarea" v-model="dataForm.outsideParticipant" placeholder="外部参加人员" :autosize="{ minRows: 3,maxRows:8}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参加人员" prop="participant">
              <el-input type="textarea" v-model="dataForm.participant" placeholder="参加人员" :autosize="{ minRows: 3,maxRows:8}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="填报人" prop="report">
              <el-input v-model="dataForm.report" placeholder="填报人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="填报日期" prop="reportDate">
              <el-date-picker type="date" placeholder="填报日期" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <my-upload-plain ref="upload" :refId="dataForm.id"></my-upload-plain>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        planAcceptanceDate: '',
        organizer: '',
        outsideParticipant: '',
        participant: '',
        report: '',
        reportDate: '',
        remark: '',
        contractCode: '',
      },
      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/mps/completionPlan'
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    dataRule() {
      return {
        contractId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        planAcceptanceDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionPlan/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
