<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__completionacceptance">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :validate-on-rule-change="false" label-width="auto" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="项目名称" prop="prjName">
              <el-input v-model="dataForm.prjName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号" prop="prjCode">
              <el-input v-model="dataForm.prjCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同标段" prop="contractName">
              <el-input v-model="dataForm.contractName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractCode">
              <el-input v-model="dataForm.contractCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验收编号" prop="code">
              <el-input v-model="dataForm.code" placeholder="验收编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="施工单位" prop="deptId">
              <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.deptId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设计单位" prop="designId">
              <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.designId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运营单位" prop="operationId">
              <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.operationId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代建单位" prop="proxyId">
              <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.proxyId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监理单位" prop="supervisionId">
              <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.supervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开工日期" prop="startDate">
              <el-date-picker type="date" placeholder="开工日期" value-format="yyyy-MM-dd" v-model="dataForm.startDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="竣工日期" prop="completionDate">
              <el-date-picker type="date" placeholder="竣工日期" value-format="yyyy-MM-dd" v-model="dataForm.completionDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验收结论" prop="conclusion">
              <el-select v-model="dataForm.conclusion" placeholder="请选择" :clearable="!dataForm.id" class="w-percent-100">
                <el-option :key="1" label="合格" :value="1"></el-option>
                <el-option :key="0" label="不合格" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="填报人" prop="report">
              <el-input v-model="dataForm.report" placeholder="填报人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="填报日期" prop="reportDate">
              <el-date-picker type="date" placeholder="填报日期" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="整改问题项" prop="problem">
              <el-input type="textarea" v-model="dataForm.problem" placeholder="整改问题项" :autosize="{ minRows: 3,maxRows:8}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <my-upload-plain ref="upload" :refId="dataForm.id"></my-upload-plain>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        deptId: '',
        designId: '',
        operationId: '',
        proxyId: '',
        supervisionId: '',
        startDate: '',
        completionDate: '',
        conclusion: '',
        problem: '',
        report: '',
        reportDate: '',
        remark: '',
        prjName: '',
        prjCode: ''
      },
      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/mps/completionAcceptance'
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        contractId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        deptId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        designId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        supervisionId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        startDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        completionDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionAcceptance/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
