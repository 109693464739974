<template>
  <el-dialog width="80%" top="5vh" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="合同名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="合同编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同类型" prop="typeId">
            <el-select v-model="dataForm.typeId" clearable class="w-percent-100">
              <el-option value="1" label="设计合同"></el-option>
              <el-option value="2" label="其他合同"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="cost">
            <el-input-number v-model="dataForm.cost" data-unit="元" placeholder="合同金额" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="甲方" prop="firstParty">
            <el-input v-model="dataForm.firstParty" placeholder="甲方"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="乙方" prop="secondParty">
            <el-input v-model="dataForm.secondParty" placeholder="乙方"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-col :span="12">
        <el-form-item label="开户银行1" prop="bank1">
          <el-input v-model="dataForm.bank1" placeholder="开户银行1"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开户银行2" prop="bank2">
          <el-input v-model="dataForm.bank2" placeholder="开户银行2"></el-input>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="银行账号1" prop="bankaccount1">
          <el-input v-model="dataForm.bankaccount1" placeholder="银行账号1"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="银行账号2" prop="bankaccount2">
          <el-input v-model="dataForm.bankaccount2" placeholder="银行账号2"></el-input>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="账户名称1" prop="bankname1">
          <el-input v-model="dataForm.bankname1" placeholder="账户名称1"></el-input>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="账户名称2" prop="bankname2">
          <el-input v-model="dataForm.bankname2" placeholder="账户名称2"></el-input>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="签订日期" prop="signDate">
          <el-date-picker type="date" placeholder="签订日期" value-format="yyyy-MM-dd" v-model="dataForm.signDate" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="编制部门" prop="deptId">
          <dept-select v-model="dataForm.deptId" style="width: 100%"></dept-select>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="主要条款" prop="mainTerms">
          <el-input type="textarea" v-model="dataForm.mainTerms" placeholder="主要条款" :autosize="{ minRows: 4, maxRows: 16}"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 4, maxRows: 16}"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="标段长度" prop="length">
          <el-input v-model="dataForm.length">
            <template slot="append">km</template>
          </el-input>
        </el-form-item>
      </el-col>
      
      <el-col :span="12">
        <el-form-item label="招标清单预算" prop="biddingBudget">
          <el-input-number v-model="dataForm.biddingBudget" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="中标价" prop="biddingPrice">
          <el-input-number v-model="dataForm.biddingPrice" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="标底/投标限价" prop="biddingLimit">
          <el-input-number v-model="dataForm.biddingLimit" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="开标日期" prop="openingDate">
          <el-date-picker type="date" placeholder="开标日期" value-format="yyyy-MM-dd" v-model="dataForm.openingDate" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="中标下浮率" prop="floatingRate">
          <el-input-number v-model="dataForm.floatingRate" controls-position="right" :min="0" :max="1"></el-input-number>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="排序" prop="sortNo">
          <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
        </el-form-item>
      </el-col>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        name: '',
        code: '',
        typeId: '',
        cost: '',
        firstParty: '',
        secondParty: '',
        bank1: '',
        bankaccount1: '',
        bankname1: '',
        bank2: '',
        bankaccount2: '',
        bankname2: '',
        signDate: '',
        deptId: '',
        mainTerms: '',
        remark: '',
        sortNo: '',
        processInstanceId: '',
        processStatus: '',
        length: '',
        biddingBudget: '',
        biddingLimit: '',
        biddingPrice: '',
        openingDate: '',
        floatingRate: ''
      }
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        cost: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        firstParty: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        secondParty: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
        signDate: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/otherContract/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/otherContract/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    },1000,{'leading': true,'trailing': false})
  }
}
</script>