<template>
  <el-dialog top="5vh" v-dialog-drag width="60%" :visible.sync="visible" title="[选择元数据]" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="6">
        <div class="tree-container">
          <el-tree
              v-loading="treeLoading"
              :data="treeData"
              node-key="id"
              :props="treeProps"
              ref="tree"
              highlight-current
              @node-click="treeNodeClick">
          </el-tree>
        </div>
      </el-col>
      <el-col :span="18">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-input v-model="dataForm.keyword" placeholder="编号或名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button-group style="margin-top: -3px;">
              <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            </el-button-group>
          </el-form-item>
        </el-form>
        <vxe-table ref="xTable"
                   :max-height="tableHeight"
                   :loading="dataListLoading"
                   :checkbox-config="checkboxConfig"
                   :column-config="columnConfig"
                   :row-config="rowConfig"
                   :data="dataList"
                   show-overflow border>
          <vxe-column type="checkbox" align="center" width="60"></vxe-column>
          <vxe-column field="code" title="编号" width="180"></vxe-column>
          <vxe-column field="name" title="名称"></vxe-column>
          <vxe-column field="typeName" title="分类" width="200"></vxe-column>
        </vxe-table>
        <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 元数据选择弹窗（左侧元数据分类，右侧元数据列表）
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'
import XEUtils from 'xe-utils'

export default {
  mixins: [mixinViewModule],
  name: 'ArchivemetaMultiSelect',
  data() {
    return {
      visible: false,
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/archiveMeta/page',
        getDataListIsPage: true,
        fetchDataSuccessCallback: this.dataSuccess
      },
      dataForm: {
        keyword: '', // 按编号或名称检索
        typeId: '' // 元数据分类ID
      },
      treeProps: {
        label: 'name',
      },
      treeLoading: false,
      treeData: [],
      tableHeight: 450,
      columnConfig: {
        resizable: true
      },
      checkboxConfig: {
        trigger: 'row',
        strict: true,
        checkMethod: this.checkSelect,
        reserve: true
      },
      rowConfig: {
        keyField: 'id',
        isCurrent: true,
        isHover: true
      },
      existIdList: [] // 已选中的数据
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getTypeData()
        this.getDataList()
      })
    },
    // 元数据分类点击事件
    treeNodeClick(data) {
      // 点击高亮节点则取消高亮
      if (this.dataForm.typeId == data.id) {
        this.dataForm.typeId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.dataForm.typeId = data.id
      }
      // 查询数据
      this.getDataList()
    },

    getTypeData() {
      this.treeLoading = true
      this.$http.get('/mps/archiveMetaType/list').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data
        this.dataForm.typeId = ''
      }).catch(() => {
        this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    checkSelect({row}) {
      let rs = true
      if (this.existIdList != null && this.existIdList.length > 0) {
        // 已选中的数据，禁止再次选择（选中/取消）
        rs = !XEUtils.includes(this.existIdList, row.id)
      }
      return rs
    },
    dataSuccess() {
      let $table = this.$refs.xTable
      if (this.existIdList != null && this.existIdList.length > 0) {
        // 已选择的元数据，默认为勾选选中状态
        XEUtils.arrayEach(this.dataList, (item) => {
          if (XEUtils.includes(this.existIdList, item.id)) {
            $table.setCheckboxRow(item, true) // 默认选中状态
          }
        })
      }
    },
    // 提交数据
    dataFormSubmitHandle: debounce(function () {
      const $table = this.$refs.xTable
      const checkboxRecord = $table.getCheckboxRecords() // 获取当前列表选中的数据
      const checkboxReserveRecord = $table.getCheckboxReserveRecords() // 获取非当前列表选中的数据
      let record = [...checkboxRecord, ...checkboxReserveRecord]
      if (this.existIdList != null && this.existIdList.length > 0) {
        // 过滤掉上次已选择的元数据记录列表,仅返回本次勾选的元数据记录
        record = XEUtils.filter(record, item => !XEUtils.includes(this.existIdList, item.id))
      }
      this.$emit('submit', record)
      this.visible = false
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>
<style scoped>
.tree-container {
  overflow: auto;
  height: 500px;
}
</style>
