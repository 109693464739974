<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div :style="divStyle">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.typeId" clearable class="w-percent-100">
            <el-option value="1" label="设计"></el-option>
            <el-option value="2" label="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.otherContractName" placeholder="合同名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <iframe style="margin-left: -5px" :src="iframeSrc" scrolling="auto" frameborder="0" id="iframe" width="99%" height="99%"></iframe>
    </div>
  </el-card>
</template>

<script>
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        otherContractName: '',
        typeId: ''
      },
      iframeSrc: '',
      divStyle: {
        height: ''
      }
    }
  },
  created () {
    this.setHeight()
  },
  methods: {
    // 项目切换事件
    prjChangeHandle (data) {
      this.getDataList()
    },
    getDataList () {
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        'prjId': this.dataForm.prjId,
        'name': this.dataForm.otherContractName, // 合同登记名称
        'typeId':this.dataForm.typeId, // 合同登记的合同类型
        '_u': 'icloud-合同支付台账.ureport.xml'
      })
      this.iframeSrc = `${window.SITE_CONFIG['apiURL']}/ur2/ureport/preview?${params}`
    },
    setHeight () {
      this.$nextTick(() => {
        this.divStyle.height = this.$refs.element.$el.clientHeight - 80 + 'px'
      })
    }
  }
}
</script>
