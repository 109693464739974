<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetylaw">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item v-show="projectSelectShow">
          <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true" @hide="hideProjectSelect" @change="projectChangeHandle"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="请输入标题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dataForm.publishDateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="publishDateRangeChangeHandle">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:safetylaw:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:safetylaw:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="title" label="标题" min-width="150" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('opm:safetylaw:update'))">{{scope.row.title}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="publisherName" label="发布人" width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="publishDate" label="发布时间" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="160">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:safetylaw:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:safetylaw:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:safetylaw:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './safetylaw-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/safetyLaw/page',
        getDataListIsPage: true,
        exportURL: '/opm/safetyLaw/export',
        deleteURL: '/opm/safetyLaw',
        createdIsNeed: false
      },
      dataForm: {
        prjId: '',
        title: '',
        publishDateRange: [],
        publishStartDate: '', // 查询条件 发布时间开始日期
        publishEndDate: ''  // 查询条件 发布时间结束日期
      },
      projectSelectShow: true,
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
  },
  methods: {
    // 项目切换事件
    projectChangeHandle (data) {
      this.getDataList()
    },

    hideProjectSelect () {
      this.projectSelectShow = false
    },

    // 新增 / 修改
    addOrUpdateHandle (id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.init()
      })
    },

    publishDateRangeChangeHandle () {
      if (!this.dataForm.publishDateRange || this.dataForm.publishDateRange.length == 0) {
        this.dataForm.publishStartDate = ''
        this.dataForm.publishEndDate = ''
      } else {
        this.dataForm.publishStartDate = this.dataForm.publishDateRange[0]
        if (this.dataForm.publishDateRange.length >= 2) {
          this.dataForm.publishEndDate = this.dataForm.publishDateRange[1]
        }
      }
    }
  }
}
</script>