<template>
  <el-dialog width="80%" v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="基本信息" name="tab1">
        <el-form key="first" :model="dataForm" ref="dataForm" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="期次" prop="periodId">
                <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId"
                               default-select :hasMeas="false" disabled></period-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标题" prop="name">
                <el-input v-model="dataForm.name" readonly></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item prop="sortNo" label="排序">
                <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序" disabled></el-input-number>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="材料明细" name="tab2">
        <vxe-table id="mxTable"
                   ref="mxTableRef"
                   :loading="mxDataListLoading"
                   size="mini"
                   :column-config="{resizable: true}"
                   border
                   show-overflow
                   :row-config="{isCurrent: true,isHover: true}"
                   :data="dataForm.details"
                   :max-height="tableHeight">
          <vxe-column field="materialName" title="材料名称" min-width="300" fixed="left"></vxe-column>
          <vxe-column field="spec" title="规格" width="200"></vxe-column>
          <vxe-column field="price" title="单价(元)" width="150" align="right"></vxe-column>
          <vxe-column field="advanceNum" title="本期垫付数量" width="150" align="right"></vxe-column>
          <vxe-column field="deductNum" title="本期扣回数量" width="150" align="right"></vxe-column>
          <vxe-column field="sortNo" title="排序号" width="80" align="center"></vxe-column>
        </vxe-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        periodId: '',
        name: '',
        sortNo: '',
        details: []
      },
      activeTabName: 'tab1',
      mxDataListLoading: false,
      tableHeight: 400,
    }
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {},
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.mxDataListLoading = true
      this.$http.get('/mps/materialDeduct/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.mxDataListLoading = false
      }).catch(() => {
        this.mxDataListLoading = false
      })
    }
  }
}
</script>