<template>
  <el-dialog :visible.sync="visible" title="查看" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="上级" prop="parentName">
            <el-input v-model="dataForm.parentName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="部位" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="形象单元" prop="scheduleUnitId">
            <scheduleunit-single-select v-model="dataForm.scheduleUnitId" :prjId="dataForm.prjId" readonly></scheduleunit-single-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="桩号" prop="stakeNo">
            <el-input v-model="dataForm.stakeNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图号" prop="drawingNo">
            <el-input v-model="dataForm.drawingNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitId" label="单位">
            <unit-select v-model="dataForm.unitId" :contract-id="dataForm.contractId" :disabled="true"></unit-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数量" prop="num">
            <el-input-number v-model="dataForm.num" :controls="false" placeholder="数量" style="width: 100%;" readonly></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dataForm.remark" type="textarea" autosize readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '0', // 根节点默认为0
        prjId: '',
        name: '',
        code: '',
        sortNo: 0,
        num: '',
        remark: '',
        parentName: ''
      }
    }
  },
  computed: {},
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/wbsBatch/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.pid === '0') {
          this.dataForm.parentName = '无'
        }
      }).catch(() => {
      })
    }
  }
}
</script>