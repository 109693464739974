<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false"
             v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="单位名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="单位名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item  label="项目名称" prop="prjId">
            <project-select v-model="dataForm.prjId" placeholder="项目选择" style="width: 100%;"></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="dataForm.phone" placeholder="电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="传真号码" prop="fax">
            <el-input v-model="dataForm.fax" placeholder="传真"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业网站" prop="website">
            <el-input v-model="dataForm.website" placeholder="网站"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人代表" prop="lawPerson">
            <el-input v-model="dataForm.lawPerson" placeholder="法人代表"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册日期" prop="registerDate">
            <el-date-picker type="date" placeholder="注册日期" value-format="yyyy-MM-dd" v-model="dataForm.registerDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码" prop="usco">
            <el-input v-model="dataForm.usco" placeholder="统一社会信用代码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="信用代码有效期" prop="uscoDate">
            <el-date-picker type="date" placeholder="信用代码有效期" value-format="yyyy-MM-dd" v-model="dataForm.uscoDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户名称" prop="accountName">
            <el-input v-model="dataForm.accountName" placeholder="开户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="depositName">
            <el-input v-model="dataForm.depositName" placeholder="开户银行"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" placeholder="开户账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注说明" prop="remark">
        <el-input type="textarea" v-model="dataForm.remark" autosize></el-input>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        deptId: '',
        prjId: '',
        name: '',
        phone: '',
        fax: '',
        email: '',
        website: '',
        lawPerson: '',
        registerDate: '',
        usco: '',
        uscoDate: '',
        accountName: '',
        depositName: '',
        bankAccount: '',
        remark: '',
        sortNo: '',
        tenantCode: '',
        delFlag: '',
        creator: '',
        createDate: '',
        updater: '',
        updateDate: '',
      }
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        prjId: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/sys/participantDept/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http.get(`/mps/project/deptId/${this.dataForm.prjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.deptId = res.data
          this.$http[!this.dataForm.id ? 'post' : 'put']('/sys/participantDept/', this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }).catch(() => {
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>