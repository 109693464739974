<template>
  <h2 v-if="!dataForm.id" style="margin-left: 10px">
    暂无数据
  </h2>
  <el-form class="form_application" v-else :model="dataForm" ref="dataForm" label-width="120px">
    <div class="propreinfor">
      <el-row>
        <el-col :span="24">
          <el-form-item label="工程/费用名称">
            <div class="content">
              {{dataForm.subItemName}}
            </div>
          </el-form-item>
        </el-col>
        <el-col v-if="dataForm.wbsId != dataForm.subItemId" :span="24">
          <el-form-item label="分部分项工程">
            <div class="content">
              {{dataForm.wbsPnames}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="起讫桩号">
            <div class="content">
              {{dataForm.stakeNo}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="交工证书">
            <div class="content">
              {{dataForm.ctoa}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图号">
            <div class="content">
              {{dataForm.drawingNo}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计量部位">
            <div class="content">
              {{dataForm.name}}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计量单号">
            <div class="content">
              {{dataForm.code}}
            </div>
          </el-form-item>
        </el-col>
        <!-- 此处可能会加字段：“册数” -->
        <el-col :span="24">
          <el-form-item label="计算式">
            <div class="content">
              {{dataForm.formula}}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="dataForm.isEntity">
        <el-col :span="8">
          <el-form-item label="开工报告">
            <div class="content">
              <i v-if="dataForm.qaFileState >= 1" style="color: green" class="el-icon-check"></i>
              <i v-else style="color: red; font-weight: bold;" class="el-icon-close"></i>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="检验申请批复">
            <div class="content">
              <i v-if="dataForm.qaFileState >= 2" style="color: green" class="el-icon-check"></i>
              <i v-else style="color: red; font-weight: bold;" class="el-icon-close"></i>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="交工证书">
            <div class="content">
              <i v-if="dataForm.qaFileState >= 3" style="color: green" class="el-icon-check"></i>
              <i v-else style="color: red; font-weight: bold;" class="el-icon-close"></i>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        id: '',
        measId: '',
        code: '',
        formula: '',
        name: '',
        stakeNo: '',
        drawingNo: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      }
    }
  },
  methods: {
    // 获取信息
    getInfo () {
      if (!this.dataForm.id) {
        return
      }
      this.$http.get('/mps/measTicket/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
<style lang="scss" scoped>
.form_application {
  //form-class
  .propreinfor {
    //上边框，左边框
    border-left: 1px solid rgb(192, 196, 204);
    border-top: 1px solid rgb(192, 196, 204);
    margin: 0 auto;
  }

  .content {
    padding: 5px 5px 0 5px;
    border-right: 1px solid rgb(192, 196, 204);
    border-bottom: 1px solid rgb(192, 196, 204);
    min-height: 50px !important;
  }

  /deep/.el-form-item {
    //每一项
    position: relative;
    height: 100%;
    margin-bottom: 0;
    height: auto !important;
  }

  /deep/ .el-form-item__label {
    //左边的laebel以及颜色，高度，字体等
    position: absolute;
    height: 100%;
    border-right: 1px solid rgb(192, 196, 204);
    border-bottom: 1px solid rgb(192, 196, 204);
    padding: 10px;
    background: #f9f9f9;
    font-weight: 400;
    color: #000;
    line-height: normal;
    display: flex;
    align-items: center;//垂直居中
    justify-content: left;//左右居中
  }

  /deep/.el-form-item__content {
    //右边输入框或者下拉框等
    line-height: 40px;
    height: 100%;
  }
  /deep/.el-form-item__error {
    //校验规则的样式，位于输入框下方
    z-index: 999;
    padding-top: 0;
    left: 10px;
    top: calc(100% - 5px);
  }
}
</style>
