<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="pid" label="上级">
            <materialtype-parent-select v-model="dataForm.pid" :disabledId="dataForm.id"></materialtype-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2 }" placeholder="备注" v-model="dataForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import MaterialtypeParentSelect from './materialtype-parent-select'

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          pid: 0,
          pids: '',
          pnames: '',
          name: '',
          code: '',
          remark: '',
          tenantCode: '',
          parentName: '',
          sortNo: 0
        },
        oldPid: '' // 记录数据原始的上级节点ID
      }
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          code: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          pid: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ]
        }
      }
    },
    components: {
      MaterialtypeParentSelect
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.getInfo()
          } else {
            // 获取排序序号
            this.getNewSortNo()
          }
        })
      },
      // 获取排序序号
      getNewSortNo () {
        this.$http.get(`/mps/materialType/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.sortNo = res.data
        }).catch(() => {
          // do nothing
        })
      },
      // 获取信息
      getInfo () {
        this.$http.get('/mps/materialType/' + this.dataForm.id).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.oldPid = this.dataForm.pid
        }).catch(() => {
          // do nothing
        })
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/materialType/',this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (this.dataForm.id) {
              this.$emit('updateNode',this.dataForm.pid,this.oldPid != this.dataForm.pid)
            } else {
              this.$emit('addNode',this.dataForm.pid)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
              }
            })
          }).catch(() => {
            // do nothing
          })
        })
      },1000,{'leading': true,'trailing': false})
    }
  }
</script>