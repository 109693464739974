<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__reporttemplate">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-select v-model="dataForm.typeId" clearable class="w-percent-80">
            <el-option value="1" label="施工单位计量报表"></el-option>
            <el-option value="2" label="监理单位计量报表"></el-option>
            <el-option value="3" label="造价管理台账报表"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable style="width: 160px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="编码" clearable style="width: 120px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:reporttemplate:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:reporttemplate:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="计量与造价管理台账的报表模板，各项目可在此基础上进行适配调整" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="previewLuckysheetHandle(row)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="编号" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="typeId" label="报表分类" align="center" sortable="custom">
          <template v-slot="{row}">
            {{row.typeLabel}}
          </template>
        </el-table-column>
        <el-table-column prop="dsId" label="数据源" align="center" :formatter="dsFormatter" sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="200">
          <template v-slot="{row}">
            <table-button role="design" v-if="$hasPermission('mps:reporttemplate:design')" @click="designHandle(row)"></table-button>
            <table-button role="copy" v-if="$hasPermission('mps:reporttemplate:copy')" @click="copyHandle(row)"></table-button>
            <table-button role="update" v-if="$hasPermission('mps:reporttemplate:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:reporttemplate:delete')" @click="deleteHandle(row.id)"></table-button>
            <!--<table-button role="files" type="text" size="small" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:reporttemplate:update'))"></table-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- luckysheet设计界面 -->
      <my-luckysheet-design v-if="luckysheetVisible" ref="luckysheet" @saveData="saveSheetDataHandle" @close="closeLuckysheetHandle"></my-luckysheet-design>
      <!-- 计量报表预览 -->
      <my-luckysheet-preview v-if="luckysheetPreviewVisible" ref="luckysheetPreview" @close="closeLuckySheetPreviewHandle"></my-luckysheet-preview>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './reporttemplate-add-or-update'
import MyLuckysheetDesign from '@/components/my-luckysheet-design'
import MyLuckysheetPreview from '@/components/my-luckysheet-preview'
export default {
  mixins: [mixinViewModule],
  components: {
    AddOrUpdate,
    MyLuckysheetDesign,
    MyLuckysheetPreview
  },
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/reportTemplate/page',
        getDataListIsPage: true,
        exportURL: '/mps/reportTemplate/export',
        deleteURL: '/mps/reportTemplate',
      },
      dataForm: {
        code: '',
        name: '',
        typeId: null
      },
      luckysheetVisible: false,
      luckysheetPreviewVisible: false,
    }
  },
  methods: {
    // 绑定的数据源
    dsFormatter(row, column) {
      switch (row.dsId) {
        case 0:
          return "通用数据源";
        case 1:
          return "中期支付证书";
        case 2:
          return "清单计量支付表";
        case 42:
          return "清单计量支付表（暂定计量）";
        case 3:
          return "中间计量汇总表";
        case 43:
          return "中间计量汇总表（暂定计量）";
        case 4:
          return "中间计量表";
        case 44:
          return "中间计量表（暂定计量）";
        case 5:
          return "开工预付款申请";
        case 6:
          return "开工预付款扣回";
        case 7:
          return "扣回材料设备款一览表";
        case 8:
          return "工程变更一览表";
        case 9:
          return "工程变更汇总表";
        case 10:
          return "监理服务费支付报表";
        case 11:
          return "工程进度表";
        case 12:
          return "扣回动员预付款一览表";

        // 以下是广东造价台账（概预算报表）数据源
        case 30:
          return "造价台账封面";
        case 31:
          return "造价管理台账1";  
        case 32:
          return "造价管理台账2";
        case 33:
          return "造价管理台账3";
        case 34:
          return "造价管理台账4";
        case 35:
          return "造价管理台账5";
        case 36:
          return "造价管理台账6";
        default:
          return "";
      }
    },
    // 打开设计窗口
    designHandle(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/reportTemplate/jsonData/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.luckysheetVisible = true
        this.$nextTick(() => {
          loading.close()
          this.$refs.luckysheet.refId = row.id
          this.$refs.luckysheet.title = row.name
          this.$refs.luckysheet.jsonData = res.data
          this.$refs.luckysheet.initLuckysheet()
        })
      }).catch(() => {
        loading.close()
      })
    },
    // 保存luckysheet的sheetData
    saveSheetDataHandle (id, jsonData) {
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.put(`/mps/reportTemplate/jsonData/${id}`, {data: jsonData}).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            loading.close()
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        loading.close()
      })
    },
    closeLuckysheetHandle () {
      this.luckysheetVisible = false
    },
    previewLuckysheetHandle(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/reportTemplate/jsonData/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.luckysheetPreviewVisible = true
        this.$nextTick(() => {
          loading.close()
          this.$refs.luckysheetPreview.title = row.name
          this.$refs.luckysheetPreview.jsonData = res.data
          this.$refs.luckysheetPreview.initByJson()
        })
      }).catch(() => {
        loading.close()
      })
    },
    // 关闭计量报表预览窗口
    closeLuckySheetPreviewHandle () {
      this.luckysheetPreviewVisible = false
    },
    copyHandle(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在复制...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/reportTemplate/copy/${row.id}`).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: '复制成功',
          type: 'success',
          duration: 2000,
          onClose: () => {
            this.getDataList()
          }
        })
      }).catch().finally(() => {
        loading.close()
      })
    },
  }
}
</script>