<template>
  <el-dialog title="选择工序" top="5vh" v-dialog-drag :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tree
      class="filter-tree"
      v-loading="treeLoading"
      :data="treeData"
      :load="loadNode"
      lazy
      node-key="id"
      :props="treeProps"
      ref="workstageTree"
      show-checkbox
      :check-on-click-node="true"
      :highlight-current="true">
    </el-tree>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  // 工序库多选
  import debounce from 'lodash/debounce'
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          code: '',
          name: '',
          sortNo: ''
        },
        treeLoading: false,
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'name',
          isLeaf: 'isLeaf',
          disabled: this.nodeDisabled,
        },
      }
    },
    computed: {},
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          this.getTreeData()
        })
      },

      getTreeData () {
        this.treeLoading = true
        this.$nextTick(() => {
          this.$http.get('/mps/wbsWorkstageBind/workstage/children',
            {
              params: {
                pid: "0"
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.treeData = res.data
            this.treeLoading = false
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            this.treeLoading = false
          })
        })
      },

      loadNode (node,resolve) {
        if (node.level > 0) {
          let pid = node.data.id
          this.$http.get('/mps/wbsWorkstageBind/workstage/children',
            {
              params: {
                pid: pid
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data);
          }).catch(() => {
            return this.$message.error('出错了')
          })
        }
      },
      // 工序节点是否可选择
      nodeDisabled (data, node) {
        if (data.isLeaf) {
          return false
        }
        // 不允许选择
        return true
      },
      // 点击确定
      submitHandle: debounce(function () {
        this.visible = false
        let selectNodes = this.$refs.workstageTree.getCheckedNodes(true)
        this.$emit('selectItems', selectNodes)
      }, 1000, {'leading': true, 'trailing': false})
    }
  }
</script>
<style lang="scss" scope>
  .filter-tree {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
</style>