<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__boqtemplatemain">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="范本名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:boqtemplate:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:boqtemplate:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="范本名称" min-width="150" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sortNo" label="排序" width="80" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="200">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:boqtemplate:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="import" v-if="$hasPermission('mps:boqtemplate:import')" @click="boqTemplateImportHandle(row.id)"></table-button>
            <table-button role="export" v-if="$hasPermission('mps:boqtemplate:export')" @click="boqTemplateExportHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:boqtemplate:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/boqTemplateMain/import" thumbnail="import_boqtemplate.png"></excel-import>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './boqtemplatemain-add-or-update'
import ViewPage from './boqtemplatemain-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/boqTemplateMain/page',
        getDataListIsPage: true,
        exportURL: '/mps/boqTemplateMain/export',
        deleteURL: '/mps/boqTemplateMain',
      },
      dataForm: {
        name: '',
      },
      order: 'desc', // 排序，asc／desc
      orderField: 'sort_no'
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    boqTemplateExportHandle(id){
      this.dataForm.mainId = id
      this.exportHandle()
    },
    boqTemplateImportHandle (id) {
      this.dataForm.mainId = id
      this.importHandle()
    },
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.mainId = id
        this.$refs.view.init()
      })
    }
  }
}
</script>