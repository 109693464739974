<template>
  <p v-if="!measTicketId" style="margin-left: 10px; font-size: 16px;">
    请选择具体分项
  </p>
  <p v-else-if="!wbsId" style="margin-left: 10px; font-size: 16px;">
    未绑定分部分项工程
  </p>
<!--  <div v-else>-->
<!--    <my-upload ref="uploader" :refId="refId" typeId="普通附件" :view="!canEdit" :tableMaxHeight="height"></my-upload>-->
<!--  </div>-->
  <el-tabs v-else tab-position="left" :style="{height: height + 'px'}">
    <el-tab-pane label="开工报告">
      <my-upload v-if="wbsId" key="a" ref="uploader" :refId="wbsId" typeId="开工报告" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 1)"></my-upload>
    </el-tab-pane>
    <el-tab-pane label="检验申请批复">
      <my-upload v-if="wbsId" key="b" ref="uploader" :refId="wbsId" typeId="检验申请批复" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 2)"></my-upload>
      <!--      <el-container>-->
<!--        <el-aside width="180px">-->
<!--          <vxe-table-->
<!--            ref="xTable"-->
<!--            resizable-->
<!--            show-overflow-->
<!--            height="auto"-->
<!--            border="none"-->
<!--            :show-header="false"-->
<!--            :data="workstageList"-->
<!--            :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"-->
<!--            empty-text="该分项没有关联的工序"-->
<!--            cell-class-name="pointer-cursor"-->
<!--            @cell-click="treeCellClick"-->
<!--          >-->
<!--            <vxe-column field="name" width="160"></vxe-column>-->
<!--          </vxe-table>-->
<!--        </el-aside>-->
<!--        <el-main>-->
<!--          <span v-if="workstageList.length == 0"></span>-->
<!--          <my-upload v-else-if="workstageId" ref="uploader" :refId="workstageId" typeId="检验申请批复" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 2)"></my-upload>-->
<!--          <p v-else style="margin-left: 10px; font-size: 16px;">-->
<!--            请选择具体工序查看检验申请批复文件-->
<!--          </p>-->
<!--        </el-main>-->
<!--      </el-container>-->
    </el-tab-pane>
    <el-tab-pane label="交工证书">
      <my-upload v-if="wbsId" key="c" ref="uploader" :refId="wbsId" typeId="交工证书" :view="!canEdit" @uploadSuccess="updateWbsFileState($event, 3)"></my-upload>
    </el-tab-pane>
    <el-tab-pane label="工序报验">
      <workstage-inspection v-if="wbsId" ref="workstageInspection" :wbsId="wbsId"></workstage-inspection>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import WorkstageInspection from './meas-workstage-inspection'
export default {
  data () {
    return {
      measTicketId: '',
      wbsId: '',
      workstageId: '',
      canEdit: false,
      workstageList: []
    }
  },
  components: {
    WorkstageInspection,
  },
  props: {
    height: Number
  },
  methods: {
    init() {
      this.wbsId = ''
      this.workstageId = ''
      if (this.measTicketId) {
        this.getMeasTicketInfo()
      }
    },
    getMeasTicketInfo() {
      this.$http.get('/mps/measTicket/' + this.measTicketId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data) {
          this.wbsId = res.data.wbsId
          if (this.wbsId) {
            this.$http.get('/mps/wbsWorkstage/refList/' + this.wbsId).then(({data: res}) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              }
              this.workstageList = res.data
              // 加载工序报验影像资料
              this.$refs.workstageInspection.wbsId = this.wbsId
              this.$refs.workstageInspection.workstageList = res.data
              this.$refs.workstageInspection.init()
              // 检验申请批复现在也关联在wbs上，所以此处先注释掉
              // // 默认选中第一条
              // if (res.data && res.data.length > 0) {
              //   this.$nextTick(() => {
              //     let defaultRow = this.$refs.xTable.getRowById(res.data[0].id)
              //     this.$refs.xTable.setCurrentRow(defaultRow)
              //     this.workstageId = defaultRow.id
              //   })
              // }
            }).catch()
          }
        }
      }).catch()
    },
    treeCellClick({row}) {
      this.workstageId = row.id
    },
    updateWbsFileState(file, typeId) {
      this.$http.post(
          '/mps/measTicket/updateWbsFileState',
          {
            wbsId: this.wbsId,
            typeId: typeId,
            fileName: file.name
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
      }).catch(() => {})
    }
  }
}
</script>
