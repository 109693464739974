<template>
  <el-dialog v-dialog-drag top="10vh" :visible.sync="visible" title="前期工作配置" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tree
        v-loading="preWorkLibListDataLoading"
        class="filter-tree"
        :data="preWorkLibList"
        :props="{ label: 'name', children: 'children' }"
        node-key="id"
        ref="preWorkLibListTree"
        highlight-current
        default-expand-all
        show-checkbox>
    </el-tree>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        prjId: '',
      },
      preWorkLibList: [],
      preWorkLibListDataLoading: false,
      defaultCheckedIdList: []
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.preWorkLibListDataLoading = true
        Promise.all([
          this.getPreWorkLibList(),
          this.getWorkLibCheckedIdList()
        ]).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.preWorkLibListDataLoading = false
        })
      })
    },
    // 获取前期项目已配置的前期标准库信息
    getWorkLibCheckedIdList() {
      return this.$http.get('/opm/preProjectWorkLib/list', {
        params: {
          prjId: this.dataForm.prjId
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.length != 0) {
          res.data.forEach(item => {
            this.defaultCheckedIdList.push(item.workLibId)
          })
        }
        // 设置目前已配置的前期工作流
        this.$refs.preWorkLibListTree.setCheckedKeys(this.defaultCheckedIdList)
      })
    },
    // 获取 前期管理标准库列表
    getPreWorkLibList() {
      return this.$http.get('/opm/preWorkLib/treeList').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.preWorkLibList = res.data
      })
    },

    submitHandle: debounce(function () {
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.dataForm.workLibIdList = this.$refs.preWorkLibListTree.getCheckedKeys(true)
      this.$http['post']('/opm/preProject/batchSaveWorkLib', this.dataForm).then(({data: res}) => {
        loading.close()
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>
<style lang="scss" scope>
.filter-tree {
  max-height: calc(90vh - 300px);
  overflow: auto;
}
</style>