<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')"
             :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners" width="60%">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="标段名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="标段名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标段简称" prop="shortName">
            <el-input v-model="dataForm.shortName" placeholder="标段简称" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="合同编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标段编号" prop="section">
            <span slot="label" style="display:inline-block;">
              标段编号
              <el-tooltip effect="light" content="用于组成计量报表的支付编号" placement="bottom">
                <i class='el-icon-question'/>
              </el-tooltip>
            </span>
            <el-input v-model="dataForm.section" placeholder="标段编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同类型" prop="typeId">
            <el-select v-model="dataForm.typeId" clearable class="w-percent-100">
              <el-option value="1" label="监理合同"></el-option>
              <el-option value="2" label="施工合同"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工单位" prop="deptId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.deptId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" placeholder="起讫桩号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="标段长度" prop="length">
            <el-input v-model="dataForm.length">
              <template slot="append">km</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="总监单位" prop="chiefSupervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.chiefSupervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="监理单位" prop="supervisionId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.supervisionId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="咨询单位" prop="consultationId">
            <participant-dept-select :prj-id="dataForm.prjId" v-model="dataForm.consultationId" placeholder="请选择" style="width: 100%;"></participant-dept-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="合同金额" prop="cost">
            <el-input v-model="dataForm.cost">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="开标日期" prop="openingDate">
            <el-date-picker type="date" placeholder="开标日期" value-format="yyyy-MM-dd" v-model="dataForm.openingDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="开工令日期" prop="startDate">
            <el-date-picker type="date" placeholder="开工日期" value-format="yyyy-MM-dd" v-model="dataForm.startDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划完工日期" prop="endDate">
            <el-date-picker type="date" placeholder="计划完工日期" value-format="yyyy-MM-dd" v-model="dataForm.endDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划工期" prop="planMonths">
            <el-input v-model="dataForm.planMonths">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="招标清单预算" prop="biddingBudget">
            <el-input-number v-model="dataForm.biddingBudget" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标价" prop="biddingPrice">
            <el-input-number v-model="dataForm.biddingPrice" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="标底/投标限价" prop="biddingLimit">
            <el-input-number v-model="dataForm.biddingLimit" data-unit="元" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标下浮率" prop="floatingRate">
            <el-input-number v-model="dataForm.floatingRate" controls-position="right" :min="0" :max="1"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="法人代表" prop="lawPerson">
            <el-input v-model="dataForm.lawPerson" placeholder="法人代表"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码" prop="usco">
            <el-input v-model="dataForm.usco" placeholder="统一社会信用代码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户名称" prop="accountName">
            <el-input v-model="dataForm.accountName" placeholder="开户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="depositBank">
            <el-input v-model="dataForm.depositBank" placeholder="开户银行"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" placeholder="开户账号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="主要内容" prop="mainTerms">
            <el-input type="textarea" v-model="dataForm.mainTerms" placeholder="主要条款" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="备注说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import {checkNumber} from "@/utils/form-validate";

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        name: '',
        shortName: '',
        code: '',
        typeId: '',
        deptId: '',
        sspo: '',
        chiefSupervisionId: '',
        supervisionId: '',
        consultationId: '',
        lawPerson: '',
        usco: '',
        accountName: '',
        depositBank: '',
        bankAccount: '',
        cost: '',
        startDate: '',
        endDate: '',
        remark: '',
        authority: '',
        sortNo: '',
        hasSubcontractor: '',
        allowExceed: 0,
        amountAccuracy: '',
        percentAccuracy: '',
        delFlag: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        length: '',
        biddingBudget: '',
        biddingLimit: '',
        biddingPrice: '',
        openingDate: '',
        floatingRate: '',
        mainTerms: ''
      },
      autosize: {
        minRows: 3,
        maxRows: 16
      }
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        shortName: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        cost: [
          {validator: checkNumber, trigger: 'change'}
        ],
        planMonths: [
          {validator: checkNumber, trigger: 'change'}
        ],
        section: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/contract/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/contract/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>