<template>
  <div>
    <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="方案名称" prop="name">
              <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="dataForm.id" label="排序序号" prop="sortNo">
              <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <vxe-table ref="xTable" size="mini"
                 :column-config="{resizable: true}"
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 :loading="dataLoading"
                 :max-height="500"
                 border show-overflow>
        <vxe-column type="seq" title="序号" width="80" align="center">
          <template v-slot:header>
            <vxe-button size="mini" status="primary" content="添加" @click="openBatchSelectDialog()" round></vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="metaCode" title="元数据编号" align="center" width="200"></vxe-column>
        <vxe-column field="metaName" title="元数据名称" min-width="180"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <archive-meta-multi-select v-if="batchSelectVisible" ref="batchSelect" @submit="addDataList" @close="closeBatchSelectDialog"></archive-meta-multi-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import XEUtils from 'xe-utils'
import ArchiveMetaMultiSelect from './archivemeta-multi-select'

export default {
  components: {
    ArchiveMetaMultiSelect
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        name: '',
        sortNo: ''
      },
      dataLoading: false,
      dataList: [],
      // 缓存已选择元数据的ID集合,避免新增时重复添加
      existMetaIdList: [],
      batchSelectVisible: false
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.dataLoading = true
      this.$http.get('/mps/archiveScheme/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          this.dataLoading = false
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.dataList = res.data.mxList ? res.data.mxList : []
        this.existMetaIdList = XEUtils.map(this.dataList, item => item.metaId)
        this.dataLoading = false
      }).catch(() => {
        this.dataLoading = false
      })
    },

    // 打开多选弹窗
    openBatchSelectDialog() {
      this.batchSelectVisible = true
      this.$nextTick(() => {
        this.$refs.batchSelect.existIdList = this.existMetaIdList
        this.$refs.batchSelect.init()
      })
    },
    addDataList(list) {
      for (let i = 0; i < list.length; i++) {
        let data = list[i]
        this.dataList.push({id: '', mainId: this.dataForm.id, metaId: data.id, metaCode: data.code, metaName: data.name})
        this.existMetaIdList.push(data.id)
      }
    },
    closeBatchSelectDialog() {
      this.batchSelectVisible = false
    },

    deleteOneHandle(row) {
      XEUtils.remove(this.existMetaIdList, id => id == row.metaId)
      XEUtils.remove(this.dataList, item => item.metaId == row.metaId)
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.dataForm.mxList = this.dataList
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archiveScheme/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>