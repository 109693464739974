<template>
  <el-dialog width="70%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="合同名称" prop="otherContractId">
            <el-input v-model="dataForm.otherContractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编码" prop="otherContractCode">
            <el-input v-model="dataForm.otherContractCode" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="otherContractAmount">
            <el-input readonly v-model="dataForm.otherContractAmount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签订单位" prop="otherContractSecondParty">
            <el-input readonly v-model="dataForm.otherContractSecondParty"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="申请日期" prop="applyDate">
            <el-date-picker readonly type="date" value-format="yyyy-MM-dd" v-model="dataForm.applyDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支付金额" prop="paymentAmount">
            <el-input readonly v-model="dataForm.paymentAmount">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="请款人" prop="requestName">
            <user-select v-model="dataForm.requestId" style="width: 100%" disabled></user-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="用途" prop="purpose">
            <el-input readonly type="textarea" v-model="dataForm.purpose" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="bank">
            <el-input readonly v-model="dataForm.bank"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名称" prop="bankAccountName">
            <el-input readonly v-model="dataForm.bankAccountName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行账号" prop="bankAccount">
            <el-input readonly v-model="dataForm.bankAccount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input readonly type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        otherContractId: '',
        otherContractName: '',
        otherContractAmount: '',
        otherContractSecondParty: '',
        otherContractCode: '',
        applyDate: '',
        requestId: '',
        paymentAmount: '',
        purpose: '',
        bank: '',
        bankAccountName: '',
        bankAccount: '',
        remark: '',
        deptName: '',
        requestName: '',
        processInstanceId: '',
        processStatus: ''
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/otherContractPay/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do noting
      })
    },
  }
}
</script>
