<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="期次名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="期次序号" prop="code">
            <el-input-number v-model="dataForm.code" controls-position="right" :min="0" label="期次序号" disabled></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.startDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="截止日期" prop="endDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.endDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="isActive">
            <el-checkbox v-model="dataForm.isActive" :true-label="1" disabled>设置为当前期</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        code: '',
        name: '',
        startDate: '',
        endDate: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        isActive: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/period/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
