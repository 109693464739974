<template>
  <el-dialog :visible.sync="visible" width="70%" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="巡视日期" prop="inspectDate">
            <el-date-picker type="date" readonly value-format="yyyy-MM-dd" v-model="dataForm.inspectDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="巡视人" prop="inspector">
            <el-input v-model="dataForm.inspector" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="巡视范围" prop="scope">
            <el-input type="textarea" v-model="dataForm.scope" :autosize="{ minRows: 4, maxRows: 6}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="主要施工情况" prop="constructionDesc">
            <el-input type="textarea" v-model="dataForm.constructionDesc" :autosize="{ minRows: 4, maxRows: 6}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="质量、安全、环保情况" prop="qseDesc">
            <el-input type="textarea" v-model="dataForm.qseDesc" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="发现的问题及处理意见" prop="problem">
            <el-input type="textarea" v-model="dataForm.problem" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        code: '',
        inspector: '',
        inspectDate: '',
        scope: '',
        constructionDesc: '',
        qseDesc: '',
        problem: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/superviseInspect/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        //  do nothing
      })
    },
  }
}
</script>
