<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-flowable__process">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.processTitle" placeholder="流程主题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="dataForm.finishedTime" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="办理开始" end-placeholder="办理结束" @change="finishedTimeChange" style="width: 240px"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle" style="width: 100%;">
          <el-table-column prop="processTitle" label="流程主题" header-align="center" align="left">
          <template slot-scope="scope">
            <a href="javascript:;" @click="showDetail(scope.row)">{{scope.row.processTitle}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="startUser.realName" label="发起人" align="center" width="120" />
        <el-table-column prop="startTime" label="接收时间" align="center" width="140" />
        <el-table-column prop="endTime" label="办理时间" align="center" width="140" />
        <el-table-column prop="duration" label="耗时" align="center" width="120"  />
        <el-table-column prop="businessStatus" label="流程状态" align="center" width="110">
          <template slot-scope="scope">
            <span :style="{ color: getBusinessStatusColor(scope.row.businessStatus) }">{{ getBusinessStatusTxt(scope.row.businessStatus) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/flowable/process/done/page',
        getDataListIsPage: true
      },
      dataForm: {
        processTitle: '',
        finishedBeginTime: '',
        finishedEndTime: '',
        finishedTime: ''
      }
    }
  },
  methods: {
    // 显示流程详情
    showDetail (row) {
      if (!row.businessKey) {
        return this.$message.error(this.$t('task.detailError'))
      }
      this.getProcDefRouteSet(row, this.forwardDetail)
    },
    finishedTimeChange(finishedTimeList) {
      if (finishedTimeList) {
        this.dataForm.finishedBeginTime = finishedTimeList[0] + ' 00:00:00'
        this.dataForm.finishedEndTime = finishedTimeList[1] + ' 23:59:59'
      } else {
        this.dataForm.finishedBeginTime = ''
        this.dataForm.finishedEndTime = ''
      }
    }
  }
}
</script>
