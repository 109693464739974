<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners" width="60%">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目简称" prop="shortName">
            <el-input v-model="dataForm.shortName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目类型" prop="typeId">
            <el-select v-model="dataForm.typeId" placeholder="请选择项目类型" :style="{ width: '100%' }" disabled>
            <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建设单位" prop="construction">
            <el-input v-model="dataForm.construction" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目地址" prop="address">
            <el-input v-model="dataForm.address" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目公司" prop="deptName">
            <el-input v-model="dataForm.deptName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="投资总额" prop="totalCost">
            <el-input v-model="dataForm.totalCost" readonly>
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建安费用" prop="ceCost">
            <el-input v-model="dataForm.ceCost" readonly>
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划工期" prop="months">
            <el-input v-model="dataForm.months" readonly>
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起讫桩号" prop="sspo">
            <el-input v-model="dataForm.sspo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开工日期" prop="startDate">
            <el-date-picker style="width: 100%" v-model="dataForm.startDate" :format="dateFormat" value-format="yyyy-MM-dd" type="date" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="完工日期" prop="endDate">
            <el-date-picker style="width: 100%" v-model="dataForm.endDate" :format="dateFormat" value-format="yyyy-MM-dd" type="date" readonly></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="建设里程" prop="km">
            <el-input placeholder="公里数" v-model="dataForm.km" readonly>
              <template slot="append">公里</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建筑面积" prop="area">
            <el-input placeholder="建筑面积" v-model="dataForm.area" readonly>
              <template slot="append">平米</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="项目概况" prop="remark">
        <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 2 }" readonly></el-input>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="经度" prop="contact">
            <el-input v-model="dataForm.longitude" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="纬度" prop="phone">
            <el-input v-model="dataForm.latitude" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目状态" prop="statusId">
            <el-select v-model="dataForm.statusId" disabled>
              <el-option :value="1" label="建设中"></el-option>
              <el-option :value="2" label="已完工"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="数字孪生工程ID" prop="dtsPid">
            <el-input v-model="dataForm.dtsPid" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数字孪生默认项目" prop="dtsDefault">
            <el-switch v-model="dataForm.dtsDefault" :active-value="1" :inactive-value="0" disabled></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dateFormat: 'yyyy 年 MM 月 dd 日',
      dataForm: {},
      typeOptions: [{ // 项目类型
        value: 1,
        label: '高速公路'
      }, {
        value: 2,
        label: '一级公路'
      }, {
        value: 3,
        label: '二级公路'
      },{
        value: 99,
        label: '其他'
      }],
    }
  },
  computed: {},
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/project/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...res.data
        }
      }).catch(() => {
      })
    }
  }
}
</script>
