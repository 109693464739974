<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__wbs">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <contract-cascader ref="contractCascader" include-sub @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button name="引用模板" icon="el-icon-paperclip" v-if="dataForm.contractId && locked != 1 && $hasPermission('mps:wbs-batch:quote')" @click="quoteTemplate()"></toolbar-button>
            <toolbar-button role="add" v-if="dataForm.contractId && locked != 1 && $hasPermission('mps:wbs-batch:save')" @click="addChildNode({id: 0})"></toolbar-button>
            <toolbar-button role="delete" v-if="dataForm.contractId && locked != 1 && $hasPermission('mps:wbs-batch:delete')" @click="myDeleteHandle()"></toolbar-button>
            <toolbar-button role="import" v-if="dataForm.contractId && locked != 1 && $hasPermission('mps:wbs-batch:import')" @click="importHandle"></toolbar-button>
            <toolbar-button role="export" v-if="dataForm.contractId && $hasPermission('mps:wbs-batch:export')" @click="exportHandle"></toolbar-button>
            <toolbar-button role="lock" v-if="dataForm.contractId && locked != 1"  @click="lock()" :disabled="!$hasPermission('mps:wbs-batch:lock')"></toolbar-button>
            <toolbar-button role="unlock" v-if="dataForm.contractId && locked == 1" @click="unlock()" :disabled="!$hasPermission('mps:wbs-batch:lock')"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-if="tableShow"
        ref="wbsTable"
        v-loading="tableLoading"
        :data="tableData"
        row-key="id"
        lazy
        @row-click="rowClick"
        :load="loadTableData"
        @selection-change="dataListSelectionChangeHandle"
        border
        highlight-current-row
        style="width: 100%;"
        :height="wbsTableHeight"
        :row-style="{height: '0px'}"
        :cell-style="{padding: '2px 0'}"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="部位" header-align="center" min-width="150"></el-table-column>
        <el-table-column prop="code" label="编号" header-align="center" min-width="200"></el-table-column>
        <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="100"></el-table-column>
        <el-table-column prop="num" label="工程量" header-align="center" align="center" width="150"></el-table-column>
        <el-table-column prop="stakeNo" label="桩号" header-align="center" align="center" min-width="150"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="170">
          <template v-slot="{row}">
            <!-- 锁定时只允许有查看按钮 -->
            <el-button v-if="locked == 1" type="text" size="small" @click="viewHandle(row.id)">查看</el-button>
            <!-- 不锁定时有下拉更多按钮 -->
            <el-dropdown v-if="locked != 1" @command="(command) => handleCommand(command, row)" :show-timeout="100">
              <el-button type="text" size="small" @click="viewHandle(row.id)">查看
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-show="$hasPermission('mps:wbs-batch:save')" command="a">新增下级</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs-batch:update')" command="b">修改</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs-batch:delete')" command="c">删除</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs-batch:save')" command="d">批量插入下级</el-dropdown-item>
                <el-dropdown-item v-show="$hasPermission('mps:wbs-batch:save')" command="e">批量复制</el-dropdown-item>
                <el-dropdown-item command="up">上移</el-dropdown-item>
                <el-dropdown-item command="down">下移</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            &nbsp;&nbsp;
<!--            <el-button type="text" size="small" @click="viewHandle(row.id)">查看</el-button>-->
<!--            <el-button v-if="$hasPermission('mps:wbs:save')" type="text" size="small" @click="addChildNode(row)">新增下级</el-button>-->
<!--            <el-button v-if="$hasPermission('mps:wbs:update')" type="text" size="small" @click="addOrUpdateHandle(row.id)">{{ $t('update') }}</el-button>-->
<!--            <el-button v-if="$hasPermission('mps:wbs:delete')" type="text" size="small" @click="myDeleteHandle(row.id, row.pid)">{{ $t('delete') }}</el-button>-->
            <el-button type="text" size="small" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:wbs:update') || locked == 1, row.name)">附件</el-button>
            <!-- <el-tooltip content="上移" placement="top">
              <el-button type="text" size="small" @click="moveBackward(row)">↑</el-button>
            </el-tooltip>
            <el-tooltip content="下移" placement="top">
              <el-button type="text" size="small" @click="moveForward(row)">↓</el-button>
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <el-table v-loading="boqTableLoading" :data="boqTableData" highlight-current-row border style="width: 100%;" :height="boqTableHeight">
        <el-table-column prop="boqCode" label="清单编号" header-align="center" width="120" show-overflow-tooltip>
          <template v-slot:header>
            <i v-show="wbsIsLeaf && locked != 1" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;" @click="batchAddBoq()"></i>
            清单编号
          </template>
        </el-table-column>
        <el-table-column prop="boqName" label="清单名称" header-align="center" min-width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="subItemCode" label="部位编号" header-align="center"  min-width="240" show-overflow-tooltip></el-table-column>
        <el-table-column prop="subItemName" label="部位名称" header-align="center"  min-width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="80"></el-table-column>
        <el-table-column prop="num" label="合同数量" header-align="center" align="right" width="160">
          <template v-slot="{row}">
<!--            <el-input v-if="(locked != 1) && $hasPermission('mps:wbs:update')" v-model="row.num" @change="numEdit(row)"></el-input>-->
<!--            <span v-else>{{row.num}}</span>-->
            <el-input-number v-if="(locked != 1) && $hasPermission('mps:wbs-batch:update')" v-model="row.num" :controls="false"  style="width: 100%"
                @change="(currentValue, oldValue) => {numEdit(currentValue, oldValue, row)}">
            </el-input-number>
            <span v-else>{{row.num}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" header-align="center" align="center" width="80">
          <template v-slot="{row}">
            <el-button v-if="$hasPermission('mps:wbs-batch:update') && (locked != 1)" type="text" size="small" @click="deleteSubBoqHandle(row.id)">{{ $t('delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <!-- 弹窗, 新增 / 修改-->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @close="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
      <!-- 弹窗, 查看-->
      <wbs-view v-if="viewVisible" ref="view" @close="closeDialogHandle"></wbs-view>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getTableData()" url="/mps/wbsBatch/import"></excel-import>
      <!-- 引用模板选择弹窗 -->
      <quote v-if="quoteVisible" ref="quote" @close="closeQuoteHandle" @refreshDataList="getTableData()"></quote>
      <!-- 批量插入子项 -->
      <add-children v-if="addChildrenVisible" ref="addChildren" @addNode="addNodeCallback" @close="closeAddChildrenHandle"></add-children>
      <!-- 批量复制 -->
      <batch-copy v-if="batchCopyVisible" ref="batchCopy" @refreshParent="batchCopyCallback" @close="closebatchCopyHandle"></batch-copy>
      <!-- 批量绑定合同清单 -->
      <batch-bind-boq v-if="batchBindBoqVisible" ref="batchBindBoq" @refreshDataList="getSubBoqData(selectedWbsId)" @close="closebatchBindBoqHandle"></batch-bind-boq>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './wbs-batch-add-or-update'
import WbsView from './wbs-batch-view'
import Contract from "@/views/modules/mps/contract"
import Quote from './wbs-quote'
import AddChildren from './wbs-add-children'
import BatchCopy from './wbs-batch-copy'
import BatchBindBoq from './wbs-batch-bind-boq'
import debounce from "lodash/debounce"

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '/mps/wbsBatch',
        deleteSuccessCallback: this.deleteSuccessCallback,
        exportURL: `/mps/wbsBatch/export`
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
      },
      addOrUpdateVisible: false,
      viewVisible: false,
      quoteVisible: false,
      addChildrenVisible: false,
      batchCopyVisible: false,
      treeLoading: false,
      treeProps: {
        children: 'children',
        label: 'name',
      },
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableLoading: false,
      tableData: [],
      tableShow: true,
      boqTableHeight: 300,
      wbsTableHeight: 400,
      boqTableLoading: false,
      boqTableData: [],
      selectedWbsId: '',
      wbsIsLeaf: false,
      batchBindBoqVisible: false,
      locked: 0,
      hasSubcontractor: true,
    }
  },
  created() {
    let _height = window.innerHeight - 200
    this.wbsTableHeight = _height * 0.6
    this.boqTableHeight = _height * 0.4
  },
  components: {Contract, AddOrUpdate, WbsView, Quote, AddChildren, BatchCopy, BatchBindBoq},
  methods: {
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get(
          '/mps/wbsBatch/findChildren',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.wbsTable.store.states.lazyTreeNodeMap, pid, [])
        }
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    closeDialogHandle () {
      this.viewVisible = false
      this.addOrUpdateVisible = false
    },
    // 项目、标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId

      this.locked = data.contract.lockSubBoq === null ? 0 : data.contract.lockSubBoq
      if (data.subcontractor) {
        this.locked = data.subcontractor.lockSubBoq === null ? 0 : data.subcontractor.lockSubBoq
      }
      this.hasSubcontractor = data.contract.hasSubcontractor
      this.selectedWbsId = ''
      this.boqTableData = []
      this.getTableData()
    },
    getTableData() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get(
          '/mps/wbsBatch/findChildren',
          {
            params: {
              pid: 0,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        this.tableLoading = false
      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid, row) {
      // 新增子节点后，将其父节点改为非叶节点，否则不出现展开按钮
      row.hasChildren = true
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid) {
      this.refreshParentNode(pid)
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve)
      } else if (pid == 0) {
        this.getTableData()
      }
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addOrUpdate.init()
      })
    },
    // 新增wbs子节点
    addChildNode (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addOrUpdate.row = row
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id,pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getTableData()
      }
    },
    // 删除 wbs节点
    myDeleteHandle (id, pid) {
      this.deleteHandle(id,
          {
            deleteSuccessCallbackArgs: {id, pid},
            promptMessage: '同时会删除下级节点',
            promptTitle: '确定进行[删除]操作?',
            autoQuery: false
          }
      )
    },
    // 上移（即：前移）
    moveBackward (row) {
      this.$http.post('/mps/wbsBatch/moveBackward', row).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.refreshParentNode(row.pid)
        this.$message.success('移动成功。')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    // 下移（即：后移）
    moveForward (row) {
      this.$http.post('/mps/wbsBatch/moveForward', row).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.refreshParentNode(row.pid)
        this.$message.success('移动成功。')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    closeQuoteHandle() {
      this.quoteVisible = false
    },
    closeAddChildrenHandle() {
      this.addChildrenVisible = false
    },
    closebatchCopyHandle() {
      this.batchCopyVisible = false
    },
    closebatchBindBoqHandle() {
      this.batchBindBoqVisible = false
    },
    // 引用wbs模板
    quoteTemplate: debounce(function () {
      // 如果此标段工区已有计量，则不允许引用模板
      this.$http.get('/mps/meas/list',
          {
            params: {
              contractId: this.dataForm.contractId,
              subcontractorId: this.dataForm.subcontractorId,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data.length > 0) {
          this.$message.error('该标段已开始计量，无法重新引用。')
        } else {
          // 提示覆盖
          this.$confirm('重新引用将自动清空原分部分项及零号清单，是否继续？', '警告', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            this.quoteVisible = true
            this.$nextTick(() => {
              this.$refs.quote.contractId = this.dataForm.contractId
              this.$refs.quote.subcontractorId = this.dataForm.subcontractorId
              this.$refs.quote.init()
            })
          }).catch(e => e)
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    }, 1000, {'leading': true, 'trailing': false}),
    handleCommand(command, row) {
      switch (command) {
        case 'a':
          this.addChildNode(row)
          break;
        case 'b':
          this.addOrUpdateHandle(row.id)
          break;
        case 'c':
          this.myDeleteHandle(row.id, row.pid)
          break;
        case 'd':
          this.addChildrenNodes(row)
          break;
        case 'e':
          this.batchCopy(row)
          break;
        case 'up':
          this.moveBackward(row)
          break;
        case 'down':
          this.moveForward(row)
          break;   
      }
    },
    // 批量插入下级
    addChildrenNodes(row) {
      this.addChildrenVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addChildren.row = row
        this.$refs.addChildren.parentWbsId = row.id
        this.$refs.addChildren.contractId = this.dataForm.contractId
        this.$refs.addChildren.subcontractorId = this.dataForm.subcontractorId
        this.$refs.addChildren.title = row.code + ' ' + row.name
        this.$refs.addChildren.init()
      })
    },
    // 批量复制
    batchCopy(row) {
      this.batchCopyVisible = true
      this.$nextTick(() => {
        this.$refs.batchCopy.dataForm.id = row.id
        this.$refs.batchCopy.dataForm.code = row.code
        this.$refs.batchCopy.parentId = row.pid
        this.$refs.batchCopy.title = row.code + ' ' + row.name
        this.$refs.batchCopy.init()
      })
    },
    // 批量复制回调方法
    batchCopyCallback(pid) {
      this.refreshParentNode(pid)
    },
    rowClick(row) {
      this.selectedWbsId = row.id
      this.wbsIsLeaf = row.isLeaf
      this.getSubBoqData(row.id)
    },
    // 加载零号清单
    getSubBoqData(subItemId) {
      this.boqTableLoading = true
      this.$http.get('/mps/wbsBatch/subBoq', {
            params: {
              subItemId: subItemId,
              ...this.dataForm
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.boqTableData = res.data
        if (this.boqTableData == null || this.boqTableData.length == 0) {
          this.$message({
            message: '未查询到相关的零号清单。',
            type: 'warning',
            duration: 2000,
            onClose: () => {
              // do nothing
            }
          })
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.boqTableLoading = false
      })
    },
    // 批量绑定清单
    batchAddBoq() {
      this.batchBindBoqVisible = true
      this.$nextTick(() => {
        this.$refs.batchBindBoq.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchBindBoq.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.batchBindBoq.dataForm.subItemId = this.selectedWbsId
        this.$refs.batchBindBoq.init()
      })
    },
    // 删除零号清单
    deleteSubBoqHandle (id) {
      this.$confirm(this.$t('prompt.info', { 'handle': this.$t('delete') }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(`/mps/wbsBatch/deleteSubBoq`,
            {
              'data': [id]
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.getSubBoqData(this.selectedWbsId)
            }
          })
        }).catch(() => { })
      }).catch(() => { })
    },
    // 修改数量
    numEdit(currentValue, oldValue, row) {
      this.$http['put']('/mps/wbsBatch/updateSubBoq', row).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: '保存成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            // do nothing
          }
        })
      }).catch(() => {})
    },
    lock() {
      this.$confirm(`是否要锁定分部分项和零号清单？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.hasSubcontractor) {
          url = `/mps/wbsBatch/lockSubBoqSubcontractor/${this.dataForm.subcontractorId}`
        } else {
          url = `/mps/wbsBatch/lockSubBoq/${this.dataForm.contractId}`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.locked = 1
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 1)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 1)
          }
          this.$message({
            message: '已锁定',
            type: 'warning',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
    unlock() {
      this.$confirm(`您确定要解锁分部分项和零号清单吗？`).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let url = ''
        if (this.hasSubcontractor) {
          url = `/mps/wbsBatch/unlockSubBoqSubcontractor/${this.dataForm.subcontractorId}`
        } else {
          url = `/mps/wbsBatch/unlockSubBoq/${this.dataForm.contractId}`
        }
        this.$http.put(url).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 因为清单锁定的状态来源于下拉框中的数据，所以此处需要反向更新下拉框中的对应字段
          // 避免下拉框选了别的选项后又选回本选项（在页面没刷新的情况下），locked字段会被下拉框回传的数据更新，导致页面的锁定状态不正常
          if (this.hasSubcontractor) {
            this.$refs.contractCascader.updateSubcontractorData(this.dataForm.subcontractorId, "lockSubBoq", 0)
          } else {
            this.$refs.contractCascader.updateContractData(this.dataForm.contractId, "lockSubBoq", 0)
          }
          this.locked = 0
          this.$message({
            message: '已解锁',
            type: 'warning',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(e => e)
    },
  }
}
</script>
<style scoped>
</style>