<template>
  <el-card shadow="never" class="aui-card--fill">
    <!-- 1、流程办理按钮组 -->
    <process-running ref="processRunning"
                     @successCallback="successCallback"
                     @errorCallback="errorCallback"
                     @submitCallback="submitCallback">
    </process-running>

    <!-- 以下是业务表单内容 -->
    <div class="mod-__othercontractpay">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :validate-on-rule-change="false" label-width="80px" size="small">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="合同名称" prop="otherContractId">
              <el-input v-model="dataForm.otherContractName" placeholder="合同名称" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同编码" prop="otherContractCode">
              <el-input v-model="dataForm.otherContractCode" placeholder="合同编码" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同金额" prop="otherContractCode">
              <el-input disabled v-model="dataForm.otherContractAmount">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签订单位" prop="otherContractSecondParty">
              <el-input v-model="dataForm.otherContractSecondParty" placeholder="签订单位" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker type="date" placeholder="申请日期" value-format="yyyy-MM-dd" v-model="dataForm.applyDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付金额" prop="paymentAmount">
              <el-input-number v-model="dataForm.paymentAmount" data-unit="元" placeholder="支付金额" :controls="false" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请款人" prop="requestId">
              <user-select v-model="dataForm.requestId" style="width: 100%"></user-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用途" prop="purpose">
              <el-input type="textarea" v-model="dataForm.purpose" :autosize="{ minRows: 3 }"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户银行" prop="bank">
              <el-input v-model="dataForm.bank" placeholder="开户银行"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账户名称" prop="bankAccountName">
              <el-input v-model="dataForm.bankAccountName" placeholder="账户名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账号" prop="bankAccount">
              <el-input v-model="dataForm.bankAccount" placeholder="银行账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3 }"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <my-upload-plain ref="upload" :refId="dataForm.id"></my-upload-plain>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>
<script>
// 2、引入流程混入代码
import processModule from '@/mixins/process-module'

export default {
  mixins: [processModule],
  data() {
    return {
      dataForm: {
        id: '',
        prjId: '',
        otherContractId: '',
        otherContractName: '',
        otherContractAmount: '',
        otherContractSecondParty: '',
        otherContractCode: '',
        applyDate: '',
        requestId: '',
        paymentAmount: '',
        purpose: '',
        bank: '',
        bankAccountName: '',
        bankAccount: '',
        remark: '',
        processInstanceId: '',
        processStatus: '',
      },
      // 3、配置URL
      process: {
        // 流程被驳回后，起草人修改提交时保存业务表单的URL
        updateURL: '/mps/otherContractPay'
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    dataRule() {
      return {
        applyDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        paymentAmount: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init() {
      // 4、通过流程获取业主主表ID
      this.dataForm.id = this.$route.params.businessKey
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/otherContractPay/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
