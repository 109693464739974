<template>
  <div>
    <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :title="$t('add')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker style="width: 100%" v-model="dataForm.startDate" :format="dateFormat"
                              value-format="yyyy-MM-dd" type="date" placeholder="请选择开始日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker style="width: 100%" v-model="dataForm.endDate" :format="dateFormat"
                              value-format="yyyy-MM-dd" type="date" readonly>
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button v-if="$hasPermission('opm:weeklyschedule:save')" type="primary" size="mini" @click="openWbsSelectHandle()">选择分部分项工程</el-button>
      <vxe-table id="table"
                 keep-source
                 ref="tableRef"
                 :loading="dataListLoading"
                 size="mini"
                 :column-config="{resizable: true}"
                 border
                 show-overflow
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 :max-height="tableHeight"
                 :edit-rules="tableRules"
                 :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
        <vxe-column field="wbsPnames" title="分部分项" min-width="300" fixed="left"></vxe-column>
        <vxe-column field="wbsUnitName" title="单位" width="100" align="center" fixed="left"></vxe-column>
        <vxe-column field="num" title="计划工程量" width="160" fixed="left" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false, min: 0}}"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <!-- wbs选择弹窗 -->
    <el-dialog v-dialog-drag :visible.sync="wbsVisible" top="10vh" title="分部分项工程" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-tree
        class="filter-tree"
        v-loading="treeLoading"
        node-key="id"
        :props="wbsTreeProps"
        ref="wbsTree"
        lazy
        show-checkbox
        :load="load"
        highlight-current
        :default-expanded-keys="expandedKeys">
      </el-tree>
      <template slot="footer">
        <el-button @click="wbsVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="wbsSelectSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        startDate: '',
        endDate: '',
        y: null,
        m: null,
        saveOrUpdateDetails: null
      },
      dateFormat: 'yyyy-MM-dd',
      tableHeight: 400,
      dataList: [],
      // 行校验
      tableRules: {
        num: [
          {required: true,type: 'string',message: '请填写计划工程量'}
        ],
      },
      dataListLoading: false,
      // wbs弹窗
      wbsVisible: false,
      wbsTreeProps: {
        label: 'name',
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      treeLoading: false,
      expandedKeys: []
    }
  },
  computed: {
    dataRule () {
      return {
        startDate: [
          {type: 'string',required: true,message: '请选择日期',trigger: 'change'}
        ],
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  watch: {
    // 周计划开始日期改变
    'dataForm.startDate' (newval,oldval) {
      this.dataForm.y = null;
      this.dataForm.m = null;
      this.dataForm.endDate=''
      if (newval) {
        // 截取开始日期，获取年份、月份
        let dateList = newval.split('-')
        this.dataForm.y = parseInt(dateList[0])
        this.dataForm.m = parseInt(dateList[1])

        // 结束日期=开始日期往后加6天
        let startDate = this.getDate(newval);
        startDate = startDate.setDate(startDate.getDate() + 6)
        this.dataForm.endDate = this.geDateStr(new Date(startDate))
      }
    }
  },
  methods: {
    init () {
      this.visible = true
    },
    // 表单提交
    dataFormSubmitHandle: debounce(async function () {
      let rs = await this.dataRowValidate('tableRef')
      if (!rs) {
        return false
      }

      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }

        const loading = this.$loading({
          lock: true,
          text: '正在保存...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        // 获取周计划明细数据
        this.dataForm.saveOrUpdateDetails = this.$refs.tableRef.getTableData().fullData || []

        this.$http['post']('/opm/weeklySchedule/',this.dataForm).then(({data: res}) => {
          loading.close()
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          loading.close()
        })
      })
    },1000,{'leading': true,'trailing': false}),

    // 周计划明细数据行校验。验证不通过，返回false
    async dataRowValidate (vxeTableRef) {
      let $tableRef = this.$refs[vxeTableRef]
      let errMap = await $tableRef.validate(true).catch((errMap) => errMap)
      if (errMap) {
        const {row,rule} = Object.values(errMap)[0][0] // 取第一个错误信息
        const rowIndex = $tableRef.getVTRowIndex(row)
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: '第' + (rowIndex + 1) + '行 校验错误：' + rule.$options.message + '',
          duration: 2000
        });
        return false
      }
      return true
    },

    // 打开wbs选择弹窗
    openWbsSelectHandle () {
      this.wbsVisible = true
    },

    checkBoxDisabled (data) {
      return !data.isLeaf
    },

    load (node,resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get(
        '/mps/wbs/children',
        {
          params: {
            'pid': pid,
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          // 返回出错
          return this.$message.error(res.msg)
        }
        resolve(res.data)

        // 实现默认展开第一级节点
        if((!node || !node.parent) && res.data.length > 0) {
          this.expandedKeys = [res.data[0].id]
        }

      }).catch(() => {
        // 查询出错
        return this.$message.error('出错了')
      })
    },

    //  wbs选择弹窗,确定按钮点击事件
    wbsSelectSubmitHandle: debounce(function () {
      let selectedWbsIdMap = new Map()
      if (this.dataList.length > 0) {
        for (let i = 0; i < this.dataList.length; i++) {
          let data = this.dataList[i];
          selectedWbsIdMap.set(data['wbsId'],data)
        }
      }
      let nodes = this.$refs.wbsTree.getCheckedNodes(true)
      let newDataList = []
      if (nodes && nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (selectedWbsIdMap.has(node.id)) {
            newDataList.push(selectedWbsIdMap.get(node.id))
          } else {
            let data = {
              id: '',
              contractId: this.dataForm.contractId,
              wbsId: node.id,
              num: 1,
              wbsPnames: node.pnames ? node.pnames + ',' + node.name : node.name,
              wbsUnitName: node.unitName || '',
            }
            newDataList.push(data)
          }
        }
      }
      this.dataList = newDataList
      this.wbsVisible = false
    },1000,{'leading': true,'trailing': false}),

    deleteOneHandle (row) {
      let wbsId = row.wbsId
      this.$refs.tableRef.remove(row)
      // 设置wbs取消勾选
      this.$refs.wbsTree.setChecked(wbsId,false)
      this.dataList = this.$refs.tableRef.getTableData().fullData
    },

    // 字符串转日期格式，strDate要转为日期格式的字符串
    getDate (strDate) {
      let date = new Date()
      if (strDate) {
        strDate = strDate.replace(/-/g,"/")
        date = new Date(strDate);
      }
      return date
    },

    // 获取日期字符串
    geDateStr (date) {
      var currentDate = date ? date : new Date();
      var year = currentDate.getFullYear().toString();
      var month = (currentDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      var day = currentDate.getDate().toString();
      if (day.length === 1) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day
    },
  }
}
</script>
<style lang="scss" scope>
  .filter-tree {
    max-height: calc(90vh - 220px);
    overflow: auto;
  }
</style>