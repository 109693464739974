<template>
  <el-dialog width="95%" v-dialog-drag :visible.sync="visible" title="查看" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-table
      v-if="tableShow"
      ref="table"
      v-loading="dataListLoading"
      :data="dataList"
      row-key="id"
      lazy
      :load="loadTableData"
      highlight-current-row
      border
      :max-height="tableHeight"
      style="width: 100%;"
      :row-style="{height: '0px'}"
      :cell-style="{padding: '2px 0'}">
      <el-table-column prop="code" label="清单编码" header-align="center" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="名称" header-align="center" min-width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="150" show-overflow-tooltip> </el-table-column>
    </el-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataListLoading: false,
        dataList: [],
        mainId: null, // 范本主表ID
        tableHeight: 600,
        // 存储已加载的节点的map
        treeNodeMap: new Map(),
        tableShow: true,
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 340
      })
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.mainId) {
            this.getDataList()
          }
        })
      },
      // el-table懒加载节点
      loadTableData(data, node, resolve) {
        let pid = data.id
        // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
        this.treeNodeMap.set(pid, {data, node, resolve})
        this.$http.get(
          '/mps/boqTemplate/findChildren',
          {
            params: {
              mainId: this.mainId,
              pid: pid
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          resolve(res.data);
          // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
          // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
          if (res.data.length == 0) {
            this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, [])
          }
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        })
      },
      getDataList() {
        this.treeNodeMap = new Map()
        // 重置一些el-table缓存的变量
        // 此处如果不重置，可能会导致一些无法预料的情况
        // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
        this.tableShow = false
        this.$nextTick(() => {
          this.tableShow = true
        })
        this.dataListLoading = true
        this.$http.get(
          '/mps/boqTemplate/findChildren',
          {
            params: {
              mainId: this.mainId,
              pid: 0,
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          this.dataList = res.data
          this.dataListLoading = false
        }).catch(() => {
          // 查询出错
          return this.$message.error('出错了')
        })
      },
    }
  }
</script>
