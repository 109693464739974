<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__dict">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.dictName" :placeholder="$t('dict.dictName')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.dictType" :placeholder="$t('dict.dictType')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:dict:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:dict:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="dictType" :label="$t('dict.dictType')" align="center" sortable="custom">
          <template slot-scope="scope">
            <el-button type="text" @click="childHandle(scope.row)">{{ scope.row.dictType }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="dictName" :label="$t('dict.dictName')" align="left" sortable="custom"></el-table-column>
        <!-- <el-table-column prop="sort" :label="$t('dict.sort')" sortable="custom" header-align="center" align="center" width="100"></el-table-column> -->
        <el-table-column prop="remark" :label="$t('dict.remark')" align="left"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="170">
          <template slot-scope="scope">
            <table-button icon="el-icon-tickets" tooltip="字典选项" v-if="$hasPermission('sys:dict:update')" @click="childHandle(scope.row)"></table-button>
            <table-button role="edit" v-if="$hasPermission('sys:dict:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:dict:delete')" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <dict-data v-if="dictDataVisible" ref="dictData" @close="closeDictDataDialogHandle"></dict-data>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './dict-type-add-or-update'
import DictData from './dict-data'
import {addDynamicRoute} from '@/router'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/dict/type/page',
        getDataListIsPage: true,
        deleteURL: '/sys/dict/type',
      },
      dataForm: {
        id: '0',
        dictName: '',
        dictType: ''
      },
      dictDataVisible: false
    }
  },
  components: {
    AddOrUpdate,
    DictData
  },
  methods: {
    // 打开数据字典选项弹窗
    childHandle(row) {
      this.dictDataVisible = true
      this.$nextTick(() => {
        this.$refs.dictData.init(row.id, row.dictType, row.dictName)
      })
    },
    // 关闭数据字典选项弹窗
    closeDictDataDialogHandle() {
      this.dictDataVisible = false
    }
  }
}
</script>
