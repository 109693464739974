<template>
  <el-dialog v-dialog-drag :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="18">
          <el-form-item label="方案名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table ref="xTable" size="mini"
               :column-config="{resizable: true}"
               :row-config="{isCurrent: true,isHover: true}"
               :data="dataForm.mxList"
               :loading="dataLoading"
               :max-height="500"
               border show-overflow>
      <vxe-column type="seq" title="#" width="50" align="center"></vxe-column>
      <vxe-column field="metaCode" title="元数据编号" align="center" width="200"></vxe-column>
      <vxe-column field="metaName" title="元数据名称" min-width="180"></vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        name: '',
        sortNo: '',
        mxList: []
      },
      dataLoading: false
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.dataLoading = true
      this.$http.get('/mps/archiveScheme/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          this.dataLoading = false
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.dataLoading = false
      }).catch(() => {
        this.dataLoading = false
      })
    },
  }
}
</script>
