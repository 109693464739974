<template>
  <el-dialog width="90%" :visible.sync="visible" title="分项清单" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <vxe-table
        size="mini"
        :loading="dataListLoading"
        border
        resizable
        show-overflow="tooltip"
        highlight-hover-row
        :scroll-y="{enabled: true}"
        max-height="400"
        :data="dataList">
      <vxe-column field="subItemName" title="分项名称">
        <template v-slot="{row}">
          <span>{{ row.pnames ? row.pnames + '/' + row.subItemName : row.subItemName }}</span>
        </template>
      </vxe-column>
      <vxe-column field="num" title="分解数量" width="160" align="right"></vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
    </template>
  </el-dialog>
</template>
<!--查看某工程量清单拆分的分项(sbs或wbs)列表-->
<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        boqId: ''
      },
      dataList: [],
      dataListLoading: true,
    }
  },
  methods: {
    init() {
      this.visible = true
      this.getDataList()
    },
    getDataList() {
      this.dataListLoading = true
      this.$http.get('/mps/boq/openapi/subBoqs',
        {
          params: {
            contractId: this.dataForm.contractId,
            boqId: this.dataForm.boqId,
            // emptyFilter参数用于过滤掉数量为空的以及非叶子的零号清单
            emptyFilter: true,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.dataListLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    }
  }
}
</script>