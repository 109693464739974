<template>
  <el-dialog v-dialog-drag top="5vh" width="80%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同名称" prop="contractName">
            <el-input v-model="dataForm.contractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同编号" prop="contractCode">
            <el-input v-model="dataForm.contractCode" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划验收日期" prop="planAcceptanceDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.planAcceptanceDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="验收组织者" prop="organizer">
            <el-input v-model="dataForm.organizer" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="外部参加人员" prop="outsideParticipant">
            <el-input type="textarea" v-model="dataForm.outsideParticipant" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="参加人员" prop="participant">
            <el-input type="textarea" v-model="dataForm.participant" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报人" prop="report">
            <el-input v-model="dataForm.report" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="reportDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.reportDate" readonly style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <my-upload-plain :refId="dataForm.id" view></my-upload-plain>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        planAcceptanceDate: '',
        organizer: '',
        outsideParticipant: '',
        participant: '',
        report: '',
        reportDate: '',
        remark: '',
        contractCode:'',
        contractName:''
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionPlan/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        //  do nothing
      })
    },
  }
}
</script>
