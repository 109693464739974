<template>
  <el-container>
    <el-aside width="180px">
      <vxe-table
        ref="xTable"
        resizable
        show-overflow
        height="auto"
        border="none"
        :show-header="false"
        :data="workstageList"
        :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"
        empty-text="该分项没有关联的工序"
        cell-class-name="pointer-cursor"
        @cell-click="treeCellClick"
      >
        <vxe-column field="name" width="160"></vxe-column>
      </vxe-table>
    </el-aside>
    <el-main class="my-el-main-style">
      <el-row>
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>工程实体照片</span>
            </div>
            <el-image
              :preview-teleported="true"
              v-for="(img, $index) in gcstzp"
              :key="$index"
              fit="cover"
              style="width: 100px; height: 100px; margin-right: 10px;"
              :src="img"
              :preview-src-list="gcstzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>质检人员照片</span>
            </div>
            <el-image
              :preview-teleported="true"
              v-for="(img, $index) in zjryzp"
              :key="$index"
              fit="cover"
              style="width: 100px; height: 100px; margin-right: 10px;"
              :src="img"
              :preview-src-list="zjryzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>实测实量照片</span>
            </div>
            <el-image
              :preview-teleported="true"
              v-for="(img, $index) in scslzp"
              :key="$index"
              fit="cover"
              style="width: 100px; height: 100px; margin-right: 10px;"
              :src="img"
              :preview-src-list="scslzp">
            </el-image>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>监理照片</span>
            </div>
            <el-image
              :preview-teleported="true"
              v-for="(img, $index) in jlzp"
              :key="$index"
              fit="cover"
              style="width: 100px; height: 100px; margin-right: 10px;"
              :src="img"
              :preview-src-list="jlzp">
            </el-image>
          </el-card>
        </el-col>
        
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>其他报验资料</span>
            </div>
            <my-upload ref="upload" :refId="workstageId" :view="true" typeId="其他报验资料"></my-upload>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      wbsId: '',
      workstageId: '',
      workstageList: [],
      gcstzp: [],
      zjryzp: [],
      scslzp: [],
      jlzp: [],
    }
  },
  watch: {
    wbsId: function () {
      this.init()
    },
  },
  methods: {
    init() {
      this.clearAll()
      if (this.workstageList && this.workstageList.length > 0) {
        this.$nextTick(() => {
          let defaultRow = this.$refs.xTable.getRowById(this.workstageList[0].id)
          this.$refs.xTable.setCurrentRow(defaultRow)
          this.workstageId = defaultRow.id
          this.loadPictures()
        })
      }
    },
    clearAll(){
      this.gcstzp = [] // 工程实体照片
      this.zjryzp = [] // 质检人员照片
      this.scslzp = [] // 实测实量照片
      this.jlzp = [] // 监理照片
    },
    treeCellClick({row}) {
      this.workstageId = row.id
      this.loadPictures()
    },
    // 加载工序报验照片和附件
    loadPictures: debounce(function () {
      this.clearAll()
      this.$http.get('/oss/file/list/' + this.workstageId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let data = res.data
        if (data && data.length > 0) {
          data.forEach(element => {
            if (element.typeId == '工程实体照片') {
              this.gcstzp.push(element.url)
            } else if (element.typeId == '质检人员照片') {
              this.zjryzp.push(element.url)
            } else if (element.typeId == '实测实量照片') {
              this.scslzp.push(element.url)
            } else if (element.typeId == '监理照片') {
              this.jlzp.push(element.url)
            }
          })
        }
      }).catch()
    }, 200, { 'leading': true, 'trailing': false }),
  }
}
</script>
<style scoped>
.my-el-main-style {
  overflow: auto;
  height: calc(100vh - 240px);
}
</style>