<template>
  <el-dialog top="5vh" width="90%" :visible.sync="visible" :title="title" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="12">
        <el-table
          :data="wbsTableData"
          highlight-current-row
          border
          :max-height="tableHeight"
          style="width: 100%;"
        >
          <el-table-column
            v-for="(item, index) in wbsTableColumn"
            :key="index"
            :prop="item.fieldName + item.boqIndex"
            header-align="center"
            align="center"
            :width="item.width"
            :fixed="item.boqIndex ? false : true"
          >
            <template v-slot:header>
              {{item.name + item.boqIndex}}
              <i v-if="index == (wbsTableColumn.length - 1) && index > 3" class="el-icon-remove-outline" style="color:red;cursor:pointer;" @click="deleteWbsColumn(index)"></i>
              <i v-if="index == (wbsTableColumn.length - 1)" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;" @click="addWbsColumn(index)"></i>
            </template>
            <template v-slot="{row, $index}">
              <el-input-number v-if="item.boqIndex" v-model="row[item.fieldName + item.boqIndex]" :controls="false" style="width: 100%" placeholder="请填写数量"></el-input-number>
              <el-input v-else-if="item.fieldName" v-model="row[item.fieldName + item.boqIndex]"></el-input>
              <span v-else>
                <i v-if="$index == wbsTableData.length - 1" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;" @click="addWbsRow()"></i>{{ $index + 1 }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <br/>
        <el-button v-if="!quoteDone" type="primary" @click="quoteParentBoq">引用模板清单</el-button>
        <el-table
          :data="boqTableData"
          border
          :max-height="tableHeight"
          highlight-current-row
          :select-on-indeterminate="false"
        >
          <el-table-column type="index" prop="index" label="" width="90" header-align="center" align="center" show-overflow-tooltip>
            <template v-slot="{row, $index}">
              {{`清单${$index + 1}`}}
              <i class="el-icon-remove" style="color:red;cursor:pointer;" @click="deleteBoqRow(row)"></i>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="编号" min-width="150" header-align="center" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="名称" header-align="center" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="unitName" label="单位" width="100" header-align="center" align="center" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="price" label="单价" width="100" header-align="center" align="right" show-overflow-tooltip></el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-table
          ref="boqLeafTable"
          :data="boqLeafData"
          border
          :max-height="tableHeight * 2 - 50"
          @selection-change="dataListSelectionChangeHandle"
          highlight-current-row
          v-loading="boqTableDataLoading"
        >
<!--          <el-table-column prop="index" label="" width="55" header-align="center" align="center" show-overflow-tooltip></el-table-column>-->
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="code" label="编号" min-width="100" header-align="center" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="名称" min-width="150" header-align="center" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="unitName" label="单位" width="80" header-align="center" align="center" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="price" label="单价" width="100" header-align="center" align="right" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="num" label="数量" width="150" header-align="center" align="right" show-overflow-tooltip></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      visible: false,
      parentWbsId: '',
      contractId: '',
      subcontractId: '',
      tableHeight: 300,
      wbsTableData: [{},{},{},{}],
      wbsTableColumn: [
        {
          name: '',
          boqIndex: '',
          width: 50,
        },
        {
          name: '部位名称',
          fieldName: 'wbsName',
          boqIndex: '',
          width: 220,
        },
        {
          name: '图(册)号',
          fieldName: 'wbsDrawingNo',
          boqIndex: '',
          width: 140,
        },
        {
          name: '清单',
          fieldName: 'boq',
          boqIndex: 1,
          width: 130,
        },
        {
          name: '清单',
          fieldName: 'boq',
          boqIndex: 2,
          width: 130,
        },
        {
          name: '清单',
          fieldName: 'boq',
          boqIndex: 3,
          width: 130,
        },
      ],
      boqTableData: [],
      boqLeafData: [],
      // 保存列表页面父节点的row数据，用于新增后刷新父节点
      row: {},
      title: '批量插入下级',
      boqTableDataLoading: false,
      // 成功引用模板清单后，此属性修改为true，隐藏引用按钮
      quoteDone: false,
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getBoqLeafData()
      })
    },
    // 获取信息
    getBoqLeafData() {
      this.boqTableDataLoading = true
      this.$http.get(`/mps/wbsBatch/boqLeafList/${this.contractId}`).then(({data: res}) => {
        this.boqTableDataLoading = false
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.boqLeafData = res.data
      }).catch(() => {
        this.boqTableDataLoading = false
      })
    },
    // wbs表格删除列
    deleteWbsColumn(index) {
      this.wbsTableColumn.pop()
    },
    // wbs表格增加列
    addWbsColumn(index) {
      this.wbsTableColumn.push({
        name: '清单',
        fieldName: 'boq',
        boqIndex: index - 1,
        width: 100,
      })
    },
    // boq表格删除行
    deleteBoqRow(row) {
      this.$refs.boqLeafTable.toggleRowSelection(row, false)
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      const loading = this.$loading({
        lock: true,
        text: '正在处理数据...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(
          '/mps/wbsBatch/batchAddChildren',
          {
            parentWbsId: this.parentWbsId,
            contractId: this.contractId,
            subcontractorId: this.subcontractorId,
            wbsList: this.wbsTableData,
            boqList: this.boqTableData
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('addNode', this.parentWbsId, this.row)
          }
        })
      }).catch(() => {
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),
    dataListSelectionChangeHandle(val) {
      this.boqTableData = val
    },
    // 引用上级清单
    quoteParentBoq() {
      if (this.boqLeafData.length > 0) {
        this.quoteDone = true
        const loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(`/mps/wbsBatch/getParentBoqCodes/${this.parentWbsId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.quoteDone = true
          let message = ''
          let boqCodes = res.data
          if (boqCodes) {
            let boqCodeArr = boqCodes.split('|')
            message = '模板清单引用成功！'
            let set = new Set(boqCodeArr)
            for (let obj of this.boqLeafData) {
              if (set.has(obj.code)) {
                this.$refs.boqLeafTable.toggleRowSelection(obj, true)
                set.delete(obj.code)
              }
            }
            if (set.size > 0) {
              message += '其中编号为'
              for (let code of set) {
                message += ` ${code} `
              }
              message += '的合同清单在本标段中不存在'
            }
          } else {
            message = '没有可以引用的模板清单'
          }
          this.$message({
            message: message,
            type: 'success',
            duration: 3000
          })
        }).catch(() => {
          this.quoteDone = false
          this.$message.error('出错了')
        }).finally(() => {
          loading.close()
        })
      } else {
        this.$message.warning('正在加载数据，请稍候')
      }
    },
    // wbs增加行
    addWbsRow() {
      this.wbsTableData.push({})
    },
  }
}
</script>