<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-flowable__process">
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" border style="width: 100%;">
        <el-table-column type="index" label="#"/>
        <el-table-column prop="processTitle" label="流程主题" align="left">
          <template slot-scope="scope">
            <a href="javascript:;" @click="taskHandle(scope.row)">{{ scope.row.processTitle }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="startUser.realName" label="发起人" align="center" width="120"></el-table-column>
        <el-table-column prop="startTime" label="发起时间" align="center" width="140"></el-table-column>
        <el-table-column prop="taskCreatedTime" label="接收时间" align="center" width="140"></el-table-column>
        <el-table-column prop="businessStatus" label="当前状态" align="center" width="120">
          <template slot-scope="scope">
            <!-- 流程被驳回时，特殊显示-->
            <span :class="{'rejectStatus': scope.row.businessStatus==4}">{{formatBusinessStatus(scope.row,scope.column)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/flowable/process/todo/page',
        getDataListIsPage: true,
      },
      dataForm: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 通过事件总线实现当用户办理完某个待办流程后自动刷新当前的待办流程列表数据
      this.$bus.$on('refreshTodoWfList', () => {
        this.getDataList()
      })
    })
  },
  beforeDestroy() {
    this.$bus.$off('refreshTodoWfList')
  },
  methods: {
    taskHandle(row) {
      if (!row.businessKey) {
        return this.$message.error(this.$t('task.businessKeyError'))
      }
      this.getProcDefRouteSet(row, this.forwardHandleUrl)
    }
  }
}
</script>
<style scoped>
.rejectStatus {
  color: red !important;
}
</style>