<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker style="width: 100%" v-model="dataForm.startDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择开始日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker style="width: 100%" v-model="dataForm.endDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" readonly>
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import {checkNumber} from '@/utils/form-validate'
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          contractId: '',
          startDate: '',
          endDate: '',
          y:null,
          m:null
        },
        dateFormat: 'yyyy-MM-dd'
      }
    },
    computed: {
      dataRule () {
        return {
          startDate: [
            {type: 'string',required: true,message: '请选择日期',trigger: 'change'}
          ],
        }
      }
    },
    watch: {
      // 周计划开始日期改变
      'dataForm.startDate' (newval,oldval) {
        this.dataForm.y = null;
        this.dataForm.m = null;
        this.dataForm.endDate=''
        if (newval) {
          // 截取开始日期，获取年份、月份
          let dateList = newval.split('-')
          this.dataForm.y = parseInt(dateList[0])
          this.dataForm.m = parseInt(dateList[1])

          // 结束日期=开始日期往后加6天
          let startDate = this.getDate(newval);
          startDate = startDate.setDate(startDate.getDate() + 6)
          this.dataForm.endDate = this.geDateStr(new Date(startDate))
        }
      }
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          }
        })
      },
      // 获取信息
      getInfo () {
        this.$http.get('/opm/weeklySchedule/' + this.dataForm.id).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          if(this.dataForm.wbsPnames){
            this.dataForm.wbsPnames=this.dataForm.wbsPnames+','+ this.dataForm.wbsName
          }else{
            this.dataForm.wbsPnames=this.dataForm.wbsName
          }
        }).catch(() => {
          // do nothing
        })
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          const loading = this.$loading({
            lock: true,
            text: '正在执行更新操作...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/weeklySchedule/', this.dataForm).then(({ data: res }) => {
            loading.close()
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }).catch(() => {
            loading.close()
          })
        })
      }, 1000, { 'leading': true, 'trailing': false }),

      // 字符串转日期格式，strDate要转为日期格式的字符串
      getDate (strDate) {
        let date = new Date()
        if (strDate) {
          strDate = strDate.replace(/-/g,"/")
          date = new Date(strDate);
        }
        return date
      },

      // 获取日期字符串
      geDateStr (date) {
        var currentDate = date ? date : new Date();
        var year = currentDate.getFullYear().toString();
        var month = (currentDate.getMonth() + 1).toString();
        if (month.length === 1) {
          month = "0" + month;
        }
        var day = currentDate.getDate().toString();
        if (day.length === 1) {
          day = "0" + day;
        }
        return year + "-" + month + "-" + day
      },
    }
  }
</script>