<template>
  <div class="mod-__qcfile">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.keyword" placeholder="表号或文件名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.typeId" placeholder="施工步骤" @clear="typeClear" @change="typeChange" clearable>
          <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          <toolbar-button role="add" v-if="$hasPermission('mps:qctabledirfile:save')" @click="openQcFileSelectModal()"></toolbar-button>
          <toolbar-button role="delete" v-if="$hasPermission('mps:qctabledirfile:delete')" @click="deleteHandle()"></toolbar-button>
          <toolbar-button role="import" @click="importHandle" v-if="$hasPermission('mps:qctabledirfile:import')"></toolbar-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table v-loading="dataListLoading" :max-height="tableHeight" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
      <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
      <el-table-column prop="qcFileName" label="文件名称" min-width="300" show-overflow-tooltip sortable="custom">
        <template v-slot="{row}">
          <el-button type="text" size="small" @click="openDocumentEditor(row.fileId, 'view')">{{ row.qcFileName }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="tableDirName" label="套表目录" width="300" show-overflow-tooltip>
        <template v-slot="{row}">
          {{ row.tableDirPnames ? row.tableDirPnames + '/' + row.tableDirName : row.tableDirName }}
        </template>
      </el-table-column>
      <el-table-column prop="qcFileCode" label="表号" width="150" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="qcFileTypeId" label="施工步骤" width="120" align="center" show-overflow-tooltip sortable="custom">
        <template v-slot="{row}">
          <span v-if="row.qcFileTypeId === 1" style="color: #5231e6">开工报告</span>
          <span v-else-if="row.qcFileTypeId === 2" style="color: #2278b2">检验申请批复</span>
          <span v-else-if="row.qcFileTypeId === 3" style="color: #8ea706">交工证书</span>
          <span v-else style="color: #ee0a30">未知</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="80">
        <template v-slot="{row}">
          <table-button role="delete" v-if="$hasPermission('mps:qctabledirfile:delete')" @click="deleteHandle(row.id)"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <!--质检用表选择弹窗-->
    <qcfile-select v-if="qcfileSelectVisible" ref="qcfileSelect" @submit="qcfileSelectSubmitHandle" @close="closeQcfileSelectDialog" isNotSelectWithNotUpload></qcfile-select>
    <!-- excel文件导入弹窗 -->
    <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="refreshDataHandle" url="/mps/qcTableDirFile/import" thumbnail="import_qctabledir_file.jpg"></excel-import>
  </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import QcfileSelect from "./qcfile-select";
import debounce from "lodash/debounce";

export default {
  mixins: [mixinViewModule],
  components: {
    QcfileSelect
  },
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/qcTableDirFile/page',
        getDataListIsPage: true,
        deleteURL: '/mps/qcTableDirFile',
      },
      dataForm: {
        prjId: '', // 项目ID
        tableDirId: '', // 质检套表目录ID
        keyword: '', // 按表号或表格名称检索
        typeId: '' // 施工步骤
      },
      qcfileSelectVisible: false, // 质检用表选择弹窗
      typeOptions: [{
        value: '1',
        label: '开工报告'
      }, {
        value: '2',
        label: '检验申请批复'
      }, {
        value: '3',
        label: '交工证书'
      }]
    }
  },
  props: {
    tableHeight: {
      type: Number,
      required: true,
      default: 400
    },
    prjId: '' // 项目ID
  },
  watch: {
    // 监听项目ID的变化
    prjId() {
      this.dataForm.prjId = this.prjId
      this.dataList = [] // 清空列表数据
    }
  },
  methods: {
    initData() {
      this.getDataList()
    },
    // 导入完成后,刷新质检列表，同时刷新目录
    refreshDataHandle() {
      this.initData()
      this.$emit("refreshData")
    },
    // 打开质检模板用表选择弹窗
    openQcFileSelectModal() {
      if(!this.dataForm.tableDirId){
         return this.$message.warning('请先选择具体的套表目录!')
      }
      this.qcfileSelectVisible = true
      this.$nextTick(() => {
        this.$refs.qcfileSelect.dataForm.prjId = this.dataForm.prjId
        this.$refs.qcfileSelect.init()
      })
    },

    qcfileSelectSubmitHandle: debounce(function (selectedList) {
      if (selectedList && selectedList.length > 0) {
        const loading = this.$loading({
          text: '正在执行，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let qcfiles = []
        selectedList.forEach(item => {
          qcfiles.push({
            id: null,
            tableDirId: this.dataForm.tableDirId,
            qcFileId: item.id
          })
        })
        this.$http.post('/mps/qcTableDirFile/batchSave', qcfiles).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$alert(res.msg, '', {
              confirmButtonText: '关闭',
              type: "error"
            })
          }
          this.$alert('已成功保存！', '', {
            confirmButtonText: '关闭',
            type: "success"
          })
        }).finally(() => {
          this.getDataList()
          loading.close()
        })
      }
    }, 1000, {'leading': true, 'trailing': false}),

    closeQcfileSelectDialog() {
      this.qcfileSelectVisible = false
    },

    typeClear(){
      this.dataForm.typeId=''
      this.getDataList()
    },
    typeChange(){
      this.getDataList()
    },
  }
}
</script>