<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div :style="divStyle">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="清单编号" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="清单名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <iframe style="margin-left: -5px" :src="iframeSrc" scrolling="auto" frameborder="0" id="iframe" width="99%" height="99%"></iframe>
    </div>
  </el-card>
</template>

<script>
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        code: '',
        name: ''
      },
      iframeSrc: '',
      divStyle: {
        height: ''
      }
    }
  },
  created () {
    this.setHeight()
    // this.getDataList()
  },
  methods: {
    getDataList() {
      const loading = this.$loading({
        text: '正在加载数据...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        'prjId': this.dataForm.prjId,
        'contractId': this.dataForm.contractId,
        'code': this.dataForm.code,
        'name': this.dataForm.name,
        '_u': 'icloud-合同清单对比.ureport.xml'
      })
      this.iframeSrc = `${window.SITE_CONFIG['apiURL']}/ur2/ureport/preview?${params}`
      window.setTimeout(()=>{
        loading.close()
      }, 3000)
    },
    setHeight () {
      this.$nextTick(() => {
        this.divStyle.height = this.$refs.element.$el.clientHeight - 80 + 'px'
      })
    },
    // 项目、标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    }
  }
}
</script>
