<template>
  <el-dialog v-dialog-drag top="5vh" width="80%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="prjName">
            <el-input v-model="dataForm.prjName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目编号" prop="prjCode">
            <el-input v-model="dataForm.prjCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同名称" prop="contractName">
            <el-input v-model="dataForm.contractName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同金额" prop="contractCost">
            <el-input v-model="dataForm.contractCost" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结算编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结算名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审单位" prop="deliver">
            <el-input v-model="dataForm.deliver" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审日期" prop="deliverDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.deliverDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审计单位" prop="approval">
            <el-input v-model="dataForm.approval" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审定日期" prop="approvalDate">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.approvalDate" style="width: 100%;" readonly></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="奖罚单位" prop="award">
            <el-input v-model="dataForm.award" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="奖罚金额" prop="awardAmount">
            <el-input v-model="dataForm.awardAmount" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="送审金额" prop="deliverAmount">
            <el-input v-model="dataForm.deliverAmount" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审定金额" prop="approvalAmount">
            <el-input v-model="dataForm.approvalAmount" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="初审价" prop="firstReviewPrice">
            <el-input v-model="dataForm.firstReviewPrice" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审计费用" prop="approvalCost">
            <el-input v-model="dataForm.approvalCost" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="累计增减金额" prop="totalChangeAmount">
            <el-input v-model="dataForm.totalChangeAmount" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="增减率" prop="changeRate">
            <el-input v-model="dataForm.changeRate" readonly>
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="其他应扣款" prop="otherDeduction">
            <el-input v-model="dataForm.otherDeduction" readonly>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" placeholder="备注" :autosize="{ minRows: 3,maxRows:8}" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <my-upload-plain :refId="dataForm.id" view></my-upload-plain>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        deliver: '',
        deliverDate: '',
        approvalDate: '',
        award: '',
        approval: '',
        awardAmount: '',
        deliverAmount: '',
        approvalAmount: '',
        firstReviewPrice: '',
        approvalCost: '',
        otherDeduction: '',
        totalChangeAmount: '',
        changeRate: '',
        remark: '',
        contractCost: '',
        contractName: ''
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/completionSettlement/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
