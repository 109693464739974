<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="单位名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item  label="项目名称" prop="prjId">
            <project-select v-model="dataForm.prjId" style="width: 100%;" disabled></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="dataForm.phone" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="传真号码" prop="fax">
            <el-input v-model="dataForm.fax" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model="dataForm.email" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业网站" prop="website">
            <el-input v-model="dataForm.website" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人代表" prop="lawPerson">
            <el-input v-model="dataForm.lawPerson" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册日期" prop="registerDate">
            <el-input v-model="dataForm.registerDate" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码" prop="usco">
            <el-input v-model="dataForm.usco" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="信用代码有效期" prop="uscoDate">
            <el-input v-model="dataForm.uscoDate" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户名称" prop="accountName">
            <el-input v-model="dataForm.accountName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="depositName">
            <el-input v-model="dataForm.depositName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户账号" prop="bankAccount">
            <el-input v-model="dataForm.bankAccount" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注说明" prop="remark">
        <el-input type="textarea" v-model="dataForm.remark" readonly></el-input>
      </el-form-item>
      <el-form-item>
        <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        deptId: '',
        prjId: '',
        name: '',
        phone: '',
        fax: '',
        email: '',
        website: '',
        lawPerson: '',
        registerDate: '',
        usco: '',
        uscoDate: '',
        accountName: '',
        depositName: '',
        bankAccount: '',
        remark: '',
        sortNo: '',
        tenantCode: '',
        delFlag: '',
        creator: '',
        createDate: '',
        updater: '',
        updateDate: '',
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/sys/participantDept/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
