<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :leftWidth="300">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              v-if="treeShow"
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              :load="loadWbsNode"
              lazy
              node-key="id"
              :check-strictly="true"
              :props="treeProps"
              ref="wbsTree"
              :show-checkbox="true"
              :expand-on-click-node="false"
              @check-change="checkChangeHandle"
              @node-click="treeNodeClick"
              :highlight-current="true">
              <span slot-scope="{ node }">
                <span>{{ node.label }}</span>
              </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__qcfilebind">
          <el-form :inline="true">
            <el-form-item>
              <el-button-group>
                <toolbar-button name="选择关键的质检用表" @click="openQcFileSelectModal" v-if="$hasPermission('mps:qcfilebind:bind')" key="1"></toolbar-button>
                <toolbar-button name="提交数据" @click="saveQcFileBindData" v-if="$hasPermission('mps:qcfilebind:bind')" key="2"></toolbar-button>
                <el-tag :class="myShakeClass" v-if="currentSelectedWbs != null && tableData != null && tableData.length > 0 && !selectedNewQcFiles" style="margin-left: 20px">{{ '【' + currentSelectedWbs.name + '】查询到 ' + tableData.length + ' 个质检用表' }}</el-tag>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table
              ref="tbl"
              v-loading="tableLoading"
              :data="tableData"
              row-key="id"
              border
              highlight-current-row
              style="width: 100%;"
              :max-height="tableHeight"
              :row-style="{height: '0px'}"
              :cell-style="{padding: '2px 0'}">
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="code" label="表号"></el-table-column>
            <el-table-column prop="typeId" label="施工阶段" header-align="center" align="center" width="110">
              <template v-slot="{row}">
                <span v-if="row.typeId === 1">开工报告</span>
                <span v-else-if="row.typeId === 2">检验申请批复</span>
                <span v-else-if="row.typeId === 3">交工证书</span>
                <span v-else style="color: #ee0a30">未知</span>
              </template>
            </el-table-column>
            <el-table-column prop="stateId" label="质检状态" header-align="center" align="center" width="100">
              <template v-slot="{row}">
                <span v-if="row.stateId === 0" style="color: rgb(76, 78, 76)">未办理</span>
                <span v-else-if="row.stateId === 1" style="color: #3a14e6">办理中</span>
                <span v-else-if="row.stateId === 2" style="color: #22b23a">已通过</span>
                <span v-else style="color: #ee0a30">未知</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <table-button role="delete" v-if="$hasPermission('mps:qcfilebind:bind')" @click="deleteQcfileBind(scope.row)"></table-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </my-container>
    <!--质检用表选择弹窗-->
    <qcfile-select v-if="qcfileSelectVisible" ref="qcfileSelect" @submit="qcfileSelectSubmitHandle" @close="closeQcfileSelectDialog"></qcfile-select>
  </el-card>
</template>

<script>
import QcfileSelect from './qcfile-select'
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        showEmptyDataWarningMessage: true
      },
      dataForm: {
        prjId: this.$store.state.user.prjId,
        contractId: '',
        subcontractorId: '',
      },
      treeShow: false,
      treeLoading: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        disabled: this.wbsNodeDisabled,
        isLeaf: 'isLeaf'
      },
      tableLoading: false,
      tableData: [],
      tableHeight: 500,
      currentSelectedWbs: null,
      selectMsg: '请先勾选要关联哪些分项工程',
      qcfileSelectVisible: false, // 质检用表选择弹窗
      selectedNewQcFiles: false,

      myShakeClass: '',
      shakeTimer: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  components: {
    QcfileSelect
  },
  methods: {
    // 项目、标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.currentSelectedWbs = null
      this.getWbsTreeData()
      this.getQcfileBindDataList()
    },

    // 加载左侧分部分项工程树
    getWbsTreeData() {
      this.treeLoading = true
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
        this.$http.get('/mps/wbs/children',
            {
              params: {
                pid: "0",
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
          this.treeLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },

    loadWbsNode(node, resolve) {
      if (node.level > 0) {
        let pid = node.data.id
        this.$http.get('/mps/wbs/children',
            {
              params: {
                'pid': pid,
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },

    // 左侧分部分项工程节点点击事件
    treeNodeClick(data, node, ele) {
      this.currentSelectedWbs = data
      this.getQcfileBindDataList()
    },
    // 勾选左侧分部分项工程时也自动查询关联的表格
    checkChangeHandle(data, checked){
      if (checked) {
        this.currentSelectedWbs = data
        this.$refs.wbsTree.setCurrentNode(data)
        this.getQcfileBindDataList()
      }
    },

    // 控制左侧分部分项工程节点是否允许勾选
    wbsNodeDisabled(data, node) {
      if (data.isLeaf) {
        return false
      }
      return true // 不可选择
    },

    // 获取勾选的wbsId集合
    getSelectWbsIds() {
      let ids = []
      let checkedWbsNodes = this.$refs.wbsTree.getCheckedNodes(true)
      if (checkedWbsNodes && checkedWbsNodes.length > 0) {
        for (let i = 0; i < checkedWbsNodes.length; i++) {
          ids.push(checkedWbsNodes[i].id)
        }
      }
      return ids
    },

    // 右侧页面加载已关联的质检用表
    getQcfileBindDataList() {
      // 顶部文字提示信息上下抖动
      this.myShakeClass = 'my-shake'
      if (this.shakeTimer) {
        clearTimeout(this.shakeTimer)
      }
      this.shakeTimer = setTimeout(()=> {
        this.myShakeClass = ''
      }, 1000)
      
      this.tableData = []
      if (this.currentSelectedWbs) {
        this.tableLoading = true
        this.$nextTick(() => {
          this.$http.get('/mps/qcFileBind/list',
              {
                params: {
                  wbsId: this.currentSelectedWbs.id
                }
              }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.tableData = res.data
            if (this.tableData == null || this.tableData.length == 0) {
              //return this.$message.warning('未查询到关联的质检用表')
            }
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            this.tableLoading = false
          })
        })
      }
    },

    // 打开质检用表选择弹窗
    openQcFileSelectModal() {
      let isOk = false;
      if (this.$refs.wbsTree) {
        let checkedWbsNodes = this.$refs.wbsTree.getCheckedNodes(true)
        isOk = checkedWbsNodes && checkedWbsNodes.length > 0
      }
      if (isOk) {
        this.qcfileSelectVisible = true
        this.$nextTick(() => {
          this.$refs.qcfileSelect.dataForm.prjId = this.dataForm.prjId
          this.$refs.qcfileSelect.init()
        })
      } else {
        this.$message.warning(this.selectMsg)
      }
    },
    qcfileSelectSubmitHandle(selectedList) {
      this.selectedNewQcFiles = true
      this.tableData = []
      if (selectedList && selectedList.length > 0) {
        selectedList.forEach(item => {
          this.tableData.push({
            id: null,
            qcfileId: item.id,
            code: item.code,
            name: item.name,
            typeId: item.typeId,
            stateId: 0
          })
        })

        this.$message({
          message: '请不要忘记【提交数据】',
          type: 'warning',
          duration: 4000,
        })
      }
    },
    closeQcfileSelectDialog() {
      this.qcfileSelectVisible = false
    },
    deleteQcfileBind(row) {
      let qcfileId = row.qcfileId
      if (!qcfileId) {
        return this.$message.warning("请选中要删除的质检用表！")
      }

      let wbsCheckNodes = this.$refs.wbsTree.getCheckedNodes(true)
      if (wbsCheckNodes && wbsCheckNodes.length <= 0) {
        return this.$message.warning("请勾选哪些分项要解除与该质检表格的关联！")
      }

      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        let selectWbsIds = this.getSelectWbsIds()
        let data = {
          wbsIds: selectWbsIds,
          qcfileId: qcfileId
        }

        this.$http.put(`mps/qcFileBind/batchDelete`, data).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = this.tableData.filter(item => {
            if (item.id !== row.id) {
              return true
            } else {
              return false
            }
          })
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
          })
        }).catch()
      }).catch()
    },

    clearData() {
      this.$refs.wbsTree.setCheckedKeys([])
      this.$refs.wbsTree.setCurrentKey(null)
      this.tableData = []
    },

    // 提交保存选择的关联关系
    saveQcFileBindData: debounce(function () {
      if (this.tableData.length <= 0) {
        return this.$message.warning('请选择要关联的质检用表')
      }
      let wbsCheckNodes = this.$refs.wbsTree.getCheckedNodes(true)
      if (wbsCheckNodes && wbsCheckNodes.length <= 0) {
        return this.$message.warning(this.selectMsg)
      }
      const loading = this.$loading({
        text: '正在执行，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let qcfiles = []
      for (let i = 0; i < this.tableData.length; i++) {
        qcfiles.push({
          qcfileId: this.tableData[i].qcfileId,
          code: this.tableData[i].code,
          name: this.tableData[i].name,
          typeId: this.tableData[i].typeId,
          stateId: 0
        })
      }
      let selectWbsIds = this.getSelectWbsIds()
      let data = {
        wbsIds: selectWbsIds,
        qcfiles: qcfiles
      }
      this.$http.post('/mps/qcFileBind/batchSave', data).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$alert(res.msg, '', {
            confirmButtonText: '关闭',
            type: "error"
          })
        }
        this.$alert('已成功保存！', '', {
          confirmButtonText: '关闭',
          type: "success"
        })
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}

.my-circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  background-color: #00b95e;
  border-radius: 50%;
}

.my-semi-circle {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #00b95e;
  border-radius: 50%;
}

.my-semi-circle:before {
  content: "";
  position: absolute;
  background: #00b95e;
  top: -1px;
  left: -1px;
  width: 5px;
  height: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #00b95e;
}

.my-empty-circle {
  display: inline-block;
  border: 1px solid #c1c1c1;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  border-radius: 50%;
}

@keyframes shake {
  0% { transform: translate(0, 0); }
  50% { transform: translate(0, 5px); }
  100% { transform: translate(0, 0); }
}
.my-shake {
  animation: shake 0.3s infinite;
}
</style>
